import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
  static targets = ["menu"]

  connect() {
    super.connect()
    this.menuTarget.classList.remove('hidden')
    this.dropdownHeight = this.menuTarget.offsetHeight;
    this.menuTarget.classList.add('hidden')

  }

  hide(event) {
    super.hide(event)
  }

  toggle(event) {

    const dropdownElement = this.menuTarget;
    const triggerElement = event.currentTarget;
    const windowHeight = window.innerHeight;
    const triggerRect = triggerElement.getBoundingClientRect();
    const dropdownHeight = this.dropdownHeight;

    const bottomPosition = triggerRect.top + triggerRect.height + dropdownHeight;
    const topPosition = triggerRect.top - dropdownHeight;
    if (bottomPosition > windowHeight && topPosition > 0) {
      dropdownElement.classList.add('bottom-6');
      // dropdownElement.style.top = `${topPosition}px`;
    } else {
      dropdownElement.classList.remove('bottom-6');
    }

    this.menuTarget.classList.toggle('show')

  }
}