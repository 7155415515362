import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'section', 'sectionNavigationButton', 'chapterNavigationButton',
                     'mobileChapterTitle', 'mobileSectionTitle', 'nextSectionButton', 'previousSectionButton',
                     'backHomeButton', 'orientationwrapper' ]
  static values = { index: Number, chapterIndex: Number, sectionCount: Number, handoverId: Number, disableAutoscroll: Boolean, filtered: Boolean }

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('preview') === 'true') {
      this.indexValue = this.sectionNavigationButtonTargets[0].dataset.sectionIndex
      this.reindexCards()
    } else {
      this.storageName = `handoverReading${this.handoverIdValue}`
      this.storage = JSON.parse(localStorage.getItem(this.storageName))
      this.reindexCards()
      console.log(urlParams.get('section_id'))
      // if there is an anchor  like handovers/2842/read?chapter_id=8407&section_id=24118#quizz-pour-transport-en-commun
      // we create a new click event to navigate to the (for mobile)
      if (window.location.hash) {
        const sectionId = urlParams.get('section_id');
        const sectionIndex = this.sectionNavigationButtonTargets.findIndex(e => e.dataset.sectionId === sectionId)
        
        if (sectionIndex) {
          this.indexValue = sectionIndex
          this.setNavbarState('closed')
        }
        
        if (sectionIndex) {
          this.indexValue = sectionIndex
        }
        this.setNavbarState('closed')
      } else {
        this._restorePreviousOpenedSection()
      }
    }
  }

  restartReading() {
    this.closeNavbar()
  }

  reindexCards() {
    if (this.hasOrientationwrapperTarget) {
      this.orientationwrapperTargets.forEach((wrapper, index) => {
        wrapper.querySelectorAll('[data-navigation-tabs-target="section-navigation-button"]').forEach((card, index) => {
          card.dataset.sectionIndex = index
        })
      })
    } else {
      this.sectionNavigationButtonTargets.forEach((card, index) => {
        card.dataset.sectionIndex = index
      })
    }

  }

  next() {
    this.indexValue += 1
    stopAllPlayingMedias()
  }

  previous() {
    if (this._reachedStart()) { return }
    this.indexValue -= 1
    stopAllPlayingMedias()
  }

  navigateTo(event) {
    if (event.currentTarget.closest('[data-locked="true"]')) { return }
    // if there is an anchor in the url we remove it
    if (window.location.hash) {
      window.location.hash = ''
    }
    stopAllPlayingMedias()
    this.sectionIdValue = event.currentTarget.dataset.sectionId
    console.log(this.sectionIdValue)
    this.indexValue = event.currentTarget.dataset.sectionIndex
    console.log(this.indexValue)
    this.setNavbarState('closed')
  }

  openNavbar() {
    this.setNavbarState('open')
  }

  closeNavbar() {
    this.setNavbarState('closed')
  }

  setNavbarState(status) {
    if(document.querySelector('.read-container-right')) {
      document.querySelector('.read-container-right').dataset.state = status
    }
    document.body.style.overflowY = `${status === "open" ? "hidden" : "visible"}`

    if (document.querySelector('.handover-navbar-mobile')) {

      document.querySelector('.handover-navbar-mobile').dataset.state = status
    }
  }

  indexValueChanged() {
    if (this.indexValue < 0) { return }
    this.chapterIndexValue = this._currentSection() ? this._currentSection().dataset.chapterIndex : 0
    this._setPreviousSections()
    this._setCurrentSection()
    this._setNextSections()
    this._displayCurrentChapterAndSectionInMobileMenu()
    this._navButtonsDisplay()
    this._revealChapterSections()
    this._storeCurrentOpenedSection()
  }

  _setCurrentSection() {
    hideElements(this.sectionTargets)
    showElements(this._currentSection())
    // if (this.disableAutoscrollValue === false) {
    //   window.scrollTo({
    //     top: this._currentSection().getBoundingClientRect().top + window.scrollY,
    //     behavior: 'smooth'
    //   })
    // }

    // We scroll at the top of the window when we change section
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.sectionNavigationButtonTargets.forEach(e => e.dataset.active = false)
    this._currentSectionButton().dataset.active = true

    this._currentChapterButton().classList.remove('previous', 'next')
    this._currentChapterButton().classList.add('current')
  }

  _setPreviousSections() {
    this._previousChapterButtons().forEach((e) => {
      e.classList.add('previous')
      e.classList.remove('current', 'next')
    })
  }

  _setNextSections() {
    this._nextChapterButtons().forEach((e) => {
      e.classList.add('next')
      e.classList.remove('previous', 'current')
    })
  }

  _currentSection() {
    return this.sectionTargets[this.indexValue] || this.sectionTargets[0]
  }

  _currentSectionButton() {
    return this.sectionNavigationButtonTargets.find(e => e.dataset.sectionIndex == this.indexValue)
  }

  _currentChapterButton() {
    return this.chapterNavigationButtonTargets[this.chapterIndexValue]
  }

  _currentChapterId() {
    return this._currentChapterButton().dataset.chapterId
  }

  _currentSectionId() {
    return this._currentSectionButton().dataset.sectionId
  }

  _previousChapterButtons() {
    return this.chapterNavigationButtonTargets.slice(0, this.chapterIndexValue)
  }

  _nextChapterButtons() {
    return this.chapterNavigationButtonTargets.slice(this.chapterIndexValue + 1)
  }

  _displayCurrentChapterAndSectionInMobileMenu() {
    if (this.hasMobileChapterTitleTarget) {
      const chapterName = this._currentChapterButton().dataset.chapterName
      const sectionName = this._currentSectionButton().dataset.sectionName
      this.mobileChapterTitleTarget.innerText = chapterName
      this.mobileSectionTitleTarget.innerText = sectionName
    }
  }

  _reachedStart() {
    return this.indexValue <= 0
  }

  _reachedEnd() {
    return this.indexValue >= this.sectionCountValue - 1
  }

  _navButtonsDisplay() {
    this.previousSectionButtonTargets.forEach(e => e.classList.toggle('hidden', this._reachedStart()) )
    this.nextSectionButtonTargets.forEach(e => e.classList.toggle('hidden', this._reachedEnd()) )
    this.backHomeButtonTargets.forEach(e => e.classList.toggle('hidden', !this._reachedEnd()) )
  }

  _revealChapterSections() {
    const event = new CustomEvent(`navigationRevealChapter${this._currentChapterId()}`, {});
    window.dispatchEvent(event)
  }


  // _storeCurrentOpenedSection() {
  //   localStorage.setItem(this.storageName, this.indexValue)
  //   window.location.hash = this.indexValue
  // }

  _storeCurrentOpenedSection() {
    // Save the current section index in localStorage
    localStorage.setItem(this.storageName, JSON.stringify(this.indexValue));

    // Update the URL with the query parameters without reloading the page
    const url = new URL(window.location);
    url.searchParams.set('chapter_id', this._currentChapterId());
    url.searchParams.set('section_id', this._currentSectionId());
    window.history.pushState({}, '', url);
  }

  // _restorePreviousOpenedSection() {
  //   if (window.location.hash) {
  //     this.indexValue = window.location.hash.substr(1)
  //   } else if (this.storage) {
  //     this.indexValue = this.storage
  //   } else {
  //     this.indexValue = 0
  //   }
  // }

  _restorePreviousOpenedSection() {
    // Extract the index from the URL's query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const chapterId = urlParams.get('chapter_id');
    const sectionId = urlParams.get('section_id');

    // If the URL contains the query parameters, use them to set the current section
    if (chapterId && sectionId && this.sectionNavigationButtonTargets.findIndex(e => e.dataset.sectionId === sectionId)) {
      const chapterIndex = this.chapterNavigationButtonTargets.findIndex(e => e.dataset.chapterId === chapterId)
      const sectionIndex = this.sectionNavigationButtonTargets.findIndex(e => e.dataset.sectionId === sectionId)
      this.indexValue = sectionIndex
      this.chapterIndexValue = chapterIndex
    } else if (this.storage && !this.filteredValue) {
      this.indexValue = this.storage
    } else {
      this.indexValue = 0
    }
  }
}
