import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['palette', 'colorPalette', 'label'];

  connect() {
    this.trixToolbar = this.element.closest('trix-toolbar')
    this.trixeditorElement = this.trixToolbar.nextSibling
    this.trixEditor = this.trixeditorElement.editor
  }



  selectDot(e) {
    this.labelTargets.forEach((label) => {
      label.setAttribute('aria-checked', false);
    });
    e.currentTarget.setAttribute('aria-checked', true);
    const radio = e.currentTarget.querySelector('input[type="radio"]');
    radio.checked = true;
    const dotColor = e.currentTarget.dataset.color
    this.dispatchColor(dotColor)
  }

  openInput(e) {
    const radio = this.colorPaletteTarget
    radio.checked = true
    radio.focus()
    radio.click()

  }


  updateColor(e) {
    const colorPaletteColor = this.colorPaletteTarget.value
    this.dispatchColor(colorPaletteColor)
  }

  dispatchColor(color) {
    this.paletteTarget.style.color = color
    this.paletteTarget.dataset.color = color

    dispatchCustomEvent('colorpicked', {
        color: color,
        editor: this.trixEditor,
      }
    )
  }

}
