import { useIntersection } from 'stimulus-use'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ ]

  connect() {
    useIntersection(this, { threshold: 0.5 })
  }

  appear(entry) {
    const handoverReadingController = this.application.getControllerForElementAndIdentifier(this.element.closest('#handover-reading-controller'), 'handover-reading');
    if (entry.target.dataset.countedOnce === 'true') { return }
    if (handoverReadingController) { 
      handoverReadingController.countView(entry.target)

    }
    entry.target.dataset.countedOnce = 'true'
  }
}
