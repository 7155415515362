// import I18n from 'i18n-js';
//import { I18n } from 'emoji-mart';
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {

  static targets = [
    'format', 'correctAnswer', 'selectText', 'possibleFormat',
    'questionHeader', 'aiBtn', 'aiAnswerBtn', 'explanationText', 'explanationField',
    'correctedText', 'correctedField', "strictnessBtn", "strictnessInput", "strictnessText",
    'labelSection', 'answersContainer', 'answersContainerSection', 'explanationSection',
    'answersButtonsContainer'
  ]

  connect() {
    this.mutateForm(false)
    this.contentController = this.application.getControllerForElementAndIdentifier(this.element.closest('.content-writing-modal'), 'media-creation--submit')
    this.quizzController = this.application.getControllerForElementAndIdentifier(this.element.closest('.writing-modal-content-quizz'), 'media-creation--quizz')
  }

  noMoreEnterKey(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
    }
    if(e.currentTarget.classList.contains('question-label')) {
      if (this.element.querySelector('.toggle-answers-container').classList.contains('hidden')) { this.element.querySelector('.toggle-answers-header').click() }
      this.element.querySelector('.aiAnswersBtn').dataset.disabled = false
    }
  }

  setStrictness(e) {
    this.strictnessBtnTargets.forEach((btn) => {btn.dataset.selected = false})
    this.strictnessTextTargets.forEach((txt) => {txt.dataset.selected = false})
    e.currentTarget.dataset.selected = true
    const relevantText = this.element.querySelector(`[data-media-creation--quizz-question-target="strictnessText"][data-strictness="${e.currentTarget.dataset.strictness}"]`)
    relevantText.dataset.selected = true
    this.strictnessInputTarget.value = e.currentTarget.dataset.strictness
  }


// resizeLongTextFields() {
 //   const zone = this.formatTarget.value === "open" ? this.correctedFieldTarget : this.explanationFieldTarget
//    console.log(zone)
 //   zone.style.height = "1px";
//    zone.style.height = (50 + zone.scrollHeight) + "px"
//  }

  showBtnsHideAi(e) {
      if( e.currentTarget.value !== '') {
        this.aiBtnTarget.dataset.disabled = true
        this.aiAnswerBtnTarget.dataset.disabled = false
        this.contentController.revealButtons()
      }
      else {
        this.aiBtnTarget.dataset.disabled = false
        this.aiAnswerBtnTarget.dataset.disabled = true
      }
  }

  cancelAiGeneration(){
    this.quizzController.disableBroadcast()
    this.element.querySelector(`.quizzable-contents-index[data-question-or-answer='answers']`).dataset.expanded = false
    this.answersContainerTarget.dataset.expanded = true
    this.answersButtonsContainerTarget.dataset.expanded = true
  }

  generateFakeAnswers(e) {
    this.quizzController.enableBroadcast()
    const emptyState = this.element.querySelector(`.quizzable-contents-index[data-question-or-answer='${e.currentTarget.dataset.questionOrAnswer}']`)
    if (emptyState) {
      const expandedBool = emptyState.dataset.expanded === "true"
      if (!expandedBool) {this.quizzController.enableBroadcast()}
      emptyState.dataset.expanded = !expandedBool
      this.answersContainerTarget.dataset.expanded = expandedBool
      this.answersButtonsContainerTarget.dataset.expanded = expandedBool
    } else {
      this.fetchAnswersLoadingState(e.currentTarget)
      this.quizzController.enableBroadcast()
    }
    const formData = new FormData()
    // nb_of_answers = params[:nb_of_answers]
    // question_label = params[:question_label]
    // good_answer = params[:good_answer]
    formData.append('question_label', this.element.querySelector('.question-label').value)
    formData.append('nb_of_answers', 3)
    fetchWithToken(`${e.currentTarget.dataset.generateUrl}`, {
      method: "PATCH",
      body: formData,
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors) {console.log(data.errors)}
    })
  }

  fetchAnswersLoadingState(button) {
    fetchWithToken(`${button.dataset.emptyStateUrl}?type=${button.dataset.questionOrAnswer}&multi_select=true&loading_state=true`, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if (data.answer) {
        this.answersContainerTarget.dataset.expanded = "false"
        this.answersButtonsContainerTarget.dataset.expanded = "false"
        this.answersContainerTarget.insertAdjacentHTML('beforebegin', data.answer)
      }
    })
  }

  proposeAi(e) {
    const questionOrAnswer = e ? e.currentTarget.dataset.questionOrAnswer : 'question'
    const quizzableContentsIndex = this.element.querySelector(`.quizzable-contents-index[data-question-or-answer='${questionOrAnswer}']`)
    //const aiBtn = e.currentTarget


    if (quizzableContentsIndex) {
      const expandedBool = quizzableContentsIndex.dataset.expanded === "true"
      if (!expandedBool) {this.quizzController.enableBroadcast()}
      quizzableContentsIndex.dataset.expanded = !expandedBool
      this.labelSectionTarget.dataset.hidden = !expandedBool
      this.answersContainerSectionTarget.dataset.hidden = !expandedBool
      this.explanationSectionTarget.dataset.hidden = !expandedBool
    } else {
      this.quizzController.enableBroadcast()
      fetchWithToken(`${e.currentTarget.dataset.url}?type=${questionOrAnswer}`, {
        method: "GET",
        headers: { "Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        if (data.question) {
          this.questionHeaderTarget.insertAdjacentHTML('afterend', data.question)
          //aiBtn.innerText = I18n.t('js.quizzs.close_ai')
          this.labelSectionTarget.dataset.hidden = true
          this.answersContainerSectionTarget.dataset.hidden = true
          this.explanationSectionTarget.dataset.hidden = true
        } else if (data.answer) {
          this.correctedTextTarget.insertAdjacentHTML('afterend', data.answer)
          //aiBtn.innerText = I18n.t('js.quizzs.close_ai')
        }
      })
    }
  }

  hideAiBtn() {
    this.aiBtnTarget.dataset.disabled = true
  }

  changeButtonFormat(emptyChecks, instructions) {
    this.element.querySelectorAll('.correct-input').forEach((target) => {

      if (emptyChecks) { target.checked = false }
      target.type = this.formatTarget.value === 'single_choice' ? 'radio' : 'checkbox'
    })
    this.selectTextTarget.innerText = instructions
  }

  selectFormat(card) {
    this.possibleFormatTargets.forEach(format => format.dataset.selected = false)
    card.dataset.selected = true
    this.formatTarget.value = card.dataset.format
  }

  changeForm(e) {
    const card = e.currentTarget
    const answers = this.element.querySelectorAll('.answer-card:not(.hidden)')
    switch (card.dataset.format) {
      case 'single_choice':
        this.selectFormat(card);
        this.addOrDeleteAnswers(4);
      break;
      case 'multiple_choices':
        this.selectFormat(card);
        this.addOrDeleteAnswers(4);
      break;
      case 'open':
        if(answers.length > 1 && answers[1].querySelector('.answer-label').value !== '') {
          Swal.fire({
            title: I18n.t('js.quizzs.switch_to_open_question_title'),
            text: I18n.t('js.quizzs.switch_to_open_losing_answers'),
            icon: 'warning',
            confirmButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.confirm_button_text'),
            showCancelButton: true,
            cancelButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.cancel_button_text')
          }).then((result) => {
            if (result.isConfirmed) {
              this.selectFormat(card);
              this.addOrDeleteAnswers(1);
            }
          })
        } else {
          this.selectFormat(card);
          this.addOrDeleteAnswers(1);
        }
      break;
      case 'matching':
        if(answers.length > 1 && answers[1].querySelector('.answer-label').value !== '') {
          Swal.fire({
            title: I18n.t('js.quizzs.switch_to_matching_question_title'),
            text: I18n.t('js.quizzs.switch_to_open_losing_answers'),
            icon: 'warning',
            confirmButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.confirm_button_text'),
            showCancelButton: true,
            cancelButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.cancel_button_text')
          }).then((result) => {
            if (result.isConfirmed) {
              this.selectFormat(card);
              this.addOrDeleteAnswers(1);
            }
          })
        } else {
          this.selectFormat(card);
          this.addOrDeleteAnswers(1);
        }
      break;
      case 'ordering':
        this.selectFormat(e.currentTarget);
        this.addOrDeleteAnswers(4);
      break;
    }
  }

  mutateForm(emptyChecks) {
    this.element.dataset.format = this.formatTarget.value
    const labelInputs = this.element.querySelectorAll('.answer-label')
    const correctChecks = this.element.querySelectorAll('.correct-input')

    switch (this.formatTarget.value) {
      case 'single_choice':
          this.addOrDeleteMatchingCards(0)
          correctChecks.forEach((correct) => showElements(correct))
          labelInputs.forEach((input) => {input.setAttribute('placeholder', I18n.t('js.quizzs.write_your_answer_here'))})
          this.changeButtonFormat(emptyChecks, I18n.t('js.quizzs.select_answer'))
      break;
      case 'multiple_choices':
        this.addOrDeleteMatchingCards(0)
        correctChecks.forEach((correct) => showElements(correct))
        labelInputs.forEach((input) => {input.setAttribute('placeholder', I18n.t('js.quizzs.write_your_answer_here'))})
        this.changeButtonFormat(emptyChecks, I18n.t('js.quizzs.select_answers'))
      break;
      case 'open':
        this.addOrDeleteMatchingCards(0)
        correctChecks.forEach((correct) => hideElements(correct))
        labelInputs.forEach((input) => {input.setAttribute('placeholder', I18n.t('js.quizzs.add_instructions_for_open'))})
        this.changeButtonFormat(emptyChecks, I18n.t('js.quizzs.open_instructions'))
      break;
      case 'matching':
        correctChecks.forEach((correct) => hideElements(correct))
        labelInputs.forEach((input) => {input.setAttribute('placeholder', I18n.t('js.quizzs.add_instructions_for_open'))})
        this.changeButtonFormat(emptyChecks, I18n.t('js.quizzs.matching_instructions'))
        this.addOrDeleteMatchingCards(4)
      break;
      case 'ordering':
        this.addOrDeleteMatchingCards(0)
        correctChecks.forEach((correct) => hideElements(correct))
        labelInputs.forEach((input) => {input.setAttribute('placeholder', I18n.t('js.quizzs.write_your_answer_here'))})
        this.changeButtonFormat(emptyChecks, I18n.t('js.quizzs.order_instructions'))
      break;
    }
  }

  addOrDeleteMatchingCards(expectedNumber) {
    const answer = this.element.querySelectorAll('.answer-card:not(.hidden)')[0]
    const pairCards = answer.querySelectorAll('.matching-answer-card:not(.hidden)')
    if(expectedNumber === 0) {
      if(pairCards) {pairCards.forEach((card) => { card.querySelector('.remove-pair-link').click(); })}
    } else {
      const gap = expectedNumber - pairCards.length
      if (gap > 0) {
        for(var i = 0; i < gap; i++){
            answer.querySelector('.add-pair-link').click();
        }
      }
    }
  }

  addOrDeleteAnswers(expectedNumber) {
    const answers = this.element.querySelectorAll('.answer-card:not(.hidden)')
    if(expectedNumber === 1 ) {
      answers.forEach((card, i) => {
        if (i > 0) { card.querySelector('.remove-answer-link').click(); }
      })
    } else {
      const gap = expectedNumber - answers.length
      if (gap > 0) {
        for(var i = 0; i < gap; i++){
            this.element.querySelector('.add-answer-link').click();
        }
      }
    }
    this.mutateForm(true)
    return "ok"
  }

  addPair(e) {
    const time = new Date().getTime()
    const timeShifted = time * 2
    const linkId = e.currentTarget.dataset.id
    const secondId = e.currentTarget.dataset.secondid
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    const secondRegexp = secondId ? new RegExp(secondId, 'g') : null
    const newFields = regexp ? e.currentTarget.dataset.fields.replace(regexp, time) : null
    const finalFields = secondRegexp ? newFields.replace(secondRegexp, timeShifted) : newFields

    if (finalFields) {
      const answerCard = e.currentTarget.closest('.answer-card')
      e.currentTarget.insertAdjacentHTML('beforebegin', finalFields)
      this.assignPlacementandPositionForPairs(answerCard)
    }
  }

  removePair(e) {
    const card = e.currentTarget.closest('.matching-answer-card')
    const answerCard = e.currentTarget.closest('.answer-card')
    card.classList.add('hidden')
    const deleteFields = card.querySelectorAll('.deletion')
    if (deleteFields) {
      deleteFields.forEach((deleteField) => {deleteField.value = true})
    } else {
      card.remove()
    }
    this.assignPlacementandPositionForPairs(answerCard)
  }

  assignPlacementandPositionForPairs(card) {
    card.querySelectorAll('.matching-answer-card:not(.hidden)').forEach((pair, pair_index) => {
      const matchingPoss = pair.querySelectorAll('.matching-card')
      matchingPoss.forEach((match, match_index) => {
        const placement = match_index == 0 ? 'left' : 'right'
        match.querySelector('.matching-card-position-index').value = pair_index + 1
        match.querySelector('.matching-card-placement-index').value = placement
      })
    })
  }

  addAnswer(e) {
    const time = new Date().getTime()
    const linkId = e.currentTarget.dataset.id
    const question_id = e.currentTarget.dataset.question
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    const newFields = regexp ? e.currentTarget.dataset.fields.replace(regexp, time) : null


    if (newFields) {
      const container = this.element.querySelector('.answers-container')
      container.insertAdjacentHTML('beforeend', newFields)
      switch(this.formatTarget.value) {
        case "single_choice":
          container.querySelectorAll('.correct-input').forEach((target) => {target.type = 'radio'})
          break;
        case "ordering":
          container.querySelectorAll('.correct-input').forEach((target) => {target.classList.add('hidden')})
          break;
        default:
          null
      }
      this.assignPositionsInContainer(container)
    }
  }

  assignPositionsInContainer(container) {
    container.querySelectorAll('.answer-card:not(.hidden)').forEach((card, i) => {
    card.querySelector('.answer-card-position-index').value = i + 1
    })
      // attention, ne pas compter les marked for deletion...
  }


  removeAnswer(e) {
    const card = e.currentTarget.closest('.answer-card')
    const container = e.currentTarget.closest('.question-form')
    card.classList.add('hidden')
    const deleteField = card.querySelector('.deletion')
    if (deleteField) {
      deleteField.value = true
    } else {
      card.remove()
    }
    this.assignPositionsInContainer(container)
    this.showBtnsHideAi
  }

  checkFormat(e) {
    if (this.formatTarget.value === 'single_choice') {
      this.correctAnswerTargets.forEach((target) => {target.checked = false})
      e.currentTarget.checked = true
    }
  }

  selectImage(e) {
    const id = e.currentTarget.dataset.id
    this.element.querySelector(`.imageinput[data-id="${id}"]`).click()
  }

  displayImage(e) {
    const id = e.currentTarget.dataset.id
    const imagePreview = this.element.querySelector(`.imagepreview[data-id="${id}"]`)
    const imageBtn = this.element.querySelector(`.imagebutton[data-id="${id}"]`)
    const reader = new FileReader();
    reader.onload = (ev) => {
      imagePreview.src = ev.currentTarget.result
      showElements(imagePreview)
      hideElements(imageBtn)
    }
    reader.readAsDataURL(e.currentTarget.files[0])
  }

}
