import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Swal from 'sweetalert2'
import Rails from '@rails/ujs'

export default class extends CheckboxSelectAll {
  static targets = ["checkboxAll", "checkbox", "actionBtn", 'actionBtnCounter']

  toggleActions(e) {
    if (this.checked.length > 0) {
      this.actionBtnTargets.forEach((btn) => btn.classList.remove('hidden'))
      this.actionBtnCounterTarget.innerText = `(${this.checked.length})`
    } else {
      this.actionBtnTargets.forEach((btn) => btn.classList.add('hidden'))
    }
  }

  toggle(e) {
    super.toggle(e)
    this.toggleActions(e)
  }

  destroy(e) {
    e.preventDefault()
    let data = new FormData()
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    
    Swal.fire({
      title: I18n.t('js.notifications.bulk_delete.are_you_sure'),
      text: I18n.t('js.notifications.bulk_delete.no_revert_count', { count: this.checked.length }),
      icon: 'warning',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          url: '/notifications/bulk',
          type: 'DELETE',
          data: data,
          success: (response) => {
            this.checked.forEach((checkbox) => checkbox.closest('tr').remove())
            this.refresh()
            this.actionBtnTargets.forEach((btn) => btn.classList.add('hidden'))
          },
          error: (response) => {
            Swal.fire(
              'Error!',
              'Something went wrong.',
              'error'
            )
          }
        })
      }
    })
  }

  markAllAsInteracted(e) {
    e.preventDefault()
    let data = new FormData()
    data.append("all", true)

    Rails.ajax({
      url: '/notifications/bulk',
      type: 'PATCH',
      data: data,
      success: (response) => {
        this.checked.forEach((checkbox) => checkbox.closest('tr').remove())
        this.refresh()
        this.actionBtnTargets.forEach((btn) => btn.classList.add('hidden'))
      },
      error: (response) => {
        Swal.fire(
          'Error!',
          'Something went wrong.',
          'error'
        )
      }
    })
  }
  
  markAsInteracted(e) {
    e.preventDefault()
    let data = new FormData()
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))

    Rails.ajax({
      url: '/notifications/bulk',
      type: 'PATCH',
      data: data,
      success: (response) => {
        this.checked.forEach((checkbox) => checkbox.closest('tr').remove())
        this.refresh()
        this.actionBtnTargets.forEach((btn) => btn.classList.add('hidden'))
      },
      error: (response) => {
        Swal.fire(
          'Error!',
          'Something went wrong.',
          'error'
        )
      }
    })
  }
}