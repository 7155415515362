import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [
    'status'
  ]

  connect() {
    this.contentCard = this.element.closest('.writing-content')
  }

  update(event) {
    const previousStatus = this.statusTarget.dataset.status
    const status = event.params.status
    if (previousStatus === status) return

    const url    = event.params.url
    const data = new FormData
    const options = {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: data
    }

    data.append(`content[status]`, status)

    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      if (data.errors && data.errors.length > 0) {
        Swal.fire({
          title: 'Erreur',
          text: data.errors,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        this.contentCard.classList = []
        this.contentCard.classList.add('writing-content', data.status)
        this.statusTarget.dataset.status = status
        this.statusTarget.outerHTML = data.status_partial

        const eventType = data.status == 'done' ? 'done' : 'todo'

        if (this.#shouldDispatchEvent(previousStatus, status)) dispatchCustomEvent(`contents:${eventType}`)
      }
    })
  }

  #shouldDispatchEvent(previousStatus, status) {
    const prev = previousStatus === 'done' ? 'done' :'todo'
    const next = status === 'done' ? 'done' :'todo'

    return prev !== next
  }
}
