import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'searchInput',
    'results',
    'url',
    'checkbox',
    'searchForm',
    'createAliasBtn',
    'createAliasForm',
    'card'
  ]

  search(event) {
    event.preventDefault()
    event.stopPropagation()

    const url = `${this.searchFormTarget.action}&query=${this.searchInputTarget.value}`
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      this.fetchResults(url)
    }, 400)
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.resultsTarget.innerHTML = data
    })
  }

  insertSpinner(target) {
    this.resultsTarget.innerHTML = '<div class="h-72 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
  }

  selectContent(event) {
    // We remove the disabled attribute from the create alias button
    // if their is at least one selected content
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      // We add a blue border to the card of the selected content
      this._toggleBlueBorder()
      
      this.createAliasBtnTarget.removeAttribute('disabled')
      let nbSelectedContent = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
      this.createAliasBtnTarget.innerText = I18n.t('js.content_alias.create_alias_count', {count: nbSelectedContent})
    } else {
      // We remove the blue border from the selected content
      this._toggleBlueBorder()
      event.currentTarget.classList.remove('border-blue-500')
      this.createAliasBtnTarget.setAttribute('disabled', true)
      this.createAliasBtnTarget.innerText = I18n.t('js.content_alias.create_alias')
    }
  }

  createAlias(event) {
    const formData = new FormData(this.createAliasFormTarget)

    fetch(this.createAliasFormTarget.action, {
      method: "POST",
      body: formData,
      headers: { "Accept": "application/json" },
    })
    .then(response => response.text())
    .then((data) => {
      // We refresh the page
      window.location.reload()
    })
  }

  _toggleBlueBorder() {
    this.cardTargets.forEach((card) => {
      if (card.querySelector('input[type="checkbox"]').checked) {
        card.classList.add('border-blue-500')
      } else {
        card.classList.remove('border-blue-500')
      }
    })
  }
}