import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {


  static targets = ['wrapper', 'button']
  static values = { fetchButtonUrl: String }

  connect() {
    this.foldedState = this._checkCookie()
  }

  fold() {
    var fetchFold = this.foldedState === 'folded' ? 'full' : 'folded'
    this.wrapperTargets.forEach((wrapper) => {
      var url = `${wrapper.dataset.contentLoaderUrlValue}&folded=${fetchFold}`
      this.replaceCardWithAnimation(wrapper, url)
    })
    this.foldedState = this.foldedState === 'folded' ? 'full' : 'folded'
    this.replaceFoldingButton()
    this._setCookie(this.foldedState)
  }

   replaceCardWithAnimation(wrapper,url) {
    if (wrapper.querySelector('.content-card')) {
      fadeHideElements(wrapper.querySelector('.content-card'))
    }
    fetchWithToken(url, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        wrapper.classList.remove('animate-slide')
        wrapper.classList.add('opacity-0')
        wrapper.innerHTML = data.partial
        wrapper.classList.replace('opacity-0', 'animate-slide')
    })
  }

  replaceFoldingButton() {
    var foldUrl = `${this.fetchButtonUrlValue}?folded=${this.foldedState}`
    fetchWithToken(foldUrl, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        this.buttonTarget.classList.remove('animate-pop')
        this.buttonTarget.classList.add('opacity-0')
        this.buttonTarget.innerHTML = data.button
        this.buttonTarget.classList.replace('opacity-0', 'animate-pop')
    })
  }

  _setCookie(value) {
    const cookie = `folded=${value};expires=${this._daysFromNow(
      365
       )};path=/;SameSite=Strict;Secure`;
    document.cookie = cookie;
  }


  _daysFromNow(days) {
    return new Date(Date.now() + days * 86400000).toUTCString();
  }

  _checkCookie() {
    const cookie = document.cookie
        .split(";")
        .filter((item) =>
          item.trim().startsWith(`folded=`)
        )[0];
      if (cookie) {
        return cookie.trim().split("=")[1];
      } else {
        return "folded";
      }
    }


  // _checkCookie() {
  //   const cookie = document.cookie
  //     .split(";")
  //     .filter((item) =>
  //       item.trim().startsWith(`mediaDevicesAccessAllowed=`)
  //     )[0];
  //   if (cookie) {
  //     return cookie.trim().split("=")[1] === "true";
  //   } else {
  //     return false;
  //   }
  // }

  // _setCookie(allowed) {
  //   const cookie = `mediaDevicesAccessAllowed=${allowed};expires=${this._daysFromNow(
  //     365
  //   )};path=/;SameSite=Strict;Secure`;
  //   document.cookie = cookie;
  // }
}
