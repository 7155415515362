import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // We launch the print dialog box when the page is loaded
    window.print();
    const primaryButton = document.getElementById('primaryBtn');

    // We launch the print dialog box when the primary button is clicked (in case the user has disabled the automatic print)
    primaryButton.addEventListener('click', () => {
      window.print();
    });
  }
}