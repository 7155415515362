import { Controller } from "stimulus";
import { fetchWithToken } from "../application/stimulus_helper";
import debounce from "lodash/debounce";
import Swal from "sweetalert2";

export default class extends Controller {
  static values = {
    threshold: { type: Number, default: 2000 },
  };

  connect() {
    this.debounceAutoSave = debounce(
      this.debounceAutoSave,
      this.thresholdValue
    ).bind(this);
    this.element.addEventListener("input", this.debounceAutoSave.bind(this));
    // TODO? this.displayLoadingSpinner = debounce(this.displayLoadingSpinner, 1500).bind(this)
  }

  debounceAutoSave(event) {
    const data = new FormData();

    data.append(`${this.element.name}`, this.element.value);
    data.append("class_name", this.element.name.match(/^.+(?=\[)/));
    data.append("input_name", this.element.name.match(/(?=\[).+(?=\])/));

    const url = `/autosave_input/${this.element
      .closest("form")
      .id.match(/\d+$/)}`;
    const options = {
      method: "PATCH",
      headers: { Accept: "application/json" },
      body: data,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (!data.updated) {
          Swal.fire({
            title: I18n.t("js.sweet_alerts.autosave.error.title"),
            text: data.errors,
            icon: "warning",
            confirmButtonColor: "var(--danger)",
            confirmButtonText: I18n.t("js.sweet_alerts.autosave.error.ok"),
          });
        }
      });
  }
}
