import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "link", "emptyState"]
  static classes = ["active"]

  navigateTo(event) {
    event.preventDefault()
    const target = event.target.closest("a")
    this.toggleActive(target)
    if (this.hasEmptyStateTarget) {
      this.mainTarget.innerHTML = this.emptyStateTarget.innerHTML
    }
    this.fetchPartial(target)
  }

  fetchPartial(target) {
    const url = target.href

    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
      .then(response => response.text())
      .then(html => {
        this.mainTarget.innerHTML = html
        // if there are scripts in the partial (for ex chartkick), they won't be executed
        // so we need to execute them manually
        const scripts = this.mainTarget.querySelectorAll("script")
        scripts.forEach(script => {
          // eval(script.text)
          new Function(script.text)()
        })
        history.pushState({}, "", url)
      })
  }

  toggleActive(target) {
    const active = target.dataset.navigationActive === "true"
    if (!active) {
      this._resetActive()
      target.dataset.navigationActive = true
      this.activeClasses.forEach(activeClass => {
        target.classList.add(activeClass)

        this.activeClasses.forEach(activeClass => {
          // remove active class from neighboring links
          const siblings = target.closest("div").querySelectorAll("a")
          siblings.forEach(sibling => {
            if (sibling !== target) {
              sibling.classList.remove(activeClass)
            }
          })
        })
      })
    }

  }

  _resetActive() {
    this.linkTargets.forEach(link => {
      link.dataset.navigationActive = false
      console.log(this.activeClass)
      link.classList.remove(this.activeClass)
    })
  }
}
