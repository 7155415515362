import { Controller } from 'stimulus';
import { Zoom } from 'swiper';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [
    'chapterSummaryCard',
    'sectionSummaryCard',
    'sectionCard',
    'chapterRatioWrapper',
    'sectionSummaryCardContainer'
  ]
  static values = {
    openFirstSection: Boolean,
    filtered: Boolean,
    openChapters: Boolean,
    firstSectionId: Number,
    handoverId: Number,
  }

  connect() {
    this.#addListeners()
    this.#observeSections()
    this.#observeChapters()

    this.storageName = `handoverReading${this.handoverIdValue}`
    const urlParams = new URLSearchParams(window.location.search);
    const storedSectionIndex = urlParams.get('section_id') ? this.sectionCardTargets.findIndex(e => e.dataset.sectionId == urlParams.get('section_id')) : null;

    if (storedSectionIndex !== null && this.sectionSummaryCardTargets.find(e => e.dataset.sectionId == this.sectionCardTargets[storedSectionIndex].dataset.sectionId)) {
      this.sectionId = this.sectionCardTargets[storedSectionIndex].dataset.sectionId
      this.showSection(this.sectionId)
      this.#openChapters(this.sectionId)
    } else {
      if (this.openFirstSectionValue) this.showSection(this.firstSectionIdValue)
      if (this.openChaptersValue) this.#openChapters(this.firstSectionIdValue)
    }
    console.log('hi I am the navigation controller')
    console.log(document.querySelector('.forceopen'))
    if (document.querySelector('.forceopen')) {
      console.log('got it')
    this.setNavbarState("closed") }
  }

  disconnect() {
    this.#removeListeners()
  }

  goTo(event) {
    this.setNavbarState("closed")
    this.showSection(event.params.sectionId)
  }

  showSection(id) {
    this.currentSectionId    = id
    const sectionCard        = this.sectionCardTargets.find(e => e.dataset.sectionId == id)
    const sectionSummaryCard = this.sectionSummaryCardTargets.find(e => e.dataset.sectionId == id)

    const chapterSummaryCard = sectionSummaryCard.closest('.writing-summary-chapter-card')

    this.sectionSummaryCardTargets.forEach(e => e.classList.remove('active'))
    sectionSummaryCard.classList.add('active')
    chapterSummaryCard.classList.add('active')

    hideElements(this.sectionCardTargets)
    showElements(sectionCard)
    this._storeCurrentOpenedSection(id)
  }


  openNavbar() {
    this.setNavbarState("open");
  }

  setNavbarState(status) {
    document.body.style.overflowY = `${status === "open" ? "hidden" : "visible"}`

    if (document.querySelector(".write-container-right")) {
      document.querySelector(".write-container-right").dataset.state = status;
    }

    if (document.querySelector(".handover-navbar-mobile")) {
      document.querySelector(".handover-navbar-mobile").dataset.state = status;
    }
  }

  toggleChapter(event) {
    event.currentTarget.closest('.writing-summary-chapter-card').classList.toggle('active')
  }

  openDrawer(drawer) {
    drawer.dataset.status = "open";
    drawer.style.maxHeight = drawer.scrollHeight + "px";
    drawer
      .querySelectorAll(".sections-navigation-summary-items")
      .forEach((item, index) => {
        setTimeout(() => {
          item.style.opacity = 1;
          item.classList.add("translate-x-0");
          if (item.classList.contains("-translate-x-14")) {
            item.classList.remove("-translate-x-14");
          }
        }, 150 * index); // Décalage de 100ms par élément
      });
  }

  closeDrawer(drawer) {
    drawer.dataset.status = "closed";
    drawer.style.maxHeight = 0;
    drawer
      .querySelectorAll(".sections-navigation-summary-items")
      .forEach((item, index) => {
        setTimeout(() => {
          item.style.opacity = 0;
          item.classList.add("-translate-x-14");
          if (item.classList.contains("translate-x-0")) {
            item.classList.remove("translate-x-0");
          }
        }, 50 * index); // Décalage de 100ms par élément
      });
  }

  editTitle(ev) {
    const id = ev.currentTarget.dataset.sectionId
    const sectionSummaryCard = this.sectionSummaryCardTargets.find(e => e.dataset.sectionId == id)
    if (sectionSummaryCard) {
      const titleToUpdate = sectionSummaryCard.querySelector('.section-summary-card-name')
      titleToUpdate.innerText = ev.currentTarget.value
    }
  }

  #observeSections() {
    const observer = new MutationObserver((changes) => {
      changes.forEach(change => {
         if (change.addedNodes.length > 0) {
          const sectionId = change.addedNodes[0].dataset.sectionId
          this.showSection(sectionId)
         } else if (change.removedNodes.length > 0) {
          const deletedNode = change.removedNodes[0]
          const chapterId = deletedNode.dataset.chapterId
          const cardContainer = this.sectionSummaryCardContainerTargets.find(e => e.dataset.chapterId == chapterId )
          const firstSectionCard = cardContainer.querySelector('.writing-summary-section-card')
          if (firstSectionCard) {
            const sectionId = firstSectionCard.dataset.sectionId
            this.showSection(sectionId)
            this.updateRatios()
          } else {
            hideElements(this.sectionCardTargets)
            const ratios = this.#chapterCompletionRatio(chapterId)
            ratios.numerator.innerText = 0
            ratios.denominator.innerText = 0
            ratios.wrapper.dataset.status = "false"
          }
         }
        });
      });
      this.sectionSummaryCardContainerTargets.forEach( (container) => {
        observer.observe(container, { childList : true });
      })
  }

  #observeChapters() {
    const observerTwo = new MutationObserver((changes) => {
      changes.forEach((change) => {
          const chapterId = change.target.dataset.chapterId
          const summaryCardContainer = this.sectionSummaryCardContainerTargets.find(e => e.dataset.chapterId == chapterId )
          const sectionSummaryCard = summaryCardContainer.querySelector('.writing-summary-section-card.active')
          const sectionId = sectionSummaryCard.dataset.sectionId
          const sectionCard = this.sectionCardTargets.find(e => e.dataset.sectionId == sectionId)
          if (sectionCard && sectionCard.querySelector('.section-card-chapter-name')) {
            sectionCard.querySelector('.section-card-chapter-name').innerText = change.target ? change.target.innerText : ''
          }
      })
    })
    this.chapterSummaryCardTargets.forEach( (chapter) => {
      observerTwo.observe(chapter.querySelector('.chapter-summary-card-name'), {attributes: true, subtree: true});
    })
  }

  #openChapters() {
    this.chapterSummaryCardTargets.forEach(e => e.classList.add('active'))
  }

  #addListeners() {
    document.addEventListener('contents:created', e => this.updateRatios())
    document.addEventListener('contents:deleted', e => this.updateRatios())
    document.addEventListener('contents:done', e => this.updateRatios())
    document.addEventListener('contents:ongoing', e => this.updateRatios())
    document.addEventListener('contents:todo', e => this.updateRatios())
    document.addEventListener('contents:changedSection', e => this.#contentChangeSection(e))
    document.addEventListener('sections:changedChapter', e => this.#sectionChangeChapter(e))
  }

  #removeListeners() {
    document.removeEventListener('contents:created', e => this.updateRatios())
    document.removeEventListener('contents:deleted', e => this.updateRatios())
    document.removeEventListener('contents:done', e => this.updateRatios())
    document.removeEventListener('contents:ongoing', e => this.updateRatios())
    document.removeEventListener('contents:todo', e => this.updateRatios())
    document.removeEventListener('contents:changedSection', e => this.#contentChangeSection(e))
    document.removeEventListener('sections:changedChapter', e => this.#sectionChangeChapter(e))
  }

  updateRatios(id = this.currentSectionId) {
    const url = `/sections/${id}/fetch_completion_ratio`
    const options = {
      method: 'GET',
      headers: {"Accept": "application/json" },
    }
    const ratios = this.#completionRatios(id)

    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      //  {chapter_denominator: x,
      //   chapter_numerator: y,
      //   section_denominator: s,
      //   section_numerator: t,   }
      ratios.chapter.numerator.innerText = data.chapter_numerator
      ratios.chapter.denominator.innerText = data.chapter_denominator
      ratios.section.numerator.innerText = data.section_numerator
      ratios.section.denominator.innerText = data.section_denominator
      this.#updateRatioColors()
    })
  }


  #completionRatios(id = this.currentSectionId) {
    const sectionSummaryCard  = this.sectionSummaryCardTargets.find(e => e.dataset.sectionId == id)
    if (sectionSummaryCard === undefined) return

    const sectionRatioWrapper = sectionSummaryCard.querySelector('.section-completion-ratio')
    const chapterId = sectionSummaryCard.closest('.writing-summary-chapter-card').dataset.chapterId

    return {
      chapter: this.#chapterCompletionRatio(chapterId),
      section: {
        wrapper: sectionRatioWrapper,
        numerator: sectionRatioWrapper.querySelector('.numerator'),
        denominator: sectionRatioWrapper.querySelector('.denominator')
      }
    }
  }

  #chapterCompletionRatio(id) {
    const chapterSummaryCard = this.chapterSummaryCardTargets.find(e => e.dataset.chapterId == id)
    const chapterRatioWrapper = chapterSummaryCard.querySelector('.chapter-completion-ratio')

    return {
      wrapper: chapterRatioWrapper,
      numerator: chapterRatioWrapper.querySelector('.numerator'),
      denominator: chapterRatioWrapper.querySelector('.denominator')
    }
  }


  #contentChangeSection(event) {
    this.updateRatios()
    this.updateRatios(event.detail.targetSectionId)
  }

  #decrementValue(element, value = 1) {
    let elementValue  = parseInt(element.innerText, 10)
    elementValue      = elementValue - value
    element.innerText = elementValue
  }

  #sectionChangeChapter(event) {
    if (event.detail.originChapterId == event.detail.targetChapterId) return

    const sectionRatio       = this.#completionRatios(event.detail.sectionId).section
    const numeratorValue     = parseInt(sectionRatio.numerator.innerText, 10)
    const denominatorValue   = parseInt(sectionRatio.denominator.innerText, 10)

    const originChapterRatio = this.#chapterCompletionRatio(event.detail.originChapterId)

    this.#decrementValue(originChapterRatio.numerator, numeratorValue)
    this.#decrementValue(originChapterRatio.denominator, denominatorValue)

    this.updateRatios()
  }

  #updateRatioColors() {
    // NOTE Can certainly be optimized, I can't wrap my head around this
    this.chapterRatioWrapperTargets.forEach((chapter) => {
      const states = []
      chapter.closest('.writing-summary-chapter-card').querySelectorAll('.section-completion-ratio').forEach((section) => {
        const p = Math.round(eval(section.innerText) * 100)
        const v = (isNaN(p) || p === 0) ? -1 : (p < 100) ? 0 : 1

        states.push(v)
        this.#ratioClass(section, v)
      })

      const uniqState = [...new Set(states)]
      const v = uniqState.length === 1 ? uniqState[0] : 0

      this.#ratioClass(chapter, v)
    })
  }

  #ratioClass(element, value) {
    switch (value) {
      case -1:
        element.dataset.status = 'false'
        break
      case 0:
        element.dataset.status = 'started'
        break
      case 1:
        element.dataset.status = 'finished'
        break
      default:
        element.dataset.status = 'false'
    }
  }

  _storeCurrentOpenedSection(id) {
    this.sectionSummaryCardTargets.forEach((e, index) => {
      if (parseInt(e.dataset.sectionId, 10) === id) {
        const url = new URL(window.location);
        url.searchParams.set('chapter_id', e.closest('.writing-summary-chapter-card').dataset.chapterId);
        url.searchParams.set('section_id', id);
        history.pushState({}, '', url);
        localStorage.setItem(this.storageName, index);
      }
    })
  }

}
