import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];
  connect() {
    console.log("Hello, Stimulus!", this.element)
  }

  toggleDisableFields() {
    this.checkboxTargets.forEach((checkbox) => {
      const parent = checkbox.parentElement
      parent.dataset.checked = !(parent.dataset.checked === 'true')
      checkbox.disabled = !checkbox.disabled
      checkbox.checked = !checkbox.disabled
    });

  }
}