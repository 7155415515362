import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static values = { createPath: String, firstPartId: Number }
  static targets = [
    'card',
    'cardsContainer',
    'desktopVideosContainer',
    'addVideoButton',
    'video',
    'videoName',
    'videoNameForm',
  ]

  connect() {
    this.currentId = this.firstPartIdValue
    this.contentCard = this.element.closest('.writing-content')
  }

  reveal(event) {
    this._stopAllActiveStreams()
    stopAllPlayingMedias()
    // TODO make this functional
    // this.recordingControllerForEvent(event.currentTarget).reset()
    this.setCurrentVideoPart(event.currentTarget.dataset.id)
  }

  _stopAllActiveStreams() {
    if (window.stream) window.stream.getTracks().forEach((e) => e.stop())
  }

  setCurrentVideoPart(id) {
    this.currentId = id
    hideElements(this.videoTargets)
    showElements(this.videosOfId(id))
    this.cardTargets.forEach(e => e.classList.remove('active'))
    this.cardsOfId(id).forEach(e => e.classList.add('active'))
  }

  create(event) {
    const el = event.currentTarget
    const url = el.dataset.path
    el.disabled = true

    fetchWithToken(url, {
      method: 'POST',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      this.insertVideoCardHTML(data.video_html, data.card_html, data.id)
      el.disabled = false

    })
  }

  delete(event) {
    const el = event.currentTarget
    const url = el.dataset.path
    const id = url.replace(/\D/g, "")

    Swal.fire({
      title: I18n.t('js.sweet_alerts.video_parts.delete.title'),
      text: I18n.t('js.sweet_alerts.video_parts.delete.text'),
      icon: 'warning',
      confirmButtonColor: '#3d52d5',
      confirmButtonText: I18n.t('js.sweet_alerts.video_parts.delete.confirm_button_text'),
      showCancelButton: true,
      cancelButtonText: I18n.t('js.sweet_alerts.video_parts.delete.cancel_button_text'),
    }).then((result) => {
      this._stopAllActiveStreams()
      if (result.isConfirmed) {
        el.disabled = true

        fetchWithToken(url, {
          method: 'DELETE',
          headers: {"Accept": "application/json" },
        })
        .then(response => response.json())
        .then((data) => {
          if (data.deleted) {
            this._removeVideoCardHTML(id)

            if (this.cardTargets.length === 0) this.markAsTodo()
          }
          el.disabled = false
          this.setCurrentVideoPart(data.previous_id)
        })
      }
    })
  }

  displayEditName(event) {
    const videoCard = event.currentTarget.closest('.video-part-writing')
    const form = videoCard.querySelector('[data-video--video-parts-target="videoNameForm"]')
    const name = videoCard.querySelector('[data-video--video-parts-target="videoName"]')
    const input = videoCard.querySelector('[data-video--video-parts-target="videoNameInput"]')
    showElements(form)
    hideElements(name)
    input.setSelectionRange(input.value.length, input.value.length)
    input.focus()
    input.addEventListener('blur', (event) => {
      this.editName(event)
    }, {once: true})
  }

  editName(event) {
    const form = event.currentTarget.closest('form')
    const input = event.currentTarget.closest('input')
    event.preventDefault()

    const videoCard = form.closest('.video-part-writing')
    const id = videoCard.dataset.id

    const name = videoCard.querySelector('[data-video--video-parts-target="videoName"]')
    const cardName = this.cardsOfId(id)[0].querySelector('[data-video--video-parts-target="cardName"]')
    

    const formData = new FormData(form)
    fetchWithToken(form.action, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.updated) {
        if (data.name.length > 0) {
          // We emit a custom event to update the video name in the video controller
          dispatchCustomEvent('video:video_parts:edit_name', { id: id, name: data.name })
          name.innerText = data.name
          cardName.innerText = data.name
        } else {
          name.innerText = input.placeholder
          cardName.innerText = input.placeholder
        }
        showElements(name)
        hideElements(form)
      }
    })
  }

  insertVideoCardHTML(videoHtml, cardHtml, id = null, position = null) {
    const hasDesktopTarget = this.hasDesktopVideosContainerTarget
    if (position) {
      if (hasDesktopTarget) { var videoReferenceElement = this.desktopVideosContainerTarget.children[position - 1] }
      var cardReferenceElement = this.cardsContainerTarget.children[position - 1]
    } else {
      if (hasDesktopTarget) { var videoReferenceElement = this.desktopVideosContainerTarget.children[this.desktopVideosContainerTarget.children.count - 1] }
      var cardReferenceElement = this.cardsContainerTarget.children[this.cardsContainerTarget.children.count - 1]
    }
    var videoNode = createNodeFromString(videoHtml)
    var cardNode = createNodeFromString(cardHtml)
    if (hasDesktopTarget) { this.desktopVideosContainerTarget.insertBefore(videoNode, videoReferenceElement) }
    this.cardsContainerTarget.insertBefore(cardNode, cardReferenceElement || this.addVideoButtonTarget)
    if (id) this.setCurrentVideoPart(id)
  }


  replaceVideoCardHTML(videoHtml, cardHtml, id) {
    const card = this.cardsOfId(id)[0]
    const video = this.videosOfId(id)[0]

    if (card) card.outerHTML = cardHtml
    if (video) video.outerHTML = videoHtml
    if (this.currentId !== id) this.videosOfId(id)[0].classList.add('hidden')
  }

  _removeVideoCardHTML(id) {
    this.cardsOfId(id).forEach(e => e.remove())
    this.videosOfId(id).forEach(e => e.remove())
  }

  cardsOfId(id) {
    return Array.from(this.cardTargets).filter((e) => {
      return e.dataset.id == id
    })
  }

  videosOfId(id) {
    return Array.from(this.videoTargets).filter((e) => {
      return e.dataset.id == id
    })
  }


  markAsTodo() {
    this.contentCard.classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }
}
