import { Controller } from "stimulus";
import { fetchWithToken } from '../application/stimulus_helper';


export default class extends Controller {
  static targets = ['input', 'form', 'submitButton'];
  static values = { taggableId: Number, taggableType: String };


  displayEditTags() {
    hideElements(this.taglistTarget)
    showElements(this.formTarget)
  }

  createTags() {
    const formData = new FormData(this.formTarget)
    const url = this.formTarget.action

    fetch(url, {
      method: 'POST',
      headers: {"Accept": "text/plain" },
      body: formData
    })
    .then(response => response.text())
    .then((data) => {
      this.taglistTarget.innerHTML = data
      showElements(this.taglistTarget)
      hideElements(this.formTarget)
    })
  }
}
