import { Controller } from "stimulus";
import { fetchWithToken } from "../../application/stimulus_helper";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = [
    "validateBtn",
    "nextBtn",
    "answerContainer",
    "answer",
    "explanationContainer",
    "explanation",
    "proposeReassessAiSection",
    "proposeReassessManualSection",
    "aiLink",
    "rightMatch",
    "rightMatchContainer",
    "leftMatch",
    "arrowMatch"
  ];
  static values = {
    lastQuestion: String,
    questionId: String,
    quizzContentId: String,
    questionFormat: String,
    showResults: String,
    alreadyAnswered: Boolean,
  };

  expand(e) {
    e.currentTarget.closest('.answer-js').dataset.expandedState = "true"
    if (e.currentTarget.closest('.answer-js').classList.contains('image-ordering')) {
      e.currentTarget.closest('.answer-js').classList.remove('reorder-handle')
    }
  }


  shrink(e) {
    e.currentTarget.closest('.answer-js').dataset.expandedState = "false"
    if (e.currentTarget.closest('.answer-js').classList.contains('image-ordering')) {
      e.currentTarget.closest('.answer-js').classList.add('reorder-handle')
    }
  }


  answer(e) {
    if (!this.alreadyAnsweredValue && e.currentTarget.dataset.expandedState === "false") {
      if (e.currentTarget.dataset.answeredState === "true") {
        this.deselectCard(e.currentTarget);
        if (
          !this.element.querySelector('.answer-js[data-answered-state="true"]')
        ) {
          this.deactivateValidateBtn();
        }
      } else {
        if (this.questionFormatValue === "single_choice" || this.questionFormatValue === 'ordering') {
          this.answerTargets.forEach((answerCard) => {
            this.deselectCard(answerCard);
          });
        }
        const card = e.currentTarget.classList.contains(".answer-js") ? e.currentTarget : e.currentTarget.closest(".answer-js");
        this.selectCard(card, e.currentTarget.dataset.type === "image");
        if(this.questionFormatValue !== 'ordering') {
          this.revealValidateBtn();
        }

      }
    }
  }

  reorder(e) {
    if (!this.alreadyAnsweredValue) {
        if (this.questionFormatValue === 'ordering') {
          this.answerTargets.forEach((answerCard) => {
            this.deselectCard(answerCard);
            answerCard.dataset.answeredState = true
          });
        }
        this.revealValidateBtn();
    }
  }

  revealMatch(e) {
    if (e.currentTarget.dataset.correctMatch) {
      const matching = this.element.querySelector(`[data-reading--quizz-question-target="rightMatch"][data-match-id="${e.currentTarget.dataset.correctMatch}"]`)
      matching.dataset.highlighted = "true"
    }
  }

  hideMatch() {
    this.element.querySelectorAll(`[data-reading--quizz-question-target="rightMatch"][data-highlighted="true"]`).forEach((highlightedMatch) =>{
      highlightedMatch.dataset.highlighted = "false"
    })
  }

  deactivateValidateBtn() {
    this.validateBtnTarget.classList.add("opacity-10", "inactive");
  }

  revealValidateBtn() {
    this.validateBtnTarget.classList.remove("opacity-10", "inactive");
  }


  selectCard(card) {
    card.dataset.answeredState = "true";

    if (
      this.questionFormatValue === "single_choice" ||
      this.questionFormatValue === "multiple_choices" || this.questionFormatValue === 'ordering'
    ) {
      card.setAttribute('data-quizz-selected-state', 'true')
    }

    const checkbox = card.querySelector(".answered-state-checkbox");
    if (checkbox) {
      checkbox.checked = true;
    }
  }

  deselectCard(card) {
    card.dataset.answeredState = "false";
    card.setAttribute('data-quizz-selected-state', 'false')
    if (card.querySelector(".answered-state-checkbox")) {
      card.querySelector(".answered-state-checkbox").checked = false;
    }
  }

  validateAnswers(e) {
    if (!e.currentTarget.classList.contains("inactive")) {
      if (
        this.element.querySelector(`.answer-js[data-answered-state="true"]`)
      ) {
        if (this.questionFormatValue === "open" && this.answerTarget.querySelector(".plaintextinput").value.length < 8) {
          this.deactivateValidateBtn()
        } else {
          if (this.questionFormatValue === "matching") {
            this.arrowMatchTargets.forEach((target) => {target.dataset.answeredCorrectly = "true"
            this.element.querySelectorAll('[data-reading--quizz-question-target="rightMatch"]').forEach((rightMatch) => { rightMatch.dataset.answeredCorrectly = "true" })
            })}
          this.fetchResult();
        }
      }
    }
  }

  fetchResult() {
    const formData = new FormData();
    if (this.questionFormatValue === 'matching') {
      this.rightMatchTargets.forEach((target)=> {
        target.classList.remove('reorder-handle')
        target.dataset.isPassed = "true"
      })
      let combinations = []
      this.element.querySelectorAll('.arrow-match').forEach((arrow) => {
        const leftMatchId = arrow.dataset.leftMatchId;
        const rightMatchId = arrow.dataset.rightMatchId;
        let combination = {};
        combination[leftMatchId] = rightMatchId
        combinations.push(combination)
      })
      formData.append(
        `quizz_answer[${this.answerTarget.dataset.answerId}]`,
        this.answerTarget.dataset.answeredState
      );
      formData.append(
        'combination',
        JSON.stringify(combinations)
      );
    } else if (this.questionFormatValue === "open") {
        formData.append(
          `quizz_answer[${this.answerTarget.dataset.answerId}]`,
          this.answerTarget.dataset.answeredState
        );
        formData.append(
          "open_answer",
          this.answerTarget.querySelector(".plaintextinput").value
        );
      this.validateBtnTarget.disabled = true
    } else if (this.questionFormatValue === "ordering") {
      this.answerTargets.forEach((answer) => {
        formData.append(
          `quizz_answer[${answer.dataset.answerId}]`,
          answer.dataset.answeredState
        );
        formData.append(
          `answer_position[${answer.dataset.answerId}]`,
          answer.querySelector(".position-input").value
        );
      });
    } else {
      this.answerTargets.forEach((answer) => {
        formData.append(
          `quizz_answer[${answer.dataset.answerId}]`,
          answer.dataset.answeredState
        );
      });
    }

    fetchWithToken(
      `/quizz_contents/${this.quizzContentIdValue}/quizz_questions/${this.questionIdValue}/answer`,
      {
        method: "POST",
        headers: { Accept: "application/json" },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => this.updateQuestionWith(data))
  }

    updateQuestionWith(data) {
        console.log(data)
        const answerJS = this.element.querySelectorAll(".answer-js");
        this.alreadyAnsweredValue = true;

        answerJS.forEach((answers) => {
          answers.setAttribute('data-quizz-finish', 'finished')
          answers.setAttribute('data-quizz-selected-state', 'false')
        })

        if (this.showResultsValue === "true") {
          this.answerTargets.forEach((answerCard) => {
            if (data.answers[`${answerCard.dataset.answerId}`]["correct"] === true
            ) {
              if (data.answers[`${answerCard.dataset.answerId}`]["answered_correctly"] === true) {
                answerCard.setAttribute('data-quizz-state', 'good')
              } else {
                answerCard.setAttribute('data-quizz-state', 'correct')
              }
            }
            else if ( data.answers[`${answerCard.dataset.answerId}`]["correct"] === false && data.answers[`${answerCard.dataset.answerId}`]["answered_correctly"] === false) {
              answerCard.setAttribute('data-quizz-state', 'wrong')
            }
          });
          if (data.explanation) {
            this.displayHint(data.explanation);
          }
          if (data.propose_assess === "propose ai") {
            this.displayProposeAi(data.question_score_id)
          }
          if (data.score) {
            const scores = this.element
              .closest(".quizz-container")
              .querySelectorAll(".score");
            scores.forEach((score) => {
              score.innerText = `Score : ${Math.round(data.score * 100)} %`;
            });
          }
          if (data.partial) {
            const endSlideScore = this.element
              .closest(".quizz-container")
              .querySelector(".end-slide-score");
            endSlideScore.innerHTML = data.partial
          }
          if (data.incorrect_pairs) {
            Object.entries(data.incorrect_pairs).forEach(([key, value]) => {
              this.element.querySelector(`.arrow-match[data-left-match-id="${key}"]`).dataset.answeredCorrectly = "false"
              this.element.querySelector(`.match-card[data-match-id="${value}"]`).dataset.answeredCorrectly = "false"
              //this.element.querySelector(`.match-card[data-match-id="${key}"]`).dataset.answeredCorrectly = "false"
              //this.element.querySelector(`.match-card[data-match-id="${data.incorrect_pairs[key].toString()}"]`).dataset.answeredCorrectly = "false"
            })
          }
          if (data.correct_pairs) {
            this.leftMatchTargets.forEach((match) => {match.dataset.correctMatch = data.correct_pairs[match.dataset.matchId]})
          }
        }
        this.hideValidateBtn();
        this.revealNextBtn();
    }

  displayProposeAi(scoreId) {
    this.proposeReassessAiSectionTarget.classList.remove("hidden")
    this.aiLinkTarget.dataset.scoreid = scoreId
  }

  reassess(e) {
    this.aiLinkTarget.disabled = true
    fetchWithToken(
      `/question_scores/${e.currentTarget.dataset.scoreid}/reassess_open_question`,
      {
        method: "POST",
        headers: { Accept: "application/json" },
      }
    )
    .then((response) => response.json())
    .then((data) => {
      this.proposeReassessAiSectionTarget.classList.add("hidden")
      this.updateQuestionWith(data)
    })
  }

  displayHint(content) {
    this.explanationContainerTarget.classList.remove("hidden");
    this.explanationTarget.innerText = content;
  }

  hideValidateBtn() {
    this.validateBtnTarget.classList.add("hidden");
  }

  revealNextBtn() {
    this.nextBtnTarget.classList.remove("hidden");
  }

  disable() {
    this.answerTargets.forEach((e) => e.classList.add("answered"));
  }
}
