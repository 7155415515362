// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default

import { Controller } from "stimulus"
import "@gorails/ninja-keys"

export default class extends Controller {
  connect() {
    // We hide a json with name of libraries and their ids { 12 => "Library 1", 13 => "Library 2"}
    // We can use this to load the libraries dynamically same for handovers
    const raw_libraries = JSON.parse(this.element.dataset.libraries)
    const libraries = Object.keys(raw_libraries).map(key => {
      return { id: key, title: raw_libraries[key] }
    })

    const raw_handovers = JSON.parse(this.element.dataset.handovers)
    const handovers = Object.keys(raw_handovers).map(key => {
      return { id: key, title: raw_handovers[key] }
    })

    this.element.placeholder = "Type a command or navigate with arrows..."

    this.element.data = [
      {
        id: "Home",
        title: "Home",
        hotkey: "ctrl+H",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        handler: () => {
          window.location.href = "/"
        }
      },
      {
        id: "Notifications",
        title: "Notifications",
        hotkey: "ctrl+N",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>',
        handler: () => {
          window.location.href = "/notifications"
        }
      },
      {
        id: "New Playbook",
        title: "New Playbook",
        hotkey: "ctrl+P",
        icon: '<svg width="24" height="24" class="ninja-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13V19H11V13H5V11H11V5H13V11H19V13H13Z" fill="currentColor"/></svg>',
        handler: () => {
          window.location.href = "/handovers/new"
        }
      },
      {
        id: 'Playbooks',
        title: 'Playbooks...',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" class="ninja-icon" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" /></svg>',
        children: handovers.map(handover => handover.title),
        handler: () => {
          // open menu if closed. Because you can open directly that menu from it's hotkey
          this.element.open({ parent: 'Playbooks' });
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'Libraries',
        title: 'Libraries...',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" /></svg>',
        children: libraries.map(library => library.title),
        hotkey: 'ctrl+T',
        handler: () => {
          // open menu if closed. Because you can open directly that menu from it's hotkey
          this.element.open({ parent: 'Libraries' });
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: "Dashboard",
        title: "Dashboard",
        hotkey: "ctrl+D",
        icon: '<svg width="24" height="24" class="ninja-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 21H18V11H21V21ZM16 21H13V8H16V21ZM11 21H8V5H11V21ZM6 21H3V13H6V21Z" fill="currentColor"/></svg>',
        handler: () => {
          window.location.href = "/organisation/dashboard?direction=desc&sort=updated_at"
        }
      },
      {
        id: "Settings",
        title: "Settings",
        hotkey: "ctrl+S",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        handler: () => {
          // Turbo.visit("/users/edit")
          window.location.href = "/users/edit"
        }
      },
    ];

    // Add libraries to the menu
    libraries.forEach(library => {
      this.element.data.push({
        id: library.title,
        title: library.title,
        parent: 'Libraries',
        section: "Libraries",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" /></svg>',
        handler: () => {
          window.location.href = `/libraries/${library.id}`
        }
      })
    })

    // Add handovers to the menu
    handovers.forEach(handover => {
      this.element.data.push({
        id: handover.title,
        title: handover.title,
        parent: 'Playbooks',
        section: "Playbooks",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" class="ninja-icon" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" /></svg>',
        handler: () => {
          window.location.href = `/handovers/${handover.id}/read`
        }
      })
    })

  }
}
