import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'rightFrame', 'general']
  static values = {
    rightUrl: String,
    submitMethod: String,
    typeId: String,
  }

  connect() {
    setTimeout(() => {
      this.highlightCard(this.typeIdValue)
    }, 500)
    this.element.addEventListener('keydown',function(e) {
      if (e.keyIdentifier=='U+000A' || e.keyIdentifier=='Enter' || e.keyCode==13) {
        e.preventDefault()
      }
    })
  }

  navigateIntegrationTypes(e) {
    const type_id = e.currentTarget.dataset.typeId
    this.highlightCard(type_id)
    const url = `${this.rightUrlValue}?integration_type=${type_id}`
    fetchWithToken(url, {
      method: "GET",
      headers: {"Accept": "application/json" }
    })
    .then(response => response.json())
    .then((data) => {
      this.rightFrameTarget.innerHTML = data.partial
    })
  }

  highlightCard(id) {
    this.element.querySelectorAll('.typecard').forEach( (card) => {
      if(card.dataset.typeId === id) {
        card.dataset.selectedState = 'true'
      } else {
        card.dataset.selectedState = 'false'
      }
    })
  }

  submitForm() {
    const integrationForm = this.rightFrameTarget.querySelector('#integration')
    let formData = new FormData(integrationForm)
    fetchWithToken(integrationForm.action, {
      method: this.submitMethodValue,
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: `Une erreur est survenue`,
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      } else if (data.partial) {
          this.rightFrameTarget.innerHTML = data.partial
          this.rightFrameTarget.dataset.contentLoaderUrlValue = data.url
          this.generalTarget.dataset['mediaCreation-IframeNavigationRightUrlValue'] = data.url
          this.generalTarget.dataset['mediaCreation-IframeNavigationSubmitMethodValue'] = 'PATCH'
          this.generalTarget.dataset['mediaCreation-IframeNavigationTypeIdValue'] = data.type_id
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
          if (data.card) {
            const content = document.querySelector(`.writing-content[data-content-id="${data.content_id}"]`)
            if (content) content.outerHTML = data.card
          }
      }
    })
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

}
