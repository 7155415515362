import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'filterTag', 'submitBtn']

connect() {
  this.searchParams = new URLSearchParams(window.location.search)
  this.filterParams = ['favorites', 'mycontents', 'mediatypes[]', 'status[]']
}

go() {
  this.submitBtnTarget.disabled = true
  const query = this.inputTarget.value
  if (query !== "") { this.searchParams.set("query", `${query}`) }
  window.location.search = this.searchParams.toString()
}

noMoreEnterKey(e) {
  if(e.keyCode == 13) {
    e.preventDefault()
    if (e.currentTarget.value !=="") {
      this.go()
    }
  }
}

toggleFilter(e) {
  if (e.currentTarget.dataset.value === 'true') {
    // simple params with one and only boolean value
    if (e.currentTarget.dataset.active === 'true') {
      this.element.querySelectorAll((`[data-filter="${e.currentTarget.dataset.filter}"]`)).forEach((element) => {element.dataset.active = 'false'})
      e.currentTarget.dataset.active = 'false'
      this.searchParams.delete(`${e.currentTarget.dataset.filter}`)
    } else {
      this.element.querySelectorAll((`[data-filter="${e.currentTarget.dataset.filter}"]`)).forEach((element) => {element.dataset.active = 'true'})
      this.searchParams.set(`${e.currentTarget.dataset.filter}`, "true")
    }
  } else {
    // sinon, on se base sur la valeur pour trouver le bon tag
    if (e.currentTarget.dataset.active === 'true') {
      this.element.querySelectorAll((`[data-value="${e.currentTarget.dataset.value}"]`)).forEach((element) => {element.dataset.active = 'false'})
      e.currentTarget.dataset.active = 'false'
    // pour enlever une valeur d'une clef dans les params, on doit conserver les autres valeurs, delete la clef et ses valeurs, et re-append la cle avec les autres valeurs
      const existingValuesForFilter = this.searchParams.getAll(`${e.currentTarget.dataset.filter}[]`).filter(tag => tag !== `${e.currentTarget.dataset.value}`)
      this.searchParams.delete(`${e.currentTarget.dataset.filter}[]`)
      existingValuesForFilter.forEach((value) => { this.searchParams.append(`${e.currentTarget.dataset.filter}[]`, value)})
    } else {
      this.element.querySelectorAll((`[data-value="${e.currentTarget.dataset.value}"]`)).forEach((element) => {element.dataset.active = 'true'})
      this.searchParams.append(`${e.currentTarget.dataset.filter}[]`,`${e.currentTarget.dataset.value}` )
    }
  }

}

empty() {
  this.submitBtnTarget.disabled = true
  const query = this.inputTarget.value
  this.searchParams.delete("query")
  this.inputTarget.value = ""
  this.clearAllFilters()
  window.location.search = this.searchParams.toString()
}

clearAllFilters() {
  this.filterParams.forEach((param) => {
    this.searchParams.delete(param)
  })
}

}
