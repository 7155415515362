import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'button'];
  static values = { open: Boolean };
  connect() {
    if (this.openValue) {
      this.expend();
    }
  }


  expend() {
    this.formTarget.classList.remove('hidden');
    // We add a nice animation to the button
    this.buttonTarget.classList.add('hidden');
  }
}