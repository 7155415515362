import { Controller } from 'stimulus';
import consumer from "../../channels/consumer"

export default class extends Controller {
  static values = { id: Number }
  static targets = [
    'waitCutIcon',
    'cutterIcon'
  ]

  connect() {
    this.videoPartsController = this.application.getControllerForElementAndIdentifier(this.element, 'video--video-parts')
    this._broadcast()
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "CutVideoPartChannel", id: this.idValue },
      { received: this._received.bind(this) }
    )
  }

  _received(data) {
    hideElements(this.waitCutIconTarget)
    showElements(this.cutterIconTarget)
    this.videoPartsController.replaceVideoCardHTML(data.part_1_video_html, data.part_1_card_html, data.part_1_id)
    this.videoPartsController.insertVideoCardHTML(data.part_2_video_html, data.part_2_card_html, data.part_2_id, data.part_2_position)
  }
}
