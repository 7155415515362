import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['originMenu', 'libraryDrawer']

  connect() {
    this.body = document.querySelector('body')
    this.html = document.querySelector('html')
    this.bodyOverflowValue = this.body.style.overflow || 'visible'
    this.htmlOverflowValue = this.html.style.overflow || 'visible'
  }

  descroll() {
    this.body.style.overflow = 'hidden'
    this.html.style.overflow = 'hidden'
  }

  open() {
    hideElements(this.originMenuTarget)
    showElements(this.libraryDrawerTarget)
  }

  return() {
    hideElements(this.libraryDrawerTarget)
    showElements(this.originMenuTarget)
  }

  rescroll() {
    this.body.style.overflow =  this.bodyOverflowValue
    this.html.style.overflow = this.htmlOverflowValue
  }
}
