import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('trix')
require('@rails/actiontext')
require('../stylesheets/application.scss')

import 'channels'
import 'controllers'
import 'selectize'
import 'application/stimulus_helper';
import 'application/sweet_alert_confirm';
import "chartkick/chart.js"
import { Application }        from 'stimulus'
import { _DataTransfer }      from '../components/custom_data_transfer'
import { Timer }              from '../components/timer'
import { KominUploader }      from '../components/komin_uploader'
import { flashesFadeOut }     from '../components/flashes';
import { initTailwindTabs }   from '../components/tailwind_tabs';
import { initSelect2 }        from '../components/init_select2';
import { scrollToLinkTarget } from '../components/scroll_to_links';
import { globalLoader }       from '../components/global_loader';
import   TextareaAutogrow     from 'stimulus-textarea-autogrow'
import   Reveal               from 'stimulus-reveal-controller'
import   CheckboxSelectAll    from 'stimulus-checkbox-select-all'
import   Dropdown             from 'stimulus-dropdown'
import   Sortable             from 'sortablejs/modular/sortable.complete.esm.js'
import   Flatpickr            from 'stimulus-flatpickr'

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('reveal', Reveal)
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('textarea-autogrow', TextareaAutogrow)


require("flatpickr/dist/flatpickr.css")
require('canvas-confetti')
application.register('flatpickr', Flatpickr)

//Modify trix default layout
import "customization/trix_custom"

// View components
import "../components"

globalLoader();

document.addEventListener('turbolinks:load', function() {
  initSelect2();
  flashesFadeOut();
  initTailwindTabs();
  scrollToLinkTarget();
});