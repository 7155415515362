import { Controller } from 'stimulus';
import { fetchWithToken } from '../../../javascript/application/stimulus_helper';

export default class extends Controller {
  static targets = ['stepsContainer', 'form', 'idInput'];

  createStep(e) {
    e.preventDefault()
    const handoverId = e.currentTarget.dataset.handoverId
    this.idInputTarget.value = handoverId
    const formData = new FormData(this.formTarget)
    const emptyCard = this.element.querySelector('.emptycard')

    fetchWithToken(this.formTarget.action, {
      method: "POST",
      body: formData,
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors) return
      if (data.partial) {
        if (emptyCard) {emptyCard.classList.add('hidden')}
        this.stepsContainerTarget.insertAdjacentHTML('beforeend', data.partial)
      }
    })

  }
}
