import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['commentsContainer'];
  connect() {
  }

  showComments() {
    fadeShowElements(this.commentsContainerTarget);
  }

  hideComments() {
    fadeHideElements(this.commentsContainerTarget);
  }
}
