import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['button', 'form']

  connect() {
  }

  toggleForm() {
    this.formTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
  }

}
