import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['parent'];
  
  toggleButton(e) {
    const parent = e.currentTarget
    const radio =  e.currentTarget.querySelector('.filter-js')
    this.parentTargets.forEach((element) => {
      element.dataset.checkedState = false
    })
    radio.checked = !radio.checked
    parent.dataset.checkedState = radio.checked
    radio.dispatchEvent(new Event('change'))
  }

}