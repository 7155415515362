import Rails from "@rails/ujs"
import Bowser from "bowser"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [
    'modal', 'dropzone', 'microphone', 'recordingAnimation',
    'preview', 'recordedMedia', 'contentPreview', 'previewIcon', 'loadingSpinner',
    'startBtn', 'stopBtn', 'deleteAttachmentBtn', 'restartBtn', 'modifyContentBtn', 'submitFormBtn',
    'fileInput', 'notesInput', 'notesForm', 'mediaPanel', 'notesPanel', 'unsplashPanel', 'rightPanel',
    'timer', 'timerBox', 'fileForm'
  ]

  static values = {
    duration: Number,
  }

  connect() {
    this._defineBrowser()
    this._displayExistingMediaIfPresent()
    this.timer = new Timer({
      target: this.timerTarget,
      duration: this.durationValue, // seconds
      onEnd: this.stopRecording.bind(this)
    })
    window.addEventListener("dragover", function(e) {
      e = e || event
      e.preventDefault()
    }, false);
    window.addEventListener("drop", function(e) {
      e = e || event;
      e.preventDefault();
    }, false);
    this.reader = new FileReader();
  }

  revealNotesMobile() {
    fadeHideElements(this.unsplashPanelTarget, this.mediaPanelTarget)
    fadeShowElements(this.notesPanelTarget, this.rightPanelTarget)
  }

  hideNotesMobile() {
    fadeHideElements(this.rightPanelTarget)
    fadeShowElements(this.mediaPanelTarget)
  }

  openOSWindow(e) {
    this.fileInputTarget.click()
    this.fileInputTarget.addEventListener('change', (e) => {this.fileSrcPreview(this.fileInputTarget.files[0])}, {once: true})
  }

  dropFile(event) {
    var list = new DataTransfer();
    if (event.dataTransfer.items) {
      var file = event.dataTransfer.items[0].getAsFile()
    } else {
      var file = event.dataTransfer.files[0]
    }

    if (!/audio/.test(file.type)) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.audio.wrong_file_type.title'),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else if (file.size >= 22009610) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.audio.file_too_big.title'),
        text: I18n.t('js.sweet_alerts.audio.file_too_big.text', { size: this._bytesToHumanString(file.size) }),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else {
      list.items.add(file)

      this.fileInputTarget.files = list.files
      this.fileSrcPreview(file)
    }

  }

  fileSrcPreview(file) {
  //  this._displayPreview(recordedBlob)
    this.reader.onload = (e) => {
      this.recordedMediaTarget.src = e.currentTarget.result
      toggleElements(this.startBtnTarget, this.restartBtnTarget, this.recordedMediaTarget, this.previewTarget, this.timerBoxTarget, this.dropzoneTarget)
    }
    this.reader.readAsDataURL(file)
  }

  _displayExistingMediaIfPresent() {
    if (this.contentPreviewTarget.dataset.presence == 'true') {
      showElements(this.contentPreviewTarget, this.modifyContentBtnTarget, this.deleteAttachmentBtnTarget)
      hideElements(this.startBtnTarget, this.stopBtnTarget, this.restartBtnTarget, this.timerTarget, this.timerBoxTarget)
    } else {
      this.displayContentRecording()
    }
  }

  displayContentRecording() {
    if (this.hasDeleteAttachmentBtnTarget) {
      hideElements(this.deleteAttachmentBtnTarget)
    }
    hideElements(this.contentPreviewTarget, this.modifyContentBtnTarget)
    showElements(this.previewTarget, this.timerTarget, this.timerBoxTarget, this.startBtnTarget)
    this._displayStream(this.previewTarget)
  }

  cancelCreation() {
    this._resetAll()
  }

  _resetAll() {
    this.timer.reset()
    this._stopStream()
    this.stopRecording()
    this.fileInputTarget.value = ''
    this.recordedMediaTarget.src = ''
    this.previewTarget.src = ''
  }

  startRecording() {
    if (this.localStream && this.localStream.active) {
      this.mediaRecorder = new MediaRecorder(this.localStream, {
        audioBitsPerSecond : 128_000,
        mimeType : this.audioContainer
      });
      this.dataChunks = [];
      this.mediaRecorder.start(this.timer.precision);
      this.timer.start()
      this.mediaRecorder.ondataavailable = e => this.dataChunks.push(e.data);
      toggleElements(this.startBtnTarget, this.stopBtnTarget, this.microphoneTarget, this.recordingAnimationTarget, this.dropzoneTarget)
    }
  }

  stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state == 'recording') {
      this.mediaRecorder.stop()
      this.timer.reset()
      let recordedBlob = this._buildBlob()
      this._displayPreview(recordedBlob)
      this.contentFile = this._buildFile(recordedBlob)
      toggleElements(this.stopBtnTarget, this.recordedMediaTarget, this.previewTarget, this.restartBtnTarget, this.timerBoxTarget, this.microphoneTarget, this.recordingAnimationTarget)
    }
  }

  restart() {
    this.fileInputTarget.value = ''
    this.recordedMediaTarget.src = ''
    this.timer.reset()
    toggleElements(this.startBtnTarget, this.restartBtnTarget, this.recordedMediaTarget, this.previewTarget, this.timerBoxTarget, this.dropzoneTarget)
    showElements(this.dropzoneTarget)
  }

  deleteAttachment(event) {
    var contentId = event.currentTarget.dataset.id
    Rails.ajax({
      url: `/contents/${contentId}/delete_attachment`,
      type: "patch",
      success: function(data) {
        if (this.contentPreviewTarget.querySelector('audio')) {
          this.contentPreviewTarget.querySelector('audio').remove()
        } else {
          this.contentPreviewTarget.querySelector('.conversion-notice').remove()
        }
        this.contentPreviewTarget.dataset.presence = 'false'
        hideElements(this.deleteAttachmentBtnTarget)
        this.modifyContentBtnTarget.querySelector('span p').innerText = 'Create'

        this.#markAsTodo()
      }.bind(this),
      error: function(data) {
        console.warn(data)
      }
    })
  }

  saveNotes(e) {
    this.notesInputTarget.value = e.currentTarget.value
    let formData = new FormData(this.notesFormTarget)
    fetchWithToken(this.notesFormTarget.action, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: `Une erreur est survenue`,
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      }
    })
  }

  submitForm() {
    let formData = new FormData(this.fileFormTarget)
    if (this.fileInputTarget.files.length === 0) {
      formData.append('content[file]', this.contentFile)
      this.finalSubmit(formData)
    } else {
      formData.append('content[file]', this.fileInputTarget.files[0])
      this.finalSubmit(formData)
    }
  }

  finalSubmit(formData) {
    fetchWithToken(this.fileInputTarget.closest('form').action, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: I18n.t('js.sweet_alerts.audio.something_went_wrong'),
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
        const target = this.element
        if (target) target.outerHTML = data.partial
        this._resetAll()
        if (data.card) {
          const content = document.querySelector(`.writing-content[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.card
        }
      }
    })
  }

  _displayStream(audioTag) {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(stream => {
        this.localStream = stream;
        audioTag.srcObject = this.localStream;
        showElements(this.startBtnTarget)
      })
      .catch(err => console.warn(err.name, err.message));
  }

  _buildBlob() {
    return new Blob(this.dataChunks, { type: this.audioContainer });
  }

  _buildFile(blob) {
    return new File([blob], `${new Date().getTime()}.${this.audioExtension}`,{ type: this.audioContainer, lastModified:new Date().getTime()});
  }

  _displayPreview(blob) {
    this.recordedMediaTarget.src = URL.createObjectURL(blob)
  }

  _show_tab() {
    Array.from(arguments).forEach((element) => {
      element.classList.remove('sm:hidden')
      element.classList.remove('hidden')
    })
  }

  _stopStream() {
    if (this.localStream && this.localStream.active) {
      this.localStream.getTracks().forEach(track => track.stop())
    }
  }

  _defineBrowser() {
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName()
    const iOS = window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    this.audioContainer = (browser == 'Safari'  || iOS) ? 'audio/mp4' : 'audio/webm'
    this.audioExtension = (browser == 'Safari' || iOS) ? 'mp4' : 'webm'
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  #markAsTodo() {
    this.element.closest('.writing-content').classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }
}
