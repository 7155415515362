import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['headingBtn']

  connect() {
    this.trixToolbar = this.element.closest('trix-toolbar')
    this.trixeditorElement = this.trixToolbar.nextSibling
    this.trixEditor = this.trixeditorElement.editor
    this.range = []
  }

  activatebutton() {
    this.headingBtnTargets.forEach((btn) => {
      btn.classList.remove('trix-active')
    });
    this.storeRange()

    const currentAttributes = this.trixEditor.getDocument().getCommonAttributesAtRange(this.range)

     if (currentAttributes["heading1"] ===  true ) {
       this.trixToolbar.querySelector('.trix-button-heading-1').classList.add('trix-active')
     } else if (currentAttributes["heading2"] ===  true) {
      this.trixToolbar.querySelector('.trix-button-heading-2').classList.add('trix-active')
     } else if (currentAttributes["heading3"] ===  true) {
      this.trixToolbar.querySelector('.trix-button-heading-3').classList.add('trix-active')
     } else {
      this.trixToolbar.querySelector('.trix-button-heading-4').classList.add('trix-active')
     }
  }

  select(e) {
    e.preventDefault()
      this.setRange()

      this.headingBtnTargets.forEach((btn) => {
        btn.classList.remove('trix-active')
        //this.trixEditor.deactivateAttribute(`${btn.dataset.headingAttribute}`)
      })
      const attribute = e.currentTarget.dataset.headingAttribute
      if (attribute !== 'normal') {
        e.currentTarget.classList.add('trix-active')
        this.trixEditor.activateAttribute(`${attribute}`)
      } else {
        this.trixToolbar.querySelector('.trix-button-heading-4').classList.add('trix-active')
      }
  }

  storeRange() {
    this.range = this.trixEditor.getSelectedRange()
  }

  setRange() {
    this.trixEditor.setSelectedRange(this.range)
  }
}
