import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['videoEditorContainer', 'videoPartsMenu', 'subtitlesPanel', 'backFromSubPanelBtn', 'submitVideoPanel', 'editVideoPanelButton', 'editVideoPanel', 'editThumbnailPanel', 'editTranscriptPanel']


  showThumbnailTab(event) {
    this.editVideoPanelTarget.classList.add('hidden')
    this.editThumbnailPanelTarget.classList.remove('hidden')
    this.editVideoPanelButtonTarget.classList.remove('hidden')
    if (this.hasEditTranscriptPanelTarget) {
      this.editTranscriptPanelTarget.classList.add('hidden')
    }
  }

  // trigger by back btn from thumbnail panel
  showVideoTab(event) {
    this.editVideoPanelTarget.classList.remove('hidden')
    this.editThumbnailPanelTarget.classList.add('hidden')
    this.editVideoPanelButtonTarget.classList.add('hidden')
    if (this.hasEditTranscriptPanelTarget) {
      this.editTranscriptPanelTarget.classList.add('hidden')
    }
  }

  toggleSubtitlesPanel() {
    this.subtitlesPanelTargets.forEach((panel) => {
      panel.dataset.open = panel.dataset.open === 'true' ? 'false' : 'true'
      panel.classList.toggle('hidden')
    })

    this.toggleSubmitVideoPanel()
    this.toggleVideoPartsMenu()
  }

  toggleSubmitVideoPanel() {
    this.submitVideoPanelTarget.classList.toggle('hidden')
  }

  toggleVideoPartsMenu() {
    this.videoPartsMenuTarget.classList.toggle('hidden')
    console.log('toggleVideoPartsMenu')
    if (this.hasVideoEditorContainerTarget) {
      console.log('hasVideoEditorContainerTarget')
      if (this.videoEditorContainerTarget.classList.contains('w-10/12')) {
        console.log('w-10/12')
        this.videoEditorContainerTarget.classList.remove('w-10/12')
        this.videoEditorContainerTarget.classList.add('w-full')
      } else {
        this.videoEditorContainerTarget.classList.remove('w-full')
        this.videoEditorContainerTarget.classList.add('w-10/12')
      }
    }
  }

}
