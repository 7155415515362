import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'match-card',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

  //METHODE MANUELLE QUI INTERVERTIT DEUX ELEMENTS AU CLICK, A CONSERVER SI JAMAIS...
  // move(item, fromPosition, toPosition) {

  //   const listItems = Array.from(this.element.children)
  //   const currentIndexofDeplacedItem = fromPosition
  //   const currentItemAtTargetIndex = listItems[toPosition]

  //   if (toPosition < fromPosition) {
  //     item.classList.add('move-up');
  //     currentItemAtTargetIndex.classList.add('move-down')
  //   } else if (toPosition > fromPosition) {
  //     item.classList.add('move-down');
  //     currentItemAtTargetIndex.classList.add('move-up')
  //   }

  //   item.addEventListener('animationend', () => {
  //     // Supprimer les classes d'animation
  //     item.classList.remove('move-up', 'move-down');
  //     currentItemAtTargetIndex.classList.remove('move-up', 'move-down');
  //     // Réinsérer l'élément à la nouvelle position
  //     //this.element.insertBefore(item, currentItemAtTargetIndex);
  //     if (toPosition < currentIndexofDeplacedItem) {
  //       this.element.insertBefore(item, currentItemAtTargetIndex);
  //       listItems.splice(currentIndexofDeplacedItem, 1);
  //       listItems.splice(toPosition, 0, item)
  //       this.element.insertBefore(currentItemAtTargetIndex, listItems[fromPosition])
  //     } else {
  //       this.element.insertBefore(item, currentItemAtTargetIndex.nextSibling);
  //       listItems.splice(currentIndexofDeplacedItem, 1);
  //       listItems.splice(toPosition, 0, item)
  //       this.element.insertBefore(currentItemAtTargetIndex, listItems[fromPosition])
  //     }
  //   }, { once: true })
  //   Array.from(this.element.children).forEach((item, index) => {item.dataset.position = index})
  // }

  onEnd(e) {
    const rightMatches = Array.from(this.element.children)
    const container = this.element.closest('.answer-container')
    const arrows = container.querySelectorAll('.arrow-match')
    const controller = this.application.getControllerForElementAndIdentifier(this.element.closest('.question'), 'reading--quizz-question')

    rightMatches.forEach((item,index) => {
      item.dataset.position = index
      arrows[index].dataset.rightMatchId = item.dataset.matchId
    })
    controller.selectCard(this.element.closest('.answer-js'))
    controller.revealValidateBtn()

  }
}
