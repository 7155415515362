import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ ]

  duplicate(event) {
    event.preventDefault()
    event.stopPropagation()

    const target = event.currentTarget
    const contentId = target.dataset.id

    this._toggleCta(target)

    Rails.ajax({
      url: `/contents/${contentId}/duplication`,
      type: "post",
      dataType: "json",
      data: `[content_id]=${contentId}`,
      success: (data) => { this._insertNewContent(target, data) },
      error: (data) => { this._toggleCta(target) }
    })
  }

  _insertNewContent(target, data) {
    const contentCard = target.closest('.content-card-full')

    this._toggleCta(target)

    contentCard.insertAdjacentHTML('afterend', data.partial)
    contentCard.nextElementSibling.classList.add('notice-new-element')

     window.scrollTo({
      top: contentCard.nextElementSibling.offsetTop - window.innerHeight / 4,
      behavior: 'smooth'
    })
  }

  _toggleCta(target) {
    target.parentElement.querySelector('img').classList.toggle('hidden')
    target.classList.toggle('hidden')
  }
}
