import { Controller } from "stimulus"


export default class extends Controller {

  static targets = ['smiley', 'answer', 'contentInput', 'choiceInput', 'form', 'container']

  connect() {
    this.body = document.querySelector('body')
    this.overflowValue = this.body.style.overflow || 'visible'
  }

  populateModal(e) {
    const choice = e.currentTarget.dataset.choice
    this.smileyTargets.forEach(smiley => smiley.classList.toggle('opacity-50', !(smiley.dataset.choice === choice)))
    this.answerTargets.forEach(answer => answer.classList.toggle('hidden', !(answer.dataset.choice === choice)))
    this.choiceInputTargets.forEach(button => button.checked = button.value === choice)
  }

  fillInput(e) {
    this.contentInputTarget.value = this.contentInputTarget.value + e.currentTarget.dataset.content
  }

  reset() {
    this.contentInputTarget.value = "";
  }

  pimpMyPage(e) {
    e.preventDefault()
    const formData    = new FormData(this.formTarget)
    const destination = this.containerTarget
    const url         = this.formTarget.action
    const options     = {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' }
    }

    fetch(url, options)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          Swal.fire({
            title: 'Erreur !',
            text: data.errors.join(', '),
            icon: 'error',
            confirmButtonColor: '#3d52d5'
          })
        } else {
          destination.innerHTML = data.partial
          this.closeModal()
        }
      }).catch(displayCatchedError)
  }

  closeModal() {
    this.body.style.overflow = this.overflowValue;
  }

}
