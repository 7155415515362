import { Controller } from 'stimulus';
import consumer from "../../channels/consumer"

export default class extends Controller {
  static values = { id: Number }

  connect() {
    this.body = document.querySelector('body')
    this.listen()
  }

  disconnect() {
    this.body.removeEventListener(`videoPartUpload${this.idValue}:end`, this._received.bind(this))
  }

  listen() {
    this.body.addEventListener(`videoPartUpload${this.idValue}:end`, this._received.bind(this))
  }

  _received(event) {
    showElements(this.element)
    this._replaceCard(event.detail)
  }

  _replaceCard(data) {
    const controllerElement = this.element.closest('.content-card')
    const videoPartsController = this.application.getControllerForElementAndIdentifier(this.element.closest('.writing-modal-content-video'), 'video--video-parts')

    videoPartsController.replaceVideoCardHTML(data.video_html, data.card_html, this.idValue)
  }
}
