import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = { journeyId: String }

  connect() {
    this.patchUrl = `/journeys/${this.journeyIdValue}/steps/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'journey_step',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      draggable: ".playbook-step",
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    let id = event.item.dataset.journeyStepId
    var data = new FormData()

    data.append('position', event.newIndex + 1)

    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data
    })

    this.element.querySelectorAll('.pozindex').forEach( (poz, index) => {
      poz.innerText = index + 1
    }

    )
  }
}
