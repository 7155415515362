import { Controller } from 'stimulus'
import Swal from 'sweetalert2'
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { linkUrl: String }
  copyInviteLink() {
    const Url = event.target.dataset.linkUrlValue
    navigator.clipboard.writeText(Url)
    Swal.fire({
      title: I18n.t("js.sweet_alerts.invitation_link.title"),
      html: I18n.t("js.sweet_alerts.invitation_link.text_html", { url: Url }),
      icon: "success"
    })
  }
}
