import { Controller } from 'stimulus'
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = [ 'content', 'section', 'chapter' ]

  connect() {
    $('#read-search').selectize({ maxItems: 1 })
    $("#read-search").on("change", (e) => { this._filterData(e.target.value) })
    $(document).on('keyup', '#read-search-selectized', (e) => {
      this._filterData(e.target.value)
    })
    const readSearch = document.getElementById("read-search-selectized")
    if (readSearch) {
      readSearch.style.width = "100%"
    }
  }

  _filterData(queryString) {
    const regexQuery = new RegExp(queryString, 'iu')
    if (queryString === '') {
      this._displayAll()
    } else {
      const contents = this.contentTargets.filter(content => content.dataset.name.match(regexQuery));
      const sectionIds = contents.map(content => content.dataset.sectionId).filter((v, i, a) => a.indexOf(v) === i);
      const sections = this._filterByIds(this.sectionTargets, sectionIds)
      const chapterIds = sections.map(section => section.dataset.chapterId).filter((v, i, a) => a.indexOf(v) === i);
      const chapters = this._filterByIds(this.chapterTargets, chapterIds)

      const toDisplay = contents.concat(sections).concat(chapters)
      const toHide = this._allTargets().filter(x => !toDisplay.includes(x))
      this._show(toDisplay)
      this._hide(toHide)
    }
  }

  _filterByIds(elements, idsArray) {
    return elements.filter(element => idsArray.includes(element.dataset.id));
  }

  _show(arr) {
    arr.forEach((element) => { element.classList.remove('hidden') })
  }

  _hide(arr) {
    arr.forEach((element) => { element.classList.add('hidden') })
  }

  _allTargets() {
    return this.contentTargets.concat(this.sectionTargets).concat(this.chapterTargets)
  }

  _displayAll() {
    this._allTargets().forEach((element) => { element.classList.remove('hidden') })
  }

}

