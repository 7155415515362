import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['results', 'template']
  static values = { 
    paginationOutsideResults: { type: Boolean, default: false },
    templateAsSpinner: { type: Boolean, default: false }
   }

  connect() {
    // When the controller is connected, we start listening to the
    // ajax request sent by the pagination links
    this.startListeningToNav()
  }

  startListeningToNav() {
    // We listen to the ajax:before event sent by the pagination links
    this.element.addEventListener('ajax:before', (event) => {
      this.insertSpinner(this.resultsTarget)
      if (this.paginationOutsideResultsValue) {
        this.adjustCurrentPage(event)
      }
      // We prevent the default behavior of the link
      event.preventDefault()
      // We get the url to fetch
      let url = event.target.href
      // We merge the url with the eventual existing params of the window url to maintain other params
      const params = new URLSearchParams(window.location.search)
      params.forEach((value, key) => {
        if (key !== "page") {
          url += `&${key}=${value}`
        }
      })
      // We fetch the results
      this.fetchResults(url)
    })
  }

  insertSpinner(target) {
    if (this.templateAsSpinnerValue && this.hasTemplateTarget) {
      this.resultsTarget.innerHTML =  this.templateTarget.innerHTML
    } else {
      this.resultsTarget.innerHTML = '<div class="h-48 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
    }
  }

  fetchResults(url) {
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      // We dispatch the event ajax:success on the element
      this.resultsTarget.innerHTML = data
    })
  }

  adjustCurrentPage(event) {
    // We remove the class 'current border-indigo-500 text-indigo-600 border-t-2' from all the links
    const links = document.querySelectorAll('.kaminarilink')
    links.forEach((link) => {
      link.classList.remove('text-indigo-600')
    })
    // We add the class 'current border-indigo-500 text-indigo-600 border-t-2' to the clicked link
    event.target.classList.add('text-indigo-600')
    // We push or replace the page in the history
    const url = event.target.href
    // We parse the url to get the page number
    const pageNumber = url.match(/page=(\d+)/) ? url.match(/page=(\d+)/)[1] : 1

    // We get the current url
    const currentUrl = window.location.href
    if (currentUrl.match(/page=(\d+)/)) {
      // If the current url contains a page number, we replace it with the new one
      const newUrl = currentUrl.replace(/page=(\d+)/, `page=${pageNumber}`)
      window.history.replaceState({}, '', newUrl)
    } else {
      // if there are existing params in the url, we append the page param
      if (currentUrl.match(/\?/)) {
        const newUrl = `${currentUrl}&page=${pageNumber}`
        window.history.replaceState({}, '', newUrl)
      } else {
        // if there are no existing params in the url, we add the page param
        const newUrl = `${currentUrl}?page=${pageNumber}`
        window.history.replaceState({}, '', newUrl)
      }
    }
  }

}
