import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

const initSelect2 = () => {
  $('.search-select2').select2({
    placeholder: 'Selectionnez une option',
    containerCssClass : "text-komin-orange"
  });

  $(document).ready(function() {
    $('.js-example-basic-multiple').select2(
      {
        placeholder: 'Selectionnez un ou plusieurs tags',
        width: 'resolve',
        closeOnSelect: false,
      },
    );
});
}

export { initSelect2 };
