
import { Controller } from 'stimulus';
import Tribute from "tributejs"

const SVG_EMAIL = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>`

const SVG_USER = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5">
                    <path d="M4 19H2C2 15.6863 4.68629 13 8 13C11.3137 13 14 15.6863 14 19H12C12 16.7909 10.2091 15 8 15C5.79086 15 4 16.7909 4 19ZM19 16H17V13H14V11H17V8H19V11H22V13H19V16ZM8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C11.9972 10.208 10.208 11.9972 8 12ZM8 6C6.9074 6.00111 6.01789 6.87885 6.00223 7.97134C5.98658 9.06383 6.85057 9.9667 7.94269 9.99912C9.03481 10.0315 9.95083 9.1815 10 8.09V8.49V8C10 6.89543 9.10457 6 8 6Z" fill="currentColor"/>
                  </svg>`

const SVG_DELETE = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5">
                      <path d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z" fill="currentColor"/>
                    </svg>`
export default class extends Controller {
  static targets = ['input',
                    'placeholder',
                    'formHiddenIdsInput',
                    'formHiddenEmailsInput',
                    'submitBtn',
                    'form',
                    'invitationItem',
                  ]
  static values = {
    fetchUrl: String,
  }

  // ******** READ THIS ********
  // This controller is used to add mentions to a contenteditable div (https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/contenteditable)
  // It uses the tributejs library to handle the mentions: https://github.com/zurb/tribute
  // When you start to type in contenteditable div a dropdown appears showing the users that match the search
  // The fetch is done in the fetchUsers method and the results are passed to the tributejs library as json
  // selectTemplate is the callback that is called when the user selects a user from the dropdown
  // It adds the user to the contenteditable div and adds a hidden input to the form in order to be able to submit the form server side


  connect() {
    const roleSelect = this.roleSelectTarget
    // When the user clicks on the div, we focus on the input
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('click', (event) => {
        // this.inputTarget.focus()
        // We remove the placeholder when the user starts typing
        if (this.hasPlaceholderTarget) {
          this.placeholderTarget.remove()
        }
      })
    }
    this._pasteInputListener()
    this.initializeTribute()
  }

  // We remove the placeholder when the user starts typing
  removePlaceholder() {
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.remove()
    }
  }

  _pasteInputListener() {
    if (!this.hasInputTarget) return;
    
    this.inputTarget.addEventListener('paste', this._handlePaste.bind(this))
  }

  _handlePaste(event) {
    event.preventDefault();

    let clipboardData = event.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');

    // Assuming emails are separated by commas or new lines
    let emails = pastedData.split(/,|\n/).map(email => email.trim());
    emails.forEach(email => {
      if (/\S+@\S+\.\S+/.test(email)) {
        this.addEmailToDiv(email);
        this.updateHiddenInputs(email);
      }
    });
    this.inputTarget.innerHTML += '&nbsp;';
    // We place the cursor at the end of the last span
    this.setCursorToEnd();
  }

  setCursorToEnd() {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(this.inputTarget);

    // Move the range to the end
    range.collapse(false);

    sel.removeAllRanges();
    sel.addRange(range);
  }

  addEmailToDiv(email) {
    let emailUser = { email: email, type: 'email' };
    let spanElement = document.createElement('span');
    spanElement.innerHTML = this.createUserSpanTemplate(emailUser, true);
    this.inputTarget.appendChild(spanElement);
  }

  roleValue() {
    const role = this.element.querySelector('input[type="hidden"][id="role"]')
    if (role) {
      return role.value
    } else {
      return null
    }
  }

  highlightDuplicateUsers() {
    const usersList = this.inputTarget.querySelectorAll('[data-user-invitations-target="invitationItem"]')
    // We convert the NodeList to an array
    const users = Array.from(usersList)

    const emails = users.map((user) => user.dataset.email)
    // if the email is present more than once, we add a bg-red-100 class to the span
    // except for the first duplicate
    emails.forEach((email, index) => {
      if (emails.indexOf(email) !== index) {
        users[index].classList.add('bg-red-100')
      }
    })
  }

  // Triggered by the select Team select
  // Populated the contenteditable div with the users of the team
  addTeamUsers(event) {
    event.preventDefault()
    this.removePlaceholder()
    const teamId = event.currentTarget.value
    const teamUrl = `/teams/${teamId}/team_members?role=${this.roleValue()}&handover_id=${this.handoverIdFromUrl()}`
    const selectedOption = event.currentTarget.querySelector(`option[value="${teamId}"]`)

    fetch(`${teamUrl}`)
    .then(response => response.json())
    .then((data) => {
      data.forEach((user) => {
        let spanElement = document.createElement('span');
        spanElement.innerHTML = this.createUserSpanTemplate(user, true);
        this.inputTarget.appendChild(spanElement);
        this.updateHiddenInputs(user.email);
        // We disabled the changed option from the select
        selectedOption.disabled = true
      })
      this.highlightDuplicateUsers();
      this.inputTarget.innerHTML += '&nbsp;';

      this.setCursorToEnd();
      })
      .catch(error => console.warn(error))
  }

  updateHiddenInputs(email) {
    this.formHiddenEmailsInputTarget.value += `${email},`;
  }

  createUserSpanTemplate(user, isSelectable) {
    let color = user.color || 'gray';
    let svg = user.type === 'email' ? SVG_EMAIL : SVG_USER;
    let id = user.id || '';
    let email = user.email || '';

    let classList = `inline-flex items-center gap-x-1.5 rounded-full bg-${color}-100 px-2 py-1 text-sm li font-medium text-${color}-700`;
    if (isSelectable) {
      classList += ` contenteditable="false" data-user-invitations-target="invitationItem" data-id="${id}" data-email="${email}" data-value="${user.id}"`;
    }

    return `
      <span class="${classList}">
        ${svg}
        ${user.type === 'email' ? user.email : user.content}
        ${isSelectable ? `<span data-action="click->user-invitations#deleteMention" class="cursor-pointer hover:bg-gray-100">${SVG_DELETE}</span>` : ''}
      </span>
    `;
  }

  createMenuItemTemplate(user) {
    if (user.original.type == 'email') {
      return `<span class="inline-flex items-center gap-x-1.5">
                <span class="text-gray-400">${user.original.email}</span>
              </span>`
    } else {
    return `<span class="inline-flex items-center gap-x-1.5">
              <span>${user.original.content}</span>
            </span>`
    }
  }

  initializeTribute() {
    this.tribute = new Tribute({
      autocompleteMode: true,
      lookup: 'name',
      allowSpaces: true,
      values: this.fetchUsers,
      menuItemLimit: 10,
      menuShowMinLength: 1,
      requireLeadingSpace: false,
      menuItemTemplate: (item) => {
        return this.createMenuItemTemplate(item);
      },
      noMatchTemplate: function () {
        return '<span style:"visibility: hidden;"></span>';
      },
      selectTemplate: (item) => {
        return this.createUserSpanTemplate(item.original, true);
      }
    })
    if (this.hasInputTarget) {
      this.tribute.attach(this.inputTarget)
    }
  }

  getFetchUrl() {
    return document.querySelector('[data-user-invitations-fetch-url-value]').dataset.userInvitationsFetchUrlValue
  }

  handoverIdFromUrl() {
    const url = window.location.href
    const handoverId = url.match(/handovers\/(\d+)/)
    if (handoverId) {
      return handoverId[1]
    } else {
      return null
    }
  }

  fetchUsers(text, callback) {
    // We lookup for the url in the data attribute of the controller
    // 'data-user-invitations-fetch-url-value'
    const url = document.querySelector('[data-user-invitations-fetch-url-value]').dataset.userInvitationsFetchUrlValue
    const role = document.querySelector('input[type="hidden"][id="role"]')

    // to avoid type error 'role' is read-only
    const roleValue = role ? role.value : null

    fetch(`${url}?query=${text}&role=${roleValue}`)
      .then(response => response.json())
      .then((data) => {
        // If the text is an email
        if (/\S+@\S+\.\S+/.test(text)) {
          data.push({
            name: text,
            email: text,
            color: 'gray',
            type: 'email'
          })
        }
        callback(data)
      })
      .catch(error => callback([]))
  }

  deleteMention(event) {
    event.preventDefault()
    event.currentTarget.parentElement.remove()
  }

  // Form submission
  inviteUsers(event) {
    event.preventDefault()
    this._copyContenteditableToHiddenInput()
    if (this.hasFormTarget) {
      this.formTarget.submit()
    } else {
      event.currentTarget.submit()
    }
  }

  _copyContenteditableToHiddenInput() {
    if (this.hasInvitationItemTarget) {
      this.invitationItemTargets.forEach((item) => {
        let id = item.dataset.id
        let email = item.dataset.email
        // We add the id to the hidden input
        if (id) {
          this.formHiddenIdsInputTarget.value += id + ','
        }
        // We add the email to the hidden input
        if (email) {
          this.formHiddenEmailsInputTarget.value += email + ','
        }
      })
    }
    // We strip the last comma for both hidden inputs
    this.formHiddenIdsInputTarget.value = this.formHiddenIdsInputTarget.value.replace(/,\s*$/, "")
    this.formHiddenEmailsInputTarget.value = this.formHiddenEmailsInputTarget.value.replace(/,\s*$/, "")
  }

}
