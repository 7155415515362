import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "progress", "progressText", "progressWidth" ]

  connect() {
    this._addProgressListener()
    this._addErrorListener()
    this._addEndListener()
  }

  disconnect() {
    this.element.removeEventListener("direct-upload:progress", this._updateProgress)
  }

  showProgress() {
    showElements(this.progressTarget)
  }

  _addProgressListener() {
    this.element.addEventListener("direct-upload:progress", this._updateProgress.bind(this))
  }

  _addErrorListener() {
    this.element.addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      console.error(error)
    })
  }

  _updateProgress() {
    const { id, progress } = event.detail
    this.progressWidthTarget.style.width = `${Math.round(progress)}%`
    this.progressTextTarget.innerHTML = `${Math.round(progress)}% complete`
  }

  _addEndListener() {
    this.element.addEventListener("direct-upload:end", (event) => {
      hideElements(this.progressTarget)
    })
  }
}
