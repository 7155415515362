import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'quizz_question',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

    onEnd(e) {
      const parentModal = this.element.closest('.writing-modal-content-quizz')
      this.element.querySelectorAll('.question-card').forEach((card, i) => {
        card.querySelector('.position').innerText = i + 1
        const input = parentModal.querySelector(`.question-position-input[data-question-id="${card.dataset.questionId}"]`)
        parentModal.querySelector(`.title-index[data-question-id="${card.dataset.questionId}"]`).innerText = `Question #${i + 1}`
        input.value = i +1
        input.dispatchEvent(new Event('input', { 'bubbles': true }))
      })
    }
  }
