import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static values = {
    intervalUnit: String, // week, month, quarter, year...
    startDate: String,
    endDate: String,
    resultsId: String,
    fetchOnChange: Boolean
  }

  static targets = [ 
    'form',
    'startDateInput',
    'endDateInput',
    'displayedInterval',
    'previousButton',
    'nextButton' 
  ]

  initialize() {

    moment.locale('fr')
    this.intervalUnitValue = this.intervalUnitValue || 'month'
    this.startDateValue = this.startDateValue || moment().startOf('month').format('YYYY-MM-DD')
    this.endDateValue = this.endDateValue || moment().endOf('month').format('YYYY-MM-DD')
    this.currentStartDate = moment(this.startDateValue)
    this.currentEndDate = moment(this.endDateValue)
  }


  previousUnit() {
    if (this.intervalUnitValue == 'month') {
      this.currentStartDate = this.currentStartDate.subtract(1, 'month')
      this.currentEndDate = this.currentEndDate.subtract(1, 'month').endOf('month')

      this.startDateInputTarget.value = this.currentStartDate.format('YYYY-MM-DD')
      this.endDateInputTarget.value = this.currentEndDate.format('YYYY-MM-DD')

      this.displayedIntervalTarget.innerText = this.currentStartDate.format('D MMM') + ' - ' + this.currentEndDate.format('D MMM')
    }
    if (this.fetchOnChangeValue) {
      this.submitForm()
    }
  }

  nextUnit() {
    // We check if the currentStartDate is after today to prevent the user to go to the future
    if (this.currentEndDate.isAfter(moment())) { return }

    if (this.intervalUnitValue == 'month') {

      this.currentStartDate = this.currentStartDate.add(1, 'month')
      this.currentEndDate = this.currentEndDate.add(1, 'month')

      this.startDateInputTarget.value = this.currentStartDate.format('YYYY-MM-DD')
      this.endDateInputTarget.value = this.currentEndDate.format('YYYY-MM-DD')

      this.displayedIntervalTarget.innerText = this.currentStartDate.format('D MMM') + ' - ' + this.currentEndDate.format('D MMM')
    }
    if (this.fetchOnChangeValue) {
      this.submitForm()
    }
  }

  submitForm() {
    // this.formTarget.submit()
  }

}