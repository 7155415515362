import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'input', 'results', 'nbResults']
  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  submitForm(e) {
    e.preventDefault()

    const url = this.formTarget.action
    const formData = new FormData(this.formTarget)
    const params = new URLSearchParams(formData).toString()
    const fullUrl = `${url}?${params}`
    this.fetchResults(fullUrl)
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.resultsTarget.innerHTML = data
      if (this.hasNbResultsTarget) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, "text/html")
        const nbResults = doc.querySelector("#nb-results").innerText
        this.nbResultsTarget.innerHTML = nbResults
      }
    })
  }

  insertSpinner(target) {
    if (this.hasResultsTargetTarget) {
      this.resultsTarget.innerHTML = '<div class="h-48 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
    }
  }
}