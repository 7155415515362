import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'templateId', 'form', 'name', 'usecase', "radio", "library" ]
  static values = { library: Number }

  selectTemplate(e) {
   this.prepopulate(e)
   this.formTarget.submit()
  }

  prepopulate(e) {
   this.idValue = e.currentTarget.dataset.id
   if (this.hasTemplateIdTarget) { this.templateIdTarget.value = this.idValue }
   this.nameTarget.value = e.currentTarget.dataset.name
   if (this.libraryValue!= 0) { this.libraryTarget.value = this.libraryValue }
  }

  selectType(e) {
    this.useCaseValue = e.currentTarget.dataset.usecaseid
    this.usecaseTarget.value = this.useCaseValue
    if (this.hasTemplateIdTarget) { this.templateIdTarget.value = "" }
    if (this.libraryValue!= 0) { this.libraryTarget.value = this.libraryValue }
    this.formTarget.submit()
    // We emit a turbolinks:click event to trigger the global loader
    document.dispatchEvent(new Event('turbolinks:click'))
  }
}
