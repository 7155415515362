import { Controller } from 'stimulus'
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = []
  static values = {sharingId: Number}

  connect() {
    document.addEventListener('slideover:close_content', this.updateReadStatus)
  }

  updateReadStatus(e) {
    this.urlValue = '/contents/:id/mark_comments_as_read?section_id=prout'
    const preumsUrl = this.urlValue.replace("prout", e.detail.section_id)
    const url = preumsUrl.replace(":id", e.detail.id)

    const options  = {
      method: 'POST',
      headers: { 'Accept': 'application/json' }
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then((data) => {
        if (data.failure) {
          console.log('error')
        } else {
          const relevantTarget = document.querySelector(`.bubulle[data-id="${e.detail.id}"]`)
          relevantTarget.innerHTML = data.partial
          const headerDrawer = document.querySelector('.comments-drawer-ajax')
          if (headerDrawer) {
            headerDrawer.innerHTML = data.header_partial
          }
      }
    })

    //console.log(`le mec a lu les comments du content ${e.detail.id}, ${e.detail.section_id}`)
  }
}
