import { Controller } from "stimulus"
import consumer from "../channels/consumer"
import Swal from 'sweetalert2';


export default class extends Controller {
  static values = { journeyId: Number }

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "UnlockSharingsChannel", id: this.journeyIdValue },
      { received: (data) => {
          location.reload(true)
        }
      }
    )
  }
}
