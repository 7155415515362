import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'drawer', 'loader']

  static values = {handoverId: String}

  calldrawer(e) {
    const versionId = e.currentTarget.dataset.versionId
    //passer la bonne id au content loader du drawer
    this.loaderTarget.dataset.contentLoaderUrlValue = `/handovers/${this.handoverIdValue}/versions/${versionId}.text`
    //forcer le reload du content-loader
    this.application.getControllerForElementAndIdentifier(this.loaderTarget, "content-loader").connect()
    // ouvrir le drawer
    this.application.getControllerForElementAndIdentifier(this.element, "slideover").slide()
  }
}
