import { Controller } from 'stimulus';

export default class extends Controller {
  switchWriteToRead(event) {
    // We do not prevent default unless the path is not write
    if (!window.location.pathname.match(/\/write$/)) { return }
    event.preventDefault()
    const url = new URL(window.location.href)
    url.pathname = url.pathname.replace('write', 'read')
    window.location.href = `${url}&rerouted=true`
  }

  switchReadToWrite(event) {
    // We do not prevent default unless the path is not read

    if (!window.location.pathname.match(/\/read$/)) { return }
    event.preventDefault()
    const url = new URL(window.location.href)
    url.pathname = url.pathname.replace('read', 'write')
    if (url.href.match(/&rerouted=true/)) {
      url.href = url.href.replace('&rerouted=true', '')
    }
    window.location.href = url
  }
}
