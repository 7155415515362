import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { fetchWithToken } from '../application/stimulus_helper';


export default class extends Controller {
  static targets = ['modal', 'header', 'results', 'form', 'query', 'input', 'oldImage', 'image', 'uploadform', 'saveBtn', 'inputfile', 'modal']
  static values = {
    orientation: String
  }

  connect() {
    this.body = document.querySelector('body')
    this.overflowValue = this.body.style.overflow || 'visible'
  }

  open() {
    fadeShowElements(this.modalTarget)
    this.body.style.overflow = 'hidden'
    const form = this.formTarget
    const url = `${form.action}?orientation=${this.orientationValue}`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  hideModal(event) {
    if (event.currentTarget == event.srcElement) this.closeModal()
  }

  closeModal() {
    this.body.style.overflow = this.overflowValue
    stopAllPlayingMedias()
    fadeHideElements(this.modalTarget)
  }

  search(e) {
    e.preventDefault()
    const form = this.formTarget
    const url = `${form.action}?query=${this.inputTarget.value}&orientation=${this.orientationValue}`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  select(e){
    this.imageTargets.forEach( (image) => {
      image.classList.remove('border-4')
    })
    e.currentTarget.classList.add('border-4');
    this.uploadformTarget.reset();
    this.revealSave();
  }

  revealSave() {
    this.saveBtnTarget.classList.remove('hidden')
  }

  submit() {
    if (this.inputfileTarget.files.length == 0) {
      const selected = document.querySelector('.border-4');
      const url = selected.dataset.url
      fetch(url)
        .then(response => response.blob())
        .then(blob => new File([blob], "library-banner.jpg", {
   		    type: blob.type
	      }))
        .then(file => {
          var list = new DataTransfer()
          list.items.add(file);
          this.inputfileTarget.files = list.files;
          this.submitForm();
	      })
    } else {
      this.submitForm()
    }
  }

  submitForm() {
    const formData = new FormData(this.uploadformTarget)
    const url = this.uploadformTarget.action

    fetchWithToken(url, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (this.hasHeaderTarget) {
        this.headerTarget.innerHTML= data.header
      }


      if (this.hasOldImageTarget) {
        this.oldImageTarget.src = data.image
      }
    })

    this.resetCloseandReload()
  }

  resetCloseandReload() {
    this.uploadformTarget.reset();
    this.formTarget.reset();
    this.resultsTarget.innerHTML="";
    fadeHideElements(this.modalTarget);
    this.body.style.overflow = this.overflowValue;
  }

  purgeImage(event) {
    event.preventDefault();
    console.log(event.currentTarget.closest('form'))

    const form = event.currentTarget.closest('form')
    const url = form.action
    
    fetchWithToken(url, {
      method: 'DELETE',
      headers: {"Accept": "application/json" }
    })
    .then(response => response.json())
    .then((data) => {
      if (this.hasHeaderTarget) {
        this.headerTarget.innerHTML= data.header
      }

      if (this.hasOldImageTarget) {
        this.oldImageTarget.src = data.image
      }      
    })
  }
}
