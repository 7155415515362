import { Controller } from 'stimulus'
import Plyr from 'plyr'

export default class extends Controller {
  static values = { index: Number, id: Number }

  connect() {
    this._displayVideo()
  }

  _displayVideo() {
    new Plyr(this.element, {
      modestbranding: 1,
      rel: 0
    });
  }
}
