const scrollToLinkTarget = () => {
  const links = document.querySelectorAll('.scroll-to-link')
  if (links) {
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        var target = document.querySelector(link.dataset.target)
        window.scrollTo({
          left: 0,
          top: target.offsetTop,
          behavior: 'smooth'
        })
      })
    })
  }
}

export { scrollToLinkTarget }
