import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['results', 'template', 'nbResults', 'form', 'input', 'paginationWrapper']

  searchAndFilter(event) {
    console.log("searchAndFilter")
    event.preventDefault()
    const url = this.formTarget.action
    const data = new FormData(this.formTarget)


    // We append the params to the url because it's a GET request
    let urlWithParams = url + "?"
    data.forEach((value, key) => {
      urlWithParams += `${key}=${value}&`
    })


    // We fetch after 400ms to avoid too many requests
    setTimeout(() => {
      this.fetchResults(urlWithParams)
    }, 400)
  }


  sort(event) {
    event.preventDefault()
    // the url is something like http://localhost:3000/handovers/1524/readers?direction=desc&page=1&sort=name
    let url = event.target.href
    if (url.includes("readers")) {
      window.history.pushState({}, "", url)
      url = url.replace("readers", "readers/fetch_readers_table")
    }



    const params = new URLSearchParams(window.location.search)
    params.forEach((value, key) => {
      if (key !== "sort" && key !== "direction") {
        url += `&${key}=${value}`
      }
    })


    let icon = event.target.querySelector("i")
    if (!icon) {
      event.target.innerHTML += ' <i class="fas fa-caret-down"></i>'
    }
    this.fetchResults(url)
    this.changeSortUrlDirection(event.target)
    this.changeSortIcon(event.target)
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      const parser = new DOMParser()
      // const doc = parser.parseFromString(data, "text/html")
      // const nbResultsString = doc.querySelector("#nb-results").innerText
      this.nbResultsTarget.innerHTML = data.nb_results
      // const nbResults = parseInt(nbResultsString.match(/\d+/)[0])


      this.resultsTarget.innerHTML = data.html_partial
      // We count the number of <tr> elements in the table
      if (data.display_pagination) {
        if (this.hasPaginationWrapperTarget) {
          this.paginationWrapperTarget.classList.add("hidden")
        }
      } else {
        if (this.hasPaginationWrapperTarget) {
          this.paginationWrapperTarget.classList.remove("hidden")
        }
      }
    })
  }

  insertSpinner(target) {
    this.resultsTarget.innerHTML =  this.templateTarget.innerHTML
  }


  changeSortUrlDirection(target) {
    const currentDirection = target.href.includes("asc") ? "asc" : "desc"
    const newDirection = currentDirection === "asc" ? "desc" : "asc"
    target.href = target.href.replace(currentDirection, newDirection)
  }

  changeSortIcon(target) {
    const currentIcon = target.querySelector("i")
    const newIcon = currentIcon.classList.contains("fa-caret-up") ? "fa-caret-down" : "fa-caret-up"
    currentIcon.classList.remove("fa-caret-up", "fa-caret-down")
    currentIcon.classList.add(newIcon)
  }
}