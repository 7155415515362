import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {

  static targets = ['display', 'close', 'plus']
  static values = {fetchUrl: String,
                   position: Number,}

  show() {
    const url = `${this.fetchUrlValue}?position=${this.positionValue}`
    fetchWithToken(url, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        this.displayTarget.classList.add('animate-slide')
        this.displayTarget.innerHTML = data.buttons
        this.plusTarget.classList.add('hidden')
        this.closeTarget.classList.add('animate-pop')
        this.closeTarget.classList.remove('hidden')
    })
  }

  hide() {
    this.displayTarget.classList.remove('animate-slide')
    this.displayTarget.innerHTML=''
    this.plusTarget.classList.add('animate-pop')
    this.closeTarget.classList.add('hidden')
    this.plusTarget.classList.remove('hidden')
  }
}
