import { Controller } from 'stimulus'
import Rails from "@rails/ujs"
import Swal from 'sweetalert2'
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ 'commentsCount', 'likesContainer', 'commentsListContainer', 'commentsContainer',
                     'commentForm', 'commentFormContainer', 'submit', 'nextCommentsBtn', 'bottomCount',
                     'flagForm', 'flagContainer', 'commentInput', 'comments', 'scrollContainer', 'emptyState', 'unflagButton' ]
  static values = {
    commentableId: Number,
    commentableType: String,
    sectionId: Number,
    userId: Number,
    handoverId: Number
  }

  connect() {
    this._broadcast()
  }

  toggleComments() {
    this.commentsTarget.classList.toggle('hidden')
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "CommentableCommentsChannel", id: this.commentableIdValue, type: this.commentableTypeValue },
      { received: (data) => {
        const userIsSender = this.userIdValue == data.user_id
          this._updateCommentCount(data.comment_count)
          if (data.flag) {
            this.commentsContainerTargets[0].innerHTML = data.flag_html_top
            if (this.commentsContainerTargets.length > 1) { this.commentsContainerTargets[1].innerHTML = data.flag_html_bottom }
          }
          if (data.created) {
            const userStatus = userIsSender ? 'sender' : 'receiver'
            if (data.internal && !userIsSender) { return }
            if(this.hasEmptyStateTarget) {
              this.emptyStateTarget.classList.add('hidden')
            }
            this._resetForms()
            if (this.commentableTypeValue == 'Content' && !data.flag) {
              this.commentsTarget.insertAdjacentHTML('beforeend', data[`${userStatus}_html`])
              this.scrollContainerTarget.scrollTop = this.scrollContainerTarget.scrollHeight
              // We append this new comment in section comments too it's not the same controller so we need to do it manually
              if (this.sectionIdValue) {
                const sectionComment = document.getElementById(`section-comments-${this.sectionIdValue}`)
                if (sectionComment) {
                  sectionComment.insertAdjacentHTML('afterBegin', data[`${userStatus}_html`])
                }
              }
            } else if (this.commentableTypeValue == 'Section') {
              this.commentsTarget.insertAdjacentHTML('afterBegin', data[`${userStatus}_html`])
            }
          } else if (data.deleted) {
            document.querySelector(`[data-comment-id="${data.comment_id}"]`).remove()
          } else if (data.errors.length > 0) {
            if (userIsSender) {
              this.commentFormContainerTarget.outerHTML = data.form_html
            }
          }
        }
      }
    )
  }

  _updateCommentCount(count) {
    if (this.hasCommentsCountTarget) {
      const topString = this.commentsCountTarget.innerHTML.replace(/\d+/, count)
      this.commentsCountTarget.innerHTML = topString
      if (this.hasBottomCountTarget) {
        let bottomString = this.bottomCountTarget.innerHTML.replace(/[^\/]\d*$/, count)
        bottomString = bottomString.replace(/\d*[^\/]/, parseInt(bottomString.match(/[^\/]*$/)[0], 10) - 1)
        this.bottomCountTarget.innerHTML = bottomString
      }
    }
  }

  save(e) {
    if (e.keyCode === 13 && e.shiftKey || e.keyCode !== 13 || e.currentTarget.value.match(/^\s*$/)) { return }
    if (e.keyCode === 13) { return } // Because of mentions controller
    Rails.ajax({
      url: this.commentFormTarget.action,
      type: "post",
      data: new FormData(this.commentFormTarget),
      success: (data) => {
        this._resetForms()
        // this._updateCommentCount(data.comment_count)
        // this.commentsContainerTarget.outerHTML = data.comments_html
      },
      error: (data) => {
      }
    })
  }

  unflag(event) {
    const url = event.currentTarget.dataset.url
    const alertTitle = event.currentTarget.dataset.alertTitle
    const alertContent = event.currentTarget.dataset.alertContent
    const confirmText = event.currentTarget.dataset.confirmText
    const cancelText = event.currentTarget.dataset.cancelText
    const comment = event.currentTarget.closest('[data-comment-id]')

    Swal.fire({
        title: alertTitle,
        text: alertContent,
        icon: 'question',
        confirmButtonColor: '#3d52d5',
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText,
      }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          url: url,
          type: "patch",
          accept: 'json',
          success: (data) => {
            const unflagButton = this.unflagButtonTargets.length > 1 ? this.unflagButtonTargets[1] : this.unflagButtonTarget
            if (unflagButton.dataset.fromDrawer) {
              // We grab the parent with the data-comment-id attribute
              if (comment) {
                comment.outerHTML = data.comments_html
              }
            }
              this.unflagButtonTarget.remove()
          },
          error: (data) => {
          }
        })
      }
    });

  }

  delete(event) {
    const deleteUrl = event.currentTarget.dataset.deleteUrl
    const alertTitle = event.currentTarget.dataset.alertTitle
    const alertContent = event.currentTarget.dataset.alertContent
    const confirmText = event.currentTarget.dataset.confirmText
    const cancelText = event.currentTarget.dataset.cancelText

    Swal.fire({
        title: alertTitle,
        text: alertContent,
        icon: 'question',
        confirmButtonColor: '#3d52d5',
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText,
      }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          url: deleteUrl,
          type: "delete"
        })
      }
    });
  }

  like() {
    Rails.ajax({
      url: `/contents/${this.commentableIdValue}/like`,
      type: "post",
      success: (data) => {
        this._updateLikes(data.likes_html)
      },
      error: (data) => {
      }
    })
  }

  unlike() {
    Rails.ajax({
      url: `/contents/${this.commentableIdValue}/unlike`,
      type: "delete",
      success: (data) => {
        this._updateLikes(data.likes_html)
      },
      error: (data) => {
      }
    })
  }

  loadNextComments(event) {
    const url = event.currentTarget.dataset.nextCommentsUrl

    Rails.ajax({
      url: url,
      type: "get",
      success: (data) => {
        this.nextCommentsBtnTarget.remove()
        this.commentsListContainerTarget.insertAdjacentHTML('beforeend', data.comments_html)
      },
      error: (data) => {
      }
    })
  }

  _resetForms() {
    if (this.hasFlagFormTarget) {
      this.flagFormTarget.reset()
    }
    this.commentFormTarget.reset()
    this.commentInputTarget.value = ''
    this.commentInputTarget.classList.remove('border-komin-red')
    this.commentFormTarget.querySelectorAll('.error').forEach((e) => e.remove())
  }

  _updateLikes(html) {
    this.likesContainerTarget.innerHTML = html
  }
}
