import { Controller } from 'stimulus';
import { PlayerSdk }    from '@api.video/player-sdk'

export default class extends Controller {
  static targets = [
    'videoTag',
    'controls',
    'totalVideoTime',
    'elapsedVideoTime',
    'progressContainer',
    'playPause',
    'pauseIcon',
    'playIcon',
    'volumeInput',
    'muteIcon',
    'audioIcon',
    'progress',
    'fullscreen',
    'playrateBtn',
    'hideStBtn',
    'showStBtn',
    'mainPlayIcon',
    'controlsContainer',
    'thumbnailContainer',
    'thumbnail',
    'mainPauseIcon',
  ]

  static values = {
    playerId: String,
    apiVideoId: String,
    apiVideoToken: String,
    multipleVideoParts: Boolean,
    start: { type: Number, default: 0 },
    end: { type: Number, default: 0 },
    index: Number,
    isPhone: { type: Boolean, default: false },
    muted: { type: Boolean, default: false }
  }

  connect() {
    this.isFullscreenMode = false
    this._setReadingVideoController()
    if (this.apiVideoTokenValue) {
      this.player = new PlayerSdk(`.${this.playerIdValue}`, {
        id: this.apiVideoIdValue,
        token: this.apiVideoTokenValue,
        muted: this.mutedValue || localStorage.getItem('muted') === 'true',
        mp4Support: true,
      });

      this.player.addEventListener('ready', this._displayVideoControls.bind(this))
      document.addEventListener('video-api:pause', (e) => {
        if (this.player) {
          this.player.pause()
        }
      })
    }
  }

  disconnect() {
    // We pause all the videos when the user leaves the page
    this.playPause()
  }


  _displayVideoControls() {
    this.player.setCurrentTime(this.startValue)
    this.player.hideControls()
    this.player.getDuration().then((duration) => {
      this.duration = duration

      this.endValue = this.endValue || this.duration

      // showElements(this.controlsContainerTarget)

      this._initVideoControls()
    })
  }

  _initVideoControls() {
    // round the duration
    this.videoDuration = roundToDecimal(this.endValue - this.startValue, 10)

    // format the time to minutes and seconds
    const time = formatTime(this.videoDuration)

    // display the time under human format in DOM
    this.totalVideoTimeTarget.innerText = `${time.minutes}:${time.seconds}`

    // set the datetime attribute
    this.totalVideoTimeTarget.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`)

    this.player.addEventListener("firstplay", () => this.player.setCurrentTime(this.startValue));

    // when the video is playing and its current playing time is changed
    this.player.addEventListener('timeupdate', (e) => {
      // update the elapsed time DOM tag
      this._updateTimeElapsed(e.currentTime)
      // update the range input
      this._updateProgress(e.currentTime)
      const ended = e.currentTime > this.endValue
      if (this.endValue && ended) {
        this.pause()
        this._videoEndBehavior()
      }

    })
    this.player.addEventListener('ended', () => { this._videoEndBehavior() })

    // Set the max range of the progress bar and the range input with the video duration
    // this.videoProgressHandleTarget.setAttribute('max', videoDuration + this.startValue);
    this.progressTarget.setAttribute('max', this.videoDuration);
  }

  _videoEndBehavior() {
    this.goTo(this.startValue)
    this.readingVideoController.playNext(this.indexValue)

  }

  _updateTimeElapsed(currentTime) {
    var elapsedTimeFloat = roundToDecimal(currentTime - this.startValue, 1)
    if (isNaN(elapsedTimeFloat)) elapsedTimeFloat = 0

    const elapsedTime = formatTime(elapsedTimeFloat)
    this.elapsedVideoTimeTarget.innerText = `${elapsedTime.minutes}:${elapsedTime.seconds}`;
    this.elapsedVideoTimeTarget.setAttribute('datetime', `${elapsedTime.minutes}m ${elapsedTime.seconds}s`)
  }

  skip(event) {
    const rect = this.progressTarget.getBoundingClientRect();
    const pos = (event.pageX  - rect.left) / this.progressTarget.offsetWidth;
    const value = pos * this.videoDuration + this.startValue;

    if (this.startValue && value < this.startValue) {
      this.goTo(this.startValue)
    } else if (this.endValue && value > this.endValue) {
      this.goTo(this.endValue)
    } else {
      this.goTo(value)
    }
  }

  goTo(value) {
    this.player.setCurrentTime(value)
    this.progressTarget.value = value - this.startValue
  }

  playPause() {
    if (this.player) {
      this.player.getPaused()
        .then((paused) => {
          if (paused) {
            stopAllPlayingMedias()
            this.play()
            dispatchCustomEvent('video-api:realplay')
          } else {
            this.pause()
            dispatchCustomEvent('video-api:realpause')
          }
        })
    }
  }

  play() {
    // if (this.thumbnailContainerTarget) {
    //   this.thumbnailContainerTarget.style.opacity = 0
    // }
    this.listenMouseMovementForDisplayingControls()
    this.adjustVolume()
    this.adjustMute()
    this.adjustPlayrate()
    this._stopOtherVideos(this.element)
    this.player.getCurrentTime()
      .then((time) => {
        if (time < this.startValue || time > this.endValue) {
          this.player.setCurrentTime(this.startValue)
        }
        this.player.play()

        hideElements(this.playIconTarget, this.controlsContainerTarget, this.mainPlayIconTarget, this.thumbnailTarget)
        showElements(this.pauseIconTarget)
      })
  }

  playFromThumbnail(event) {
    this.playPause()
  }

  pause() {
    if (this.hideControlsTimeout) clearTimeout(this.hideControlsTimeout);
    this.playerPause()

    hideElements(this.pauseIconTarget)
    showElements(this.playIconTarget, this.controlsContainerTarget, this.mainPlayIconTarget)
    // this.readingVideoController.showVideoPartsContainer();
  }

  playerPause() {
    if (!this.player) return
    this.player.pause();
  }

  listenMouseMovementForDisplayingControls() {
    this.element.addEventListener('mousemove', () => {
      if (this.hideControlsTimeout) clearTimeout(this.hideControlsTimeout);

      showElements(this.controlsContainerTarget)
      this.hideControlsTimeout = setTimeout(() => {
        hideElements(this.controlsContainerTarget)
      }, 3000)
    })
  }

  adjustVolume() {
    let volume_from_storage = localStorage.getItem('volume')
    let volume = volume_from_storage ? parseFloat(volume_from_storage) : 1
    this.volumeInputTarget.value = volume
    this.player.setVolume(volume)
  }

  adjustMute() {
    let muted_from_storage = localStorage.getItem('muted')
    let muted = this.mutedValue || muted_from_storage === 'true'
    if (muted) {
      this.player.mute()
    } else {
      this.player.unmute()
    }
    if (this.hasAudioIconTarget) {
      this.audioIconTarget.classList.toggle('hidden', muted)
    }
    if (this.hasMuteIconTarget) {
      this.muteIconTarget.classList.toggle('hidden', !muted)
    }
  }


  updateVolume() {
    this.player.getMuted().then((muted) => {
      if(muted) {
        this.player.unmute()
        this.audioIconTarget.classList.remove('hidden')
        this.muteIconTarget.classList.add('hidden')
      }
    })
    localStorage.setItem('volume', this.volumeInputTarget.value)
    this.player.setVolume(this.volumeInputTarget.value)
  }

  mute() {
    this.player.getMuted().then((muted) => {
      if(muted) {
        this.player.unmute()
        localStorage.setItem('muted', 'false')
      } else {
        this.player.mute()
        localStorage.setItem('muted', 'true')
      }
      if (this.hasAudioIconTarget) {
        this.audioIconTarget.classList.toggle('hidden', !muted)
        this.muteIconTarget.classList.toggle('hidden', muted)
      }
    })
  }

  _updateProgress(currentTime) {
    this.progressTarget.value = currentTime - this.startValue
  }

  adjustPlayrate() {
    let playrate_from_storage = localStorage.getItem('playrate')
    let playrate = playrate_from_storage ? parseFloat(playrate_from_storage) : 1
    this.player.setPlaybackRate(playrate)
    this.playrateBtnTarget.innerText = `x${playrate}`
  }

  setPlayrate(e) {
    const playrateValue = e.params.value
    // We set the playback rate of the video in the local storage
    localStorage.setItem('playrate', playrateValue)
    this.player.setPlaybackRate(playrateValue)

    this.playrateBtnTarget.innerText = `x${playrateValue}`
  }

  handleFullscreen() {
    if (this.isFullscreenMode) {
      this.exitFullscreen()
    }
    else {
      this.enterFullscreen()
    }
  }

  enterFullscreenIphoneOnSafari() {
    this.isFullscreenMode = true
    document.body.style.overflow = 'hidden'
    const container = this.element.closest("#readVideoContainer")
    if (this.hasThumbnailTarget) {
      hideElements(this.thumbnailTarget)
    }

    // We stretch the video to the full width of the screen
    container.style.width = '100dvw'
    container.style.height = '100dvh'
    container.style.objectFit = 'cover'
    container.style.objectPosition = 'center'
    container.style.position = 'fixed'
    container.style.top = '0'
    container.style.left = '0'
    container.style.zIndex = '9999'
    // container.style.background = 'black'
    container.style.margin = '0'
    container.style.padding = '0'
    container.style.border = 'none'
    container.style.borderRadius = '0'
    // We add a listener to exit the fullscreen when the user clicks on the e
    // this.fullscreenTarget.addEventListener('click', this.exitFullscreenIphoneOnSafari.bind(this))
  }

  exitFullscreenIphoneOnSafari() {
    this.isFullscreenMode = false
    document.body.style.overflowY = 'auto'
    const container = this.element.closest("#readVideoContainer")
    if (this.hasThumbnailTarget) {
      showElements(this.thumbnailTarget)
    }
    // We remove the listener to exit the fullscreen when the user clicks on the e
    // this.fullscreenTarget.removeEventListener('click', this.exitFullscreenIphoneOnSafari.bind(this))
    // We remove the fullscreen styles
    container.style.width = ''
    container.style.height = ''
    container.style.objectFit = ''
    container.style.objectPosition = ''
    container.style.position = ''
    container.style.top = ''
    container.style.left = ''
    container.style.zIndex = ''
    container.style.background = ''
    container.style.margin = ''
    container.style.padding = ''
    container.style.border = ''
    container.style.borderRadius = ''
  }


  enterFullscreen() {
    if (document.querySelector('.intercom-lightweight-app-launcher')) {
      document.querySelector('.intercom-lightweight-app-launcher').style.display = "none"
    }
    this.isFullscreenMode = true
    const container = this.element.closest("#readVideoContainer")
    if ((/iPhone/i).test(navigator.userAgent) && (/Safari/i).test(navigator.userAgent)) {
      this.enterFullscreenIphoneOnSafari()
      this.setFullscreenData(true);


    } else {
       // Before custome fullscreen feature 👇
      if (container.requestFullscreen) container.requestFullscreen();
      else if (container.mozRequestFullScreen) container.mozRequestFullScreen();
      else if (container.webkitRequestFullScreen) container.webkitRequestFullScreen();
      else if (container.msRequestFullscreen) container.msRequestFullscreen();
      else if (container.webkitEnterFullscreen) container.webkitEnterFullscreen();
      else console.error('No fullscreen available')


      this.element.addEventListener('keyup', event => {
        if(event.code === 'Space') {
          this.playPause()
        }
      })
      // const containerElement = this.element.closest('#readVideoContainer')

      // if (containerElement) {
      //   containerElement.classList.add('custom-fullscreen')

      //   this.isFullscreenMode = true
      // }
    }
    // this.setFullscreenData(true);
  }

  exitFullscreen() {
    this.isFullscreenMode = false
    if (document.querySelector('.intercom-lightweight-app-launcher')) {
      document.querySelector('.intercom-lightweight-app-launcher').style.display = "block"
    }
    if ((/iPhone/i).test(navigator.userAgent) && (/Safari/i).test(navigator.userAgent)) {
      this.exitFullscreenIphoneOnSafari()
    } else {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else console.error('No fullscreen available')

      // const containerElement = this.element.closest('#readVideoContainer')
      // if (containerElement) {
      //   containerElement.classList.remove('custom-fullscreen')

      //   this.isFullscreenMode = false
      // }
    }
    // this.setFullscreenData(false);
  }

  displayFullScreenButton() {
    const fullscreenAvailableOnFigureElement = this.element.requestFullscreen ||
                                                this.element.mozRequestFullScreen ||
                                                this.element.webkitRequestFullScreen ||
                                                this.element.msRequestFullscreen ||
                                                this.element.webkitEnterFullscreen
    if (!fullscreenAvailableOnFigureElement) hideElements(this.fullscreenTarget)
  }

  setFullscreenData(state) {
    this.element.setAttribute('data-fullscreen', !!state);
  }


  _setReadingVideoController() {
    this.readingVideoController = this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller="reading--video"]'), 'reading--video')
  }

  hideSt() {
    this.player.hideSubtitles()
    hideElements(this.hideStBtnTarget)
    showElements(this.showStBtnTarget)

  }

  showSt() {
    this.player.showSubtitles()
    hideElements(this.showStBtnTarget)
    showElements(this.hideStBtnTarget)
  }

  _stopOtherVideos() {
    // We retrieve all the api_video-reading-controls controllers on the page
    const apiVideoReadingControlsControllers = document.querySelectorAll('[data-controller="api-video--reading-controls"]')
    // We loop through each controller
    apiVideoReadingControlsControllers.forEach((controller) => {
      // We retrieve the api_video-reading-controls controller instance
      const apiVideoReadingControlsController = this.application.getControllerForElementAndIdentifier(controller, 'api-video--reading-controls')
      // We check if the controller is not the current one
      if (apiVideoReadingControlsController !== this) {
        // We pause the video
        apiVideoReadingControlsController.playerPause()
      }
    })
  }

}
