import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [
    'pill',
    'videoTagContainer',
    'videoTag',
    'thumbnail',
    'videoPartsContainer',
    'videoPartName',
  ]

  connect() {
    // We listen to the dispatchCustomEvent('video:video_parts:edit_name', { id: id, name: data.name })
    // to update the video name
    document.addEventListener('video:video_parts:edit_name', (event) => {
      this.videoPartNameTargets.forEach(e => {
        if (e.dataset.videoPartId == event.detail.id) {
          e.innerText = event.detail.name
        }
      })
    })
    document.addEventListener('video-api:realpause', (event) => {
      this.videoPartsContainerTarget.classList.remove('hidden')
      this.videoPartsContainerTarget.classList.add('flex')
    })
    document.addEventListener('video-api:realplay', (event) => {
      if (this.hasVideoPartsContainerTarget) {
        this.videoPartsContainerTarget.classList.add('hidden')
        this.videoPartsContainerTarget.classList.remove('flex')
      }
    })
  }

  goTo(event) {
    this.stopVideos()
    const index = event.params.index
    this.displayVideo(index)
  }

  toggleVps() {
    this.videoPartsContainerTarget.classList.toggle('hidden')
  }


  playNext(currentIndex) {
    const nextIndex = currentIndex + 1

    // We need to check if the video is played fullscreen
    let currentVideo = this.videoTagContainerTargets[currentIndex]
    let figure = currentVideo.querySelector('#videoContainer')

    if (figure && figure.dataset.fullscreen === 'true') {
      this.stopVideos()
    } else {
      this.addBackgroundLayout(nextIndex)
      this.displayVideo(nextIndex)
      this.playVideo(nextIndex)
    }
  }

  addBackgroundLayout(nextIndex) {
    // We create a div that will be used to add a background layout
    let div = document.createElement('div')
    div.id = 'backgroundLayout'
    if (this.videoTagContainerTargets[nextIndex]) {
      if (this.videoTagContainerTargets[nextIndex].querySelector('#backgroundLayout')) {
        this.videoTagContainerTargets[nextIndex].querySelector('#backgroundLayout').remove()
      }
      this.videoTagContainerTargets[nextIndex].appendChild(div)
      div.classList.add('bg-black', 'absolute', 'top-0', 'left-0', 'w-full', 'h-full', 'z-10', 'fadeOut')
    }
  }

  displayVideo(index) {
    // this.stopVideos()
    const video = this.videoTagContainerTargets[index]
    if (video) {
      hideElements(this.videoTagContainerTargets)
      this.videoTagContainerTargets[index].classList.remove('hidden')
      if (this.thumbnailTargets[index]) this.thumbnailTargets[index].classList.remove('hidden', 'fade-out')
      this.pillTargets.forEach(e => e.classList.remove('active'))
      this.pillTargets[index].classList.add('active')
    } else {
      this.stopVideos()

    }
  }

  stopVideos() {
    this.videoTagTargets.forEach(e => {if (e.pause) e.pause()})
    dispatchCustomEvent('video-api:pause')
  }

  playVideo(index) {
    const video = this.videoTagTargets[index]
    if (video) this.thumbnailTargets[index].click()
    dispatchCustomEvent('video-api:play')
  }

  hideVideoPartsContainer() {
    if (this.hasVideoPartsContainerTarget) {
      hideElements(this.videoPartsContainerTarget)
    }
  }

  showVideoPartsContainer() {
    if (this.hasVideoPartsContainerTarget) {
      showElements(this.videoPartsContainerTarget)
    }
  }
}
