import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper'

export default class extends Controller {
  static targets = [ 'user' ]

  filterUsers(e) {
    const search = event.currentTarget.value.toLowerCase()
    this.userTargets.forEach(user => user.classList.toggle('hidden', !user.dataset.fullName.toLowerCase().includes(search)))
  }
}
