import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["color"];
  static values = { id: Number };

  selectOption(event) {
    this.option = document.getElementById(
      `organisation_organisation_color_${event.srcElement.dataset.organisationColorIdValue}`
    );
    this.option.checked = true;

    this.colorTargets.forEach((element, index) => {
      element.classList.remove("ring-2", "ring-black");
      if (
        element.dataset.organisationColorIdValue ==
        event.srcElement.dataset.organisationColorIdValue
      ) {
        element.classList.add("ring-2", "ring-black");
      }
    });
  }
}
