// edit_transcript_controller.js
import { Controller } from "stimulus"
import Inputmask from "inputmask"


export default class extends Controller {
  static targets = ["input", "startTimeInput", "endTimeInput", "error"];

  connect() {
    this.applyMasks();
  }

  applyMasks() {
    this.startTimeInputTargets.forEach(input => this.applyMask(input, 'start'));
    this.endTimeInputTargets.forEach(input => this.applyMask(input, 'end'));
  }

  applyMask(input, type) {
    Inputmask("99:99:99.999", {
      placeholder: "HH:MM:SS.SSS",
      showMaskOnHover: false,
      showMaskOnFocus: true,
      clearIncomplete: true,
      onincomplete: () => {
        input.classList.add("border", "border-red-500");
        this.setError(input, `Format is incomplete for ${type} time.`);
      },
      oncomplete: () => {
        input.classList.remove("border", "border-red-500");
        this.clearError(input);
        this.validateAll();
      }
    }).mask(input);
  }

  setError(input, message) {
    const errorElement = this.errorTargetForInput(input);
    errorElement.textContent = message;
    errorElement.style.display = 'block';
  }

  clearError(input) {
    const errorElement = this.errorTargetForInput(input);
    if (!errorElement) return;
    errorElement.style.display = 'none';
  }

  errorTargetForInput(input) {
    return input.closest('tr').querySelector('.error-message');
  }

  validateAll() {
    let isValid = true;
    this.clearAllErrors();

    this.startTimeInputTargets.forEach((startTimeInput, index) => {
      const endTimeInput = this.endTimeInputTargets[index];
      if (!this.validateTimes(startTimeInput.value, endTimeInput.value)) {
        isValid = false;
        this.setError(endTimeInput, 'End time must be after start time.');
      }
    });

    if (isValid) {
      isValid = this.validateSequence();
    }

    if (!isValid) {
      // Overall sequence validation failed
      
      this.setError(this.element, 'There is a timing overlap between subtitles.');
    }
  }

  validateTimes(startTime, endTime) {
    const startMillis = this.timeToMillis(startTime);
    const endMillis = this.timeToMillis(endTime);
    return startMillis < endMillis;
  }

  validateSequence() {
    for (let i = 0; i < this.endTimeInputTargets.length - 1; i++) {
      const endMillis = this.timeToMillis(this.endTimeInputTargets[i].value);
      const nextStartMillis = this.timeToMillis(this.startTimeInputTargets[i + 1].value);
      if (endMillis > nextStartMillis) {
        this.setError(this.endTimeInputTargets[i], 'Subtitles overlap.');
        return false;
      }
    }
    return true;
  }

  clearAllErrors() {
    this.errorTargets.forEach(error => error.style.display = 'none');
  }
  
  timeToMillis(timeString) {
    const [hours, minutes, seconds] = timeString.split(':');
    const [secs, millis] = seconds.split('.');
    return parseInt(hours) * 3600000 + parseInt(minutes) * 60000 + parseInt(secs) * 1000 + parseInt(millis);
  }
}
