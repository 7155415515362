import { Controller } from "stimulus";
import { fetchWithToken } from "../../application/stimulus_helper";
import Swal from "sweetalert2";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static targets = ["createForm", "list", 'proposedQuizz'];

  static values = { sectionId: String };

  connect() {
    document.addEventListener("contents:reordered", (e) => this.resetIndexes());
    this._broadcast();
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "ContentGeneratorChannel", id: this.sectionIdValue },
      {
        received: (data) => {
          switch(data.whatisit) {
            case "title":
              this.changeTitle(data.contentid, data.title);
              break;
            case "proposal":
              if (data.context === 'summary') {
                this.revealProposeBtn(data.contentid)
              } else {
                this.persistInCookies(data.sectionid, false)
                this.showProposedQuizz()
              }
              break;
            case "card":
              if (data.insertinplace){
                this.replaceCard(data.insertinplace, data.html)
              } else {
                this.insertCard(data.insertafter, data.html)
                this.prepareForStream(data.insertafter, data.context, data.contentid)
                dispatchCustomEvent("contents:created");
              }
              setTimeout(() => {
                this.resetIndexes();
              }, 1200);
              break;
          }
        },
      }
    );
  }

  revealProposeBtn(contentId) {
    const button = document.querySelector(`.propose-btn[data-contentId="${contentId}"]`)
    if (button) {
      const footer = button.closest('.content-footer')
      button.classList.remove('hidden')
      footer.classList.replace('opacity-0', 'opacity-100')
    }
    const tresPuntosProposeBtns = document.querySelectorAll(`.propose-btn-menu[data-contentid="${contentId}"]`)
   tresPuntosProposeBtns.forEach((btn) => {showElements(btn)})
  }

  hideProposeBtn(contentId) {
    const button = document.querySelector(`.propose-btn[data-contentid="${contentId}"]`)
    if (button) {
      const footer = button.closest('.content-footer')
      button.classList.add('hidden')
      footer.classList.replace('opacity-100', 'opacity-0')
    }

    const tresPuntosProposeBtns = document.querySelectorAll(`.propose-btn-menu[data-contentid="${contentId}"]`)
    tresPuntosProposeBtns.forEach((btn) => {hideElements(btn)})
  }


  changeTitle(contentId, title) {
    const contentName = document.querySelector(
      `#content_card_name_${contentId}`
    );
    if (contentName.closest(".full-card-header")) {
      contentName
        .closest(".full-card-header")
        .classList.remove("opacity-0");
    }
    contentName.classList.add("animate-charcter");
    contentName.innerText = title;
    document.querySelector(
      `.content_name_input_${contentId}`
    ).value = title;
  }

  insertCard(position, html) {
    if (position === "end") {
      this.listTarget.insertAdjacentHTML("beforeend", html);
    } else {
      const sourceContent = this.listTarget.querySelector(`.writing-content[data-content-id="${position}"]`)
      if (sourceContent) { sourceContent.insertAdjacentHTML('afterEnd', html)}
    }
  }

  _daysFromNow(days) {
    return new Date(Date.now() + days * 86400000).toUTCString();
  }

  prepareForStream(sourceId, context, quizzId) {
    if (context === 'quizz') {
      this.hideProposedQuizz()
      this.openQuizzModal(quizzId)
    } else {
      this.hideProposeBtn(sourceId)
    }
  }

  openQuizzModal(id) {
    setTimeout(() => {
      const card = document.querySelector(`.content-card[data-content-id="${id}"]`)
      card.querySelector('.updateBtn').click()
    }, 2000)
  }

  replaceCard(id, html) {
    const sourceContent = this.listTarget.querySelector(`.writing-content[data-content-id="${id}"]`)
      if (sourceContent) { sourceContent.outerHTML = html }
    }


  generate(e) {
    e.preventDefault();
    const url = e.currentTarget.href;
    const options = {
      method: "PATCH",
      headers: { Accept: "application/json" },
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          Swal.fire({
            title: "Erreur",
            text: data.errors,
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        }
      });
  }

  discardProposedQuizz(e) {
    const id = e.currentTarget.dataset.id
    this.persistInCookies(id, true)
    this.hideProposedQuizz()
  }

  persistInCookies(id, hide) {
    const cookie = `no_quizz_for_${id}=${hide};expires=${this._daysFromNow(
      365
    )};path=/;SameSite=Strict;Secure`
    document.cookie = cookie
  }

  hideProposedQuizz() {
    hideElements(this.proposedQuizzTarget)
  }

  showProposedQuizz() {
    showElements(this.proposedQuizzTarget)
  }

  save_summary(e) {
    const targetContent = e.currentTarget.closest(".writing-content");
    const url = `/contents/${e.currentTarget.dataset.contentId}/contents/save_summary`

    const options = {
      method: 'PATCH',
      headers: {Accept: "application/json"},
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data)=> {
        if (data.errors) {
          Swal.fire({
            title: "Erreur",
            text: data.errors,
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          if (data.partial) {
            targetContent.outerHTML = data.partial;
            this.hideProposeBtn(data.sourcecontentid)
          }
        }
      })
  }

  create({ params }) {
    const url = this.createFormTarget.action;
    const formData = new FormData(this.createFormTarget);
    formData.append("content[media_type]", params.type);
    formData.append("content[position]", params.position);

    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: formData,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          if (data.position) {
            // data.position is based on acts_as_list 'position' : begins at 1, not 0 => differs from index/
            this.listTarget.children[`${data.position}` - 1].insertAdjacentHTML(
              "beforeBegin",
              data.html
            );
          } else {
            this.listTarget.insertAdjacentHTML("beforeend", data.html);
          }
          document.querySelector("#headercta").innerHTML = data.cta;
          const emptystate = document.querySelector(".empty-state-section");
          if (emptystate) emptystate.classList.add("hidden");
          const normalBtns = document.querySelector(".not-empty-buttons");
          if (normalBtns) normalBtns.classList.remove("hidden");
          dispatchCustomEvent("contents:created");
          setTimeout(() => {
            const card = data.position
              ? this.listTarget.children[`${data.position}` - 1].querySelector(
                  ".content-card"
                )
              : this.listTarget.lastElementChild.querySelector(".content-card");
            // uncomment if you wish to open edition modal when a content is added to the playbook
            // this.application.getControllerForElementAndIdentifier(card, 'modal').open()
            // uncomment if you wish to select the title field in the card (not in the modal)
            if (card && card.querySelector(".writing-content-card-name")) {
              card.querySelector(".writing-content-card-name").click();
            }
            this.resetIndexes();
          }, 1200);
        }
        this.createFormTarget.reset();
      });
  }

  resetIndexes() {
    Array.from(this.listTarget.children).forEach((card, index) => {
      card.querySelector(
        "[data-buttons-in-between-position-value]"
      ).dataset.buttonsInBetweenPositionValue = index + 1;
    });
  }

  duplicate(event) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.currentTarget;
    const data = new FormData();
    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: data,
    };
    target.disabled = true

    fetchWithToken(target.dataset.url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          dispatchCustomEvent("contents:created");
          target
            .closest(".writing-content")
            .insertAdjacentHTML("afterend", data.html);
          this.resetIndexes();
        }
        target.disabled = false
      });
  }

  delete(event) {
    const id = event.params.id;
    const contentUseInOtherSections = event.params.contentUseInOtherSections;
    const element = event.currentTarget.closest(".writing-content");
    const done = element.classList.contains("done");
    const url = `/content_sections/${id}`;
    const options = {
      method: "DELETE",
      headers: { Accept: "application/json" },
    };

    if (event.params.proposedContent) {
      fetchWithToken(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.deleted) {
            element.remove();
            dispatchCustomEvent("contents:deleted", { done: element });
            this.resetIndexes();
          } else {
            Swal.fire({
              title: I18n.t("js.sweet_alerts.contents.delete.errors.title"),
              text: I18n.t("js.sweet_alerts.contents.delete.errors.text"),
              icon: "error",
              confirmButtonColor: "#3d52d5",
            });
          }
        });
    } else {
      Swal.fire({
        title: I18n.t("js.sweet_alerts.contents.delete.title"),
        text: contentUseInOtherSections
          ? I18n.t(
              "js.sweet_alerts.contents.delete.content_use_in_other_section"
            )
          : I18n.t("js.sweet_alerts.contents.delete.text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--danger)",
        cancelButtonColor: "#CDD2DE",
        cancelButtonText: "Annuler",
        confirmButtonText: I18n.t(
          "js.sweet_alerts.contents.delete.confirm_button_text"
        ),
      }).then((result) => {
        if (result.isConfirmed) {
          fetchWithToken(url, options)
            .then((response) => response.json())
            .then((data) => {
              if (data.deleted) {
                element.remove();
                dispatchCustomEvent("contents:deleted", { done: element });
                this.resetIndexes();
              } else {
                Swal.fire({
                  title: I18n.t("js.sweet_alerts.contents.delete.errors.title"),
                  text: I18n.t("js.sweet_alerts.contents.delete.errors.text"),
                  icon: "error",
                  confirmButtonColor: "#3d52d5",
                });
              }
            });
        }
      });
    }
  }
}
