import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["form", "input", "results", "inputWrapper", "nbResults"]

  connect() {
  }

  search() {
    let url = `${this.formTarget.action}?query=${this.inputTarget.value}`

    // We include eventual existing params in the URL
    // to keep the pagination working
    const params = new URLSearchParams(window.location.search)
    params.forEach((value, key) => {
      if (key !== "query") {
        url += `&${key}=${value}`
      }
    })

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.fetchResults(url)
    }, 800)
  }

  sort(event) {
    event.preventDefault()
    let url = event.target.href
    
    // We include eventual existing params in the URL
    // to keep the pagination working and the search query
    const params = new URLSearchParams(window.location.search)
    params.forEach((value, key) => {
      if (key !== "sort" && key !== "direction") {
        url += `&${key}=${value}`
      }
    })

    let icon = event.target.querySelector("i")
    if (!icon) {
      event.target.innerHTML += ' <i class="fas fa-caret-down"></i>'
    }
    this.fetchResults(url)
    this.changeSortUrlDirection(event.target)
    this.changeSortIcon(event.target)
  }

  expandSearchBar(e) {
    e.preventDefault()
    e.currentTarget.classList.add("md:hidden")
    this.inputWrapperTarget.classList.remove("md:hidden")
    this.inputTarget.focus()
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(data, "text/html")
      const nbResults = doc.querySelector("#nb-results").innerText
      this.nbResultsTarget.innerHTML = nbResults
      this.resultsTarget.innerHTML = data
    })
  }

  changeSortUrlDirection(target) {
    const currentDirection = target.href.includes("asc") ? "asc" : "desc"
    const newDirection = currentDirection === "asc" ? "desc" : "asc"
    target.href = target.href.replace(currentDirection, newDirection)
  }

  changeSortIcon(target) {
    const currentIcon = target.querySelector("i")
    const newIcon = currentIcon.classList.contains("fa-caret-up") ? "fa-caret-down" : "fa-caret-up"
    currentIcon.classList.remove("fa-caret-up", "fa-caret-down")
    currentIcon.classList.add(newIcon)
  }

  insertSpinner(target) {
    this.resultsTarget.innerHTML = '<div class="h-48 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
  }

}