import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['toggleIcon', 'readQuizzBtn', 'progress', 'question', 'endSlide', 'previousBtn', 'prevBtn' ]
  static values = { firstUnansweredQuestionIndex: Number, showEndSlide: Boolean, reset: Boolean, quizzContentId: Number}

  connect() {
    if (this.resetValue) {
      this.endSlideTarget.classList.add('hidden')
      this.questionTargets[0].classList.remove('hidden')
    } else if (this.showEndSlideValue) {
      this.goToEndSlide()
    } else {
      showElements(this.questionTargets[this.firstUnansweredQuestionIndexValue])
    }
  }

  navigate(e) {
    e.currentTarget.closest('.question').classList.add('hidden')
    this.questionTargets[e.currentTarget.dataset.goToIndex].classList.remove('hidden')
  }

  goToEndSlide() {
    hideElements(this.questionTargets)
    showElements(this.endSlideTarget)
    showElements(this.prevBtnTargets)
  }

  reset(e) {
    fetchWithToken( `/quizz_contents/${this.quizzContentIdValue}/reset_quizz`,
      {
        method: "GET",
        headers: { Accept: "application/json" },
      })
      .then((response) => response.json())
      .then((data) => {
        this.element.outerHTML = data.partial
      })
  }

  toggle() {
    this.toggleIconTarget.classList.toggle('-rotate-180')
    this.answersContainerTarget.classList.toggle('hidden')
    if (!this.answeredValue) {
      this.readQuizzBtnTarget.classList.toggle('hidden')
    }
  }

  showProgress(progress_stats) {
    let i = 1
    this.progressTargets.forEach((e) => {
      // We remove hidden class to show the progress bar
      e.classList.remove('md:hidden')
      // In the element there is a span with the percentage
      e.querySelector('span').innerText = progress_stats[i] + '%'
      // We set the width of the progress bar
      e.querySelector('#bar').style.width = progress_stats[i] * 2 + 'px'

      i += 1
    })
  }

  _markAsRead() {
    const handoverReadingController = this.application.getControllerForElementAndIdentifier(this.element.closest('#handover-reading-controller'), 'handover-reading');
    // can be triggered on the handover write page, need for a guard clause
    if(handoverReadingController) {
      handoverReadingController.countView(this.element)
      if (this.element.dataset.isRead === 'true') { return }
      if (this.element.dataset.isReader === 'false') { return }

      handoverReadingController.sendReadRequest(this.element)
    }

  }
}
