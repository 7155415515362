import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  submit(event) {
    event.preventDefault()

    // To let html5 validation work
    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();

      return;
    }

    const data = new FormData(this.formTarget)
    const url = this.formTarget.action
    const method = this.formTarget.method

    fetch(url, {
      method: method,
      body: data
    })
  }
}