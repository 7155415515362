import { Controller } from 'stimulus'
import { fetchWithToken } from '../../javascript/application/stimulus_helper';
import consumer from "../../javascript/channels/consumer";


export default class extends Controller {
  static targets = [ 'streamReceiver', 'textConverted', 'buttons', 'spinner']
  static values = { contentId: String }

  connect() {
    this._broadcast()
    this.docStructure = [];
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "StreamSummaryChannel", id: this.contentIdValue },
      {
        received: (data) => {
          setTimeout(()=>{ this.streamInsideContent(data.html); }, 500)
        }
      })
  }

  streamInsideContent(html) {
    const cardContainers = this.element.querySelectorAll('.content-container')

    if (this.streamReceiverTarget.classList.contains('hidden')) {
      cardContainers.forEach((container) => {container.classList.add('hidden')})
      showElements(this.streamReceiverTarget)
    }
    if (html === "end_stream" ) {
      showElements(this.buttonsTarget)
    } else {
      this.textConvertedTarget.innerHTML = html
    }
  }

  // oldstreamInsideContent(html) {
  //   const cardContainers = this.element.querySelectorAll('.content-container')
  //   const container = this.streamReceiverTarget
  //   const htmlDiv = this.textConvertedTarget
  //   const textDiv = this.textStreamedTarget
  //   const buttons = this.buttonsTarget

  //   if (container.classList.contains('hidden')) {
  //     cardContainers.forEach((container) => {container.classList.add('hidden')})
  //     showElements(container)
  //   }
  //   if (html === null ) {
  //     // Le dernier Chunk ne contient plus de html.
  //     showElements(buttons)
  //     this.docStructure = []

  //   } else {
  //     // On affiche le stream en texte.
  //     textDiv.innerText = textDiv.innerText + html
  //     // on vire les caractères inutiles
  //     if (textDiv.innerText.match(/html|plaintext|```/)) {
  //       textDiv.innerText = textDiv.innerText.split(/html|plaintext|```/)[1]
  //     }
  //     // on ajoute des espaces quand chiffres et lettres sont collés
  //     //(on teste que pas de ">" pour pas niquer une balise html)
  //     if (textDiv.innerText.match(/[a-z]\d[^>]/)) {
  //       const letterNumberStuck = textDiv.innerText.match(/[a-z]\d[^>]/)[0]
  //       textDiv.innerText = textDiv.innerText.replace(letterNumberStuck, `${letterNumberStuck.substring(0,1)} ${letterNumberStuck.substring(1,3)}`)
  //     }
  //     // On teste la balise ouvrante
  //     if(textDiv.innerText.match(/<[^\/][a-z]*[0-9]?>/)) {
  //       // On splitte le texte droite-gauche de la balise
  //       const splitted = textDiv.innerText.split(/<[^\/][a-z]*[0-9]?>/)
  //       // On récupère le nom du tag dans la balise
  //       const balise = textDiv.innerText.match(/<[^\/][a-z]*[0-9]?>/)[0]
  //       const tag = balise.substring(1, balise.length - 1 )
  //       // On insère la partie de droite + la balise ouvrante + fermante
  //       // au bon endroit de la Div grâce à DocStructure
  //       // qui indique quelle est la dernière balise en cours.
  //       if (this.docStructure.length === 0) {
  //         htmlDiv.insertAdjacentHTML('beforeend',`${splitted[0]}<${tag}></${tag}>`)
  //       } else {
  //         const relevantTag = this.docStructure[this.docStructure.length-1].element
  //         const index = this.docStructure[this.docStructure.length-1].index
  //         const allTags = htmlDiv.querySelectorAll(`${relevantTag}`)
  //         allTags[index].insertAdjacentHTML('beforeend',`${splitted[0]}<${tag}></${tag}>`)
  //       }
  //     // On ne garde que la partie de droite dans l'aperçu du texte
  //       textDiv.innerText = splitted[1]
  //     // On met à jour DocStructure pour garder en mémoire qu'on a ouvert une balise et son index dans le DOM.
  //       const index = this.getCurrentIndexForTag(htmlDiv, tag)
  //       this.docStructure.push({element: `${tag}`, index: index})

  //     }
  //     // Balise fermante...
  //     if (textDiv.innerText.match(/<\/[a-z]*[0-9]?>/)) {
  //       const balise = textDiv.innerText.match(/<\/[a-z]*[0-9]?>/)[0]
  //       const tag = balise.substring(2, balise.length - 1 )
  //       const splitted = textDiv.innerText.split(/<\/[a-z]*[0-9]?>/)
  //       const allTags = htmlDiv.querySelectorAll(`${tag}`)
  //       // On insère la partie de gauche entre les balises de ce type correspondant à l'index renseigné dans doc structure
  //       //(si je ferme un ul, le texte doit être inséré
  //       // entre les  <ul></ul> correspondant à l'index)
  //       const index = this.docStructure[this.docStructure.length-1].index
  //       allTags[index].insertAdjacentHTML('beforeend', splitted[0])
  //       textDiv.innerText = splitted[1]
  //       // On met à jour DocStructure en retirant cette balise de la liste
  //       // => le texte ou balises suivantes seront insérées dans le parent.
  //       this.docStructure.pop()
  //     }
  //   }
  // }

  getCurrentIndexForTag(div, tag) {
    var index = 0
    if (div.querySelectorAll(`${tag}`)) {
      index = div.querySelectorAll(`${tag}`).length - 1
    }
    return index
  }

  regenerate(e) {
    e.preventDefault();

    this.textConvertedTarget.innerHTML = ''
    hideElements(this.buttonsTarget, this.streamReceiverTarget)
    showElements(this.spinnerTarget)

    const url = e.currentTarget.href;
    const options = {
      method: "PATCH",
      headers: { Accept: "application/json" },
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          Swal.fire({
            title: "Erreur",
            text: data.errors,
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        }
      });
  }


}
