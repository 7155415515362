import { get } from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['btnLabel', 'iconLabel', 'hiddenInput']
  static values = { 
    displayHiddenInput: Boolean,
    submitOnChange: { type: Boolean, default: false },
  }

  connect() {
    this.activeElement = this.element.querySelector('[aria-selected="true"]') || this.element.querySelector('[role="option"]');
    this.options = this.element.querySelectorAll('[role="option"]');

    // Setup listeners for mouseenter, mouseleave, and click on each option
    this.options.forEach(option => {
      option.addEventListener('mouseenter', this.highlightOption.bind(this));
      option.addEventListener('mouseleave', this.unhighlightOption.bind(this));
      option.addEventListener('click', this.selectOption.bind(this));
    });

    // Setup keyboard navigation listener on the parent element of the options
    this.element.addEventListener('keydown', this.navigateWithKeyboard.bind(this));
  }

  highlightOption(event) {
    event.currentTarget.ariaSelected = true;
  }

  unhighlightOption(event) {
    event.currentTarget.ariaSelected = false;
  }

  selectOption(event) {
    const selectedOption = event.currentTarget;
    if (!selectedOption || selectedOption.dataset.selected == "true" || selectedOption.ariaDisabled == "true") return;

    this.handleSelection(selectedOption);
  }

  navigateWithKeyboard(event) {
    let currentIndex = Array.from(this.options).indexOf(document.activeElement);
    let nextIndex;
    
      switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        nextIndex = this.getNextIndex(currentIndex, 1);
        this.options[nextIndex].focus();
        break;
      case 'ArrowUp':
        event.preventDefault();
        nextIndex = this.getNextIndex(currentIndex, -1);
        this.options[nextIndex].focus();
        break;
      case 'Enter':
        event.preventDefault();
        this.selectOptionByKeyboard(currentIndex);
        break;
    }
  }

  getNextIndex(currentIndex, step) {
    let nextIndex = currentIndex;
    do {
      nextIndex = (nextIndex + step + this.options.length) % this.options.length;
    } while (this.options[nextIndex].ariaDisabled == "true");
    return nextIndex;
  }

  selectOptionByKeyboard(index) {
    const selectedOption = this.options[index];
    if (!selectedOption || selectedOption.dataset.selected == "true" || selectedOption.ariaDisabled == "true") return;

    this.handleSelection(selectedOption);
  }

  handleSelection(selectedOption) {
    if (selectedOption.ariaDisabled == "true") return;

    this.clearSelection();
    this.setOptionData(selectedOption);
    this.updateLabel(selectedOption);
    if (this.displayHiddenInputValue) this.updateHiddenInput(selectedOption);
  }

  clearSelection() {
    this.options.forEach((option) => {
      option.ariaSelected = false
      option.dataset.selected = false;
    });
  }

  setOptionData(selectedOption) {
    selectedOption.ariaSelected = true;
    selectedOption.dataset.selected = true;
  }

  updateHiddenInput(selectedOption) {
    // Update the hidden input value
    this.hiddenInputTarget.value = selectedOption.dataset.value;
    if (this.submitOnChangeValue) {
      this.hiddenInputTarget.form.requestSubmit();
    }
  }

  updateLabel(selectedOption) {
    // Update the label text, icon

    this.btnLabelTarget.textContent = this.getLabel(selectedOption);
    this.iconLabelTarget.innerHTML = this.getSvgIcon(selectedOption);
  }

  getLabel(selectedOption) {
    // Return the text label for the selected option
    return selectedOption.querySelector('.select-menu__option-label').textContent;
  }

  getSvgIcon(selectedOption) {
    // Return the SVG icon for the selected option
    const svg = selectedOption.querySelector('svg').cloneNode(true);
    svg.removeAttribute('class');
    return svg.outerHTML;
  }
}
