import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    url: String,
    lazyLoading: Boolean,
    lazyLoadingThreshold: Number,
    lazyLoadingRootMargin: {
      type: String,
      default: '0px'
    },
    refreshInterval: Number,
    loadScripts: Boolean,
    unloadOnHide: Boolean,
    animationClass: String,
  }

  connect() {
    if (!this.hasUrlValue) {
      console.error('[content-loader] You need to pass an url to fetch the remote content.')
      return
    }

    if (this.unloadOnHideValue) this.unloadedContent = this.element.innerHTML

    this.hasLazyLoadingValue ? this.lazyLoad() : this.load()
    this.animationClass = this.hasAnimationClassValue ? this.animationClassValue : 'animate-slide'
  }

  urlValueChanged(currentValue, previousValue) {
    if (currentValue !== previousValue && previousValue !== "") {
      this.load()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    this.fetch()

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  unload() {
    this.element.innerHTML = this.unloadedContent
  }

  lazyLoad() {
    const options = {
      threshold: this.lazyLoadingThresholdValue,
      rootMargin: this.lazyLoadingRootMarginValue
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.load()

            if (!this.unloadOnHideValue) observer.unobserve(entry.target)
          } else {
            if (this.unloadOnHideValue) this.unload()
          }
        })
      },
      options
    )

    observer.observe(this.element)
  }

  fetch() {
    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.element.classList.remove(this.animationClass)
        this.element.innerHTML = html
        this.element.classList.add(this.animationClass)
        if (this.loadScriptsValue) {
          // this.loadScripts()
        }
        // We emit an event DOMContentLoaded to let other controllers know
        // that the content has been loaded (React, Stimulus, ...)
        document.dispatchEvent(new Event('DOMContentLoaded'))
        this.element.dispatchEvent(new Event('finishedLoading'))
      })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetch()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  loadScripts() {
    this.element.querySelectorAll('script').forEach((content) => {
      const script = document.createElement('script')
      script.innerHTML = content.innerHTML

      document.head.appendChild(script).parentNode.removeChild(script)
    })
  }
}
