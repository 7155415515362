import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['passwordField', 'loginBtn', 'validateUserBtn', 'emailInput']
  static values = { samlurl: String, checkssourl: String, loginurl: String }


  noMoreEnterKey(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
      this.checkUserLoginPage()
    }
  }

  noMoreEnterKeySsoPage(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
      this.checkUserSsoPage()
    }
  }


  redirectLostSsoUsers(e) {
    if (this.emailInputTarget.value !== '') {
      const emailURI= encodeURIComponent(this.emailInputTarget.value)
      const url = `${this.checkssourlValue}?email=${emailURI}`
      fetchWithToken(url, {
        method: 'GET',
        headers: {"Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
         if (data.organisation !== 'unknown' && data.sso) {
          Swal.fire({
            title: 'SSO',
            text: I18n.t('js.sso.redirect'),
          }).then(() => {
            window.location.href = `${this.samlurlValue}?organisation=${data.organisation}`
          })
        }
      })
    }
  }

  checkUserLoginPage(e) {
    if (e) {e.preventDefault()}
    if (this.emailInputTarget.value !== '') {
      const emailURI= encodeURIComponent(this.emailInputTarget.value)
      const url = `${this.checkssourlValue}?email=${emailURI}`
      fetchWithToken(url, {
        method: 'GET',
        headers: {"Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: "Erreur",
            text: data.error,
          })
        } else if (data.organisation !== 'unknown' && data.sso) {
          window.location.href = `${this.samlurlValue}?organisation=${data.organisation}`
        } else {
          document.querySelector('form').submit()
          }
        //  window.location.href = `${this.loginurlValue}`
      })
    }
  }

  checkUserSsoPage(e) {
    if (e) {e.preventDefault()}
    if (this.emailInputTarget.value !== '') {
      const emailURI= encodeURIComponent(this.emailInputTarget.value)
      const url = `${this.checkssourlValue}?email=${emailURI}`
      fetchWithToken(url, {
        method: 'GET',
        headers: {"Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: "Erreur",
            text: data.error,
          })
        } else if (data.organisation !== 'unknown' && data.sso) {
          window.location.href = `${this.samlurlValue}?organisation=${data.organisation}`
        } else {
          Swal.fire({
            title: "Erreur",
            text: "SSO inconnu pour cet email",
          }).then((result) => {
            this.emailInputTarget.select(),
            this.emailInputTarget.focus()
          }
        )
        //  window.location.href = `${this.loginurlValue}`
        }
      })
    }
  }

}
