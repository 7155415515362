import Trix from "trix";
import translations from "./trix-translations.json";

if (document.documentElement.lang == "fr") {
  Trix.config.lang = translations[document.documentElement.lang];
}

Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;
document.addEventListener("trix-before-initialize", updateToolbars);
document.addEventListener("colorpicked", updateColor);

function updateColor(event) {
  const trixEditor = event.detail.editor;
  trixEditor.activateAttribute("foregroundColor", event.detail.color);
}

function updateToolbars(event) {
  const toolbarElement = event.target.toolbarElement;
  toolbarElement.innerHTML = Trix.config.toolbar.getDefaultHTML(
    toolbarElement.nextElementSibling.dataset.short
  );
}

Trix.config.textAttributes.foregroundColor = {
  styleProperty: "color",
  inheritable: 1,
};

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

function toolbarDefaultHTML(shorty) {
  switch (shorty) {
    case 'true':
      return short();
    case 'long':
      return long();
    case 'mini':
      return mini()
    default:
      return long()
  }
}

function long() {
  const { lang } = Trix.config;
  const useAi = document.documentElement.lang == "fr" ? "Utiliser l'IA" : "Use AI";
  const aiEnabled = document.body.dataset.aiEnabled


  return `
  <div class='w-full h-fit overflow-x-auto trix-button-row' data-controller="emoji-picker trix-color-palette-picker trix-heading-deselectioner">
    <div class='flex w-fit gap-4 md:gap-8 lg:gap-28 h-12 py-1 px-1 rounded-lg bg-gray-100 items-center mb-4 overflow-auto'>
      <div class='flex gap-2 lg:gap-4 h-full overflow-auto'>
        <div class='relative flex gap-2 h-full items-center justify-start w-fit px-1 py-1 trix-button-group trix-button-group--text-tools' data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${
            lang.bold
          }" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${
            lang.italic
          }" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${
            lang.strike
          }" tabindex="-1">${lang.strike}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${
            lang.link
          }" tabindex="-1">${lang.link}</button>
          <div data-controller="dropdown" class="relative h-full">
            <button class="trix-emoji" type="button" data-trix-attribute="emoji"  title="emojis" data-action="dropdown#toggle click@window->dropdown#hide">😀</button>
            <div data-emoji-picker-target="pickerContainer" class="hidden transition transform origin-top-right fixed z-50 ml-12" data-dropdown-target="menu">
            </div>
          </div>
        </div>
        <div class='flex gap-2 h-full items-center justify-start w-fit px-1 py-1' data-trix-button-group="color-tools">
          ${ichBinColorPalette()}
        </div>

        <div class='flex gap-2 h-full items-center justify-start w-fit px-1 py-1 trix-button-group--block-tools ' data-trix-button-group="block-tools">
          <div data-controller="dropdown" class="relative h-full">
            <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" title="${lang.heading1}" tabindex="-1" data-action="dropdown#toggle trix-heading-deselectioner#activatebutton click@window->dropdown#hide">${lang.heading}</button>
            <div data-dropdown-target="menu"
              class="hidden transition transform origin-bottom-right fixed z-50 bg-white rounded shadow w-fit"
              data-transition-enter-from="opacity-0 scale-95"
              data-transition-enter-to="opacity-100 scale-100"
              data-transition-leave-from="opacity-100 scale-100"
              data-transition-leave-to="opacity-0 scale-95">
              <button type="button" class="trix-button trix-button-heading-1" data-heading-attribute="heading1" data-trix-heading-deselectioner-target="headingBtn" data-action= "click->trix-heading-deselectioner#select" title="heading1">T</button>
              <button type="button" class="trix-button trix-button-heading-2" data-heading-attribute="heading2" data-trix-heading-deselectioner-target="headingBtn" data-action= "click->trix-heading-deselectioner#select" title="heading2">T</button>
              <button type="button" class="trix-button trix-button-heading-3" data-heading-attribute="heading3" data-trix-heading-deselectioner-target="headingBtn" data-action= "click->trix-heading-deselectioner#select" title="heading3">T</button>
              <button type="button" class="trix-button trix-button-heading-4" data-heading-attribute="normal" data-trix-heading-deselectioner-target="headingBtn" data-action= "click->trix-heading-deselectioner#select" title="heading3">T</button>
            </div>
          </div>

          <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${
            lang.quote
          }" tabindex="-1">${lang.quote}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${
            lang.code
          }" tabindex="-1">${lang.code}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${
            lang.bullets
          }" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${
            lang.numbers
          }" tabindex="-1">${lang.numbers}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${
            lang.outdent
          }" tabindex="-1">${lang.outdent}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${
            lang.indent
          }" tabindex="-1">${lang.indent}</button>
          <button type="button" class="trix-emoji" data-action="click->init-mention-from-trix-toolbar#tag" data-trigger="#" title="${lang.tagContent}" tabindex="-1">#</button>
        </div>


        <div class='flex gap-2 h-full items-center justify-start w-fit px-1 py-1 '>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${
            lang.attachFiles
          }" tabindex="-1">${lang.attachFiles}</button>
        </div>
      </div>

      <div class='flex gap-8 h-full w-fit'>
        <div class='flex gap-2 h-full items-center justify-start py-1'>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${
            lang.undo
          }" tabindex="-1">${lang.undo}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${
            lang.redo
          }" tabindex="-1">${lang.redo}</button>
        </div>
        <div data-ai-enabled="${aiEnabled}" class='data-[ai-enabled=false]:hidden flex gap-1 h-full items-center justify-start'>
          <button type="button" class="w-fit rounded-md px-4 py-1 h-full gap-2 flex justify-between items-center trix-button-AI" data-action = "click->media-creation--ai--text#openmodal">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 6.5C12 6.2 11.8 6 11.5 6C9.5 6 8.5 5.8 7.8 5.2C7.1 4.6 7 3.5 7 1.5C7 1.2 6.8 1 6.5 1C6.2 1 6 1.2 6 1.5C6 3.5 5.8 4.5 5.2 5.2C4.6 5.9 3.5 6 1.5 6C1.2 6 1 6.2 1 6.5C1 6.8 1.2 7 1.5 7C3.5 7 4.5 7.2 5.2 7.8C5.9 8.4 6 9.5 6 11.5C6 11.8 6.2 12 6.5 12C6.8 12 7 11.8 7 11.5C7 9.5 7.2 8.5 7.8 7.8C8.4 7.1 9.5 7 11.5 7C11.8 7 12 6.8 12 6.5ZM11.5 4C12.2 4 12.6 4.1 12.8 4.3C13 4.5 13 4.8 13 5.5C13 5.8 13.2 6 13.5 6C13.8 6 14 5.8 14 5.5C14 4.8 14.1 4.4 14.3 4.2C14.5 4 14.8 4 15.5 4C15.8 4 16 3.8 16 3.5C16 3.2 15.8 3 15.5 3C14.8 3 14.4 2.9 14.2 2.7C14 2.5 14 2.2 14 1.5C14 1.2 13.8 1 13.5 1C13.2 1 13 1.2 13 1.5C13 2.2 12.9 2.6 12.7 2.8C12.5 3 12.2 3 11.5 3C11.2 3 11 3.2 11 3.5C11 3.8 11.2 4 11.5 4Z" fill="currentColor"/>
              <path d="M14.2001 11.4L11.0001 8.2C10.9001 8.1 10.8001 8 10.6001 8C9.9001 7.9 9.2001 8.1 8.6001 8.5C8.1001 9.1 7.9001 9.9 8.0001 10.6C8.0001 10.7 8.0001 10.9 8.1001 11L11.3001 14.2C11.5001 14.4 11.8001 14.4 12.0001 14.2L14.1001 12.1C14.4001 11.9 14.4001 11.6 14.2001 11.4ZM22.4001 19.6L15.6001 12.8C15.4001 12.6 15.1001 12.6 14.9001 12.8L12.8001 14.9C12.6001 15.1 12.6001 15.4 12.8001 15.6L19.6001 22.4C20.4001 23.2 21.6001 23.2 22.4001 22.4C23.2001 21.6 23.2001 20.4 22.4001 19.6Z" fill="currentColor"/>
            </svg>
            <p>${useAi}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="trix-dialogs flex bg-white" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields flex bg-white items-center">
        <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${
          lang.urlPlaceholder
        }" aria-label="${lang.url}" required data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button--dialog" value="${
            lang.link
          }" data-trix-method="setAttribute">
          <input type="button" class="trix-button--dialog" value="${
            lang.unlink
          }" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  </div>
  `;
}

function short() {
  const { lang } = Trix.config;

  return `
  <div class='w-full h-fit overflow-x-auto' data-controller="emoji-picker">
    <div class='flex w-full h-12 py-1 px-1 rounded-t-lg bg-zinc-100 items-center overflow-auto'>
        <div class="relative flex lg:gap-4 gap-2 h-full items-center justify-start w-full px-1 py-1">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
          <div data-controller="dropdown" class="relative h-full">
            <button class="trix-emoji" type="button" data-trix-attribute="emoji"  title="${lang.emojis}" data-action="dropdown#toggle click@window->dropdown#hide">😀</button>
            <div data-emoji-picker-target="pickerContainer" class="hidden transition transform bottom-0 origin-top-right fixed -translate-x-1/2 z-50 ml-12" data-dropdown-target="menu"></div>
          </div>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
          <button type="button" class="trix-tag" data-action="click->init-mention-from-trix-toolbar#tag" data-trigger="@" title="${lang.tagPeople}" tabindex="-1">@</button>
          <button type="button" class="trix-emoji" data-action="click->init-mention-from-trix-toolbar#tag" data-trigger="#" title="${lang.tagContent}" tabindex="-1">#</button>
        </div>
    </div>
  </div>
  <div class="trix-dialogs flex bg-zinc-100" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields flex bg-zinc-100 items-center">
        <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
          <input type="button" class="trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  </div>
`;
}

function mini() {
  const { lang } = Trix.config;

  return `
  <div class='w-full h-fit overflow-x-auto' data-controller="emoji-picker trix-color-palette-picker">
    <div class='flex w-full h-10 py-1 px-1 rounded-t-lg bg-gray-100 items-center overflow-auto'>
        <div class="relative flex lg:gap-2 gap-1 h-full items-center justify-evenly w-full px-1 py-1">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold trix-button-mini" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic trix-button-mini" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike trix-button-mini" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link trix-button-mini" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
          <div data-controller="dropdown" class="relative h-full">
            <button class="trix-emoji" type="button" data-trix-attribute="emoji"  title="${lang.emojis}" data-action="dropdown#toggle click@window->dropdown#hide">😀</button>
            <div data-emoji-picker-target="pickerContainer" class="hidden transition transform bottom-0 origin-top-right fixed -translate-x-1/2 z-50 ml-12" data-dropdown-target="menu"></div>
          </div>
          <div class='flex gap-2 h-full items-center justify-start w-fit px-1 py-1' data-trix-button-group="color-tools">
            ${ichBinColorPalette()}
          </div>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list trix-button-mini" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list trix-button-mini" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        </div>
    </div>
  </div>
  <div class="trix-dialogs flex bg-white" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields flex bg-white items-center">
        <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${
          lang.urlPlaceholder
        }" aria-label="${lang.url}" required data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button--dialog" value="${
            lang.link
          }" data-trix-method="setAttribute">
          <input type="button" class="trix-button--dialog" value="${
            lang.unlink
          }" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  </div>
`;
}

function ichBinColorPalette() {
  return `
  <div class="relative flex flex-col bg-transparent items-center justify-center" data-controller="dropdown">
    <div class="palette trix-button border rounded h-10 w-10 sm:h-8 sm:w-8 cursor-pointer p-1 hover:bg-komin-gray"
      style="font-size: 1.6rem; color: #ff0000;"
      data-action='click->dropdown#toggle click@window->dropdown#hide'
      data-trix-color-palette-picker-target='palette'
      data-color="#ff0000">
      T
    </div>
    <div class="hidden transition transform origin-bottom-right fixed z-50 bg-white rounded shadow w-fit p-2"
      data-dropdown-target="menu"
      data-transition-enter-from="opacity-0 scale-95"
      data-transition-enter-to="opacity-100 scale-100"
      data-transition-leave-from="opacity-100 scale-100"
      data-transition-leave-to="opacity-0 scale-95">
      <fieldset>
        <div class="grid grid-cols-4 gap-3">
            ${ichBinColorDot("#020617")}
            ${ichBinColorDot("#ef4444")}
            ${ichBinColorDot("#f97316")}
            ${ichBinColorDot("#f59e0b")}
            ${ichBinColorDot("#eab308")}
            ${ichBinColorDot("#84cc16")}
            ${ichBinColorDot("#22c55e")}
            ${ichBinColorDot("#10b981")}
            ${ichBinColorDot("#14b8a6")}
            ${ichBinColorDot("#06b6d4")}
            ${ichBinColorDot("#0ea5e9")}
            ${ichBinColorDot("#6366f1")}
            ${ichBinColorDot("#8b5cf6")}
            ${ichBinColorDot("#d946ef")}
            ${ichBinColorDot("#ec4899")}
            ${ichBinColorDot("#f43f5e")}
        </div>
      </fieldset>
      <div class="relative w-5 h-5 mt-2">
        <svg data-action="click->trix-color-palette-picker#openInput" class="w-5 h-5 absolute" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.41999 20.579C4.13948 20.5785 3.87206 20.4603 3.68299 20.253C3.49044 20.0475 3.39476 19.7695 3.41999 19.489L3.66499 16.795L14.983 5.48103L18.52 9.01703L7.20499 20.33L4.51099 20.575C4.47999 20.578 4.44899 20.579 4.41999 20.579ZM19.226 8.31003L15.69 4.77403L17.811 2.65303C17.9986 2.46525 18.2531 2.35974 18.5185 2.35974C18.7839 2.35974 19.0384 2.46525 19.226 2.65303L21.347 4.77403C21.5348 4.9616 21.6403 5.21612 21.6403 5.48153C21.6403 5.74694 21.5348 6.00146 21.347 6.18903L19.227 8.30903L19.226 8.31003Z" fill="currentColor"/>
        </svg>

        <input class="invisible absolute w-0 h-0 -z-10"
              value=""
              data-action="input->trix-color-palette-picker#updateColor"
              data-trix-color-palette-picker-target='colorPalette' type="color" name="" id="">
      </div>
    </div>
  </div>`;
}

function ichBinColorDot(color) {
  return `
  <label data-action="click->trix-color-palette-picker#selectDot"
    data-trix-color-palette-picker-target="label"
    aria-checked="false"
    data-color="${color}"
    class="aria-checked:ring-2 relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-blue opacity-70"
    tabindex="0">
  <input type="radio" class="sr-only radio-button", id="color_${color}" >
  <span id="color-choice-0-label" class="sr-only">${color}</span>
  <span aria-hidden="true" class="h-4 w-4 rounded-full border border-black border-opacity-10" style="background-color:${color};"></span>
</label>`;
}
