import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'submitFileBtn', 'form', 'linkInput', 'linkPreview', 'validationMessage', 'deleteBtn' ]
  static values = {
    contentId: Number,
    removeUrl: String,
    linkPreviewUrl: String,
    iframeType: Boolean,
  }

  connect() {
    //this.resizeInput()
    this.previewLink()
    }

  emptyInput() {
    this.deleteBtnTarget.disabled = true
    showElements( this.validationMessageTarget)
    this.linkPreviewTarget.classList.add('hidden')
  }

  previewLink() {
   // this.resizeInput()
    if (this.linkInputTarget.value === '') {
      this.emptyInput()
    }
    else {
      this.deleteBtnTarget.disabled = false
      const validRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      const link = this.linkInputTarget.value
      if (validRegExp.test(link) || this.iframeTypeValue) {
        const url = `${this.linkPreviewUrlValue}?link=${encodeURIComponent(link)}`
        fetchWithToken(url, {
          method: "GET",
          headers: {"Accept": "application/json" }
        })
        .then(response => response.json())
        .then((data) => {
          this.linkPreviewTarget.innerHTML = data.partial
          this.linkInputTarget.value = data.url
          hideElements( this.validationMessageTarget)
          this.linkPreviewTarget.classList.remove('hidden')
          hideElements(document.querySelector(`.simple-cancel-button[data-id="${this.contentIdValue}"]`))
          fadeShowElements(
            document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`),
            document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`),
            document.querySelector(`.cancel-button[data-id="${this.contentIdValue}"]`),
          )
        })
        .catch((error) => {
              this.validationMessageTarget.classList.remove('hidden')
              this.linkPreviewTarget.classList.add('hidden')
        });
      } else {
        this.validationMessageTarget.classList.remove('hidden')
        this.linkPreviewTarget.classList.add('hidden')
      }
    }
  }

  submitForm() {
    let formData = new FormData(this.formTarget)

    fetchWithToken(this.formTarget.action, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: `Une erreur est survenue`,
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        if (data.has_content) {
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
        }
        const target = this.element
        if (target) target.outerHTML = data.partial
        this.deleteBtnTarget.disabled = false
        if (data.card) {
          const content = document.querySelector(`.writing-content[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.card
        }
      }
    })
  }

  deleteLink(event) {
    this.linkInputTarget.value = ''
    this.validationMessageTarget.classList.remove('hidden')
    hideElements( this.linkPreviewTarget)
    this.deleteBtnTarget.disabled = true
    fadeHideElements(document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`), document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`))
  }


  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  #markAsTodo() {
    this.element.closest('.writing-content').classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }

  resizeInput() {
    const width = Math.min(Math.max(30, this.linkInputTarget.value.length + 2),60)
    this.linkInputTarget.style.width = `${width}ch`;
  }
}
