import { Controller } from 'stimulus';
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';

export default class extends Controller {
  static targets = ['pickerContainer', 'textArea', 'hiddenInput'];

  static values = {
    replace: {type: Boolean, default: false},
    displayHiddenInput: {type: Boolean, default: false},
  }

  connect() {
    this.picker = new Picker({
      data: data,
      parent: this.pickerContainerTarget,
      onEmojiSelect: (emoji) => {        
        if (this.replaceValue) {
          this.textAreaTarget.innerText = emoji.native
        } else {
          this.textAreaTarget.innerText += emoji.native
        }
        if (this.displayHiddenInputValue) {
          this.hiddenInputTarget.value = this.textAreaTarget.innerText
          // We dispatch a change event on the hidden input to notify the browser
          // that its value has changed
          this.hiddenInputTarget.dispatchEvent(new Event('change'));
        }
      }
    })  
  }
}