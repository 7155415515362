import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'handleNumber' ]
  static values =  { chapterIndex: String, chapterId: String}


  connect() {
    this.patchUrl = `/sections/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'section',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
    document.addEventListener('sections:changedChapter', e => {
      if ( e.detail.targetChapterId == this.chapterIdValue ) {
        this.resetNumbers()
      }
    }
    )
  }

  onEnd(event) {
    const id = event.item.dataset.sectionId
    const data = new FormData()

    data.append('position', event.newIndex + 1)
    data.append('chapter_target', event.to.dataset.chapterId)

    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data,
      success: (data) => {
        dispatchCustomEvent('sections:changedChapter', {
          sectionId: id,
          originChapterId: event.from.closest('.writing-summary-chapter-card').dataset.chapterId,
          targetChapterId: event.to.dataset.chapterId
        })
      }
    })

    this.resetNumbers()
    dispatchCustomEvent('element:reordered')
  }

  resetNumbers() {
    this.handleNumberTargets.forEach((e, i) => e.innerText = `${this.chapterIndexValue}.${i + 1}`)
  }
}
