import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'input', 'positionLabel' ]

  connect() {
    this.lookupForDomChanges()
    this.adjustPositionLabel()
    this.sortable = Sortable.create(this.element, {
      group: 'skill_level',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

    onEnd(e) {
      // We update all the hidden inputs with the new position
      this.inputTargets.forEach((input, index) => {
        input.value = index + 1
      })
      this.adjustPositionLabel()
    }

  adjustPositionLabel() {
    this.positionLabelTargets.forEach((label, index) => {
      label.innerText = index + 1
    })
  }

  lookupForDomChanges() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          this.adjustPositionLabel()
        }
      })
    })

    observer.observe(this.element, {
      childList: true
    })
  }
}
