import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['titleInput', 'title', 'descInput', 'desc', 'pencilTitle',
  'tickTitle', 'pencilDesc', 'tickDesc', 'descWrapper']

  noMoreEnterKey(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
      if (e.currentTarget.value !=="") {
          this.hideTitleForm()
          this.updateTitle()
      }
    }
  }

  updateTitle() {
    if(this.titleInputTarget.value !== "") {
      this.titleTarget.innerText = this.titleInputTarget.value
    }
    this.hideTitleForm()
  }

  updateDesc(e) {
    e.preventDefault()
    if(this.descInputTarget.value !=="") {
      this.descTarget.innerHTML = this.descInputTarget.value
    }
    this.hideDescForm()
  }

  showTitleForm(e) {
    e.preventDefault()
    showElements(this.titleInputTarget, this.tickTitleTarget)
    hideElements(this.titleTarget, this.pencilTitleTarget)
    this.titleInputTarget.focus();
    this.titleInputTarget.addEventListener('blur', (e) => {
        e.preventDefault()
        this.hideTitleForm(e)
        if (this.titleInputTarget.value != "") {
        this.updateTitle()
        }
      }, {once: true})
    }

    showDescForm(e) {
      e.preventDefault()
      showElements(this.descWrapperTarget, this.descInputTarget, this.tickDescTarget)
      hideElements(this.descTarget, this.pencilDescTarget)
      this.descInputTarget.focus();
      }


    // showTitleFormMobile(e) {
    // showElements(this.cardInputTarget, this.tickTitleTarget)
    // hideElements(this.cardTitleTarget, this.pencilTitleTarget)
    // this.cardInputTarget.focus();
    // }



  hideTitleForm() {
    showElements(this.titleTarget, this.pencilTitleTarget)
    hideElements(this.titleInputTarget, this.tickTitleTarget)
  }

  hideDescForm() {
    showElements(this.descTarget, this.pencilDescTarget)
    hideElements(this.descWrapperTarget, this.descInputTarget, this.tickDescTarget)
  }
}
