import { log } from "@tensorflow/tfjs";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['card', 'container', 'loader']
  static values = {
    nbLinesRevealed: { type: Number, default: 1 }
  }

  connect() {
    if (this.hasContainerTarget) {
      if (this.nbLinesRevealedValue > 1) {
        this.containerTarget.style.maxHeight = this.cardTargets[0].scrollHeight * this.nbLinesRevealedValue + 'px'
      }
      else {
        this.containerTarget.style.maxHeight = this.cardTargets[0].scrollHeight + 'px'
      }
    }
    if (this.hasLoaderTarget) {
      this.loaderTarget.addEventListener('finishedLoading', (e) => {
        if (this.nbLinesRevealedValue > 1) {
          this.containerTarget.style.maxHeight = this.cardTargets[0].scrollHeight * this.nbLinesRevealedValue + 'px'
        }
        else {
          this.containerTarget.style.maxHeight = this.cardTargets[0].scrollHeight + 'px'
        }
        this.hideOverflowingCards()
      })
    }

    window.addEventListener('resize', this.hideOverflowingCards);

  }
  toggleOverflowCards() {
    let state = false
    if(!state) {
      this.hideOverflowingCards()
      state = true
    } else {
      this.showOverflowingCards()
      state = false
    }
  }

  hideOverflowingCards() {
    if (!this.element) return;
    const showBtn = this.element.querySelector('.show-js')
    const hideBtn = this.element.querySelector('.hide-js')
    if (hideBtn) {
      hideBtn.style.display = 'none'
    }
    const pagination = this.element.querySelector('.pagination')
    if (pagination) {
      pagination.style.display = 'none'
    }

    if (this.cardTargets.length === 0) return;

    // Prendre la hauteur du premier élément comme référence
    const firstCardHeight = this.nbLinesRevealedValue > 1 ? this.cardTargets[0].getBoundingClientRect().height * this.nbLinesRevealedValue : this.cardTargets[0].getBoundingClientRect().height
    const containerRect = this.element.getBoundingClientRect();

    // Cacher les éléments qui dépassent de la première ligne
    this.cardTargets.forEach(card => {
      const cardRect = card.getBoundingClientRect();
      if (cardRect.top >= containerRect.top + firstCardHeight) {
        // Si l'élément déborde de la première ligne, le cacher
        card.style.display = 'none';
        showBtn.style.display = 'flex'
      } else {
        // Assurer que les éléments dans la première ligne sont visibles
        card.style.display = '';
      }
    });
  }


  showOverflowingCards() {
    const showBtn = this.element.querySelector('.show-js')
    const hideBtn = this.element.querySelector('.hide-js')
    if (hideBtn) {
      hideBtn.style.display = 'flex'
    }
    if (showBtn) {
      showBtn.style.display = 'none'
    }
    // Rendre tous les éléments visibles
    this.cardTargets.forEach(card => {
      card.style.display = ''; // Supprime le style display, rendant l'élément à nouveau visible
    });
    this.containerTarget.style.maxHeight = ''
    const pagination = this.element.querySelector('.pagination')
    if (pagination) {
      pagination.style.display = ''
    }
  }
}
