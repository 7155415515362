import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['newResponseFormButton', 'responseFormWrapper', 'formResponseForm', 'responseFormsList', 'formHeader'];

  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  newResponseForm(event) {
    event.preventDefault();
    // this.newResponseFormButton is a button_to so we grab the parent pareent form generated by Rails
    const form = this.newResponseFormButtonTarget.parentElement
    console.log(form)

    fetch(form.action, {
      method: form.method,
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.responseFormWrapperTarget.innerHTML = data
      if (this.newResponseFormButtonTarget.dataset.newFormResponse === 'true') {
        this.newResponseFormButtonTarget.innerText = this.newResponseFormButtonTarget.dataset.continueFormResponseText
      }
    })
  }

  submitForm(event) {
    event.preventDefault();

    // Check html form validity
    if (!this.formResponseFormTarget.checkValidity()) {
      this.formResponseFormTarget.reportValidity();
      return;
    }
    // We first resubmit one last time the form to get the latest data
    // And then we Submit the form to handle form status
    const submitFormForm = event.currentTarget.closest('form');

    fetch(this.formResponseFormTarget.action, {
      method: this.formResponseFormTarget.method,
      body: new FormData(this.formResponseFormTarget),
      headers: { 
        "Accept": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      }
    }).then(response => response.json())
      .then((data) => {
        fetch(submitFormForm.action, {
          method: submitFormForm.method,
          body: new FormData(submitFormForm),
          headers: { "Accept": "application/json" }
        })
          .then(response => response.json())
          .then((data) => {
            this.responseFormWrapperTarget.innerHTML = data.drawer_partial
            this.responseFormsListTarget.innerHTML = data.response_list_partial
            this.newResponseFormButtonTarget.innerText = this.newResponseFormButtonTarget.dataset.newFormResponseText
            const slideoverController = this.application.getControllerForElementAndIdentifier(this.formHeaderTarget, "slideover")
            if (slideoverController) {
              slideoverController.slide()
            }
          })
      })
      .catch(error => console.error('Error:', error));

    // fetch(form.action, {
    //   headers: { 
    //     "Accept": "application/json",
    //   },
    //   method: form.method,
    //   body: new FormData(form)
    // })
    // .then(response => response.json())
    // .then((data) => {
    //   this.responseFormWrapperTarget.innerHTML = data.drawer_partial
    //   this.responseFormsListTarget.insertAdjacentHTML('afterbegin', data.content_partial)
    // })

  }

  resetForm(event) {
    event.preventDefault();
    this.responseFormWrapperTarget.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    this.newResponseForm(event);
  }
}