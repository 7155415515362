import { Controller } from 'stimulus';
// This is the controller to ajaify forms in slideovers

export default class extends Controller {
  static values = {
    url: String,
    method: String,
    format: String
  };
  submit(event) {
    event.preventDefault();

    // To let html5 validation work
    if (!this.element.checkValidity()) {
      this.element.reportValidity();

      return;
    }

    const form = this.element;
    const url = this.urlValue ? this.urlValue : form.action;
    const formData = new FormData(form);
    const method = this.methodValue ? this.methodValue : form.method;
    const format = this.formatValue ? this.formatValue : 'json';

    fetch(url, {
      method: method,
      body: formData,
      headers: {
        'Accept': `application/${format}`,
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        // Convert the errors array into a string
        const errorsString = data.errors;
        // Parse the JSON string into an object
        const errors = JSON.parse(errorsString);
        // Iterate over the keys of the errors object
        Object.keys(errors).forEach((key) => {
          // Create a regex that matches the key or key followed by '_id'
          const regex = new RegExp(`\\[${key}(?:_id)?\\]$`);
        
          // Find all input elements in the form
          const inputs = Array.from(form.querySelectorAll('input, select, textarea'));
        
          // Find the input element that matches the regex
          const input = inputs.find(element => regex.test(element.name));
          const existingError = document.getElementById(`${key}-error`);
          
          if (input && !existingError) {
            const errorDiv = document.createElement('div');
            errorDiv.id = `${key}-error`;
            errorDiv.classList.add('text-red-500', 'text-sm');
            // Assuming each key in the errors object has an array of messages,
            // join them into a single string.
            errorDiv.innerText = errors[key].join(', ');
            input.insertAdjacentElement('afterend', errorDiv);
          }
        });
        
        
      } else {
        window.location = data.redirect_to
      }
    })
  }
}