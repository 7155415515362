import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = [ ]

  reload() {
    location.reload(true)
  }
}
