import { Controller } from 'stimulus';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import Swal from 'sweetalert2'
import consumer from "../channels/consumer";



export default class extends Controller {
  static targets = ['tabulatorContainer', 'tabulatorDataInput', 'columnDefinitionsInput', 'tabulatorSettingsInput',
    'form', 'deleteRowButton', 'saveStatus', 'AllowReadersToWriteInput']
  static values = {
    contentTableId: String,
    tableData: String,
    tableOptions: String,
    tableColumnDefinitions: String,
    readMode: Boolean,
    initFromFile: Boolean,
    fileUrl: String,
    allowEdit: Boolean,
    currentUserId: String,
  }

  connect() {
    this.tableData = JSON.parse(this.tableDataValue)
    this.tableColumnDefinitions = this.tableColumnDefinitionsValue ? JSON.parse(this.tableColumnDefinitionsValue) : []
    this.tableColumnDefinitions.shift()
    
    this.tableOptions = this.readModeValue ? this.#readDefaultOptions() : this.#writeDefaultOptions()
    this.table = this.#createTabulator(this.tableData, this.tableOptions)

    this.table.on("rowSelectionChanged", (data, rows) => {
      if (rows.length > 0) {
        this.#showDeleteButton(rows)
      } else {
        this.#hideDeleteButton()
      }
    });

    if (this.readModeValue) {
      this.#setupAutoSave()
      this.#subscribeToChannel()
    }
    if (this.initFromFileValue) {
      this.#loadDataFromFile()
    }
  }

  loadFile(event) {
    if (this.table) {
      Swal.fire({
        title: I18n.t('js.tabulator.load_file.title'),
        text: I18n.t('js.tabulator.load_file.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: I18n.t('js.tabulator.load_file.confirm_button_text'),
        cancelButtonText: I18n.t('js.tabulator.load_file.cancel_button_text'),
      }).then((result) => {
        if (result.isConfirmed) {
          // We need to set the autocolum to true to be able to import the file and clear the columns
          // this.table.setOption("autoColumns", true)
          // this.table.setOption("columns", [])
          this.table.options.autoColumns = true
          this.table.options.columns = []
          this.table.clearData()

          this.table.import("xlsx", [".xlsx", ".csv", ".ods"], "buffer")
            .then(() => {
              console.log("File imported successfully")
              // this.table.setOption("autoColumns", false)
            })
            .catch(error => console.error("Error importing file:", error))
        }
      })
    }
  }

  // call by the media_creation--submit_controller when click on the submit button
  addValuesToForm() {
    // We save the column definitions in the form
    this.columnDefinitionsInputTarget.value = JSON.stringify(this.table.getColumnDefinitions())
    this.tabulatorDataInputTarget.value = JSON.stringify(this.table.getData())
    // this.tabulatorSettingsInputTarget.value = JSON.stringify(optionsWithoutData)
  }

  toggleAllowReadersToWrite() {
    this.AllowReadersToWriteInputTarget.value = this.AllowReadersToWriteInputTarget.value === "true" ? "false" : "true"
  }

  addRow() {
    if (!this.table) return console.error("Table not initialized")
      
    this.table.addRow({})
      .then(row => {
        this.table.scrollToRow(row, "bottom", "center", 1000)
        if (this.readModeValue) {
          this.#saveData()
        }
      })
      .catch(error => console.error("Error adding row:", error))
  }

  addColumn() {
    if (!this.table) return console.error("Table not initialized")

    this.table.addColumn({
      title: I18n.t('js.tabulator.new_column'),
      field: `newColumn${Math.floor(Math.random() * 1000)}`,
      editor: "textarea",
      formatter: "textarea",
      editableTitle: true,
      headerMenu: [
        {
          label: I18n.t('js.tabulator.delete_column'),
          action: (e, column) => column.delete()
        },
      ]
    })
      .then(() => console.log("Column added successfully"))
      .catch(error => console.error("Error adding column:", error))
  }

  undo() {
    this.table.undo()
    if (this.readModeValue) {
      this.#saveData()
    }
  }

  redo() {
    this.table.redo()
    if (this.readModeValue) {
      this.#saveData()
    }
  }

  deleteSelectedRows() {
    const selectedRows = this.table.getSelectedRows()
    selectedRows.forEach(row => this.#deleteRow(row))
    this.#hideDeleteButton()
  }

  downloadCsv(event) {
    event.preventDefault()
    this.table.download("csv", "data.csv");
  }

  downloadXlsx(event) {
    event.preventDefault()
    this.table.download("xlsx", "data.xlsx", { sheetName: "data" });
  }

  // Only use in read mode
  #setupAutoSave() {
    this.table.on("cellEdited", (cell) => {
      this.#saveData();
    })
  }

  #saveData() {
    this.addValuesToForm()
    this.#fetchServer()
  }

  #fetchServer() {
    const formData = new FormData(this.formTarget)
    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.#displaySaveStatus('success')
      })
      .catch(error => { 
        console.error("Error saving data:", error)
        this.#displaySaveStatus('error')
      })
  }


  #deleteRow(row) {
    row.delete()
  }

  #writeDefaultOptions() {
    return {
      height: 311,
      layout: "fitDataFill",
      history: true,
      placeholder: I18n.t('js.tabulator.awaiting_data'),
      importReader: 'buffer',
      movableRows: true,
      resizableColumnFit:true,
      editor: "textarea",
      formatter: "textarea",
      editorParams: {
        verticalNavigation:"editor", //navigate cursor around text area without leaving the cell
        shiftEnterSubmit:true,
      },
      resizableRows: true,
      autoColumns: this.tableColumnDefinitions.length === 0,
      columns: this.tableColumnDefinitions.length === 0 ? [] : this.tableColumnDefinitions.map(column => {
        return {
          title: column.title,
          field: column.field,
          editor: "textarea",
          editableTitle: true,
          formatter: "textarea",
          headerMenu: this.#getColumnHeaderMenu()
        }
      }),
      // autoColumnsDefinitions: (definitions) => {
      //   definitions.forEach(column => {
      //     column.editor = "input"
      //     column.editableTitle = true
      //     column.headerMenu = this.#getColumnHeaderMenu()
      //   })
      //   return definitions
      // },
      rowHeader: {
        headerSort: false,
        resizable: false,
        frozen: true,
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        cellClick: (e, cell) => cell.getRow().toggleSelect()
      }
    }
  }

  #readDefaultOptions() {
    return {
      height: 311,
      layout: "fitColumns",
      history: true,
      placeholder: I18n.t('js.tabulator.awaiting_data'),
      importReader: 'buffer',
      movableRows: true,
      resizableColumnFit:true,
      formatter: "textarea",
      editor: this.allowEditValue ? "textarea" : false,
      autoColumns: this.tableColumnDefinitions.length === 0,
      resizableRows: true,
      columns: this.tableColumnDefinitions.length === 0 || this.tableColumnDefinitions == {} ? [] : this.tableColumnDefinitions.map(column => {
        return {
          title: column.title,
          field: column.field,
          editor: this.allowEditValue ? "textarea" : false,
          formatter: "textarea",
        }
      }),
      // autoColumnsDefinitions: (definitions) => {
      //   definitions.forEach(column => {
      //     column.editor = this.allowEditValue ? "input" : false
      //     column.editableTitle = false
      //   })
      //   return definitions
      // },
    }
  }

  #getColumnHeaderMenu() {
    return [
      {
        label: I18n.t('js.tabulator.delete_column'),
        action: (e, column) => column.delete()
      },
    ]
  }
  // Does not work because cannot find the position of the column
  #addColumnRight(column) {
    const newColumn = {
      title: I18n.t('js.tabulator.new_column'),
      field: `newColumn${Math.floor(Math.random() * 1000)}`,
      editor: "input",
      editableTitle: true
    }
    const columns = column.getTable().getColumnDefinitions()
    const index = columns.findIndex(col => col.field === column.getField())
    column.getTable().addColumn(newColumn, false, index + 1)
  }

  // Does not work because cannot find the position of the column
  #addColumnLeft(column) {
    const newColumn = {
      title: I18n.t('js.tabulator.new_column'),
      field: `newColumn${Math.floor(Math.random() * 1000)}`,
      editor: "input",
      editableTitle: true
    }
    const columns = column.getTable().getColumnDefinitions()
    const index = columns.findIndex(col => col.field === column.getField())
    column.getTable().addColumn(newColumn, false, index)
  }

  #displaySaveStatus(status) {
    this.saveStatusTarget.dataset.status = status // success or error managed by css
    this.saveStatusTarget.querySelector('.status-text').innerText = status === 'success' ? I18n.t('js.tabulator.save_success') : I18n.t('js.tabulator.save_error')
    this.saveStatusTarget.classList.remove('hidden')

    setTimeout(() => {
      this.saveStatusTarget.querySelector('.status-text').innerText = "";
      this.saveStatusTarget.classList.add("hidden");
      this.saveStatusTarget.dataset.status = "-";
    }, 2000);
  }

  #showDeleteButton(rows) {
    this.deleteRowButtonTarget.innerText = rows.length > 1
      ? `${this.deleteRowButtonTarget.dataset.deleteRowsText.replace(/\d+/, rows.length)}`
      : `${this.deleteRowButtonTarget.dataset.deleteRowText}`
    this.deleteRowButtonTarget.classList.remove('hidden')
  }

  #hideDeleteButton() {
    this.deleteRowButtonTarget.classList.add('hidden')
  }

  #createTabulator(data, options) {
    return new Tabulator(this.tabulatorContainerTarget, {
      data: this.#setTableData(data),
      ...options,
    })
  }

  #setTableData(data) {
    return this.initFromFileValue ? [] : data
  }

  #loadDataFromFile() {
    fetch(this.fileUrlValue)
      .then(response => response.text())
      .then(data => {
        const json_data = csvJson(data) // stimulus helper
        this.table.setData(json_data)
      })
      .catch(error => console.error("Error fetching file:", error))
  }

  #subscribeToChannel() {
    this.channel = consumer.subscriptions.create(
      { channel: "ContentTableChannel", id: this.contentTableIdValue }, 
      {
        received: (data) => {
          if (data.current_user_id != this.currentUserIdValue) {
            this.table.setData(JSON.parse(data.table_data))
          }
        }
      }
    )
  }

  disconnect() {
    if (this.channel) {
      consumer.subscriptions.remove(this.channel)
    }
  }
}