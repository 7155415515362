import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'handleNumberTargets']

  connect() {
    this.patchUrl = `/blocks/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'block',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    const id = event.item.dataset.blockId
    const data = new FormData()

    data.append('position', event.newIndex + 1)

    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data
    })

  }
}
