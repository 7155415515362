import { Controller } from "stimulus";
import { fetchWithToken } from '../application/stimulus_helper';


export default class extends Controller {

  static values = { url: String }

    navigate(e) {
      this.highlightCard(e.currentTarget)
      const target = this.element.querySelector('.template-index')
      const url = this.urlValue
      const title = this.element.querySelector('.page-title')
      const newTitle = e.currentTarget.dataset.title

      let organisation = ""
      let useCase = ""

      if (e.currentTarget.dataset.paramOrganisation) {
        organisation = e.currentTarget.dataset.paramOrganisation
      }
      if (e.currentTarget.dataset.paramUseCase) {
        useCase = e.currentTarget.dataset.paramUseCase
      }

      const  finalUrl= `${url}?organisation=${organisation}&use_case=${useCase}`

      fetchWithToken(finalUrl, {
        method: 'GET',
        headers: {"Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        if (data.partial) {
         target.innerHTML = data.partial
         title.innerText = newTitle
        }
      })
    }

    highlightCard(card) {
      this.element.querySelectorAll('.template-card').forEach( (c) => {
        c.classList.remove('bg-gray-300')
      })
      card.classList.add('bg-gray-300')
    }
  }
