import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'submitFileBtn', 'form', 'linkInput', 'linkPreview', 'validationMessage', 'deleteBtn' ]
  static values = {
    contentId: Number,
    integrationTypeId: Number,
    removeUrl: String,
    linkPreviewUrl: String,
    iframeType: Boolean,
  }

  connect() {
    //this.resizeInput()
    this.previewLink()
    }

  emptyInput() {
    this.deleteBtnTarget.classList.replace('opacity-100', 'opacity-0')
    showElements( this.validationMessageTarget)
    this.linkPreviewTarget.classList.add('hidden')
  }

  previewLink() {
   // this.resizeInput()
    if (this.linkInputTarget.value === '') {
      this.emptyInput()
    }
    else {
      this.deleteBtnTarget.classList.replace('opacity-0', 'opacity-100')
      const link = this.linkInputTarget.value
      const type_id  = this.integrationTypeIdValue
      const url = `${this.linkPreviewUrlValue}?integration_type_id=${type_id}&link=${encodeURIComponent(link)}`
    //  const url = `${this.linkPreviewUrlValue}?integration_type_id=${type_id}&link=${link}`
      const validRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      fetchWithToken(url, {
        method: "GET",
        headers: {"Accept": "application/json" }
      })
      .then(response => response.json())
      .then((data) => {
        if (validRegExp.test(data.url)) {
          this.linkPreviewTarget.innerHTML = data.partial
          this.linkInputTarget.value = data.url
          hideElements( this.validationMessageTarget)
          this.linkPreviewTarget.classList.remove('hidden')
          hideElements(document.querySelector(`.simple-cancel-button[data-id="${this.contentIdValue}"]`))
          fadeShowElements(
            document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`),
            document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`),
            document.querySelector(`.cancel-button[data-id="${this.contentIdValue}"]`),
          )
        }
      })
      .catch((error) => {
            this.validationMessageTarget.classList.remove('hidden')
            this.linkPreviewTarget.classList.add('hidden')
      });
    }
  }

  deleteLink(event) {
    this.linkInputTarget.value = ''
    this.validationMessageTarget.classList.remove('hidden')
    hideElements( this.linkPreviewTarget)
    fadeHideElements(document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`), document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`))
    this.deleteBtnTarget.classList.replace('opacity-100', 'opacity-0')
  }

  resizeInput() {
    const width = Math.min(Math.max(30, this.linkInputTarget.value.length + 2),60)
    this.linkInputTarget.style.width = `${width}ch`;
  }
}
