import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'imgPreview', 'cta', 'input' ]

  openWindow() {
    if (this.hasInputTarget) {
      this.inputTarget.click()
    }
  }

  displayFile(event) {
    this.imgPreviewTarget.classList.remove('hidden')
    if(this.hasCtaTarget) {
      this.ctaTarget.innerText = 'Changer la photo'
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      // TODO
      // if (/\.*image\.*/.test(srcElement.result.split(';')[0].split(':')[1])) {
      if (event.currentTarget.result) {
        this.imgPreviewTarget.src = event.currentTarget.result
      } else {
        this.imgPreviewTarget.src = ''
      }
    }
    reader.readAsDataURL(event.target.files[0])
  }

}
