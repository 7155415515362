import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    checked: Boolean,
    submitOnChange: { type: Boolean, default: true }
  }

  connect() {
    console.log('connected')
    this.checkedValue = this.checkedValue
    this.submitOnChangeValue = this.submitOnChangeValue
  }

  toggle(event) {
    console.log(event)
    event.preventDefault();
    this.checkedValue = !this.checkedValue
    this.element.checked = this.checkedValue
    this.element.dataset.checked = this.checkedValue

    const data = new FormData();
    data.append('checked', this.checkedValue)
    if (this.submitOnChangeValue) {
      this.element.closest('form').requestSubmit()
    }

  }
}