import { Controller }   from 'stimulus'
import { PlayerSdk }    from '@api.video/player-sdk'
import interact from 'interactjs'
import { fetchWithToken } from '../../application/stimulus_helper'

export default class extends Controller {
  static targets = [
    'videoTag',
    'controls',
    'totalVideoTime',
    'elapsedVideoTime',
    'playPause',
    'mute',
    'volumeInput',
    'volumeUp',
    'volumeDown',
    'muteIcon',
    'audioIcon',
    'playIcon',
    'pauseIcon',
    'timelineBase',
    'timeline',
    'progressFallback',
    'fullscreen',
    'playrateBtn',
    'cursor',
    'startTooltip',
    'startTime',
    'endTime',
    'endTooltip',
    'hideStBtn',
    'showStBtn',
    'resetBtn',
    'thinkingBtn',
  ]

  static values = {
    id: Number,
    playerId: String,
    apiVideoId: String,
    apiVideoToken: String,
    start: { type: Number, default: 0 },
    end: { type: Number, default: 10 },
    threshold: { type: Number, default: 50 },
    duration: Number,
    resetUrl: String,
    muted: { type: Boolean, default: false }
  }

  connect() {
    this.volume = localStorage.getItem('volume') || 1
    if (this.apiVideoTokenValue) {
      this.player = new PlayerSdk(`.${this.playerIdValue}`, {
        id: this.apiVideoIdValue,
        token: this.apiVideoTokenValue,
        muted: this.mutedValue,
      });
      this.player.addEventListener('ready', this._setControls.bind(this))
    }
    document.addEventListener('video-api:pause', (e) => {
      this.player.pause()
    })
  }

  _setControls() {
    this.player.hideControls()
    this.player.setCurrentTime(this.startValue)

    this.player.getDuration().then((duration) => {
      if (this.durationValue !== duration) this._updateDuration(duration)
      this.duration             = duration
      this.durationMilliseconds = duration * 1000
      this._initAll()
    })
  }

  _initAll() {
    showElements(this.controlsTarget)
    this._initVideoControls()
    this._updateControlsWithTrimValues()
    this._setTrimBegin()
    this._setTrimEnd()
  }

  _initVideoControls() {
    const duration = roundToDecimal((this.endValue || this.duration), 10)
    const time     = formatTime(duration)

    // this.totalVideoTimeTarget.innerText = `${time.hours}:${time.minutes}:${time.seconds}`
    // this.totalVideoTimeTarget.setAttribute('datetime', `${time.hours}h ${time.minutes}m ${time.seconds}s`)
    this.player.onpause = () => { this.pause() }
    this.player.onplay  = () => { this.play() }
    this.player.addEventListener('timeupdate', (e) => {
      this._updateTimeElapsed(e.currentTime)
      this._updateProgress(e.currentTime)
      this._setTrimBegin()
      this._setTrimEnd()
      console.log('✅ start time updated')
      console.log(e.currentTime)
      console.log(this.endValue)
      console.log(this.endValue && e.currentTime)
      console.log(this.endValue && e.currentTime > this.endValue)
      if (this.endValue && e.currentTime > this.endValue) {
        console.log('✅ end time reached')
        this.goTo(this.startValue)
        this.pause()
      }
    })
    this.player.addEventListener('ended', (e) => {
      this.goTo(this.startValue)
      this.pause()
    })
  }

  _updateControlsWithTrimValues() {
    this.startValue = this.startValue || 0
    this.endValue = this.endValue || roundToDecimal(this.duration, 10)
  }

  _updateProgress(currentTime) {
    this._updateCursorTimestamp(currentTime)
    const percentage = roundToDecimal(currentTime, 4) / (this.duration / 100)
    this.cursorTarget.style.marginLeft = `${this.timelineBaseTarget.offsetWidth * (percentage / 100)}px`
  }

  _setTrimBegin() {
    const percentage = roundToDecimal(this.startValue,4) / (this.duration / 100)
    const marginLeftValue = this.timelineBaseTarget.offsetWidth * (percentage / 100)
    this.timelineTarget.style.marginLeft = `${marginLeftValue}px`

    var that = this
    // We set the startTime target to the startValue
    this.startTimeTarget.innerText = formatTime(this.startValue).hours + ':' + formatTime(this.startValue).minutes + ':' + formatTime(this.startValue).seconds

    interact(`.trim-handle-begin-${this.idValue}`).draggable({
      listeners: {
        start(event) {
          that.trimming = true
          showElements(that.startTooltipTarget)
        },
        move(event) {
          const originalMarginCssRule    = (that.timelineTarget.style.marginLeft || '0').match(/\d+/)[0]
          const originalMarginLeftPx     = parseInt(originalMarginCssRule, 10)
          const nextCalculatedMarginLeft = originalMarginLeftPx + event.dx
          const timelineWidth            = that.timelineTarget.getBoundingClientRect().width
          const maxMarginLeft            = timelineWidth + nextCalculatedMarginLeft - that.thresholdValue
          const marginLeft               = clamp(0, nextCalculatedMarginLeft, maxMarginLeft)

          // that._hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth)
          that.timelineTarget.style.marginLeft = `${marginLeft}px`
          that.timestamp = that.duration / 100 * marginLeft / (that.timelineBaseTarget.offsetWidth / 100)
          that.startValue = that.timestamp
          that._updateStartEndTime(that.timestamp, that.endValue)
          that._updateTooltip(that.startTooltipTarget, that.timestamp)
          that._setTime(that.timestamp)
        },
        end(event) {
          hideElements(that.startTooltipTarget)
          setTimeout(() => that.trimming = false, 50)
          that._updateVideoTimestamps(that.timestamp, that.endValue)
        }
      }
    })
  }

  _setTrimEnd() {
    const marginRightValue = `${this.timelineBaseTarget.offsetWidth * ((this.duration - this.endValue) / this.duration )}px`
    this.timelineTarget.style.marginRight = marginRightValue
    // We set the endTime target to the endValue
    this.endTimeTarget.innerText = formatTime(this.endValue).hours + ':' + formatTime(this.endValue).minutes + ':' + formatTime(this.endValue).seconds

    var that = this

    interact(`.trim-handle-end-${this.idValue}`).draggable({
      listeners: {
        start(event) {
          that.trimming = true
          showElements(that.endTooltipTarget)
        },
        move(event) {
          const originalMarginCssRule     = (that.timelineTarget.style.marginRight || '0').match(/\d+/)[0]
          const originalMarginRightPx     = parseInt(originalMarginCssRule, 10)
          const nextCalculatedMarginRight = originalMarginRightPx - event.dx
          const timelineWidth             = that.timelineTarget.getBoundingClientRect().width
          const maxMarginRight            = timelineWidth + nextCalculatedMarginRight - that.thresholdValue
          const marginRight               = clamp(0, nextCalculatedMarginRight, maxMarginRight)

          // that._hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth)
          that.timelineTarget.style.marginRight = `${marginRight}px`
          that.timestamp = that.duration - (that.duration / 100 * marginRight / (that.timelineBaseTarget.offsetWidth / 100))
          that.endValue = that.timestamp
          that._updateTooltip(that.endTooltipTarget, that.timestamp)
          that._updateStartEndTime(that.startValue, that.timestamp)
          that._setTime(that.timestamp)

          const formattedEndTime = formatTime(that.timestamp)
          // that.totalVideoTimeTarget.innerText = `${formattedEndTime.hours}:${formattedEndTime.minutes}:${formattedEndTime.seconds}`
        },
        end(event) {
          hideElements(that.endTooltipTarget)
          setTimeout(() => that.trimming = false, 50)
          that._updateVideoTimestamps(that.startValue, that.timestamp)
        }
      }
    })
  }

  _hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth) {
    this.totalVideoTimeTarget.classList.toggle('hidden', (timelineWidth - (2 * 25) < 85))
  }

  _updateStartEndTime(start, end) {
    this.startTimeTarget.innerText = formatTime(start).hours + ':' + formatTime(start).minutes + ':' + formatTime(start).seconds
    this.endTimeTarget.innerText   = formatTime(end).hours + ':' + formatTime(end).minutes + ':' + formatTime(end).seconds
  }

  _updateTooltip(tooltip, timestamp) {
    const time        = formatTime(timestamp)
    const timeString  = `${time.hours}:${time.minutes}:${time.seconds}`
    tooltip.innerText = timeString

  }

  _updateTimeElapsed(currentTime) {
    const elapsedTime = formatTime(currentTime);

    this.elapsedVideoTimeTarget.innerText = `${elapsedTime.hours}:${elapsedTime.minutes}:${elapsedTime.seconds}`;
    this.elapsedVideoTimeTarget.setAttribute('datetime', `${elapsedTime.hours}:${elapsedTime.minutes}m ${elapsedTime.seconds}s`)
  }

  skip(e) {
    var rect = this.timelineBaseTarget.getBoundingClientRect();
    var pos  = (e.pageX  - rect.left) / this.timelineBaseTarget.offsetWidth;
    const value       = pos * this.duration;
    const customStart = this.startValue
    const customEnd   = this.endValue

    if (customStart && value < customStart) {
      this.goTo(customStart)
    } else if (customEnd && value > customEnd) {
      this.goTo(customEnd)
    } else {
      this.goTo(value)
    }
  }

  moveSkip(e) {
    if (e.buttons == 1) {
      window.getSelection().removeAllRanges();
      this.skip(e)
    }
  }

  touchSkip(e) {
    if (e.touches.length == 1) {
      window.getSelection().removeAllRanges();
      this.skip(e)
    }
  }

  goTo(value) {
    if (this.trimming) return
    this._setTime(value)
  }

  _setTime(timestamp) {
    this.player.setCurrentTime(timestamp)
    const percentage         = roundToDecimal(timestamp,4) / (this.duration / 100)
    const marginLeftValue    = this.timelineBaseTarget.offsetWidth * (percentage / 100)
    const timelineMarginLeft = parseInt((this.timelineTarget.style.marginLeft || '0').match(/\d+/)[0], 10)
    this.cursorTarget.style.marginLeft = `${marginLeftValue}px`

    this._updateCursorTimestamp(timestamp)

    const cursorAtEnd = marginLeftValue > timelineMarginLeft + this.timelineTarget.offsetWidth - 10
    this.cursorTarget.classList.toggle('-translate-x-0.5', cursorAtEnd)
  }

  playPause() {
    this.player.getPaused()
      .then((paused) => {
        if (paused) {
          stopAllPlayingMedias()
          this.play()
        } else {
          this.pause()
        }
      })
  }

  hideSt() {
    this.player.hideSubtitles()
    hideElements(this.hideStBtnTarget)
    showElements(this.showStBtnTarget)
  }

  showSt() {
    this.player.showSubtitles()
    hideElements(this.showStBtnTarget)
    showElements(this.hideStBtnTarget)
  }

  resetSt() {
    fetchWithToken(this.resetUrlValue, {
      method: 'POST',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        Swal.fire({
          title: data.message,
          icon: 'warning',
        })
        hideElements(this.resetBtnTarget)
        showElements(this.thinkingBtnTarget)
      })
  }

  playPauseFromSpaceBar(e) {
    if (
      !(e.keyCode === 32) ||
      this.element.closest('.video-part-writing').classList.contains('hidden') ||
      this.element.closest('.content-writing-modal').classList.contains('hidden') ||
      document.activeElement.tagName === 'TEXTAREA' ||
      document.activeElement.tagName === 'INPUT'
    ) return

    this.playPause()
  }

  _updateCursorTimestamp(timestamp) {
    this.cursorTarget.dataset.timestamp = timestamp
  }

  async mute() {
    const muted = await this.player.getMuted()
    if (muted) {
      this.player.unmute()
      this.player.setVolume(this.volume)
      this.volumeInputTarget.value = this.volume
    } else {
      this.player.mute()
      this.player.setVolume(0)
      this.volumeInputTarget.value = 0
    }
    this.audioIconTarget.classList.toggle('hidden', !muted)
    this.muteIconTarget.classList.toggle('hidden', muted)
  }

  async play() {
    hideElements(this.playIconTarget)
    showElements(this.pauseIconTarget)

    const start = this.startValue
    const end   = this.endValue
    const currentTime = await this.player.getCurrentTime()

    if (start && currentTime < start || end && currentTime > end) this.player.setCurrentTime(start)
    this.player.play();
  }

  pause() {
    hideElements(this.pauseIconTarget)
    showElements(this.playIconTarget)
    this.player.pause();
  }

  async updateVolume() {
    const muted = await this.player.getMuted()
    const vol = this.volumeInputTarget.value
    if (muted) {
      this.player.unmute()
      showElements(this.audioIconTarget)
      hideElements(this.muteIconTarget)
    }
    if (vol == 0) {
      this.player.mute()
      hideElements(this.audioIconTarget)
      showElements(this.muteIconTarget)
    }
    this.volume = vol
    this.player.setVolume(vol)
  }

  setPlayrate(e) {
    const playrateValue = e.params.value
    this.player.setPlaybackRate(playrateValue)
    this.playrateBtnTarget.innerText = `x${playrateValue}`
  }

  handleFullscreen() {
    if (this._isFullScreen()) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
    }
  }

  enterFullscreen() {
    if (this.element.requestFullscreen) this.element.requestFullscreen();
    else if (this.element.mozRequestFullScreen) this.element.mozRequestFullScreen();
    else if (this.element.webkitRequestFullScreen) this.element.webkitRequestFullScreen();
    else if (this.element.msRequestFullscreen) this.element.msRequestFullscreen();
    else if (this.element.webkitEnterFullscreen) this.element.webkitEnterFullscreen();
    else console.error('No fullscreen available')
    this.setFullscreenData(true);
  }

  exitFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else console.error('No fullscreen available')
    this.setFullscreenData(false);
  }

  displayFullScreenButton() {
    const fullscreenAvailableOnFigureElement = this.element.requestFullscreen ||
                                                this.element.mozRequestFullScreen ||
                                                this.element.webkitRequestFullScreen ||
                                                this.element.msRequestFullscreen ||
                                                this.element.webkitEnterFullscreen
    if (!fullscreenAvailableOnFigureElement) hideElements(this.fullscreenTarget)
  }

  setFullscreenData(state) {
    this.element.setAttribute('data-fullscreen', !!state);
  }

  _fullscreenEnabled() {
    return !!(
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled ||
      document.webkitSupportsFullscreen ||
      document.webkitFullscreenEnabled ||
      document.createElement('video').webkitRequestFullScreen
    )
  }

  _isFullScreen() {
    return !!(
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    )
  }

  _updateVideoTimestamps(start, end) {
    this.element.dataset.start = start
    this.element.dataset.end   = end

    const id     = this.idValue
    let formData = new FormData()

    formData.append('video_part[video_start]', start)
    formData.append('video_part[video_end]', end)

    fetchWithToken(`/video_parts/${id}/update_video_timestamps`, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors) {
        Swal.fire({
          title: 'Erreur',
          text: data.errors,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      }
    })
  }

  _updateDuration(duration) {
    let formData = new FormData()

    formData.append('video_part[duration]', duration)

    fetchWithToken(`/video_parts/${this.idValue}`, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (!data.updated) {
        Swal.fire({
          title: 'Erreur',
          text: 'Error updating duration of the video',
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      }
    })
  }
}
