import debounce from 'lodash/debounce'
import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'card', 'form', 'input', 'bottom']

  connect() {
    this.#setInputMaxHeight()
    // this.debounceAutoSave = debounce(this.debounceAutoSave, 2000).bind(this)
    // this.displayLoadingSpinner = debounce(this.displayLoadingSpinner, 1500).bind(this)
  }


  submitForm() {
    const formData = new FormData(this.formTarget)

    Rails.ajax({
      type: "patch",
      url: this.formTarget.action,
      data: formData,
      success: (data) => {
        if (data.text_content.body == "") {
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
        } else {
          this.#markAsDone()
          const content = document.querySelector(`[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.partial
        }
       },
      error: (data) => {
        // TODO Raise alert here
        console.error("Error - ", data)
      }
    })
  }

  #setInputMaxHeight() {
    const bottomThreshold = this.bottomTarget.offsetHeight
    const toolbarThreshold = this.element.querySelector('trix-toolbar').offsetHeight
    const maxInputHeight = this.element.offsetHeight - bottomThreshold - toolbarThreshold
    this.inputTarget.style.height = `${maxInputHeight}px`
    this.inputTarget.style.maxHeight = `${maxInputHeight}px`
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    // data-writing--status-target="status"
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsTodo() {
    this.element.closest('.writing-content').classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }
}
