import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {

  connect() {
    this.patchUrl = `/content_sections/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'content',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      draggable: ".writing-content",
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    let id = event.item.dataset.contentSectionId
    var data = new FormData()

    data.append('position', event.newIndex + 1)
    data.append('section_target', event.to.dataset.sectionId)

    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data
    })
    dispatchCustomEvent('contents:reordered')
    dispatchCustomEvent('element:reordered')
  }

  moveToSection(event) {
    const card = event.currentTarget.closest('.writing-content')
    const contentId = event.params.contentId
    const contentSectionId = event.params.contentSectionId
    const sectionId = event.params.sectionId
    const data = new FormData()

    data.append('section_target', sectionId)

    Rails.ajax({
      url: this.patchUrl.replace(":id", contentSectionId),
      type: 'PATCH',
      data: data,
      success: (data) => {
        card.remove()
        dispatchCustomEvent('contents:changedSection', {
          targetSectionId: sectionId,
          done: card.classList.contains('done')
        })
        dispatchCustomEvent('element:reordered')
      },
      error: (data) => {
        console.error(data)
      }
    })
  }
}
