import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['formContainer', 'formSelect', 'form', 'searchForm', 'searchInput', 'results', 'radio', 'formIdHiddenInput']
  static values = {
    contentId: String
  }

  connect() {
    const submitControllerWrapper = this.element.closest('.content-writing-modal')
    this.submitController = this.application.getControllerForElementAndIdentifier(submitControllerWrapper, 'media-creation--submit')
  }

  selectForm() {
    console.log('selectForm')
    this.formIdHiddenInputTarget.value = this.radioTargets.find(radio => radio.checked).value
    // if a form is selected, we reveal the buttons
    if (this.formIdHiddenInputTarget.value) {
      this.submitController.revealButtons()
    }
  }

  search(event) {
    event.preventDefault()
    event.stopPropagation()

    const url = `${this.searchFormTarget.action}?query=${this.searchInputTarget.value}`
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      console.log(url)
      this.fetchResults(url)
    })
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.resultsTarget.innerHTML = data
    })
  }

  insertSpinner(target) {
    this.resultsTarget.innerHTML = '<div class="h-72 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
  }


  submitForm() {    
    const formData = new FormData(this.formTarget)

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.status === "done") {
        this.#markAsDone()
      } else {
        this.#markAsOngoing()
      }
      console.log(data)

      const content = document.querySelector(`[data-content-id="${data.content_id}"]`)
      if (content) content.outerHTML = data.partial
    })
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  drawingDataChanged() {
    this.submitController.revealButtons()
  }
}
