import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['publishedBtn'];
  static values = {
    publishUrl: String,
  };

  publish(event) {
    this.publishedBtnTarget.disabled = true;
    fetch(this.publishUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // We replace the publish button with a data.html
        this.publishedBtnTarget.outerHTML = data.html;
        // window.location.href = data.redirect_url;
      });

  }
}