import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { fetchWithToken } from '../application/stimulus_helper';


export default class extends Controller {
  static targets = ['header', 'results', 'form', 'query', 'input', 'image', 'uploadform', 'saveBtn', 'inputfile', 'notesPanel', 'unsplashPanel', 'audioBackground', 'mediaPanel', 'rightPanel']
  static values = {orientation: String, unsplashurl: String}


  connect() {
    if (this.audioBackgroundTarget.dataset.picturepersisted === "no") {
      const background = this.audioBackgroundTarget
      const url = `${this.unsplashurlValue}?orientation=${this.orientationValue}&keyword=podcast`
      Rails.ajax({
        url: url,
        type: 'get',
        success: (data) => {
          this.appendAndSubmit(data.image_url)
        }
      })
      background.dataset.picturepersisted = "yes"
    }
  }

  open() {
    fadeShowElements(this.unsplashPanelTarget)
    fadeHideElements(this.notesPanelTarget)
    const form = this.formTarget
    const url = `${form.action}?orientation=${this.orientationValue}&specific=-audio&columns=4`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  openMobile() {
    fadeHideElements(this.mediaPanelTarget)
    this.open()
    fadeShowElements(this.rightPanelTarget)
  }

  hideModal(event) {
    if (event.currentTarget == event.srcElement) this.closeModal()
  }

  hideModalMobile(event) {
    if (event.currentTarget == event.srcElement) {
      stopAllPlayingMedias()
      fadeHideElements(this.rightPanelTarget)
      fadeShowElements(this.mediaPanelTarget)
    }
  }


  closeModal() {
    stopAllPlayingMedias()
    fadeHideElements(this.unsplashPanelTarget)
    fadeShowElements(this.notesPanelTarget)
  }

  openOSWindow(){
    this.inputfileTarget.click()   
    this.inputfileTarget.addEventListener('input', (event) => {
      this.submitForm()
    })
  }

  search(e) {
    e.preventDefault()
    const form = this.formTarget
    const url = `${form.action}?query=${this.inputTarget.value}&orientation=${this.orientationValue}&specific=-audio`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  select(e){
    this.imageTargets.forEach( (image) => {
      image.classList.remove('border-4')
    })
    e.currentTarget.classList.add('border-4');
    this.uploadformTarget.reset();
    this.audioBackgroundTarget.src = e.currentTarget.dataset.url
    this.revealSave();
  }

  revealSave() {
    this.saveBtnTarget.classList.remove('hidden')
  }

  submit() {
    if (this.inputfileTarget.files.length == 0) {
      const selected = document.querySelector('.border-4');
      const url = selected.dataset.url
      this.appendAndSubmit(url)
    } else {
      this.submitForm()
    }
  }

  appendAndSubmit(url) {
    fetch(url)
    .then(response => response.blob())
    .then(blob => new File([blob], "audio-bg.jpg", {
      type: blob.type
    }))
    .then(file => {
      var list = new DataTransfer()
      list.items.add(file);
      this.inputfileTarget.files = list.files;
      this.submitForm();
    })
  }

  submitForm() {
    const formData = new FormData(this.uploadformTarget)
    const url = this.uploadformTarget.action

    fetchWithToken(url, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      this.audioBackgroundTarget.src = data.image_url
    })
    this.resetCloseandReload()
  }

  resetCloseandReload() {
    this.uploadformTarget.reset();
    this.formTarget.reset();
    this.resultsTarget.innerHTML="";
    if (this.mediaPanelTarget.classList.contains('hidden')) {
      fadeHideElements(this.rightPanelTarget)
      fadeShowElements(this.mediaPanelTarget)
    } else {
      this.closeModal()
    }
  }
}
