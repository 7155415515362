import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inviteUsersSection', 'settingsSection'];

  toggleSettings(event) {
    this.inviteUsersSectionTarget.dataset.active = false;

    this.settingsSectionTarget.dataset.active = true;
  }

  toggleInviteUsers(event) {
    this.settingsSectionTarget.dataset.active = false;

    this.inviteUsersSectionTarget.dataset.active = true;
  }

}