import { Controller } from "stimulus"
import consumer from "../channels/consumer"
import Swal from 'sweetalert2';


export default class extends Controller {
  static values = { sharingId: Number }

  connect() {
    console.log(document.querySelectorAll('.show-if-certifiable'))
    this.channel = consumer.subscriptions.create(
      { channel: "ReadingProgressChannel", id: this.sharingIdValue },
      { received: (data) => {
          // TODO link to handover card?
          if (document.contains(this.element)) {
            this.element.outerHTML = data.partial
          }
            // We replace this.element with data.html
          if (data.show_certif === true) {
            document.querySelectorAll('.show-if-certifiable').forEach( (toast) => {
              toast.classList.remove('hidden')
            })
          }

          if (data.show_step_completed === true) {
            Swal.fire({
              text: I18n.t('js.journeys.step_completed', {name: data.journey_name}),
              title: I18n.t('js.journeys.completed_title'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: I18n.t('js.journeys.cta_title'),
              cancelButtonText: I18n.t('js.journeys.back_title'),
              confirmButtonColor: '#3d52d5'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = `/journeys/${data.journey_id}`;
              }
            });
          }
        }
      }
    )
  }
}
