import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']


  connect() {
    const submitControllerWrapper = this.element.closest('.content-writing-modal')
    this.submitController = this.application.getControllerForElementAndIdentifier(submitControllerWrapper, 'media-creation--submit')
    if (this.submitController) {
      this.submitController.revealButtons()
    }
  }

  submitForm() {
    const tabulatorController = this.application.getControllerForElementAndIdentifier(this.element, 'tabulator')
    tabulatorController.addValuesToForm()

    const formData = new FormData(this.formTarget)
    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        data.status === "done" ? this.#markAsDone() : this.#markAsOngoing()
        const content = document.querySelector(`[data-content-id="${data.content_id}"]`)
        if (content) content.outerHTML = data.partial
      })
  }

  #markAsDone() {
    const element = this.element.closest('.writing-content')
    element.classList.replace('todo', 'done')
    element.classList.replace('ongoing', 'done')
    element.querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    const element = this.element.closest('.writing-content')
    element.classList.replace('todo', 'ongoing')
    element.classList.replace('done', 'ongoing')
    element.querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }
}