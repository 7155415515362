import { Controller } from 'stimulus';
import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'

export default class extends Controller {
  static targets = ['pickerContainer']
  
  connect() {
    this.trixEditorElement = this.element.closest('trix-toolbar').nextElementSibling

    this.picker = new Picker({
      data: data,
      parent: this.pickerContainerTarget,
      onEmojiSelect: (emoji) => {        
        // We insert the emoji at the current cursor position
        const trixEditor = this.trixEditorElement.editor
        const currentPosition = trixEditor.getSelectedRange()[0]
        trixEditor.setSelectedRange([currentPosition, currentPosition])
        trixEditor.insertString(emoji.native)
      }
    })
  }
}