import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context(".", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)

application.load(
  definitionsFromContext(context).concat(definitionsFromContext(contextComponents))
  )
// TODO find the origin of the bug
// import { AbymeController } from 'abyme';
// application.register('abyme', AbymeController);
