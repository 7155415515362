import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['tooltip']

  initialize() {
    if(this.hasTooltipTarget) {
      this.tooltipTarget.setAttribute(
      'style',
      `transform:translate(${this.data.get('translateX')}, ${this.data.get('translateY')});`,
      )
    }
  }

  appear() {
  //  if (this.problematicElement) {this.problematicElement.classList.replace('overflow-y-scroll', 'overflow-y-visible')}
    showElements(this.tooltipTarget)
  }

  disappear() {
  //  if (this.problematicElement) {this.problematicElement.classList.replace('overflow-y-visible', 'overflow-y-scroll')}
    hideElements(this.tooltipTarget)
  }

  appearw() {
    this.tooltipTarget.closest('a').classList.add('w-fit')
    this.tooltipTarget.closest('a').classList.replace('icon-md', 'btn-md')
    showElements(this.tooltipTarget)
  }

  disappearw() {
    this.tooltipTarget.closest('a').classList.remove('w-fit')
    this.tooltipTarget.closest('a').classList.replace('btn-md', 'icon-md')
    hideElements(this.tooltipTarget)
  }
}
