import { Controller } from "stimulus"
import { I18n } from "i18n-js";
import translations from "../locales/translations.json";


export default class extends Controller {
  static values = { locale: String }

  // This controller is used to set the locale for the js part of the app
  // We simply retriev the locale from the body (set by the server) and
  // set it in the I18n object
  connect() {
    const i18n = new I18n(translations);
    i18n.defaultLocale = "fr";
    i18n.locale = this.localeValue;
    window.I18n = i18n;
  }
}
