import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = [
    'muteButton',
  ]

  connect() {
    this.currentId = this.firstPartIdValue
    this.contentCard = this.element.closest('.writing-content')
  }

  toggleMute(event) {
    console.log('toggleMute')
    event.preventDefault()

    const btn = event.currentTarget
    // We toggle the dataset value of muted
    btn.dataset.muted = btn.dataset.muted === 'true' ? 'false' : 'true'

    const form = btn.closest('form')
    const formData = new FormData(form)
    fetchWithToken(form.action, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      // We replace the video card button_to with the new one
      console.log(data)
      console.log(this.muteButtonTarget)
      this.muteButtonTarget.innerHTML = data.mute_button_html
      
      const videoControlsElement = this.element.querySelector('[data-controller="api-video--controls"]')
      if (videoControlsElement) {
        const controller = this.application.getControllerForElementAndIdentifier(videoControlsElement, 'api-video--controls')
        if (controller) {
          controller.mute()
        }
      }
    })

  }

}
