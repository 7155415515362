import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['links', 'template']

  addAssociation(event) {
    const btn = event.currentTarget;
    const association = btn.getAttribute('data-association');
    console.log(association);
    let newId = new Date().getTime();
    let newContent = this.templateTargets.find((template) => template.getAttribute('data-association') === association).innerHTML.replace(/NEW_RECORD/g, newId);
    btn.closest('[data-nested-form-target="links"]').insertAdjacentHTML('beforebegin', newContent);
  }

  removeAssociation(event) {
    event.preventDefault();
    let wrapper = event.target.closest('.nested-fields');
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
      // We remove the required attribute from the input so that the form can be submitted even if the field is empty
      wrapper.querySelectorAll('input').forEach((input) => {
        if (input.required) {
          input.required = false;
        }
      });
    }
  }
}