import { Controller } from "stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'objectAttribute',
    'objectAttributeForm',
    'objectAttributeInput',
    'resizeInput',
    'pencil',
    'tick',
  ]

  static values = {
    class: String,
    resize: Boolean,
  }

  displayEditableAttribute() {
    hideElements(this.objectAttributeTarget, this.pencilTarget);
    showElements(this.tickTarget, this.objectAttributeFormTarget)

    this.objectAttributeInputTarget.focus();
    if (this.resizeValue) {
      this.resize(this.objectAttributeInputTarget);
    }
    this.objectAttributeInputTarget.select();
    this.objectAttributeInputTarget.addEventListener('blur', (event) => {
      this.editAttribute(event)
    }, {once: true})
  }

  noMoreEnterKey(event) {
    event.preventDefault()
  }

  editAttribute(event) {
    event.preventDefault()
    const formData = new FormData(this.objectAttributeFormTarget)

    fetch(this.objectAttributeFormTarget.action, {
      method: 'PATCH',
      body: formData,
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors && data.errors.length > 0) {
        showElements(this.objectAttributeTarget, this.pencilTarget)
        hideElements(this.objectAttributeFormTarget, this.tickTarget)
        this.objectAttributeFormTarget.reset()
      } else {
        showElements(this.objectAttributeTarget, this.pencilTarget)
        hideElements(this.objectAttributeFormTarget, this.tickTarget)
        this._handleCallbacks(data)
      }
    })
    .catch((error) => {
      Swal.fire({
          title: 'Erreur !',
          text: error,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
    })
  }

  resizeInput(e) {
    if (this.resizeValue) {
      this.resize(e.currentTarget)
    }
  }

  resize(zone) {
    let width = Math.min(Math.max(12, zone.value.length + 2), 40)
    zone.style.width = `${width}ch`;
  }

  _handleCallbacks(data) {
    console.log(data)
    console.log(this.classValue)
    if (this.classValue == 'content') {
      this._handleContentCallbacks(data)
    } else if (this.classValue == 'handover' && this.objectAttributeInputTarget.name == 'handover[name]') {
      this._handleHandoverNameCallbacks(data)
    } else if (this.classValue == 'handover' && this.objectAttributeInputTarget.name == 'handover[description]') {
      this._handleHandoverDescriptionCallbacks(data)
    } else if (this.classValue == 'library') {
      this._handleLibraryCallbacks(data)
    } else if (this.classValue == 'team') {
      this._handleTeamCallbacks(data)
    } else if (this.classValue == 'sharing') {
      this._handleSharingCallbacks(data)
    } else if (this.classValue == 'review') {
      this._handleReviewCallbacks(data)
    } else if (this.classValue == 'form_question' && this.objectAttributeInputTarget.name == 'form_question[label]') {
      this._handleFormQuestionCallbacks(data)
    } else if (this.classValue == 'form_question' && this.objectAttributeInputTarget.name == 'form_question[description]') {
      this._handleHandoverDescriptionCallbacks(data)
    } else if (this.classValue == 'block' && this.objectAttributeInputTarget.name == 'block[name]') {
      this._handleHandoverNameCallbacks(data)
    } else if (this.classValue == 'block' && this.objectAttributeInputTarget.name == 'block[description]') {
      this._handleHandoverDescriptionCallbacks(data)
    }
    else {
      this._handleHandoverNameCallbacks(data)
    }
    dispatchCustomEvent("element:renamed");
  }

  _handleFormQuestionCallbacks(data) {
    console.log(data)
    console.log(this.objectAttributeTarget)
    this.objectAttributeTarget.innerText = data.label
  }

  _handleReviewCallbacks(data) {
    this.objectAttributeTarget.innerText = data.note
  }

  _handleContentCallbacks(data) {
    if (data.errors.length > 0) return
    if (data.content_name.length > 0) {
      this.objectAttributeTarget.innerText = data.content_name
      this.element.closest('.writing-content').querySelector('.writing-content-card-name').innerText = data.content_name
      this.element.closest('.writing-content').querySelector('.modaltitle').innerText = data.content_name
    } else {
      this.objectAttributeTarget.innerText = this.objectAttributeInputTarget.placeholder
      this.element.closest('.writing-content').querySelector('.writing-content-card-name').innerText = this.objectAttributeInputTarget.placeholder
      this.element.closest('.writing-content').querySelector('.modaltitle').innerText = this.objectAttributeInputTarget.placeholder
    }
    this.element.closest('.writing-content').querySelector('.writing-content-card-name').classList.remove('text-komin-darkgray')
    if (this.element.closest('.writing-content').querySelector('.full-card-header')) {
      this.element.closest('.writing-content').querySelector('.full-card-header').classList.remove('opacity-0')
    }
  }

  _handleHandoverNameCallbacks(data) {
    this.objectAttributeTarget.innerText = data.name
  }

  _handleHandoverDescriptionCallbacks(data) {
    if (data.description.length > 0) {
      this.objectAttributeTarget.innerText = data.description
    } else {
      // We display the placeholder if the description is empty
      // Within a <span class="text-gray-400">
      const span = document.createElement('span')
      span.classList.add('text-gray-400')
      span.innerText = this.objectAttributeInputTarget.placeholder
      this.objectAttributeTarget.innerHTML = ''
      this.objectAttributeTarget.appendChild(span)
    }
  }

  _handleLibraryCallbacks(data) {
    this.objectAttributeTarget.innerText = data.name
    // We are looking for <a data-sidebar-navigation-target="link" href="/library/1"> in the sidebar to update the name
    const link = document.querySelector(`a[data-sidebar-navigation-target="link"][href="/libraries/${data.id}"]`)
    if (link) {
      link.querySelector('span').innerText = data.name
    }
  }

  _handleTeamCallbacks(data) {
    this.objectAttributeTarget.innerText = data.name
    // We are looking for <a data-sidebar-navigation-target="link" href="/teams/1"> in the sidebar to update the name
    const link = document.querySelector(`a[data-sidebar-navigation-target="link"][href="/teams/${data.id}"]`)
    if (link) {
      link.querySelector('span').innerText = `${data.emoji} ${data.name}`
    }
  }

  _handleSharingCallbacks(data) {
    this.objectAttributeTarget.innerText = data.date
    const el = document.getElementById(`DueReadingSharing${data.sharing_id}`)
    if (el) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(data.date_partial, 'text/html');
      el.innerHTML = doc.body.innerHTML
    }
  }

}
