import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal' ]

  // TODO duplicate of modal_controller.js
  openModal() {
    showElements(this.modalTarget)
  }

  closeModal() {
    hideElements(this.modalTarget)
  }

  hideModal(event) {
    if (event.srcElement == this.modalTarget) {
      hideElements(this.modalTarget)
    }
  }

}
