import { Controller } from 'stimulus';


export default class extends Controller {

  connect() {
    this.submitBtn = this.element.querySelector('button[type="submit"]')
  }

  revealSubmitButton() {
    if (this.submitBtn) {
      this.submitBtn.disabled = false
      this.submitBtn.classList.remove('hidden')
    }

  }
}
