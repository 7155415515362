import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [
    'form',
    'search',
    'searchInput',
    'assignee',
    'card',
    'userlist',
    'assignees',
    'userInput',
    'emailInput',
    'openButton',
    'instructions',
    'assignBtn',
    'unassignBtn',
  ]

  static values = {
    url: String,
    assigneesUrl: String,
    restrictedRights: Boolean,
   }

  search(e) {
    this.searchTarget.classList.add("rounded-b-none", "border-b-0")
    showElements(this.userlistTarget)
    this.searchInputTarget.addEventListener('blur', (event) => {
      setTimeout(() => {
        hideElements(this.userlistTarget)
        this.searchTarget.classList.remove("rounded-b-none", "border-b-0")
      }, 200)
    })
    const url = `${this.urlValue}?query=${e.currentTarget.value}`
    const options = {
      method: 'GET',
      headers: {"Accept": "application/json" },
    }

    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: 'Erreur',
          text: data.errors,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        this.userlistTarget.innerHTML = data.partial
      }
    })
  }

  addUsertoList(e) {
    e.preventDefault()
    hideElements(this.userlistTarget)
    this.searchInputTarget.value = ''
    hideElements(this.searchTarget, this.unassignBtnTarget)
    this.searchTarget.classList.remove("rounded-b-none", "border-b-0")
    showElements(this.assigneeTarget, this.assignBtnTarget)

    const id = e.currentTarget.dataset.id
    const url = `${this.assigneesUrlValue}?user_id=${id}`
    const options = {
      method: 'GET',
      headers: {"Accept": "application/json" },
    }

    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: 'Erreur',
          text: data.errors,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        this.cardTarget.innerHTML = data.partial
        this.userInputTarget.value = id
        this.emailInputTarget.value = null
      }
    })
  }

  addUnknownUsertoList(e) {
    if (this.restrictedRightsValue === false ) {
      const email = this.searchInputTarget.value
      hideElements(this.userlistTarget)
      this.searchInputTarget.value = ''
      hideElements(this.searchTarget, this.unassignBtnTarget)
      this.searchTarget.classList.remove("rounded-b-none", "border-b-0")
      showElements(this.assigneeTarget, this.assignBtnTarget)

      const url = `${this.assigneesUrlValue}?email=${email}`
      const options = {
        method: 'GET',
        headers: {"Accept": "application/json" },
      }

      fetchWithToken(url, options)
      .then(response => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: 'Erreur',
            text: data.errors,
            icon: 'error',
            confirmButtonColor: '#3d52d5'
          })
        } else {
          this.cardTarget.innerHTML = data.partial
          this.userInputTarget.value = null
          this.emailInputTarget.value = email
        }
      })
    }
  }

  kill() {
    this.userInputTarget.value = null
    this.emailInputTarget.value = null
    showElements(this.searchTarget, this.unassignBtnTarget)
    hideElements(this.assigneeTarget, this.assignBtnTarget)
  }

  uninvite(event) {
    event.preventDefault()
    const url = event.currentTarget.dataset.unassignUrl
    const options = {
      method: 'POST',
      headers: {"Accept": "application/json" },
    }
    this.finalFetch(url, options)
  }

  invite(event) {
    event.preventDefault()

    const url = this.formTarget.action
    const data = new FormData(this.formTarget)
    const options = {
      method: 'POST',
      headers: {"Accept": "application/json" },
      body: data
    }
    this.finalFetch(url, options)
  }

  finalFetch(url, options) {
    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: 'Erreur',
          text: data.errors,
          icon: 'error',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        this.element.closest('.writing-content').querySelectorAll('.assign-target-avatar').forEach(e => e.innerHTML = data.avatar)
      }
    })
  }

  clearAllFields() {
    this.searchInputTarget.value = ''
    this.userInputTarget.value = ''
    this.instructionsTarget.value = ''
    this.emailInputTarget.value = ''
  }
}
