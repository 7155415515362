import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { 
    id: String,
    url: String,
    unit: String,
    displayAxis: Boolean,
    adjustToContainerWidth: Boolean,
    metric: String,
    metricDiff: String,
    launchAnimation: Boolean,
    lazyLoadedContainer: { type: Boolean, default: false }
  }
  
  static targets = [ 'container', 'metricContainer', 'metricDiffContainer' ]
  
  connect() {
    // We reload the chart if the container it has been lazy loaded
    if (this.lazyLoadedContainerValue) {
      let script = this.element.querySelector('script')
      if (script) {
        new Function(script.text)();
      }
    }
    const unit = this.unitValue
    const chart = Chartkick.charts[this.idValue]

    // Setting new options to:
    // - remove padding
    // - add unit to tooltip
    // - remove axis if needed
    if (chart) {
      const newOptions = {
        ...chart.getOptions(),
        layout: {
          ...chart.getOptions().layout,
          padding: 0
        },
        library: {
          ...chart.getOptions().library,
          plugins: {
            ...chart.getOptions().library.plugins,
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y + ' ' + unit;
                  }
                  return label;
                },
              },
              mode: 'index',
              intersect: false,
              filter: function (tooltipItem) {
                return tooltipItem.datasetIndex === 0;
              },
            },
            cutoutPercentage: 80,
          }
        }
      }
      chart.setOptions(newOptions)
    }

    // Adjust the width to container size
    if (this.adjustToContainerWidthValue) {
      const chartContainer = this.containerTarget
      const chartWidth = chartContainer.offsetWidth
      const chartHtml = document.getElementById(this.idValue)
      chartHtml.style.width = chartWidth + 'px'
    }
  }

  animateMetric() {
    // We create a nice animation for the metric value that increment the value from 0 to the actual value
    const metricContainer = this.metricContainerTarget
    
    const [metric, unit] = this.metricValue.split(' ')
    const increment = 1
    let current = 0
    const interval = setInterval(() => {
      current += increment
      metricContainer.innerHTML = `${current.toFixed(0)} ${unit}`
      if (current >= metric) {
        clearInterval(interval)
      }
    })    
  }

  animateMetricDiff() {
    const metricDiffContainer = this.metricDiffContainerTarget
    const [metricDiff, unit] = this.metricDiffValue.split(' ')
    const increment = 1
    let current = 0

    const interval = setInterval(() => {
      current += increment
      metricDiffContainer.innerHTML = `${current.toFixed(0)} ${unit}`
      if (current >= metricDiff) {
        clearInterval(interval)
      }
    })
  }
}