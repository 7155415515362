
import { Controller } from "stimulus";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["positionIndex"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: "quizz_user_answer",
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(.17,.67,.83,.67)",
      onEnd: this.onEnd.bind(this),
    });
  }

  onEnd(e) {
    this.element.querySelectorAll(".position-index").forEach((position, i) => {
      position.querySelector(".position-show").innerText = i + 1;
      position.querySelector(".position-input").value = i + 1;
      position
        .querySelector(".position-input")
        .dispatchEvent(new Event("input", { bubbles: true }));
    });
  }
}
