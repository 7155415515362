import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'duedate', 'manager', 'dateForm', 'dateInput', 'dateButton', 'transferForm' ]
  static values = { handoverId: Number }
  connect() {
    document.addEventListener('contents:created', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('contents:done', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('contents:ongoing', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('contents:todo', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('contents:deleted', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('sections:created', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('sections:deleted', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('chapters:created', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('chapters:deleted', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('element:renamed', (e) => {
      this.updateProgressBar()
    })
    document.addEventListener('element:reordered', (e) => {
      this.updateProgressBar()
    })
  }

  fadeDate() {
    fadeHideElements(this.duedateTarget)
  }

  fadeManager() {
    fadeHideElements(this.managerTarget)
  }

  displayCalendar() {
    const input = this.dateInputTarget
    hideElements(this.dateButtonTarget)
    showElements(this.dateFormTarget);
    input.focus();
    // input.size = input.value.length
  }

  editDate(e) {
    const form = this.dateFormTarget
    e.preventDefault()
    const input = this.dateInputTarget
    const formData = new FormData(form)

    fetchWithToken(form.action, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.updated) {
        hideElements(form)
        // this.fadeDate()
        // We need to update the date in the banner
        // We need to display how many days left from today
        this.element.innerHTML = data.progress_bar
      }
    })
  }

  transferOwnership(e) {
    //TO UPDATE
    this.email = e.currentTarget.hasAttribute('data-email') ? e.currentTarget.dataset.email : e.currentTarget.closest("li").dataset.sharingsUserEmailValue
    this.form = this.transferFormTarget
    this.form.querySelector('#sharing_email').value = this.email
    this.form.submit()
  }

  updateProgressBar(e) {
    const url = `/handovers/${this.handoverIdValue}/fetch_progress_bar`
    fetch(url, {
      method: 'GET',
      headers: {"Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        this.element.innerHTML = data.progress_bar
        document.querySelector('.cta-handover-nav').outerHTML = data.buttons
      })
  }

}
