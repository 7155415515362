import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ['userInput', 'allPages', 'rangeDetails']

  all() {
    if (this.allPagesTarget.checked === true) {
      this.userInputTarget.dataset.input = 'all'
    }
  }

  transformInput() {
    var inputString = this.userInputTarget.value
    var usableArray = []
    if (this.allPagesTarget.checked === true) {
      usableArray = 'all'
    }
    const ranges = inputString.match(/\d+-\d+/g)
    if(ranges) {
      ranges.forEach((range) => {
        const startNumber = Number(range.split('-')[0])
        const endNumber = Number(range.split('-')[1])
        var list = [];
        for (var i = startNumber; i <= endNumber; i++) {
        list.push(i);
        }
        usableArray.push(...list)
        inputString = inputString.replace(range, '')
      })
    }
    if (inputString.match(/\d+/g)) {
      inputString.match(/\d+/g).forEach((number) => {
        usableArray.push(Number(number))
      })
    }
    this.userInputTarget.dataset.input = usableArray
  }
}
