import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import Swiper from 'swiper/swiper-bundle';
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [ 'slidesContainer', 'swiperPreviousBtn', 'swiperNextBtn',
                     'addSlideLink', 'form', 'colorPalette',
                     'removeButton', 'lastslide', "bgdots", "image",
                    'cardContainer', "previousSlideButton", "nextSlideButton", 'editButton',
                    'expandButton', 'shrinkButton', "coverInput", "palette", "bgInput", "bg",
                    'checkForDeletion', "input",  'dropzone', 'modalInput', 'modalDescInput',  "layoutListItem", "modalSource"]
  static values = { contentId: Number }

  connect() {
    window.addEventListener("dragover", function(e) {
      e = e || event
      e.preventDefault()
    }, false);
    window.addEventListener("drop", function(e) {
      e = e || event;
      e.preventDefault();
    }, false);
    this.lastOpenedSlideIndex = 0
    this._buildSwiper()

    if (this._countActiveSlides() === 0) {
      this.addSlideLinkTarget.click()
    }
    this.listenToImageChanges()
    this.connectSortable()
    this._reassignIndexes()
    this.highlightCard()
    this.updateButtons()
  }



  connectSortable() {
    if (this.hasCardContainerTarget) {
      this.sortable = Sortable.create(this.cardContainerTarget, {
        group: 'slide',
        put: 'content',
        delayOnTouchOnly: 150,
        animation: 150,
        handle: ".slide-reorder-handle",
        draggable: ".slidecard",
        easing: "cubic-bezier(1, 0, 0, 1)",
        fallbackOnBody: true,

        onEnd: this.afterSort.bind(this)
      })
    }
  }

  afterSort(event) {
    if (event.newIndex === this._countActiveSlides() -1) {
      const cardafter_id = this.cardContainerTarget.querySelector(`.slidecard[data-slidex="${event.newIndex}"]`).dataset.slideid
      const slide = this.slidesContainerTarget.querySelector(`#slidefor${event.item.dataset.slideid}`)
      const slideafter = this.slidesContainerTarget.querySelector(`#slidefor${cardafter_id}`)
      this.slidesContainerTarget.insertBefore(slide, slideafter)
      this.slidesContainerTarget.insertBefore(slideafter, slide)
      this._reassignIndexes()
    } else {
      this._reassignIndexes()
      const cardafter_id = this.cardContainerTarget.querySelector(`.slidecard[data-slidex="${event.newIndex + 1}"]`).dataset.slideid
      const slide = this.slidesContainerTarget.querySelector(`#slidefor${event.item.dataset.slideid}`)
      const slideafter = this.slidesContainerTarget.querySelector(`#slidefor${cardafter_id}`)
      this.slidesContainerTarget.insertBefore(slide, slideafter)
      this._reassignIndexes()
    }
    this._resetSwiper()
    this.swiper.slideTo(event.newIndex,0,false)
    this.highlightCard()
  }


  closeModal() {
    this._setLastOpenedSlideToCurrentSlide()
    this.swiper.destroy(false, false)
  }

  nextSlide() {
    this.swiperNextBtnTarget.click()
    this._setLastOpenedSlideToCurrentSlide()
    this.highlightCard()
    this.whiteControls()
  }

  navigate(e) {
    const slidex = e.currentTarget.dataset.slidex
    this.swiper.slideTo(slidex, 0, false)
    this._setLastOpenedSlideToCurrentSlide()
    this.highlightCard()
  }

  previousSlide() {
    this.swiperPreviousBtnTarget.click()
    this._setLastOpenedSlideToCurrentSlide()
    this.highlightCard()
    this.whiteControls()
  }

  openUnsplash(e) {
   e.preventDefault()
   this.application.getControllerForElementAndIdentifier(this.element, 'unsplash-slideshow').open(this.swiper.slides.length - 1, "index")
  }

  changeImage(e) {
    if (e.currentTarget.getAttribute('disabled')) {
      return
      } else {
        if (this.slidesContainerTarget.querySelector(`.imageinput[data-slidex="${this.swiper.activeIndex}"]`)) {
          this.slidesContainerTarget.querySelector(`.imageinput[data-slidex="${this.swiper.activeIndex}"]`).click()
        }
      }
  }

  changeImageUnsplash(e) {
    if (e.currentTarget.getAttribute('disabled')) {
      return
      } else {
        this.application.getControllerForElementAndIdentifier(this.element, 'unsplash-slideshow').open(this.swiper.activeIndex, "slidex")
      }
  }

  expand(e) {
    if (e.currentTarget.getAttribute('disabled')) {
    return
    } else {
      const activeImage = this.slidesContainerTarget.querySelector(`.imagepreview[data-slidex="${this.swiper.activeIndex}"]`)
      const coverFieldForm = this.slidesContainerTarget.querySelector(`.coverinput[data-slidex="${this.swiper.activeIndex}"]`)
    if (activeImage.classList.contains('object-cover')) {
      activeImage.classList.replace('object-cover','object-scale-down')
    } else {
      activeImage.classList.replace('object-scale-down','object-cover')
    }
    coverFieldForm.checked = activeImage.classList.contains('object-cover')
    this.#displayShrinkExpandButton()
  }
  }

  #displayShrinkExpandButton() {
    this.expandButtonTarget.classList.remove('hidden')
    //this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget)
    this.shrinkButtonTarget.classList.add('hidden')
    const activeImage = this.slidesContainerTarget.querySelector(`.imagepreview[data-slidex="${this.swiper.activeIndex}"]`)
    if (activeImage.getAttribute('src') && activeImage.classList.contains('object-scale-down')) {
      this.expandButtonTarget.classList.remove('hidden')
      this.shrinkButtonTarget.classList.add('hidden')
      //this.#enableButtons(this.expandButtonTarget)
    } else if (activeImage.getAttribute('src') && activeImage.classList.contains('object-cover')) {
      this.expandButtonTarget.classList.add('hidden')
      this.shrinkButtonTarget.classList.remove('hidden')
      //this.#enableButtons(this.shrinkButtonTarget)
    }
  }


  #disableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.setAttribute('disabled', true)
      button.classList.replace('bg-komin-blue', 'bg-komin-gray')
      button.classList.remove('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }

  #enableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.removeAttribute('disabled')
      button.classList.replace('bg-komin-gray', 'bg-komin-blue')
      button.classList.add('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }

  submitForm(event) {
    this._setLastOpenedSlideToCurrentSlide()
    if(event) event.preventDefault()
    //showElements(this.loadingSpinner)
    const slides = this.slidesContainerTarget.querySelectorAll(`.swiper-slide-${this.contentIdValue}`)
    slides.forEach((slide, index) => {
      slide.querySelector('.positioninput').value = index
    })
   // const form = event.target.closest('form')
    const form = this.formTarget
    const formData = new FormData(form)

    Rails.ajax({
      url: form.action,
      type: "patch",
      data: formData,
      success: (data) => {
        if (data.errors.length >= 1) {
          Swal.fire({
            title: I18n.t('js.sweet_alerts.slideshow.unable_to_save'),
            text: `${data.errors.join(', ')} - (${data.response_status})`,
            icon: 'warning',
            confirmButtonColor: '#3d52d5'
          })
        } else {
          if (data.count === 0) {
            // this.#markAsTodo()
          } else {
            if (data.done === true) {
              const target = document.querySelector(`[data-content-id="${data.content_id}"]`)
              if (target) target.outerHTML = data.partial
              if (data.status === "done") {
                this.#markAsDone()
              } else {
                this.#markAsOngoing()
              }
            }
          }
        }
      },
      error: (data) => {

        Swal.fire({
          title: I18n.t('js.sweet_alerts.slideshow.something_went_wrong'),
          text: `${data.response_status}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      }
    })
  }

  addSlide(event) {
    const time = new Date().getTime()
    const linkId = event.currentTarget.dataset.id
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    const newFields = regexp ? event.currentTarget.dataset.fields.replace(regexp, time) : null

    this._setLastOpenedSlideToCurrentSlide(1)
    if (newFields) {
      this.slidesContainerTarget.insertAdjacentHTML('beforeend', newFields)
      this._resetSwiper()
      const deleteField = this.checkForDeletionTargets[this.swiper.slides.length - 1]
      if (deleteField) {
        const fieldParent = deleteField.closest('.nested-fields')
        deleteField.checked = true
        if (fieldParent) {
          fieldParent.classList.add('hidden')
        }
      }
      if (this._countActiveSlides() !== 0) { this.proposePictureSource()}
    }
  }

  proposePictureSource() {
    showElements(this.modalSourceTarget)
    hideElements(this.addSlideLinkTarget)
  }

  hidePictureSource() {
    hideElements(this.modalSourceTarget)
    showElements(this.addSlideLinkTarget)
  }

  openOSWindow() {
      this.inputTargets[this.swiper.slides.length - 1].dataset.new = true
      this.inputTargets[this.swiper.slides.length - 1].click()
    }

  revealSlide(e) {
    const file = e.currentTarget.files[0]
    if (e.currentTarget.dataset.new === 'true' ) {
      this.updateLastSlide(file)
    } else {
      this.updateSlide(file, e)
    }
  }

  updateLastSlide(file) {
    if (file) {
      this.inputTargets[this.swiper.slides.length - 1].dataset.new = false
      const url = URL.createObjectURL(file)
      if (/image/.test(file.type)) {
        this.reader = new FileReader();
        this.reader.onload = (ev) => {
          this.imageTargets[this.swiper.slides.length - 1].src = ev.currentTarget.result
        }
        this.reader.readAsDataURL(file)
        this.fromCanvas = false
        const deleteField = this.checkForDeletionTargets[this.swiper.slides.length - 1]
        const fieldParent = deleteField.closest('.nested-fields')
        deleteField.checked = false
        fieldParent.classList.remove('hidden')
        this._resetSwiper()
        this.swiper.slideTo(this.swiper.slides.length, 0, false)
        this._addCard()
        this.dropzoneTarget.classList.add('hidden')
        hideElements(document.querySelector(`.simple-cancel-button[data-id="${this.contentIdValue}"]`))
        fadeShowElements(
          document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`),
          document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`),
          document.querySelector(`.cancel-button[data-id="${this.contentIdValue}"]`),
        )
      } else {
        Swal.fire({
          title: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.title'),
          text: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.text', { file_types: this.acceptedMimetypes }),
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          timer: 3000
        })
      }
    }
  }

  updateSlide(file, e) {
    if (file) {
      const imagePreview = this.slidesContainerTarget.querySelector(`.imagepreview[data-slidex="${this.swiper.activeIndex}"]`)
      const url = URL.createObjectURL(file)
      if (/image/.test(file.type)) {
        this.reader = new FileReader();
        this.reader.onload = (ev) => {
          imagePreview.src = ev.currentTarget.result
        }
        this.reader.readAsDataURL(file)
        this.fromCanvas = false
        hideElements(document.querySelector(`.simple-cancel-button[data-id="${this.contentIdValue}"]`))
        fadeShowElements(
          document.querySelector(`.draft-button[data-id ="${this.contentIdValue}"]`),
          document.querySelector(`.save-button[data-id="${this.contentIdValue}"]`),
          document.querySelector(`.cancel-button[data-id="${this.contentIdValue}"]`),
        )
      } else {
        Swal.fire({
          title: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.title'),
          text: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.text', { file_types: this.acceptedMimetypes }),
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          timer: 3000
        })
      }
    }
  }


  dropFile(event) {
    if (event.dataTransfer.items) {
      var file = event.dataTransfer.items[0].getAsFile()
    } else {
      var file = event.dataTransfer.files[0]
    }

    if (file) {
        this.#insertFile(file)
    }
  }

  #insertFile(file) {
    var list = new DataTransfer();

    if (!/image/.test(file.type)) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.title'),
        text: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.text', { file_types: this.acceptedMimetypes }),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else if (file.size >= 22009610) {
      Swal.fire({
        title: `L'image que vous souhaitez télécharger est trop lourde (maximum 20Mo).`,
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else {
      list.items.add(file)

      this.inputTargets[0].files = list.files
      this.revealSlide()
    //  this.#closeEditor()
    }
  }

  revealPalette(e) {
    // We hide the input and just want to show the palette tht's why we trigger a click on the palette
    this.colorPaletteTarget.value = this.paletteTarget.dataset.color
    this.paletteTarget.style.backgroundColor = this.colorPaletteTarget.value
    const bgInput = this.slidesContainerTarget.querySelector(`.bginput[data-slidex="${this.swiper.activeIndex}"]`)
    if (bgInput) {
      bgInput.value = this.colorPaletteTarget.value
    }
    this.colorPaletteTarget.click()
  }

  updateColor(e) {
    this.paletteTarget.style.backgroundColor = this.colorPaletteTarget.value
    const bgInput = this.slidesContainerTarget.querySelector(`.bginput[data-slidex="${this.swiper.activeIndex}"]`)
    const bgelement = this.slidesContainerTarget.querySelector(`.bgslide[data-slidex="${this.swiper.activeIndex}"]`)
    bgelement.style.backgroundColor = this.colorPaletteTarget.value
    bgelement.dataset.color = this.colorPaletteTarget.value
    bgInput.value = this.colorPaletteTarget.value
    this.updateTextColor(this.colorPaletteTarget.value)
  }

  updateTextColor(baseColor) {
    const yiqColor = this._getContrastYIQ(baseColor)
    // on ne change pas la couleur de tout le texte, seulement du titre, la couleur du texte est à la main du user via trix.
    //const textelement = this.slidesContainerTarget.querySelector(`.name-text-show[data-slidex="${this.swiper.activeIndex}"]`)
    const title = this.slidesContainerTarget.querySelector(`.title[data-slidex="${this.swiper.activeIndex}"]`)
    const textColorInput = this.slidesContainerTarget.querySelector(`.text-color-input[data-slidex="${this.swiper.activeIndex}"]`)
    const tickTitle = this.slidesContainerTarget.querySelector(`.tick-title[data-slidex="${this.swiper.activeIndex}"]`)
    //const tickDesc = this.slidesContainerTarget.querySelector(`.tick-desc[data-slidex="${this.swiper.activeIndex}"]`)
    const pencilTitle = this.slidesContainerTarget.querySelector(`.pencil-title[data-slidex="${this.swiper.activeIndex}"]`)
    //const pencilDesc = this.slidesContainerTarget.querySelector(`.pencil-desc[data-slidex="${this.swiper.activeIndex}"]`)
    const elements = [title, tickTitle, pencilTitle, this.paletteTarget ]
    elements.forEach((element) => {
      if (element) { element.style.color = yiqColor }
    })
    textColorInput.value = yiqColor
  }

  _getContrastYIQ(hexColor) {
    // Taken from: https://24ways.org/2010/calculating-color-contrast/
    if (hexColor === "#fff") {
      return '#000'
    } else {
      hexColor = hexColor.replace('#', '');

      const yiqThreshold = 128;
      const r = parseInt(hexColor.substr(0, 2), 16);
      const g = parseInt(hexColor.substr(2, 2), 16);
      const b = parseInt(hexColor.substr(4, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      return (yiq >= yiqThreshold) ? '#000' : '#fff';
    }
  }

  applyLayout(e) {
    console.log(this.swiper.activeIndex)
    const layout = e.currentTarget.dataset.layoutId
    this.highlightLayoutValue(layout)
    const layoutInput = this.slidesContainerTarget.querySelector(`.layoutinput[data-slidex="${this.swiper.activeIndex}"]`)
    const layoutElement = this.slidesContainerTarget.querySelector(`.bgslide[data-slidex="${this.swiper.activeIndex}"]`)
    const possible_layouts = ['slide-layout-0', 'slide-layout-1', 'slide-layout-2', 'slide-layout-3', 'slide-layout-4']
    possible_layouts.forEach((layoutClass) => { layoutElement.classList.replace(layoutClass, `slide-layout-${layout}`)})
    layoutInput.value = layout
  }

  highlightLayoutValue(index) {
    this.layoutListItemTargets.forEach((layout) => {layout.dataset.active = false})
    this.layoutListItemTargets[index].dataset.active = true
  }

   whiteControls() {
    this.previousSlideButtonTarget.classList.replace('text-komin-blue', 'text-white')
    this.previousSlideButtonTarget.classList.replace('border-komin-blue','border-white')
    this.previousSlideButtonTarget.classList.remove('hover:text-white')
    this.nextSlideButtonTarget.classList.replace('text-komin-blue','text-white')
    this.nextSlideButtonTarget.classList.replace('border-komin-blue', 'border-white')
    this.nextSlideButtonTarget.classList.remove('hover:text-white')
   }

   blueControls() {
    this.previousSlideButtonTarget.classList.replace('text-white', 'text-komin-blue')
    this.previousSlideButtonTarget.classList.replace('border-white','border-komin-blue')
    this.previousSlideButtonTarget.classList.add('hover:text-white')
    this.nextSlideButtonTarget.classList.replace('text-white', 'text-komin-blue')
    this.nextSlideButtonTarget.classList.replace('border-white','border-komin-blue')
    this.nextSlideButtonTarget.classList.add('hover:text-white')
   }


  listenToImageChanges() {
    const observer = new MutationObserver((changes) => {
      changes.forEach(change => {
          if (change.attributeName.includes('src')) {
            const slideid = change.target.closest(`.swiper-slide-${this.contentIdValue}`).dataset.id
            const thumbnail = document.getElementById(`thumbnail${slideid}`)
            if (thumbnail) {thumbnail.setAttribute('src', change.target.getAttribute('src'))}
        }
      });
    });
    this.imageTargets.forEach( (image) => {
      observer.observe(image, { attributes : true });
    })
  }

  forceThumbnail() {
    const src = this.imageTargets[this.swiper.slides.length - 1].getAttribute('src')
    const thumbnail = this.cardContainerTarget.querySelectorAll('.video-part-card-thumbnail')[this._countActiveSlides() - 1]
    thumbnail.setAttribute('src', src)
  }

  removeSlide(event) {
    const deleteField = this.slidesContainerTarget.querySelector(`.deletion[data-slidex="${this.swiper.activeIndex}"]`)
    if (this._countActiveSlides() === 1) {
      this.addSlideLinkTarget.click()
      showElements(this.dropzoneTarget)
    }
    if (deleteField) {
      const fieldParent = deleteField.closest('.nested-fields')
      this._setLastOpenedSlideToCurrentSlide(-1)
      deleteField.checked = true
      fieldParent.classList.add('hidden')
      const slideID = fieldParent.dataset.id
      this._removeCard(slideID)
      this._resetSwiper()
      this.swiper.slideTo(this.lastOpenedSlideIndex, 0, false)
      this.highlightCard()
      this._toggleBtns()
    }
  }

  highlightCard() {
    if (this.hasCardContainerTarget) {
      this.cardContainerTarget.querySelectorAll('.slidecard').forEach( (card) => {
        card.dataset.active = false
      })
      const slidecard = this.cardContainerTarget.querySelector(`.slidecard[data-slidex="${this.swiper.activeIndex}"]`)
      if (slidecard) {
        slidecard.dataset.active = true
      }
    }
    this.updateButtons()
  }

  updateButtons() {
    this.editButtonTarget.dataset.slidex = this.swiper.activeIndex;
    const existingbg = this.slidesContainerTarget.querySelector(`.bgslide[data-slidex="${this.swiper.activeIndex}"]`)
    const layout = this.existingLayout()
    if (existingbg) {
      this.updatePalette(existingbg.dataset.color)
      this.updateTextColor(existingbg.dataset.color)
      this.#displayShrinkExpandButton()
      this.highlightLayoutValue(layout)
    }
  }

  existingLayout() {
    const layoutInput = this.slidesContainerTarget.querySelector(`.layoutinput[data-slidex="${this.swiper.activeIndex}"]`)
    if (layoutInput) {
      return layoutInput.value
    } else {
      return "0"
    }
  }

  updatePalette(color) {
    const currentColor = this.paletteTarget.dataset.color
    // this.paletteTarget.classList.replace(`bg-${currentColor}`, `bg-${color}`)
    this.paletteTarget.style.backgroundColor = color
    // this.paletteTarget.classList.add('text-white')
    this.paletteTarget.dataset.color = color
    if (color === 'white') {
      this.paletteTarget.classList.remove('text-white')
    }
  }

  _addCard() {
    const newSlidex = this._countActiveSlides() - 1
    const fakeid = Date.now()
    Rails.ajax({
      url: `/contents/${this.contentIdValue}/fetch_slidecard`,
      type: "get",
      data: `[position]=${newSlidex}&[slideid]=${fakeid}`,
      success: (data) => {
          const slides = this.slidesContainerTarget.querySelectorAll(`.swiper-slide-${this.contentIdValue}`)
          slides[slides.length-1].dataset.id = data.id
          slides[slides.length-1].setAttribute('id', `slidefor${data.id}`)
          this._reassignIndexes()
          this.listenToImageChanges()
          this.highlightCard()
          if(this.hasCardContainerTarget) {
            this.lastslideTarget.insertAdjacentHTML('beforebegin', data.partial)
            this.forceThumbnail()
          }
          this._setColor(data.id)
        //  slides[slides.length-1].querySelector('.positioninput').value = data.index
      }
    })
  }

  _setColor(slideId) {
    // // For the new added slide we set the color of the parent to the color of the slide if a parent exists
    // const slides = this.slidesContainerTarget.querySelectorAll(`.swiper-slide-${this.contentIdValue}`)
    // const parentSlide = slides[slides.length-2]
    // if (parentSlide) {
    //   // We retrieve the color of the parent slide
    //   const parentSlideColor = parentSlide.querySelector('.bgslide').dataset.color
    //   // We set the color of the palette to the color of the parent slide
      this.colorPaletteTarget.value = '#ffffff'
      // We call updateColor to update the color of the slide
      this.updateColor()
    // }
  }


  _removeCard(slideID) {
    if (this.hasCardContainerTarget) {
      document.getElementById(`slidecard${slideID}`).remove()
      this._reassignIndexes()
    }
  }

  _reassignIndexes() {
    if (this.hasCardContainerTarget) {
      this.cardContainerTarget.querySelectorAll('.slidecard').forEach((slidecard, index) => {
        slidecard.dataset.slidex = index
      })
    }
    console.log(this.slidesContainerTarget)
    this.slidesContainerTarget.querySelectorAll('[data-slidex]').forEach((r) => {r.dataset.slidex="x"})
    var i = 0
    this.slidesContainerTarget.querySelectorAll(`.swiper-slide-${this.contentIdValue}`).forEach((slide) => {
      if (!slide.classList.contains('hidden')) {
        slide.dataset.slidex = i
      slide.querySelectorAll('[data-slidex]').forEach((e) => {
        e.dataset.slidex = i
      })
      i = i + 1
    }
    })
  }

  _removeRedBorder() {
    document.querySelector(`#card${this.contentIdValue}`).classList.remove("border-komin-red", 'animate-pulse')
    document.querySelector(`#card${this.contentIdValue}`).classList.add("border-komin-gray")
  }

  _addRedBorder() {
    document.querySelector(`#card${this.contentIdValue}`).classList.add("border-komin-red", 'animate-pulse')
    document.querySelector(`#card${this.contentIdValue}`).classList.remove("border-komin-gray")
  }

  _buildSwiper() {
    this.swiper = new Swiper(`.swiper-writing-${this.contentIdValue}`, {
      pagination: {
        el: `.swiper-pagination-${this.contentIdValue}`,
        type: "bullets"
      },
      navigation: {
        nextEl: `.swiper-button-next-${this.contentIdValue}`,
        prevEl: `.swiper-button-prev-${this.contentIdValue}`,
      },
      slideClass: `swiper-slide-${this.contentIdValue}`,
      allowTouchMove: false
    })
    this.swiper.on('slideChange', () => {
      this._toggleBtns()
    });
    this._toggleBtns()
    this.swiper.slideTo(this.lastOpenedSlideIndex, 0, false)
    //this.highlightCard()
  }

  _resetSwiper() {
    this.swiper.update()
    this._toggleBtns()
  }

  _toggleBtns() {
    if (this._countActiveSlides() < 2) {
      hideElements(this.previousSlideButtonTarget, this.nextSlideButtonTarget)
    } else if (this.swiper.activeIndex === this._countActiveSlides() - 1) {
      showElements(this.previousSlideButtonTarget)
      hideElements(this.nextSlideButtonTarget)
    } else if (this.swiper.activeIndex === 0) {
      showElements(this.nextSlideButtonTarget)
      hideElements(this.previousSlideButtonTarget)
    } else {
      showElements(this.previousSlideButtonTarget, this.nextSlideButtonTarget)
    }
  }
  _countActiveSlides() {
    return Array.from(this.slidesContainerTarget.querySelectorAll(`.swiper-slide-${this.contentIdValue}`)).filter((e) => {
      return !e.classList.contains('hidden')
    }).length
  }

  _setLastOpenedSlideToCurrentSlide(variant = 0) {
    this.lastOpenedSlideIndex = this.swiper.activeIndex + variant
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  #markAsTodo() {
    this.element.closest('.writing-content').classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }

  updateTitleMobile() {
    const titleShow = this.slidesContainerTarget.querySelector(`.title[data-slidex="${this.swiper.activeIndex}"]`)
    const titleRealInput = this.slidesContainerTarget.querySelector(`.cardInput[data-slidex="${this.swiper.activeIndex}"]`)
    titleShow.innerText = this.modalInputTarget.value
    titleRealInput.value = this.modalInputTarget.value
  }


  updateDescMobile() {
    const descShow = this.slidesContainerTarget.querySelector(`.desc[data-slidex="${this.swiper.activeIndex}"]`)
    const descRealInput = this.slidesContainerTarget.querySelector(`.descinput[data-slidex="${this.swiper.activeIndex}"]`)
    descShow.innerHTML= this.modalDescInputTarget.value
    descRealInput.value = this.modalDescInputTarget.value
  }
}
