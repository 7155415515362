import { Controller } from "stimulus";
import { fetchWithToken } from "../../application/stimulus_helper";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = [
    "newFormBtn",
    "createFormContainer",
    "summaryCardContainer",
    "sectionContainer",
  ];

  connect() {
    // This observer is used to detect when a new section is added to the DOM to open the name in edit mode
    this.observer = new MutationObserver((changes) => {
      changes.forEach((change) => {
        if (
          change.addedNodes.length > 0 &&
          change.addedNodes[2].tagName === "DIV"
        ) {
          change.addedNodes[2].querySelector(".section-title").click();
        }
      });
    });
  }

  new(event) {
    const i = this.newFormBtnTargets.findIndex(
      (e) => e === event.currentTarget
    );
    const form = this.createFormContainerTargets[i];

    hideElements(this.newFormBtnTargets);

    const chapterId = event.currentTarget.dataset.chapter_id;
    const finalForm = form.querySelector(".simple_form");
    const url = `/chapters/${chapterId}/sections`;
    const summaryContainer = this.summaryCardContainerTargets.find(
      (e) => e.dataset.chapterId == chapterId
    );

    const formData = new FormData(finalForm);
    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: formData,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          summaryContainer.insertAdjacentHTML(
            "beforeend",
            data.summary_card_html
          );
          this.sectionContainerTarget.insertAdjacentHTML(
            "beforeend",
            data.section_html
          );
          showElements(this.newFormBtnTargets);
          hideElements(form.closest("div"));
          finalForm.querySelectorAll("input")[1].value = "";
          summaryContainer
            .closest(".writing-summary-chapter-card")
            .classList.add("active");
          setTimeout(() => {
            summaryContainer
              .querySelector(".writing-summary-section-card:last-of-type")
              .click();
          }, 100);
          this.observer.observe(this.sectionContainerTarget.lastElementChild, {
            childList: true,
          });
          dispatchCustomEvent("sections:created");
        }
        finalForm.reset();
      });
  }

  disconnect() {
    this.observer.disconnect();
  }

  hideCreate(event) {
    showElements(this.newFormBtnTargets);
    hideElements(this.createFormContainerTargets);
  }

  edit(event) {
    const summaryCardNameTag = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name");
    const summaryCardNameForm = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name-form");
    hideElements(summaryCardNameTag);
    showElements(summaryCardNameForm);
  }

  update(event) {
    event.preventDefault();

    const summaryCardNameTag = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name");
    const summaryCardNameForm = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name-form");
    const form = event.currentTarget;
    const formData = new FormData(form);
    const url = form.action;
    const options = {
      method: "PATCH",
      headers: { Accept: "application/json" },
      body: formData,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          summaryCardNameTag.innerText = data.name;
          hideElements(summaryCardNameForm);
          showElements(summaryCardNameTag);
          form.querySelectorAll("input")[1].value = "";
        }
        form.reset();
      });
  }

  hideEdit(event) {
    const summaryCardNameTag = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name");
    const summaryCardNameForm = event.currentTarget
      .closest("li")
      .querySelector(".section-summary-card-name-form");
    hideElements(summaryCardNameForm);
    showElements(summaryCardNameTag);
  }

  duplicate(event) {
    event.preventDefault();
    event.stopPropagation();

    //  const toggleSpinner = (target) => {
    //    target.parentElement.querySelector('.loading-spinner').classList.toggle('hidden')
    //    target.classList.toggle('hidden')
    //  }

    const target = event.currentTarget;
    const sectionId = target.dataset.sectionId;
    const chapterId = target.dataset.chapterId;
    const summaryCardContainer = this.summaryCardContainerTargets.find(
      (e) => e.dataset.chapterId == chapterId
    );
    const card = summaryCardContainer.querySelector(
      `[data-section-id = "${sectionId}"]`
    );
    const stepId = target.dataset.stepId;
    const data = new FormData();
    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: data,
    };

    data.append(`[step_id]`, stepId);
    //  toggleSpinner(target)

    fetchWithToken(target.href, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          card.insertAdjacentHTML("afterend", data.summary_card_html);
          this.sectionContainerTarget.insertAdjacentHTML(
            "beforeend",
            data.section_html
          );
          dispatchCustomEvent("sections:created");
        }
        //      toggleSpinner(target)
      });
  }

  delete(event) {
    const id = event.params.id;
    const chapterId = event.currentTarget.dataset.chapterId;
    const summaryCardContainer = this.summaryCardContainerTargets.find(
      (e) => e.dataset.chapterId == chapterId
    );
    const summaryCard = summaryCardContainer.querySelector(
      `[data-section-id = "${id}"]`
    );
    const url = `/sections/${id}`;
    const options = {
      method: "DELETE",
      headers: { Accept: "application/json" },
    };

    Swal.fire({
      title: I18n.t("js.sweet_alerts.sections.delete.title"),
      text: I18n.t("js.sweet_alerts.sections.delete.text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "#CDD2DE",
      cancelButtonText: I18n.t(
        "js.sweet_alerts.sections.delete.cancel_button_text"
      ),
      confirmButtonText: I18n.t(
        "js.sweet_alerts.sections.delete.confirm_button_text"
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        fetchWithToken(url, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.deleted) {
              summaryCard.remove();
              dispatchCustomEvent("sections:deleted");
            } else {
              Swal.fire({
                title: I18n.t("js.sweet_alerts.sections.errors.title"),
                text: I18n.t("js.sweet_alerts.sections.errors.text"),
                icon: "error",
                confirmButtonColor: "#3d52d5",
              });
            }
          });
      }
    });
  }
}
