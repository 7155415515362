import { DirectUpload } from "@rails/activestorage"

export class KominUploader {
  constructor(file, url, id, callback) {
    this.upload = new DirectUpload(file, url, this)
    this.id = id
    this.callback = callback
  }

  start(input) {
    this.upload.create((error, blob) => {
      if (error) {
        // handle the error
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = input.name
        input.closest('form').appendChild(hiddenField)
        this.callback(blob.signed_id)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      "progress", event => this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    let progress = ((event.loaded / event.total) * 100).toFixed(1)
    let progressText = document.querySelector(`#upload-progress-text-${this.id}`)
    if (progressText) {
      // We round to the nearest
      progressText.innerText = `${Math.round(progress)}%`
    }
    document.querySelector(`#upload-progress-bar-${this.id}`).style.width = `${progress}%`
  }
}
