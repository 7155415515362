import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'submit', 'input' ]

  connect() {
    this.initialValues = this._buildObjectFromInputs()
    this._addEventListeners()
  }

  _buildObjectFromInputs() {
    const object = new Object
    this.inputTargets.forEach((input) => { object[input.id] = input.value })
    return object
  }

  _inputValuesChanged() {
    return (JSON.stringify(this.initialValues) !== JSON.stringify(this._buildObjectFromInputs()))
  }

  _addEventListeners() {
    this.inputTargets.forEach((input) => {
      input.addEventListener('input', () => { this.submitTarget.disabled = !this._inputValuesChanged() })
    })
  }
}
