import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['circle']
  static values = { percent: Number }

  connect() {
    const circle = this.circleTarget
    const radius = circle.r.baseVal.value
    const circumference = radius * 2 * Math.PI
    const offset = circumference - this.percentValue / 100 * circumference
    circle.style.strokeDasharray = `${circumference} ${circumference}`
    circle.style.strokeDashoffset = offset
  }
}