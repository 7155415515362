import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider", "bgBlured", "contentContainer"];
  static values = {
    open: Boolean,
    scrollTop: Boolean,
    propagate: Boolean,
  };

  connect() {
    document.addEventListener("keydown", this.handleEscapeKey.bind(this));
    this.open = this.openValue;
  }

  slide() {
    this.open = !this.open;

    if (this.open) {
      this._open();
    } else {
      this._close();
    }
  }
  handleEscapeKey(event) {
    if (event.key === "Escape" || event.keyCode === 27) {
      if (this.open) {
        this._close();
        this.open = !this.open;
      }
    }
  }

  handleOutsideSlider(event) {
    if (!this.sliderTarget.contains(event.target) && this.open) {
      this._close();
      this.open = !this.open;
    }
  }



  _open() {
    // if present we remove Intercom chat to avoid conflict
    if (document.querySelector('.intercom-lightweight-app-launcher')) {
      document.querySelector('.intercom-lightweight-app-launcher').style.display = "none"
    }

    this.sliderTarget.style.display = "block"
    this.sliderTarget.dataset.animate = "opening"
    this.bgBluredTarget.style.display = "block"
    this.bgBluredTarget.dataset.animate = "opening"


    document.body.style.overflow = "hidden";
    if(this.scrollTopValue) {
      this.contentContainerTarget.scrollTop = this.contentContainerTarget.scrollHeight
    }
  }

  _close() {
    document.body.style.overflowY = "auto";

    this.sliderTarget.dataset.animate = "closing"
    this.bgBluredTarget.dataset.animate = "closing"
    this.sliderTarget.addEventListener('animationend', () => {
      this.sliderTarget.style.display = "none"
      this.sliderTarget.dataset.animate = ""
      this.bgBluredTarget.style.display = "none"
      this.bgBluredTarget.dataset.animate = ""
    }, { once: true })



    // We add Intercom chat back
    if (document.querySelector('.intercom-lightweight-app-launcher')) {
      document.querySelector('.intercom-lightweight-app-launcher').style.display = "block"
    }

    //Propagate Event for comments read callback
    if (this.hasPropagateValue && this.propagateValue === true) {
      if (this.element.dataset.handoverId) {
        dispatchCustomEvent('slideover:close_handover', {
          id: this.element.dataset.handoverId
        })
      } else {
        dispatchCustomEvent('slideover:close_content', {
          id: this.element.dataset.id,
          section_id: this.element.dataset.sectionId
        })
      }
    }

  }

  disconnect() {
    this.open = false;
    document.removeEventListener("keydown", this.handleEscapeKey.bind(this));
    document.removeEventListener('click', this.handleOutsideSlider.bind(this))
  }
}
