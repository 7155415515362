
import { add, Controller } from 'stimulus';
import Swal from 'sweetalert2';
import { fetchWithToken } from '../../application/stimulus_helper';
import consumer from "../../channels/consumer";

export default class extends Controller {
  static targets = [ 'card', 'addQuestionLink', 'spinner', 'formContainer', 'questionCardsContainer', 'questionCard', 'questionForm', 'scoreInput', 'attemptsInput', 'questionsContainer', 'aiBtn' ]
  static values = { id: Number, method: String, urlFetchQuestionCard: String, aiQuizz: String}

  connect() {
    if (this.questionCardsContainerTarget.querySelectorAll('.question-card').length === 0) {
      this.addQuestionLinkTarget.click()
      if (this.aiQuizzValue === "true") {
        setTimeout(() => {
          this.fetchLoadingState()
        }, 300)
      }
    } else {
      this.questionCardsContainerTarget.querySelectorAll('.question-card')[0].click()
    }
    this._broadcast();
  }

  disableBroadcast() {
    this.element.dataset.broadcast = false
  }

  enableBroadcast() {
    this.element.dataset.broadcast = true
  }

  fetchLoadingState() {
    fetchWithToken(`${this.aiBtnTarget.dataset.url}?type=${this.aiBtnTarget.dataset.questionOrAnswer}&multi_select=true&loading_state=true`, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      console.log(data)
      if (data.question) {
        this.questionsContainerTarget.insertAdjacentHTML('beforebegin', data.question)
        this.questionsContainerTarget.dataset.expanded = false
      }
    })
  }

  proposeAi(e) {
    const questionOrAnswer = e ? e.currentTarget.dataset.questionOrAnswer : 'question'
    const quizzableContentsIndex = this.element.querySelector(`.quizzable-contents-index[data-question-or-answer='${questionOrAnswer}'][data-quizzable-contents-index--component-multiselect-value="true"]`)
    //const aiBtn = e.currentTarget

    if (quizzableContentsIndex) {
      const expandedBool = quizzableContentsIndex.dataset.expanded === "true"
      if (!expandedBool) {  this.enableBroadcast() }
      quizzableContentsIndex.dataset.expanded = !expandedBool
      this.questionsContainerTarget.dataset.expanded = expandedBool
    } else {
      this.enableBroadcast()
      fetchWithToken(`${e.currentTarget.dataset.url}?type=${e.currentTarget.dataset.questionOrAnswer}&multi_select=true`, {
        method: "GET",
        headers: { "Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        if (data.question) {
          this.questionsContainerTarget.insertAdjacentHTML('beforebegin', data.question)
          this.questionsContainerTarget.dataset.expanded = false
        }
      })
    }
  }

  cancelAiGeneration(){
    this.disableBroadcast()
    const form = this.questionsContainerTarget.dataset.expanded === "true" ? this.element.querySelector('.question-form:not(.hidden)') : 'general'
    console.log(form)
    this.resetLoadingState(form)
  }


  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "StreamQuestionChannel", id: this.idValue },
      {
        received: (data) => {
          if (this.element.dataset.broadcast === "true") {
            if (data.done) {
              this.resetLoadingState('general')
            } else {
              const activeForm = this.element.querySelector('.question-form:not(.hidden)')
              if (data.context === 'question') {
                if (activeForm.querySelector('.question-label').value !== '' ) {
                  this.addQuestionLinkTarget.click()
                  setTimeout(() => {
                    const questions = this.element.querySelectorAll('.question-form')
                    const relevantForm = questions[questions.length - 1]
                    this.fillFormWithData(relevantForm, data)
                  }, 300)
                } else {
                  this.fillFormWithData(activeForm, data)
                }
              } else if (data.context === 'answer') {
                const correctedField = activeForm.querySelector('.corrected-answer-field')
                if (correctedField) {
                  this.stopAnimatingField(correctedField)
                  correctedField.classList.add("animate-charcter")
                  correctedField.value = data.corrected
                  this.resize(correctedField)
                  correctedField.classList.remove("animate-charcter")
                }
              } else if (data.context === 'answers') {
                activeForm.querySelector('.toggle-answers-container').classList.remove('hidden')
                const answersContainer = activeForm.querySelector('.answers-container')
                const answersButtonsContainer = activeForm.querySelector('.answers-buttons-container')
                const emptyState = activeForm.querySelector(`.quizzable-contents-index[data-question-or-answer='answers']`)

                data.quizzanswers.forEach((answer) => {
                  const labels = activeForm.querySelectorAll('.answer-label')
                  const firstEmptyLabel = Array.from(labels).find(label => label.value === '' && !label.closest('.answer-card').classList.contains('hidden'))
                  if (firstEmptyLabel) {
                    const card = firstEmptyLabel.closest('.answer-card')
                    firstEmptyLabel.value = answer.label
                    card.querySelector('.correct-input').checked = answer.correct
                  } else {
                    activeForm.querySelector('.add-answer-link').click()
                      setTimeout(() => {
                        const answerForms = activeForm.querySelectorAll('.answer-card:not(.hidden)')
                        const form = answerForms[answerForms.length - 1]
                        form.querySelector('.answer-label').value = answer.label
                        form.querySelector('.correct-input').checked = answer.correct
                      }, 200)
                  }
                })
                this.revealButtons()
                answersContainer.dataset.expanded = true
                emptyState.dataset.expanded = false
                answersButtonsContainer.dataset.expanded = true
              }
            }
          }
        }
      }
    )
  }

  hideAiBtns() {
    this.element.querySelectorAll('.aiQuestionBtn').forEach((btn) => {
      btn.dataset.disabled = true
    })
  }

  resize(zone) {
    zone.style.height = "1px";
    zone.style.height = (25+zone.scrollHeight)+"px"
  }

  fillFormWithData(form, data) {
    const questionLabelInput = form.querySelector('.question-label')
    const sourceContentInput = form.querySelector('.source-content-input')
    const answerForms = form.querySelectorAll('.answer-card:not(.hidden)')
    const addAnswerBtn = form.querySelector('.add-answer-link')
    const questionFormat = form.querySelector('.question-format').value
    const correctedField = form.querySelector('.corrected-answer-field')
    const aiField = form.querySelector('.ai-initiated-field')
    const aiBtn = form.querySelector('.aiQuestionBtn')

    this.resetLoadingState(form)

    questionLabelInput.value = data.label
    sourceContentInput.value = data.sourcecontentid
    if (questionFormat === 'open' ) {
      correctedField.value = data.quizzanswers[0].label
      this.resize(correctedField)
    } else {
      form.querySelector('.toggle-answers-container').classList.remove('hidden')

      data.quizzanswers.forEach((answer) => {
        if (answerForms.length > (answer.position - 2 )) {
          const form = answerForms[answer.position - 1]
          form.querySelector('.answer-label').value = answer.label
          form.querySelector('.correct-input').checked = answer.correct
          form.querySelector('.answer-label').classList.remove("animate-charcter")

        } else {
          addAnswerBtn.click()
          setTimeout(() => {
            const form = answerForms[answer.position - 1]
            form.querySelector('.answer-label').value = answer.label
            form.querySelector('.correct-input').checked = answer.correct
            form.querySelector('.answer-label').classList.remove("animate-charcter")
          }, 500)
        }
      })
    }
    aiField.value = true
    questionLabelInput.classList.remove("animate-charcter")
    aiBtn.dataset.disabled = true
    this.revealButtons()
  }

  revealButtons() {
    const modal = this.element.closest('.content-writing-modal')
    this.application.getControllerForElementAndIdentifier(modal, 'media-creation--submit').revealButtons()
  }

  updateCard(e) {
    const id = e.currentTarget.dataset.questionId
    const format = e.currentTarget.dataset.format
    const card = this.questionCardsContainerTarget.querySelector(`.card-icon[data-question-id="${id}"]`)
    switch (format) {
      case 'single_choice':
      card.src = '/assets/svg/basic/circle_check_outline.svg'
      break;
      case 'multiple_choices':
      card.src = '/assets/svg/basic/checkbox_checked.svg'
      break;
      case 'ordering':
      card.src = '/assets/svg/edit/reorder_down.svg'
      break;
      case 'open':
      card.src = '/assets/svg/edit/double_quotes_l.svg'
      break;
      case 'matching':
      card.src = '/assets/svg/intertwined_arrows.svg'
      break;
    }
  }

  navigate(e) {
    const id = e.currentTarget.dataset.questionId
    //this.questionsContainerTarget.querySelector(`.question-form[data-question-id="${id}"]`)
    this.navigateTo(id)
  }

  navigateTo(id) {
    this.element.querySelectorAll('.question-form').forEach((question) => {
      if (question.dataset.questionId === id) {
        showElements(question)
        this.assignPositionsInContainer(question.querySelector('.answers-container'))
      } else {
        hideElements(question)
      }
    })
    this.highlightCard(id)
  }

  assignPositionsInContainer(container) {
    container.querySelectorAll('.answer-card:not(.hidden)').forEach((card, i) => {
    card.querySelector('.answer-card-position-index').value = i + 1
    })
      // attention, ne pas compter les marked for deletion...
  }

  highlightCard(id) {
    this.questionCardsContainerTarget.querySelectorAll('.question-card').forEach((card) => {
      if (card.dataset.questionId === id) {
        card.dataset.selected = "selected"
      } else {
        card.dataset.selected = ""
      }
    })
  }

  resetLoadingState(form) {
    if (form === 'general') {
      const quizzableContentsIndex = this.element.querySelector(`.quizzable-contents-index[data-question-or-answer='question'][data-quizzable-contents-index--component-multiselect-value="true"]`)
      this.application.getControllerForElementAndIdentifier(quizzableContentsIndex, 'quizzable-contents-index--component').reset()
      this.proposeAi()
    } else {
      const formQuizzableContentsIndex = form.querySelector('.quizzable-contents-index')
      if (formQuizzableContentsIndex) {
        this.application.getControllerForElementAndIdentifier(formQuizzableContentsIndex, 'quizzable-contents-index--component').reset()
        this.application.getControllerForElementAndIdentifier(form, 'media-creation--quizz-question').proposeAi()
      }
    }
  }

  addQuestion(e) {
    const time = new Date().getTime()
    const linkId = e.currentTarget.dataset.id
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    const newFields = regexp ? e.currentTarget.dataset.fields.replace(regexp, time) : null

    if (newFields) {
      this.element.querySelector('.questionsContainer').insertAdjacentHTML('beforeend', newFields)
      const questionAdded = this.element.querySelector('.questionsContainer').lastChild
      const id = questionAdded.dataset.questionId
      this.addQuestionCard(id)
    }
  }


  addQuestionCard(id) {
    const position = this.questionCardsContainerTarget.querySelectorAll('.question-card').length
    fetchWithToken(`${this.urlFetchQuestionCardValue}?navid=${id}&position=${position}`, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      this.questionCardsContainerTarget.insertAdjacentHTML('beforeend', data.partial)
      this.assignPositionBasedonCards()
      this.navigateTo(id)
    })
  }

  duplicateQuestion(e) {
    const sourceQuestion = e.currentTarget.closest('.question-form')
    this.addQuestionLinkTarget.click()
    const questionAdded = this.element.querySelector('.questionsContainer').lastChild
    const originalQuestionFormat = sourceQuestion.querySelector(`.format-button[data-selected="${true}"]`).dataset.format
    // this.replicateFields(['question-format'], sourceQuestion, questionAdded)
    setTimeout(() => {
      questionAdded.querySelector('.aiQuestionBtn').dataset.disabled = true
      questionAdded.querySelector('.aiAnswerBtn').dataset.disabled = true
      questionAdded.querySelector(`.format-button[data-format='${originalQuestionFormat}']`).click()
      this.replicateFields(['ai-initiated-field', 'source-content-input', 'question-label', 'corrected-answer-field'], sourceQuestion, questionAdded)
      this.copyImage(sourceQuestion, questionAdded)
      this.replicateAnswers(sourceQuestion, questionAdded)
    }, 500)
  }

  copyImage(sourceForm, destinationForm) {
    const sourceImageInput = sourceForm.querySelector('.imageinput')
    const destinationImageInput = destinationForm.querySelector('.imageinput')
    const sourceImagePreview = sourceForm.querySelector(`.imagepreview`)
    const destinationImagePreview = destinationForm.querySelector(`.imagepreview`)
    if (sourceImageInput.files.length  !== 0) {
      destinationImageInput.files = sourceImageInput.files
      destinationImagePreview.src = sourceImagePreview.src
      showElements(destinationImagePreview)
      hideElements(destinationForm.querySelector(`.imagebutton`))
      //destinationImageInput.dispatchEvent(new Event('change'))
    }
    else if (sourceImagePreview.dataset.persistedUrl !== "no" ) {
      fetch(sourceImagePreview.dataset.persistedUrl)
    .then(response => response.blob())
    .then(blob => new File([blob], "quizz-img.jpg", {
      type: blob.type
    }))
    .then(file => {
      var list = new DataTransfer()
      list.items.add(file);
      destinationImageInput.files = list.files;
      destinationImagePreview.src = sourceImagePreview.src
      showElements(destinationImagePreview)
      hideElements(destinationForm.querySelector(`.imagebutton`))
    })
    }
  }

  replicateFields(array, sourceForm, destinationForm) {
    array.forEach((input) => {
      if(sourceForm.querySelector(`.${input}`)) {
        destinationForm.querySelector(`.${input}`).value = sourceForm.querySelector(`.${input}`).value
      }
    })
  }

  replicateAnswers(sourceQuestion, questionAdded) {
    sourceQuestion.querySelectorAll('.answer-card:not(.hidden)').forEach((originalAnswer, index) => {
      const destinationAnswer = questionAdded.querySelectorAll('.answer-card:not(.hidden)')[index]
      this.replicateFields(['answer-label', 'deletion'], originalAnswer, destinationAnswer)
      this.copyImage(originalAnswer, destinationAnswer)
      destinationAnswer.querySelector('.correct-input').checked = originalAnswer.querySelector('.correct-input').checked
    })
  }

  removeQuestion(e) {
    const questionId = e.currentTarget.dataset.questionId
    const questionForm = this.element.querySelector(`.question-form[data-question-id="${questionId}"]`)
    const deleteField = questionForm.querySelector(`.deletion[data-question-id="${questionId}"]`)
    if (deleteField) {
      deleteField.value = true
      questionForm.classList.add('hidden')
    } else {
      questionForm.remove()
    }
      this.removeQuestionCard(questionId)
  }

  removeQuestionCard(id) {
    this.questionCardsContainerTarget.querySelector(`.question-card[data-question-id="${id}"]`).remove()
    this.assignPositionBasedonCards()
    if (this.questionCardsContainerTarget.querySelector('.question-card')) {
      this.questionCardsContainerTarget.querySelector('.question-card').click()
    }
  }

  assignPositionBasedonCards() {
    this.questionCardsContainerTarget.querySelectorAll('.question-card').forEach((card, i) => {
        card.querySelector('.position').innerText = i + 1
        this.element.querySelector(`.question-position-input[data-question-id="${card.dataset.questionId}"]`).value = i +1
        this.element.querySelector(`.title-index[data-question-id="${card.dataset.questionId}"]`).innerText = `Question #${i + 1}`
      })
  }

  submitForm() {
  //  if(event) event.preventDefault()
    const formErrors = this.checkForms()
    if(formErrors.length < 1) {
      this.finalSubmit()
    } else {
      Swal.fire({
      title: I18n.t('js.quizzs.cannot_save'),
      text: `${formErrors}`,
      icon: 'warning',
    })
    }
  }

  toggleAttempts() {
    if(this.element.querySelector('.attempts-switch').dataset.switchedOn === "false") {
      this.element.querySelector('.attempts-switch').click()
    }
  }

  checkForms() {
    var formErrors = []

    if (this.element.querySelector('.attempts-switch').dataset.switchedOn === 'false' && this.attemptsInputTarget.value) {
      this.attemptsInputTarget.value = ''
    }
    if (this.element.querySelector('.score-switch').dataset.switchedOn === 'false' && this.scoreInputTarget.value) {
      this.scoreInputTarget.value = ''
    }

    if(this.scoreInputTarget.value && !this.attemptsInputTarget.value) {
      formErrors.push(I18n.t('js.quizzs.error_messages.missing_attempts') )
    }

    this.element.querySelectorAll('.question-form').forEach( (questionForm) => {
      const position = questionForm.querySelector('.question-position-input').value
      const questionFormat = questionForm.querySelector('.question-format').value
      const questionId = questionForm.dataset.questionId
      const cardTick = this.questionCardsContainerTarget.querySelector(`.tick-alert[data-question-id="${questionId}"]`)
      const errorMessage = questionForm.querySelector('.error-msg')
      const correctedField = questionForm.querySelector('.corrected-answer-field')

      if (questionForm.querySelector('.deletion').value === 'true') { return }

      hideElements(cardTick, errorMessage)
      errorMessage.innerText = ""

      if (!questionForm.querySelector('.question-label').value) {
        formErrors.push(I18n.t('js.quizzs.error_messages.missing_question_label', { position: `${position}` }))
        errorMessage.innerText += I18n.t('js.quizzs.error_messages.missing_question_label', { position: `${position}` }) + "- "
        showElements(cardTick, errorMessage)
      }

      if (questionFormat !== 'open' && questionFormat!=='matching' ) {
        questionForm.querySelectorAll('.answer-card').forEach((card) => {
          const answerPosition = card.querySelector('.answer-card-position-index').value

          if (!card.querySelector('.answer-label').value && card.querySelector('.deletion').value === 'false' && !card.querySelector('.answer-image').value && card.querySelector('.imagepreview').dataset.persistedUrl === 'no') {

            card.querySelector('.remove-answer-link').click();
            //formErrors.push(I18n.t('js.quizzs.error_messages.missing_answer_label', { answer_position: `${answerPosition}`, position: `${position}` }))
          }
        })
        // })
      }

      if (questionFormat === 'matching') {
        questionForm.querySelectorAll('.matching-answer-card').forEach((pair) => {
          let missingMatch = 0
          pair.querySelectorAll('.matching-card').forEach((match) => {
            if (!match.querySelector('.matching-label').value && match.querySelector('.deletion').value === 'false' && !match.querySelector('.matching-image').value && match.querySelector('.imagepreview').dataset.persistedUrl === 'no') {
              missingMatch++
            }
          })
          if (missingMatch === 2) {
            pair.querySelector('.remove-pair-link').click()
          }
          else if (missingMatch === 1) {
            formErrors.push(I18n.t('js.quizzs.error_messages.missing_matching_pair', { position: `${position}` }) )
            errorMessage.innerText += I18n.t('js.quizzs.error_messages.missing_matching_pair', { position: `${position}` })
            showElements(cardTick, errorMessage)
          }
        })
      }

      if (questionFormat!=='open' && questionFormat!=='ordering' && questionFormat!=='matching') {
        var correctAnswers = 0
        questionForm.querySelectorAll('.correct-input').forEach((checkBox) => {
          if (checkBox.checked === true) { correctAnswers++ }
        })
        if (correctAnswers < 1) {
          formErrors.push(I18n.t('js.quizzs.error_messages.missing_correct_answer', { position: `${position}` }) )
          errorMessage.innerText += I18n.t('js.quizzs.error_messages.missing_correct_answer', { position: `${position}` })
          showElements(cardTick, errorMessage)
        }
      }

      if (questionFormat === 'open' && !correctedField.value) {
        formErrors.push(I18n.t('js.quizzs.error_messages.missing_corrected_answer', { position: `${position}` }))
        errorMessage.innerText += I18n.t('js.quizzs.error_messages.missing_corrected_answer', { position: `${position}` }) + "- "
        showElements(cardTick, errorMessage)
      }

    })
    return formErrors;
  }

  animateEmptyFields() {
    const form = this.element.querySelector('.question-form:not(.hidden)')
    form.querySelectorAll('input').forEach((input) => {
      this.animateField(input)
      })
  }

  animateField(field) {
    field.setAttribute('placeholder', 'loading...')
    field.classList.add('animate-charcter-loop')
  }

  stopAnimatingEmptyFields() {
    const form = this.element.querySelector('.question-form:not(.hidden)')
    form.querySelectorAll('input').forEach((input) => {
      this.stopAnimatingField(input)
     })
  }

  stopAnimatingField(field) {
    field.setAttribute('placeholder', '')
    field.classList.remove('animate-charcter-loop')
  }

  revealSpinner() {
    showElements(this.element.querySelector('.ia-spinner'))
    hideElements(this.addQuestionLinkTarget)
  }

  hideSpinner() {
    hideElements(this.element.querySelector('.ia-spinner'))
    showElements(this.addQuestionLinkTarget)
  }


  finalSubmit() {
    const form = this.element.querySelector(`#form_container_for_${this.idValue}`)
    const formData = new FormData(form)

    fetchWithToken(form.action, {
      method: form.method,
      body: formData
    })
      .then(response => response.json())
      .then((data) => {
        const target = this.element.querySelector(`#form_container_for_${this.idValue}`)
        if (target) target.outerHTML = data.form
        if (!data.errors) {
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
          if (data.card) {
            const content = document.querySelector(`.writing-content[data-content-id="${data.content_id}"]`)
            if (content) content.outerHTML = data.card
          }
        }
      })
  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    if(this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]')) {
      this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    }
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    if(this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]')) {
      this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    }
    dispatchCustomEvent('contents:ongoing')
  }


  #markAsTodo() {
    this.element.closest('.writing-content').classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }
}
