import { useIntersection } from 'stimulus-use'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ ]

  // Useful to mark as read on load will call the appear method if the element is already in the viewport
  connect() {
    useIntersection(this, { threshold: 0.5 })
  }

  appear(entry) {
    const handoverReadingController = this.application.getControllerForElementAndIdentifier(this.element.closest('#handover-reading-controller'), 'handover-reading');
    if (entry.target.dataset.isRead === 'true' || entry.target.dataset.isReader === 'false' ) { return }
    handoverReadingController.sendReadRequest(entry.target)
  }
}
