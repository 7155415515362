import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'ongoing', 'processing' ]
  static values = { id: String, subid: String, arrowid: String }

  toggleSection() {
    this.section = event.srcElement.closest("section").dataset.hideSectionId
    this.sectionTargets.forEach((element) => {
      if (this.section == element.dataset.hideSectionSubid) {
        element.classList.toggle('hidden')
      }
    })
    this.arrowTargets.forEach((element) => {
      if (this.section == element.dataset.hideSectionArrowid) {
        element.classList.toggle('komin-rotate')
      }
    })
  }
}
