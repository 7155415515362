import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["results"]

  connect() {
    this.startListeningToNav()
  }


  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.resultsTarget.innerHTML = data
      this.startListeningToNav()
    })
  }

  insertSpinner(target) {
    this.resultsTarget.innerHTML = '<div class="h-48 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
  }

  startListeningToNav() {
    this.resultsTarget.querySelectorAll('.kaminarilink').forEach(
      (span) => {
        if (span.querySelector('a')) {
          span.querySelector('a').addEventListener('click', (e) => {
            e.preventDefault();
            this.fetchResults(e.currentTarget.href)
          })
        }
      }
    )
   }
}
