import { Controller } from 'stimulus'
import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["form", "iframeBtn", "qrcodeBtn", "publicLinkBtn", "landingPageForm", "ctaHeaderWrapper"]
  static values = { handoverId: Number }

  connect() {
    // We set the current Option globally
    if (this.hasFormTarget) {
      this.currentOption = this.formTarget.querySelector('[selected]')
    }
  }

  changeAudience(event) {
    // We need to find the seleted option
    const selectedOption = event.currentTarget.options[event.currentTarget.selectedIndex]
    if (selectedOption.value === "only_by_invitation") {
      this._selectGuest()
    } else if (selectedOption.value === "without_invitation") {
      this._selectLibrary()
    } else if (selectedOption.value === "world") {
      this._selectWorld()
    }
  }

  submit(event) {
    event.preventDefault()
  }
  nowToggleIt(orwhat) {
    Swal.fire({
      title: orwhat.alertTitle,
      text: orwhat.alertText,
      icon: "warning",
      confirmButtonText: orwhat.confirmText,
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      dangerMode: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          url: `/handovers/${orwhat.handoverId}/toggle`,
          type: "patch",
          dataType: 'json',
          data: `[audience]=${orwhat.audience}`,
          success: function(data) {
            if (data.errors.length > 1) {
              Swal.fire({
                title: "Erreur",
                text: data.errors,
                warning: "success",
                icon: 'warning'
              })
              this._backToPreviousOption()
            } else {
              if (data.public) {
                if (this.hasCtaHeaderWrapperTarget) {
                  this.ctaHeaderWrapperTarget.ariaHidden = false
                }
                this.landingPageFormTarget.classList.remove('hidden')
              } else {
                if (this.hasCtaHeaderWrapperTarget) {
                  this.ctaHeaderWrapperTarget.ariaHidden = true
                }
                this.landingPageFormTarget.classList.add('hidden')
              }
            }
          }.bind(this),
          error: function(data) {
            Swal.fire({
              title: "Erreur",
              text: data.errors,
              warning: "success"
            })
          }
        })
      } else {
        this._backToPreviousOption()
        Swal.fire({
          title: orwhat.cancelTitle
        });
      }
    });
  }

  _backToPreviousOption() {
    this.currentOption.selected = true
  }

  _selectGuest() {
    this.nowToggleIt( {
      handoverId: this.handoverIdValue,
      audience: "only_by_invitation",
      alertTitle: I18n.t('js.sweet_alerts.visibility.select_guest.title'),
      alertText: I18n.t('js.sweet_alerts.visibility.select_guest.text'),
      confirmText: I18n.t('js.sweet_alerts.visibility.select_guest.confirm_button_text'),
      successTitle: I18n.t('js.sweet_alerts.visibility.select_guest.success_title'),
      cancelTitle: I18n.t('js.sweet_alerts.visibility.select_guest.cancel_title'),
    })
  }

  _selectLibrary() {
    this.nowToggleIt( {
      handoverId: this.handoverIdValue,
      audience: "without_invitation",
      alertTitle: I18n.t('js.sweet_alerts.visibility.select_library.title'),
      alertText: I18n.t('js.sweet_alerts.visibility.select_library.text'),
      confirmText: I18n.t('js.sweet_alerts.visibility.select_library.confirm_button_text') ,
      successTitle: I18n.t('js.sweet_alerts.visibility.select_library.success_title'),
      cancelTitle: I18n.t('js.sweet_alerts.visibility.select_library.cancel_title')
    })
  }

  _selectWorld() {
    this.nowToggleIt( {
      handoverId: this.handoverIdValue,
      audience: "world",
      alertTitle: I18n.t('js.sweet_alerts.visibility.select_world.title'),
      alertText: I18n.t('js.sweet_alerts.visibility.select_world.text'),
      confirmText: I18n.t('js.sweet_alerts.visibility.select_world.confirm_button_text'),
      successTitle: I18n.t('js.sweet_alerts.visibility.select_world.success_title'),
      cancelTitle: I18n.t('js.sweet_alerts.visibility.select_world.cancel_title')
    })
  }
}
