import { Controller } from 'stimulus';
import FilerobotImageEditor from 'filerobot-image-editor';
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'image', 'editContainer', 'slidesContainer',
                     'input', 'loader', 'editButton' ]


  openEditor(e) {
    if (e.currentTarget.getAttribute('disabled')) {
      return
     } else {
      this.slidex = this.editButtonTarget.dataset.slidex
      this.image = this.slidesContainerTarget.querySelector(`.imagepreview[data-slidex="${this.slidex}"]`)
      this.input = this.slidesContainerTarget.querySelector(`.imageinput[data-slidex="${this.slidex}"]`)
      fadeShowElements(this.editContainerTarget)
      document.body.style.overflow = 'hidden'
      this.#buildEditor()
     }
  }

  displayFileInPreviewObject() {
    const file = this.input.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      if (/image/.test(file.type)) {
        this.reader = new FileReader();
        this.reader.onload = (ev) => {
          this.image.src = ev.currentTarget.result
        }
        this.reader.readAsDataURL(file)
        this.fromCanvas = false
      } else {
        Swal.fire({
          title: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.title'),
          text: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.text', { file_types: this.acceptedMimetypes }),
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          timer: 3000
        })
      }
    }
  }



  #disableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.setAttribute('disabled', true)
      button.classList.replace('bg-komin-blue', 'bg-komin-gray')
      button.classList.remove('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }

  #enableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.removeAttribute('disabled')
      button.classList.replace('bg-komin-gray', 'bg-komin-blue')
      button.classList.add('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }


  #closeEditor() {
    fadeHideElements(this.editContainerTarget)
    document.body.style.overflowY = 'visible'
    if (this.editor) this.editor.terminate();
  }

  #buildEditor() {
    const filerobotImageEditor = new FilerobotImageEditor(
      this.editContainerTarget,
      this.#editorConfig()
    );

    this.editor = filerobotImageEditor

    // the default save button is hidden with a css rule on .FIE_topbar-save-button
    this.editor.render({
      onClose: (closingReason) => {
        this.#closeEditor()
      },
      onModify: () => {
        this.#displayCustomSaveButton()
      }
    })
    this.editContainerTarget.querySelectorAll('button').forEach((e) => {
      e.setAttribute('type', 'button')
    })
  }

  #displayCustomSaveButton() {
    const button = document.createElement('button')
    const originalButton = this.editContainerTarget.querySelector('.FIE_topbar-save-button')

    button.innerHTML = 'Save'
    button.classList.add('btn', 'secondary', 'leading-5', 'btn-md')

    if (originalButton) {
      originalButton.parentNode.insertBefore(button, originalButton.nextSibling);
      originalButton.remove()
    }

    button.addEventListener('click', this.#applyEditchanges.bind(this))
  }

  #applyEditchanges(event) {
    event.stopPropagation()
    event.preventDefault()

    Swal.fire({
      title:  I18n.t('js.sweet_alerts.contents.save_modifications'),
      text:  I18n.t('js.sweet_alerts.contents.cannot_cancel_modifications'),
      icon: 'question',
      confirmButtonText: I18n.t('js.sweet_alerts.confirm.continue'),
      showCancelButton: I18n.t('js.sweet_alerts.confirm.cancel'),
      cancelButtonText: I18n.t('js.sweet_alerts.confirm.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          var data = this.editor.getCurrentImgData()
        } catch (error) {
          displayCatchedError(error, { message: 'The file is too big' })
          return
        }
        const imageBase64 = data.imageData.imageBase64
        const blobExtension = data.imageData.extension

        this.#buildNewFile(imageBase64, blobExtension)
        this.#closeEditor()
      } // else if (result.dismiss === Swal.DismissReason.cancel) {}
    })
  }

  async #buildNewFile(base64url, extension) {
    await fetch(base64url)
      .then((response) => response.blob())
      .then((blobData) => {
        const newFile = this.#createFileFromBlob(blobData, extension)
        this.#insertFile(newFile)
      })
  }

  #createFileFromBlob(blob, extension) {
    return new File([blob], `${new Date().getTime()}.${extension}`,{ type: `image/${extension}`, lastModified:new Date().getTime()});
  }

  #editorConfig() {
    const c = document.createElement('canvas')

    c.height = this.image.naturalHeight
    c.width = this.image.naturalWidth
    const ctx = c.getContext('2d')

    ctx.drawImage(this.image, 0, 0, c.width, c.height)
    const base64String = c.toDataURL()

    const { TABS, TOOLS } = FilerobotImageEditor;
    return {
      source: base64String,
      annotationsCommon: {
        fill: '#ffAA13',
      },
      Text: {
        text: 'Your text here'
      },
      Rotate: {
        angle: 90,
        componentType: 'slider'
      },
      Crop: {
        presetsItems: [
          {
            titleKey: 'Square',
            descriptionKey: '1:1',
            ratio: 1 / 1,
          },
        ],
      },
      tabsIds: [ TABS.ANNOTATE, TABS.ADJUST ],
      defaultTabId: TABS.ANNOTATE,
      defaultToolId: TOOLS.TEXT,
    };
  }

  #insertFile(file) {
    var list = new DataTransfer();
      list.items.add(file)
      this.input.files = list.files
      this.displayFileInPreviewObject()
      this.#closeEditor()
  }
}
