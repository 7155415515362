import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['drawingContainer', 'excalidrawDataInput', 'form']
  static values = {
    contentId: String
  }

  connect() {
    this._updateDrawingSize()
    const submitControllerWrapper = this.element.closest('.content-writing-modal')
    this.submitController = this.application.getControllerForElementAndIdentifier(submitControllerWrapper, 'media-creation--submit')
  }

  _updateDrawingSize() {
    setTimeout(() => {
      const drawingContainer = this.drawingContainerTarget.querySelector(`#drawing-write-wrapper-container-${this.contentIdValue}`)

      if (!drawingContainer) return

      drawingContainer.style.height = `${drawingContainer.offsetHeight - 1}px`
    }, 1000)
  }

  submitForm() {
    // We call saveDrawingToBlob (attached to the window object) to get the drawing image blob
    // and then we attach it to the form data before submitting the form.
    window.saveDrawingToBlob().then((drawingBlob) => {
    
      const formData = new FormData(this.formTarget)
      // We attach the drawing blob to the form data with the key "drawing" as image param

      if (drawingBlob) formData.append("image", drawingBlob, "drawing.png")


      fetch(this.formTarget.action, {
        method: this.formTarget.method,
        body: formData
      })
        .then(response => response.json())
        .then((data) => {
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }

          const content = document.querySelector(`[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.partial
        })
    })

  }

  #markAsDone() {
    this.element.closest('.writing-content').classList.replace('todo', 'done')
    this.element.closest('.writing-content').classList.replace('ongoing', 'done')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  drawingDataChanged() {
    this.submitController.revealButtons()
  }
}
