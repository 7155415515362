import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';
import { KominUploader } from '../../components/komin_uploader';
export default class extends Controller {
  static targets = ['deleteAttachmentBtn', 'modalContent',
    'form', 'progressBarContainer', 'fileContainer', 'fileName', 'fileDisplayContainer',
    'infoBoxContainer', 'editImageButton', 'changeFileButton', 'fileInput', 'fileFormOptionsContainer',
    'downloadFileAllowedInput', 'displayFilePreviewInput', 'genericFileDisplay', 'filePreviewContainer',
    'displayPagesFormContainer', 'convertionProgressBarContainer', 'covertionProgressBar', 'convertionProgressText' ]

  static values = {
    contentId: Number
  }

  connect() {
    this.contentCard = this.element.closest('.writing-content')
    document.addEventListener('content:file-deleted', this.#listenToDetach.bind(this))
  }


  disconnect() {
    document.removeEventListener('content:file-deleted', this.#listenToDetach.bind(this))
  }

  fileSelected(event) {
    this.fileContainerTarget.classList.add('hidden')
    this.fileFormOptionsContainerTarget.classList.add('hidden')
    this.fileDisplayContainerTarget.classList.add('hidden')
    this.fileNameTarget.innerText = event.target.files[0].name
    const file = event.target.files[0];
    const url = event.target.dataset.directUploadUrl;
    const id = this.contentIdValue;

    // Show progress bar
    this.progressBarContainerTarget.classList.remove('hidden')

    this.uploader = new KominUploader(file, url, id, this._handleDirectUploadEnd.bind(this));
    this.uploader.start(event.target);
  }

  changeFile(event) {
    this.fileInputTarget.click()
  }

  toggleFileVisibility(event) {
    this.displayFilePreviewInputTarget.value = this.displayFilePreviewInputTarget.value === "true" ? "false" : "true"
    this.filePreviewContainerTarget.classList.toggle('hidden')
    this.genericFileDisplayTarget.classList.toggle('hidden')

    // We disable the toggle for display pages (the last toggle)
    const firstToggle = event.currentTarget
    const lastToggle = firstToggle.nextElementSibling.nextElementSibling
    if (lastToggle) {

      lastToggle.disabled = this.displayFilePreviewInputTarget.value ===
        "true" ? false : true
    }
  }

  toggleDownloadFileAllowed() {
    this.downloadFileAllowedInputTarget.value = this.downloadFileAllowedInputTarget.value === "true" ? "false" : "true"
  }

  toggleDisplayPages() {
    this.displayPagesFormContainerTarget.classList.toggle('hidden')
  }

  _handleDirectUploadEnd(event) {
    this.progressBarContainerTarget.classList.add('hidden')
    this.infoBoxContainerTarget.classList.add('invisible')
    // this.fileDisplayContainerTarget.classList.remove('hidden')
    // this.fileFormOptionsContainerTarget.classList.remove('hidden')
    this.#fetchPreview(event)
  }

  #fetchPreview(event) {
    let form = new FormData(this.formTarget)
    fetch(`/contents/${this.contentIdValue}/preview_file`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: form
    })
    .then(response => response.json())
    .then((data) => {
      this.#markAsOngoing()
      // We reveal the submit button
      this.#revealSubmitButton()
      // We take the first child of the file display container
      if (data.convertion) {
        this.convertionProgressBarContainerTarget.classList.remove('hidden')

        this.#launchFakeProgress(data.nb_pages)
      } else {
        this.fileDisplayContainerTarget.innerHTML = data.partial
        this.infoBoxContainerTarget.classList.remove('invisible')
        this.fileDisplayContainerTarget.classList.remove('hidden')
        this.fileFormOptionsContainerTarget.classList.remove('hidden')
        if (!data.display_pages_visible) {
          // query selector 'data-action=click->media-creation--file#toggleDisplayPages'
          const displayPagesToggle = this.fileFormOptionsContainerTarget.querySelector('.file-option-2')
          displayPagesToggle.classList.add('hidden')
          
        }
      }
    })
  }

  #launchFakeProgress(nbPages) {
    // We know each pages roughly takes 1.5 seconds to convert
    const timeToConvert = 1
    const totalTime = nbPages ? timeToConvert * nbPages : 8
    let progress = 0
    let progressText = this.convertionProgressTextTarget
    let progressBar = this.covertionProgressBarTarget
    let interval = setInterval(() => {
      progress += 1
      if (progress >= totalTime) {
        clearInterval(interval)
        this.#fetchFilePreview()
      }
      let progressPercentage = (progress / totalTime) * 100
      progressBar.style.width = `${progressPercentage}%`
      progressText.innerText = `${Math.round(progressPercentage)}%`
    }, 1000)
  }

  #fetchFilePreview() {
    const url = `/contents/${this.contentIdValue}/fetch_file_preview`

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then((data) => {
      this.infoBoxContainerTarget.classList.remove('invisible')
      this.convertionProgressBarContainerTarget.classList.add('hidden')
      this.fileDisplayContainerTarget.classList.remove('hidden')
      this.fileFormOptionsContainerTarget.classList.remove('hidden')
      this.fileDisplayContainerTarget.innerHTML = data.partial
    })

  }

  #revealSubmitButton() {
    const submitControllerElement = this.element.closest('.content-writing-modal')
    const stimulusController = this.application.getControllerForElementAndIdentifier(submitControllerElement, 'media-creation--submit')
    stimulusController.revealButtons()
  }

  deleteAttachment(event) {
    this.element.querySelector('.droppable-editable-input-delete-button').click()
  }

  #listenToDetach(event) {
    if (event.detail.id == this.contentIdValue) {
      this.#markAsTodo()
    }
  }

  submitForm() {
    let formData = new FormData(this.formTarget)
    if (this.element.querySelector(".display-range").dataset.input !== "[]") {
      formData.append("content[display_pages]", this.element.querySelector(".display-range").dataset.input)
    }
    fetchWithToken(this.formTarget.action, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      console.log(data)
      if (data.errors.length > 0) {
        Swal.fire({
          title: `Une erreur est survenue`,
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      } else {
        if (data.has_content) {
          if (data.status === "done") {
            console.log('received done status')
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
        }
        const target = this.modalContentTarget
        console.log(`I insert partial in lieu of ${target}`)
        if (target) target.outerHTML = data.partial
        if (data.card) {
          const content = document.querySelector(`.writing-content[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.card
        } else if (data.text) {
          const loadingText = document.querySelector(`.loading-text[data-content-id="${data.content_id}"]`)
          if (loadingText) {
            loadingText.innerText = data.text
            document.querySelector(`.waiting-container[data-content-id="${data.content_id}"]`).classList.remove('hidden')
          }
        }
      }
    })
  }

  #markAsDone() {
    this.contentCard.classList.replace('todo', 'done')
    this.contentCard.classList.replace('ongoing', 'done')
    this.contentCard.querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    this.contentCard.classList.replace('todo', 'ongoing')
    this.contentCard.classList.replace('done', 'ongoing')
    this.contentCard.querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
  }

  #markAsTodo() {
    this.contentCard.classList.replace('done', 'todo')
    dispatchCustomEvent('contents:todo')
  }
}
