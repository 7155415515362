import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {

  connect() {
    this.patchUrl = `/video_parts/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'video_parts',
      put: 'content',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".video-parts-reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      fallbackOnBody: true,

      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    let id = event.item.dataset.id
    var data = new FormData()
    data.append('position', event.newIndex + 1)
    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
