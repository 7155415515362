import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { fetchWithToken } from '../application/stimulus_helper';


export default class extends Controller {
  static targets = ['modal', 'dropzone', 'slidesPreview', 'header', 'results', 'form', 'query', 'input', 'oldImage', 'image', 'saveBtn', 'inputfile', 'modal']
  static values = {orientation: String}

  connect() {
    this.body = document.querySelector('body')
    this.overflowValue = this.body.style.overflow || 'visible'
  }

  open(index, type) {
    fadeShowElements(this.modalTarget)
    fadeHideElements(this.dropzoneTarget, this.slidesPreviewTarget)
    this.inputIndex = index
    this.type = type
    this.body.style.overflow = 'hidden'
    const form = this.formTarget
    const url = `${form.action}?orientation=${this.orientationValue}&specific=-slideshow&columns=5`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  hideModal(event) {
    if (event.currentTarget == event.srcElement) this.closeModal()
  }

  closeModal() {
    this.body.style.overflow = this.overflowValue
    stopAllPlayingMedias()
    fadeHideElements(this.modalTarget);
    fadeShowElements(this.slidesPreviewTarget);
  }

  search(e) {
    e.preventDefault()
    const form = this.formTarget
    const url = `${form.action}?query=${this.element.querySelector('.searchinput').value}&orientation=${this.orientationValue}&specific=-slideshow&columns=5`
    const display = this.resultsTarget
    Rails.ajax({
      url: url,
      type: 'get',
      success: function(data) {
        display.innerHTML = data.partial
        form.reset()
      }
    })
  }

  select(e){
    this.imageTargets.forEach( (image) => {
      image.classList.remove('border-4')
    })
    e.currentTarget.classList.add('border-4');
    this.submit()
  }


  submit() {
      const selected = document.querySelector('.border-4');
      const url = selected.dataset.url
      fetch(url)
        .then(response => response.blob())
        .then(blob => new File([blob], "slide-illustration.jpg", {
   		    type: blob.type
	      }))
        .then(file => {
          var list = new DataTransfer()
          list.items.add(file);
          const input = this.determineRighInput()
          input.files = list.files;
          if (this.type === "index") { input.dataset.new = true }
          input.dispatchEvent(new Event('change'));
          this.resetCloseandReload()
	      })
  }

  determineRighInput(){
    if (this.type === "index") {
     return this.inputfileTargets[this.inputIndex]
    } else {
      return this.element.querySelector(`.imageinput[data-slidex="${this.inputIndex}"]`)
    }
  }


  resetCloseandReload() {
    this.formTarget.reset();
    this.resultsTarget.innerHTML="";
    fadeHideElements(this.modalTarget);
    fadeShowElements(this.slidesPreviewTarget);
    this.body.style.overflow = this.overflowValue;
  }
}
