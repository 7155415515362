class Flashes {
  constructor(attributes) {
    this.container = attributes.container;
    this.startTimeOut();
    this.hasTimeOut = true;
    this.setBehavior();
    this.setChildrenBehavior();
  }

  setBehavior() {
    this.container.addEventListener("mouseover", () => {
      this.stopTimeout();
    });
    this.container.addEventListener("mouseleave", () => {
      this.startTimeOut();
    });
  }

  setChildrenBehavior() {
    this.container.querySelectorAll(".flash").forEach((flash) => {
      flash.querySelector(".flash-close").addEventListener("click", () => {
        this.remove(flash);
      });
    });
  }

  stopTimeout() {
    this.hasTimeOut = false;
    clearTimeout(this.timeout);
  }

  remove(element) {
    element.classList.replace("animate-appear", "disappear");
    setTimeout(() => {
      element.remove();
    }, 2000);
  }

  startTimeOut() {
    if (this.hasTimeOut) {
      return;
    }

    this.hasTimeOut = true;
    this.timeout = setTimeout(() => {
      this.remove(this.container);
    }, 3000);
  }
}

const flashesFadeOut = () => {
  setTimeout(() => {
    const flashesContainer = document.querySelector(".flashes-container");

    if (flashesContainer) {
      new Flashes({ container: flashesContainer });
    }
  }, 250);
};

export { flashesFadeOut };
