import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

global.Swal = Swal

const confirmed = (element, result) => {
  if (result.isConfirmed) {
    element.removeAttribute('data-confirm-swal');
    element.click();
  }
};

const showConfirmationDialog = (element) => {
  const title = element.getAttribute('data-confirm-swal-title');
  const message = element.getAttribute('data-confirm-swal');
  const icon = element.getAttribute('data-icon');
  const confirmation = element.getAttribute('data-confirmation');
  const color = element.getAttribute('data-color');

  Swal.fire({
    title: title || I18n.t('js.sweet_alerts.confirm.would_you_like_to_continue'),
    text: message || '',
    icon: icon || 'info',
    showCancelButton: true,
    confirmButtonColor: color || '#3d52d5',
    cancelButtonColor: '#CDD2DE',
    cancelButtonText: I18n.t('js.sweet_alerts.confirm.cancel'),
    confirmButtonText: confirmation || I18n.t('js.sweet_alerts.confirm.continue'),
  }).then(result => confirmed(element, result));
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true;
  }
  showConfirmationDialog(element);
  return false;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  } 
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);
