import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';


export default class extends Controller {

  static targets = [ 'confirmationModal', 'button', 'simpleCancel']
  static values = { mediaType: String, fetchWriteUrl: String, filledContent: Boolean }

  revealButtons() {
    if (this.hasSimpleCancelTarget) { hideElements(this.simpleCancelTarget) }
    showElements(this.buttonTargets)
  }

 // For all contents but video : we use the submitForm from dedicated media-creation controller
 // For video, we use our own form and own submitForm method to update content STATUS only.

  saveAsDraft(e) {
    const id = e.currentTarget.dataset.id
    this.element.querySelector(`#status_for_${id}`).value = "ongoing"
    const mediaController = this.application.getControllerForElementAndIdentifier(this.element.querySelector(`#form_for_${id}`), `media-creation--${this.mediaTypeValue.replace(/_/g, '-')}`)
    mediaController.submitForm()
    this.closeConfirmation()
  }

  saveAsDone(e) {
    const id = e.currentTarget.dataset.id
    this.element.querySelector(`#status_for_${id}`).value = "done"
    const mediaController = this.application.getControllerForElementAndIdentifier(this.element.querySelector(`#form_for_${id}`), `media-creation--${this.mediaTypeValue.replace(/_/g, '-')}`)
    mediaController.submitForm()
  }

  cancel() {
    showElements(this.confirmationModalTarget)
  }

  closeConfirmation() {
    hideElements(this.confirmationModalTarget)
  }


// for Video :
  draftNoSave(e) {
    const id = e.currentTarget.dataset.id
    this.element.querySelector(`#status_for_${id}`).value = "ongoing"
    const form = e.currentTarget.closest('form')
    this.submitForm(form)
    this.replaceCard(id)
  }

  doneNoSave(e) {
    const id = e.currentTarget.dataset.id
    this.element.querySelector(`#status_for_${id}`).value = "done"
    const form = e.currentTarget.closest('form')
    this.submitForm(form)
    // await this.replaceCard(id)

  }

  submitForm(form) {
    let formData = new FormData(form)
    fetchWithToken(form.action, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors.length > 0) {
        Swal.fire({
          title: `Une erreur est survenue`,
          text: `${data.errors.join(', ')}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5'
        })
      } else {
          if (data.status === "done") {
            this.#markAsDone()
          } else {
            this.#markAsOngoing()
          }
          const content = document.querySelector(`[data-content-id="${data.content_id}"]`)
          if (content) content.outerHTML = data.partial

      }
    })

  }

  replaceCard(id){
    const content = document.querySelector(`.writing-content[data-content-id="${id}"]`).querySelector('.wrapper')
    const url = this.fetchWriteUrlValue
    fetchWithToken(url, {
      method: "GET",
      headers: {"Accept": "application/json" },

    })
    .then(response => response.json())
    .then((data) => { 
      console.log(data.partial)
      content.innerHTML = data.partial 
    })

  }

  #markAsDone() {
    console.log('je suis dans markAsDone du submit_controller')
    console.log(this.element.closest('.writing-content'))
    if(this.element.closest('.writing-content')) {
      this.element.closest('.writing-content').classList.replace('todo', 'done')
      this.element.closest('.writing-content').classList.replace('ongoing', 'done')
      this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'done'
    }
    dispatchCustomEvent('contents:done')
  }

  #markAsOngoing() {
    if(this.element.closest('.writing-content')) {
      this.element.closest('.writing-content').classList.replace('todo', 'ongoing')
    this.element.closest('.writing-content').classList.replace('done', 'ongoing')
    this.element.closest('.writing-content').querySelector('[data-writing--status-target="status"]').dataset.status = 'ongoing'
    dispatchCustomEvent('contents:ongoing')
    }
  }

}
