import {Controller} from "stimulus"
import confetti from "canvas-confetti"

// inspired by stimulus-confetti (which works only on links, and is written in typescript)

export default class extends Controller {
  static targets = ["element"]
  static values = {
    // Type of animation basic|school-pride|stars
    animation: {
      type: String,
      default: 'basic'
    },

    duration: {
      type: Number,
      default: 5 // number of seconds
    },

    firstColor: {
      type: String,
      default: "#0886DE"
    },

    secondColor: {
      type: String,
      default: "#FF6154"
    },

    particleCount: {
      type: Number,
      default: 100
    },
    startVelocity: {
      type: Number,
      default: 20
    },
    spread: {
      type: Number,
      default: 360
    },
    ticks: {
      type: Number,
      default: 90
    },

    debug: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.clicked = false
    this.canvasElement = null

    // Create a canvas as big as the screen and make it fixed and ontop of everything
    const myCanvas = document.createElement("canvas")
    myCanvas.style.cssText = this.inlineStylesFormObject({
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      "pointer-events": "none",
      "z-index": "9999",
    })

    // Add it to the DOM
    document.body.appendChild(myCanvas)

    // Cache it
    this.canvasElement = myCanvas

    this.instance = confetti.create(this.canvasElement, {
      resize: true,
      useWorker: true,
    })
  }

  inlineStylesFormObject(styles = {})  {
    const resultAsArray = Object.keys(styles).map((property) => {
    const value = styles[property]

    return `${property}:${value}`
  })

  return resultAsArray.join(";")
}

  spray(e) {
    e.preventDefault()
    this.getStyle(e).then(() => {
      console.log("Finished confetti")
    })
  }

  getStyle(e) {
    switch (this.animationValue) {
      case 'basic':
      default:
        return this.basic(e)
      case 'school-pride':
        return this.schoolPride(e)
      case 'school-pride-right':
        return this.schoolPrideRight(e)
      case 'stars':
        return this.stars(e)
    }
  }

  basic(e) {
    return this.instance({
      particleCount: this.particleCountValue,
      startVelocity: this.startVelocityValue,
      spread: this.spreadValue,
      ticks: this.ticksValue,
      origin: {
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      },
    })
  }

  stars(e) {
    var defaults = {
      spread: this.spreadValue,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: this.startVelocityValue,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
      origin: {
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      },
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        this.instance({
          ...defaults,
          particleCount: this.particleCountValue,
          // scalar: 1.2,
          shapes: ['star']
        });
      }, 70);

      this.instance({
        ...defaults,
        particleCount: this.particleCountValue,
        // scalar: 0.75,
        shapes: ['star']
      }).then(() => {
        resolve()
      });
    })
  }

  schoolPride(e) {
    return new Promise((resolve) => {
      var end = Date.now() + (this.durationValue * 1000);
      var colors = [this.firstColorValue, this.secondColorValue];
      const vm = this;

      (function frame() {
        vm.instance({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors
        });
        vm.instance({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        } else {
          resolve()
        }
      })();
    })
  }

  schoolPrideRight(e) {
    return new Promise((resolve) => {
      var end = Date.now() + (this.durationValue * 1000);
      var colors = [this.firstColorValue, this.secondColorValue];
      const vm = this;

      (function frame() {
        vm.instance({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        } else {
          resolve()
        }
      })();
    })
  }
}
