import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {

  static targets = [ 'positionIndex' ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'quizz_answer',
      delayOnTouchOnly: 150,
      animation: 150,
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

    onEnd(e) {
      this.element.querySelectorAll('.answer-card:not(.hidden)').forEach((card, i) => {
        const input = card.querySelector('.answer-card-position-index')
        input.value = i + 1
        input.dispatchEvent(new Event('input', { 'bubbles': true }))
      })
    }
  }
