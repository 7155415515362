import { Controller }     from 'stimulus';
import { fetchWithToken } from '../../application/stimulus_helper';
import Rails              from "@rails/ujs"
import Bowser             from "bowser"
import { VideoUploader }  from '@api.video/video-uploader' // https://docs.api.video/docs/video-uploader
import { ApiVideoMediaRecorder } from '@api.video/media-recorder' // https://docs.api.video/docs/media-recorder
import { MediaStreamComposer } from '@api.video/media-stream-composer' // https://docs.api.video/sdks/vod/apivideo-typescript-media-stream-composer
import * as tf            from '@tensorflow/tfjs'
import * as bodyPix       from '@tensorflow-models/body-pix'
import JSConfetti from 'js-confetti'


export default class extends Controller {
  static targets = [
    'timer',
    'mediaChoice',
    'video',
    'streamVideoTag',
    'streamVideoTagContainer',
    'startRecordingCountdown',
    'recordingDisplay',
    'recordingAvailable',
    'countdown',
    'recordedVideoTagContainer',
    'recordedVideoTag',
    'recordedVideoDisplay',
    'videoTag',
    'playButton',
    'pauseButton',
    'elapsedVideoTime',
    'totalVideoTime',
    'videoProgressBar',
    'videoProgressHandle',
    'videoProgressHandleTooltip',
    'attachedVideoTag',
    'videoForm',
    'videoInput',
    'cameraChoices',
    'audioChoices',
    'fakeVideoTag',
    'trimCutSlider',
    'apiVideoInfosInput',
    'videoUploadingLoadingNotice',
    'processingNotice',
    'duplicationButton',
    'deleteButton',
    'controls',
    'cutButton',
    'waitCutIcon',
    'cutterIcon',
    'streamCanvasTag',
    'blurButton',
    'uploadBackgroundImageButton',
    'backgroundImageInput',
    'noEffectButton',
    'effectsButton',
    'composedCanvasContainer',
    'lastUsedBackgroundImageContainer',
    'moveCameraTooltip',
    'videoPartActions',
  ]

  static values = {
    id: Number,
    contentId: Number,
    maxVideoDuration: Number,
    videoAttached: Boolean,
    attachedVideoDuration: Number,
    skipUploadLimit: { type: Boolean, default: false }
  }

  async connect() {
    // Ensure TensorFlow.js is ready
    if (tf && !tf.engine().backend) {
      tf.backend().set('webgl');
    }
    this._defineBrowser()
    this._setDefaultEffects() // state of the effects
    this._setVideoPartsController() // expose videoPartsController used to mark as done the content
    this._basicCameraConstraints()
    this._basicScreenConstraints()
    this._fillVideoBackgroundImageFromLocalStorage()
    this.timer = new Timer({
      target: this.timerTarget,
      duration: this.maxVideoDurationValue,
      onEnd: this.stopRecording.bind(this)
    })
    this.displayDevicesChoice()
    this.contentCard = this.element.closest('.writing-content')
    this.net = await bodyPix.load()
  }

  // ########################################################### INIT PROCESS ###########################################################

  _defineBrowser() {
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName()
    this.videoContainer = browser == 'Safari' ? 'video/mp4' : 'video/webm'
    this.videoExtension = browser == 'Safari' ? 'mp4' : 'webm'
  }

  _setDefaultEffects() {
    this.noEffects = true
    this.blurOngoing = false
    this.backgroundImageEffect = false
  }

  _setVideoPartsController() {
    this.videoPartsController = this.application.getControllerForElementAndIdentifier(this.element.closest('.writing-modal-content-video'), 'video--video-parts')
  }

  _basicCameraConstraints() {
    this.cameraConstraints = {
      audio: true,
      video: {
        width: 1920,
        height: 1080,
        facingMode: 'user',
        resizeMode: 'crop-and-scale',
        ratio: 1.7777777778
      }
    }
  }

  _basicScreenConstraints() {
    this.screenConstraints = {
      display: {
        video: {
          width: 1920,
          height: 1080,
          cursor: "always",
          resizeMode: 'crop-and-scale'
        }
      },
      user: {
        audio: true,
        video: false
      }
    }
  }

  _fillVideoBackgroundImageFromLocalStorage() {
    Object.keys(localStorage).filter(key => key.includes('backgroundImage-')).forEach((key) => {
      const backgroundImage = localStorage.getItem(key)
      const backgroundImageContainer = this.lastUsedBackgroundImageContainerTarget
      // We clone the element
      const backgroundImageElementContainer = backgroundImageContainer.cloneNode(true);
      // We set the image
      backgroundImageElementContainer.querySelector('img').src = "data:image/png;base64," + backgroundImage;
      // We remove the hidden class
      backgroundImageElementContainer.classList.remove('hidden');
      // We add the element to the DOM
      backgroundImageContainer.insertAdjacentElement('afterend', backgroundImageElementContainer);
    })
  }

  displayDevicesChoice() {
    navigator.mediaDevices.enumerateDevices().then(this.getDevicesList.bind(this));
  }

  getDevicesList(mediaDevices) {
    const cameraDropdown = this.cameraChoicesTarget
    const audioDropdown = this.audioChoicesTarget
    cameraDropdown.innerHTML = ''
    audioDropdown.innerHTML = ''

    let cameraCount = 1
    let audioCount = 1
    mediaDevices.forEach(mediaDevice => {
      const option = document.createElement('span')
      option.classList.add('whitespace-nowrap', 'inline-block', 'py-1', 'px-2', 'text-sm', 'hover:bg-komin-blue-light', 'cursor-pointer', 'transition', 'rounded-lg', 'w-full')
      option.setAttribute('data-device', mediaDevice.deviceId)
      if (mediaDevice.kind === 'videoinput') {
        option.setAttribute('data-action', 'click->video--recording#changeCameraDevice click->dropdown#hide')
        const deviceName = mediaDevice.label || `Camera ${cameraCount++}`
        option.insertAdjacentHTML('afterbegin', `<span class="device-check">✓</span>${deviceName}`)
        cameraDropdown.appendChild(option)
      } else if (mediaDevice.kind === 'audioinput') {
        option.setAttribute('data-action', 'click->video--recording#changeAudioDevice click->dropdown#hide')
        const deviceName = mediaDevice.label || `Micro ${audioCount++}`
        option.insertAdjacentHTML('afterbegin', `<span class="device-check">✓</span>${deviceName}`)
        audioDropdown.appendChild(option)
      }
    });
  }

  disconnect() {
    localStorage.removeItem(`videoPartBlobUrl-${this.idValue}`)
    this._stopBodySegmentationAndBlurring()
    this._stopStream()
  }
  // ########################################################### INIT PROCESS END ############################################################
  // #########################################################################################################################################
  // ########################################################### Type of Recording Selection #####################################################################

  selectSource(event) {
    this.reset()
    this.source = event.currentTarget.dataset.mediaType
    this._startStream(this.source)
    hideElements(this.mediaChoiceTarget)
    showElements(this.videoTarget)
    showElements(this.videoPartActionsTarget)
  }

  _startStream(device) {
    if (this.#apiVideoDelegatedToken() === '') {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.recording.start_stream.title'),
        text: I18n.t('js.sweet_alerts.recording.start_stream.text'),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        confirmButtonText: 'Ok',
      })
      return
    }
    this._navigatorUserMedia()
    if (device == 'camera') {
      this._startAndDisplayCameraStream(this.cameraConstraints)
    } else if (device == 'screen') {
      this._startAndDisplayScreenStream(this.screenConstraints)
    } else if (device == 'cameraAndScreen') {
      this._startAndDisplayCameraAndScreenStream(this.screenConstraints)
    }
  }

  _startAndDisplayCameraStream(constraints) {
    navigator.mediaDevices.getUserMedia(constraints)
      .then((e) => {
        this.stream = e
        this._displayVideoPreview({camera: true})
      }).catch((err) => {
        this._streamError('Video/Audio', err)
      });
  }

  _startAndDisplayScreenStream(constraints) {
    navigator.mediaDevices.getUserMedia(constraints.user)
      .then((audioStream) => {
        navigator.mediaDevices.getDisplayMedia({ video: constraints.video })
          .then((screenStream) => {
            this.stream = new MediaStream([...screenStream.getTracks(), ...audioStream.getTracks()])
            this._displayVideoPreview({camera: false})
          }).catch((err) => {
            if (err.name == 'InvalidAccessError') {
              this._retryStartAndStreamVideoDevice(constraints, audioStream)
            } else {
              this._streamError('Screen Video', err)
            }
          })
      }).catch((err) => {  this._streamError('Audio', err)  })
  }

  _startAndDisplayCameraAndScreenStream(constraints) {
    hideElements(this.fakeVideoTagTarget)
    fadeShowElements(this.streamVideoTagContainerTarget, this.recordingAvailableTarget, this.moveCameraTooltipTarget)
    hideElements(this.effectsButtonTarget)

    const canvasContainer = this.composedCanvasContainerTarget;

    fadeShowElements(canvasContainer)
    const width = 1920;
    const height = 1080;

    canvasContainer.style.width = `100%`;
    canvasContainer.style.height = `100%`;

    (async () => {
      const screencast = await navigator.mediaDevices.getDisplayMedia({ video: constraints.video });
      const webcam = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
      });
      // To avoid loosing the audio of the webcam
      this.stream = new MediaStream([...webcam.getTracks()])

      // create the media stream composer instance
      this.mediaStreamComposer = new MediaStreamComposer({
          resolution: {
              width,
              height
          }
      });

      // add the screencast stream
      this.mediaStreamComposer.addStream(screencast, {
          position: "contain",
          mute: true,
      });

      // add the webcam stream in the lower left corner, with a circle mask
      this.mediaStreamComposer.addStream(webcam, {
        position: "fixed",
        mute: false,
        x: 50,
        y: height - 320,
        height: 300,
        mask: "circle",
        draggable: true,
        resizable: true,
        onClick: () => {
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti({
            emojis: ["🎉", "🎊", "🦄", "🌈"],
            confettiNumber: 100,
            confettiRadius: 5,
            confettiColors: ["#3d52d5", "#f43f5e", "#ffcc29"],
          });
        },
      });

      // display the canvas (preview purpose)
      this.composingCameraStream = true
      this.mediaStreamComposer.appendCanvasTo(`#${canvasContainer.id}`);
      this.canvasComposedStream = canvasContainer.querySelector('canvas')
      this.canvasComposedStream.style.height = `100%`;
      this.canvasComposedStream.style.width = `100%`;
    })();

  }

  _displayVideoPreview({camera}) {
    this.streamVideoTagTarget.srcObject = this.stream
    this.streamVideoTagTarget.setAttribute("playsinline", true)
    if (this.hasAttachedVideoTagTarget) { hideElements(this.attachedVideoTagTarget) }
    this.streamVideoTagTarget.play()
    this.streamVideoTagTarget.addEventListener('play', (e) => {
      this._highlightCurrentUsedDevices()
      hideElements(this.fakeVideoTagTarget)
      fadeShowElements(this.streamVideoTagTarget, this.streamVideoTagContainerTarget, this.streamVideoTagContainerTarget, this.recordingAvailableTarget)
      if (!camera) hideElements(this.effectsButtonTarget)


    }, { once: true })
  }

  // ########################################################### Type of Recording Selection END ###########################################################
  // #######################################################################################################################################################
  // ########################################################### RECORDING #################################################################################
  startRecording() {
    if (!this.stream && !this.stream.active) return // stream has been defined by SelectSource()

    hideElements(this.recordingAvailableTarget)
    showElements(this.startRecordingCountdownTarget)

    var count = 2
    this.recordCountdown = setInterval(() => {
      this.countdownTarget.innerText = count
      count -= 1
    }, 1000)
    this.startRecordTimeout = setTimeout(() => {
      clearInterval(this.recordCountdown)
      this._record()
    }, 3020)
  }

  _record() {
    const targetStream = this._getRecordingStream();

    // We disable the modal buttons to avoid quitting the modal while recording
    
    hideElements(this.startRecordingCountdownTarget, this.duplicationButtonTarget, this.deleteButtonTarget);
    showElements(this.recordingDisplayTarget);

    this.mediaRecorder = new ApiVideoMediaRecorder(targetStream, {
        audioBitsPerSecond: 128_000,
        videoBitsPerSecond: 1_500_000,
        uploadToken: this.#apiVideoDelegatedToken(),
        retries: 10,
    });

    this.timer.start();
    this.mediaRecorder.start({ timeslice: 5000 });

    this.clientMediaRecorder = new MediaRecorder(targetStream, {
        audioBitsPerSecond: 128_000,
        videoBitsPerSecond: 1_500_000,
        mimeType: this.videoContainer
    });

    this.dataChunks = [];
    this.clientMediaRecorder.start(this.timer.precision);
    this.clientMediaRecorder.ondataavailable = e => this.dataChunks.push(e.data);
  }

  _getRecordingStream() {
    if (this.streamCanvasTagTarget && (this.blurOngoing || this.backgroundImageEffect)) {
        this.canvasStream = this.streamCanvasTagTarget.captureStream(15);  // 15 fps because of the bodyPix segmentation of the stream
        const audioTrack = this.stream.getAudioTracks()[0];
        this.canvasStream.addTrack(audioTrack);
        return this.canvasStream;
    } else if (this.composingCameraStream) {
      this.streamComposed = this.canvasComposedStream.captureStream(15);
      const audioTrack = this.stream.getAudioTracks()[0];
      this.streamComposed.addTrack(audioTrack);
      return this.streamComposed;
    }
    return this.stream;
  }

  // ########################################################### RECORDING END ###################################################################
  // #############################################################################################################################################
  // ########################################################### MANUAL UPLOAD ###################################################################

  manualUpload() {
    this.videoInputTarget.click()

    this.videoInputTarget.addEventListener('input', (e) => {
      const videoFile = this.videoInputTarget.files[0]
      const sizeLimit = this.skipUploadLimitValue ? 524_288_000 : 1_073_741_824
      if (videoFile.type.match(/video\//) && videoFile.size <= sizeLimit) {
        const url = URL.createObjectURL(videoFile)

        // this is needed so the video/controls_controller.js can retrieve the blob's duration
        localStorage.setItem(`videoPartBlobUrl-${this.idValue}`, url)

        this.reset()

        hideElements(this.mediaChoiceTarget, this.fakeVideoTagTarget, this.duplicationButtonTarget, this.deleteButtonTarget)
        fadeShowElements(this.videoUploadingLoadingNoticeTarget)
        this._displayRecordedVideoPreview(url)

        const notice = this.videoUploadingLoadingNoticeTarget.querySelector('p')
        notice.innerText = `${notice.dataset.prefix}...`

        this.videoUploader = new VideoUploader({
          file: this.videoInputTarget.files[0],
          uploadToken: this.#apiVideoDelegatedToken(),
          chunkSize: 1024*1024*5, // 5MB minimum
          retries: 10,
        })

        this.videoUploader.onProgress((event) => {
          const percentage = Math.round((event.uploadedBytes / event.totalBytes) * 100)
          notice.innerText = `${notice.dataset.prefix} ${percentage} %`
        });

        this.videoUploader.upload()
          .then((video) => {
            this.apiVideoObject = video
            this._attachApiVideoId('upload')
            showElements(this.duplicationButtonTarget, this.deleteButtonTarget)
            this.revealButtons()
          })
          .catch((error) => console.warn(error.status, error.message));
      } else {
        const message = videoFile.type.match(/video\//) ? 'not be larger than 500 MB' : 'be a video'
        Swal.fire({
          title: `File ERROR`,
          text: `File should ${message}`,
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          confirmButtonText: 'Ok',
        })
      }
    }, { once: true })
  }

  // ########################################################### MANUAL UPLOAD END ###############################################################
  // #############################################################################################################################################
  // ########################################################### STOP RECORDING && RESET ##################################################################

  stopRecording() {
    this._restoreStateAfterRecording()

    showElements(this.duplicationButtonTarget, this.deleteButtonTarget)

    if (this.mediaRecorder && this.mediaRecorder.getMediaRecorderState() == 'recording') {
      this.clientMediaRecorder.stop()

      // this is needed so the video/controls_controller.js can retrieve the blob's duration
      const recordedBlob = this._buildBlob()
      const blobUrl      = URL.createObjectURL(recordedBlob)
      localStorage.setItem(`videoPartBlobUrl-${this.idValue}`, blobUrl)

      hideElements(this.streamVideoTagTarget, this.streamVideoTagContainerTarget, this.recordingDisplayTarget)
      fadeShowElements(this.videoUploadingLoadingNoticeTarget)
      this._displayRecordedVideoPreview(blobUrl)

      const notice = this.videoUploadingLoadingNoticeTarget.querySelector('p')
      notice.innerText = `${notice.dataset.prefix}`

      this.mediaRecorder.stop().then((apiVideoObject) => {
        this.apiVideoObject = apiVideoObject
        fadeShowElements(this.videoUploadingLoadingNoticeTarget)
        this._attachApiVideoId('record')
      })
      this.timer.reset()
      this._stopStream()
      this.revealButtons()
      this.markAsDone()
    }
  }

  reset() {
    this.resetStreamError()
    if (this.timer) this.timer.reset()
    this._stopStream()
    this._stopBodySegmentationAndBlurring()
    this.removeBackgroundImageEffect()
    this.stopRecording()
    this.streamVideoTagTarget.src = ''
    this.recordedVideoTagTarget.src = ''
    if (this.hasAttachedVideoTagTarget) { this.attachedVideoTagTarget.pause() }
    if (this.startRecordTimeout) { clearTimeout(this.startRecordTimeout) }
    if (this.recordCountdown) { clearInterval(this.recordCountdown) }
    if (!this.videoAttachedValue) {
      showElements(this.fakeVideoTagTarget)
    }
    this.countdownTarget.innerText = 3
    hideElements(
      this.videoTarget,
      this.startRecordingCountdownTarget,
      this.recordedVideoTagContainerTarget,
      this.startRecordingCountdownTarget,
      this.recordingDisplayTarget,
      this.streamVideoTagTarget,
      this.streamVideoTagContainerTarget,
      this.recordingAvailableTarget,
      this.recordedVideoDisplayTarget,
      this.composedCanvasContainerTarget,
      this.streamCanvasTagTarget,
    )
    showElements(
      this.mediaChoiceTarget,
      this.effectsButtonTarget,
    )
  }

  // ########################################################### STOP RECORDING && RESET END ###########################################################
  // ###################################################################################################################################################
  // ########################################################### VIDEO TOOLS / Basics ###########################################################################
  changeCameraDevice(event) {
    this._stopStream()
    const deviceId = event.currentTarget.dataset.device
    if (this.source == 'camera') {
      this.cameraConstraints.video.deviceId = { exact: deviceId }
      this._startAndDisplayCameraStream(this.cameraConstraints)
    } else if (this.source == 'screen') {
      this._startStream('camera')
    }
  }

  changeAudioDevice(event) {
    this._stopStream()
    const deviceId = event.currentTarget.dataset.device
    if (this.source == 'camera') {
      this.cameraConstraints.audio = { deviceId: { exact: deviceId } }
      this._startAndDisplayCameraStream(this.cameraConstraints)
    } else if (this.source == 'screen') {
      this.screenConstraints.user.audio = { deviceId: { exact: deviceId } }
      this._startAndDisplayScreenStream(this.screenConstraints)
    }
  }

  // ########################################################### VIDEO TOOLS / Effects ####################################################################

  removeEffects() {
    this.noEffects = true
    this._adjustEffectButtonsState({active: this.noEffectButtonTarget, inactives: [this.blurButtonTarget, this.uploadBackgroundImageButtonTarget]})
    if (this.blurOngoing) this.removeBluring()
    if (this.backgroundImageEffect) this.removeBackgroundImageEffect()
    this.streamVideoTagTarget.classList.remove('hidden')
  }

  addBluring() {
    if (this.blurOngoing) return
    if (this.backgroundImageEffect) this.removeBackgroundImageEffect()

    this.blurOngoing = true
    this.noEffects = false
    this._startBodySegmentationAndBlurring()
    if (this.streamCanvasTagTarget) this.streamCanvasTagTarget.classList.remove('hidden')
    fadeHideElements(this.streamVideoTagTarget)
    const blurButton = this.blurButtonTarget
    this._adjustEffectButtonsState({active: blurButton, inactives: [this.noEffectButtonTarget, this.uploadBackgroundImageButtonTarget]})
  }

  removeBluring() {
    if (!this.blurOngoing) return
    this._stopBodySegmentationAndBlurring()
    if (this.streamCanvasTagTarget) this.streamCanvasTagTarget.classList.add('hidden')
    fadeShowElements(this.streamVideoTagTarget)
    this.blurOngoing = false
  }

  uploadBackgroundImage() {
    if (this.backgroundImageEffect) return
    if (this.blurOngoing) this.removeBluring()
    this._adjustEffectButtonsState({active: this.uploadBackgroundImageButtonTarget, inactives: [this.noEffectButtonTarget, this.blurButtonTarget]})
    this.backgroundImageEffect = true

    // We set the input to accept only images
    this.backgroundImageInputTarget.accept = "image/*";
    this.backgroundImageInputTarget.multiple = false;
    this.backgroundImageInputTarget.setAttribute('accept', '.jpg, .jpeg, .png');


    // We click on the input
    this.backgroundImageInputTarget.click();

    // We listen to the change event
    this.backgroundImageInputTarget.addEventListener('input', (e) => {
      e.stopPropagation(); // stop the event from propagating

      if (this.streamCanvasTagTarget) this.streamCanvasTagTarget.classList.remove('hidden')
      this.backgroundImage = new Image();
      this.backgroundImage.src = URL.createObjectURL(e.target.files[0]);
      this.backgroundImage.onload = () => {
          // Start the segmentation with the new background
          setTimeout(() => {
            this._startBodySegmentationWithBackgroundReplacement();
            this._processUploadedBackgroundImage() // update DOM & store image in localStorage for later use
          }, 500);
      };
    });
  }

  useBackgroundImage(event) {
    if (this.backgroundImageEffect) {
      this.removeBackgroundImageEffect()
    }
    if (this.blurOngoing) this.removeBluring()
    this.backgroundImageEffect = true
    if (this.streamCanvasTagTarget) this.streamCanvasTagTarget.classList.remove('hidden')
    const backgroundImage = event.currentTarget.querySelector('img')
    this.backgroundImage = new Image();
    this.backgroundImage.crossOrigin = "anonymous"; // Important! Otherwise, CORS error crazy stuff happens
    this.backgroundImage.src = backgroundImage.src;
    setTimeout(() => {
      this._startBodySegmentationWithBackgroundReplacement();
    }, 500);
  }

  removeBackgroundImageEffect() {
    if (!this.backgroundImageEffect) return

    this._stopBodySegmentationWithBackgroundReplacement()
    this.backgroundImage = null
    this.backgroundImageEffect = false
    if (this.streamCanvasTagTarget) this.streamCanvasTagTarget.classList.add('hidden')

    this._adjustEffectButtonsState({active: this.noEffectButtonTarget, inactives: [this.blurButtonTarget, this.uploadBackgroundImageButtonTarget]})
  }

  _adjustEffectButtonsState({active: activeButton, inactives: inactiveButtons}) {
    activeButton.classList.add('bg-gray-500', 'text-white')
    activeButton.classList.remove('bg-white', 'text-black')
    inactiveButtons.forEach((button) => {
      button.classList.remove('bg-gray-500', 'text-white')
      button.classList.add('bg-white', 'text-black')
    })
  }

  // ########################################################### EFFECTS END ##############################################################################
  // ######################################################################################################################################################
  // ########################################################### SEGMENTATION #############################################################################

  _startBodySegmentationAndBlurring() {
    this.segmentationInterval = setInterval(async () => {
      // const segmentation = await this.net.segmentPerson(this.streamVideoTagTarget, {
      //   internalResolution: 'low', // Defaults to medium. The internal resolution percentage that the input is resized to before inference. The larger the internalResolution the more accurate the model at the cost of slower prediction times. Available values are low, medium, high, full, or a percentage value between 0 and 1. The values low, medium, high, and full map to 0.25, 0.5, 0.75, and 1.0 correspondingly.
      //   segmentationThreshold: 0.7, // Defaults to 0.7. Must be between 0 and 1. For each pixel, the model estimates a score between 0 and 1 that indicates how confident it is that part of a person is displayed in that pixel. This segmentationThreshold is used to convert these values to binary 0 or 1s by determining the minimum value a pixel's score must have to be considered part of a person. In essence, a higher value will create a tighter crop around a person but may result in some pixels being that are part of a person being excluded from the returned segmentation mask.
      //   segmentBodyParts: true, // Required. If set to true, then 24 body parts are segmented in the output, otherwise only foreground / background binary segmentation is performed.
      //   multiSegmentation: false, // Required. If set to true, then each person is segmented in a separate output, otherwise all people are segmented together in one segmentation.
      //   maxDetections: 1, // Defaults to 10. The maximum number of people to estimate poses for.
      // });
      const segmentation = await this.net.segmentPerson(this.streamVideoTagTarget)
      const backgroundBlurAmount = 6;
      const edgeBlurAmount = 0.5;
      const flipHorizontal = false;
      bodyPix.drawBokehEffect(
          this.streamCanvasTagTarget,
          this.streamVideoTagTarget,
          segmentation,
          backgroundBlurAmount,
          edgeBlurAmount,
          flipHorizontal
      );
    }, 66);
  }

  _stopBodySegmentationAndBlurring() {
    if (this.segmentationInterval) {
        clearInterval(this.segmentationInterval);
    }
  }
  _startBodySegmentationWithBackgroundReplacement() {
    hideElements(this.streamVideoTagTarget);

    if (this.streamCanvasTagTarget.classList.contains('hidden')) {
        this.streamCanvasTagTarget.classList.remove('hidden');
    }

    const canvas = this.streamCanvasTagTarget;
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    const video = this.streamVideoTagTarget;
    const backgroundImage = this.backgroundImage;

    video.width = canvas.width;
    video.height = canvas.height;

    this.segmentationInterval = setInterval(async () => {

        // Get the segmentation mask
        // const segmentation = await this.net.segmentPerson(video, {
        //     internalResolution: 'low',
        //     segmentationThreshold: 0.7,
        //     segmentBodyParts: true,
        //     multiSegmentation: false,
        //     maxDetections: 1,
        // });
        const segmentation = await this.net.segmentPerson(video);

        const maskImageData = bodyPix.toMask(segmentation, { r: 0, g: 255, b: 0, a: 255 }, { r: 0, g: 0, b: 0, a: 0 });

        // Clear the canvas and Draw the mask
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.putImageData(maskImageData, 0, 0);

        // Use source-in to draw the video only where the mask is present
        ctx.globalCompositeOperation = 'source-in';
        ctx.drawImage(video, 0, 0, video.width, video.height);

        // Use destination-over to draw the background behind the video
        ctx.globalCompositeOperation = 'destination-over';
        ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

        // Reset to default composite operation
        ctx.globalCompositeOperation = 'source-over';

    }, 66);
}


  _stopBodySegmentationWithBackgroundReplacement() {
    if (this.segmentationInterval) {
        clearInterval(this.segmentationInterval);
    }
  }

  // ########################################################### SEGMENTATION END ###########################################################
  // ########################################################################################################################################
  // ########################################################### ATTACH TO API VIDEO ############################################################
  _attachApiVideoId(source) {
    const videoId = this.apiVideoObject["videoId"]
    const url     = new URL(`${window.location.origin}/video_attach`)
    const data    = new FormData()

    data.append('id', this.idValue)
    data.append('video_part[api_video_id]', videoId)
    data.append('source', source)

    const options = {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: data
    }

    fetchWithToken(url, options)
    .then(response => response.json())
    .then((data) => {
      if (data.errors > 1) {
        Swal.fire({
          title: `${type} ERROR`,
          text: data.errors.join(', '),
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          confirmButtonText: 'Ok',
        })
      } else {
        this._manageProccessing(data)
      }
    })
  }

  _manageProccessing(data) {
    fadeShowElements(this.processingNoticeTarget)
    fadeHideElements(this.recordedVideoDisplayTarget)

    this.videoAttachedValue = true
    this._displayVideoTrimCut()

    document.querySelector('body').addEventListener(`videoPartProcessing${data.video_part_id}:end`, (e) => {
      const thumbnail = document.querySelector(`.video-part-thumbnail-${this.idValue}`) // Todo, change to closest after merge of write redesign
      const card      = thumbnail.closest('.video-part-card')

      thumbnail.src   = e.detail.thumbnail_url

      card.classList.remove('missing')
      card.classList.add('filled')

      fadeHideElements(this.processingNoticeTarget)
      fadeShowElements(this.duplicationButtonTarget, this.deleteButtonTarget)
    }, { once: true })
  }

  // ######################################################### ATTACH TO API VIDEO END ###########################################################
  // ############################################################################################################################################
  // ######################################################### TRIM CUT #######################################################################

  _displayVideoTrimCut() {
    showElements(this.cutButtonTarget)
  }

  cut(event) {
    const el        = event.currentTarget
    el.disabled     = true
    const id        = event.params.videoPartId
    let formData    = new FormData()
    const timestamp = event.target.closest('.video-cutter').dataset.timestamp
    formData.append('video_part[cut_value]', timestamp)
    showElements(this.waitCutIconTarget)
    hideElements(this.cutterIconTarget)

    fetchWithToken(`/video_parts/${id}/cut`, {
      method: "POST",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      el.disabled = false
      if (!data.cut) {
        hideElements(this.waitCutIconTarget)
        showElements(this.cutterIconTarget)
        Swal.fire({
          title: `Error`,
          text: data.errors,
          icon: 'warning',
          confirmButtonColor: '#3d52d5',
          confirmButtonText: 'Ok',
        })
      }
    })
  }
  // ######################################################### TRIM CUT END ###################################################################
  // ############################################################################################################################################
  // ######################################################### HELPERS ###########################################################
  _toggleDisabledModalButtons({ disabled }) {
    const modalSavingButtons = document.querySelectorAll(`button[data-id="${this.contentIdValue}"]`)

    modalSavingButtons.forEach((button) => {
      button.disabled = disabled
    })
  }

  _navigatorUserMedia() {
    navigator.getUserMedia = (navigator.mediaDevices.getUserMedia     ||
                              navigator.mediaDevices.mozGetUserMedia  ||
                              navigator.mediaDevices.msGetUserMedia   ||
                              navigator.mediaDevices.webkitGetUserMedia)
  }

  _highlightCurrentUsedDevices() {
    Array.from(this.cameraChoicesTarget.children).forEach(e => e.classList.remove('stream-device-active'))
    Array.from(this.audioChoicesTarget.children).forEach(e => e.classList.remove('stream-device-active'))
    const usedDevicesIds = this.stream.getTracks().map(track => track.getSettings().deviceId)
    usedDevicesIds.forEach((deviceId) => {
      const device = this.recordingAvailableTarget.querySelector(`[data-device="${deviceId}"]`)
      if (device) device.classList.add('stream-device-active')
    })
  }

  _retryStartAndStreamVideoDevice(constraints, audioStream) {
    const title = this.fakeVideoTagTarget.querySelector('p')
    const retryBtn = this.fakeVideoTagTarget.querySelector('.authorizeBtn')
    const resetBtn = this.fakeVideoTagTarget.querySelector('.resetBtn')

    hideElements(resetBtn)
    fadeShowElements(retryBtn)

    title.innerText = I18n.t('js.sweet_alerts.recording.autorise_access_to_video')
    retryBtn.addEventListener('click', (event) => {
      hideElements(retryBtn)
      fadeShowElements(resetBtn)
      navigator.mediaDevices.getDisplayMedia({ video: constraints.video })
        .then((screenStream) => {
          this.stream = new MediaStream([...screenStream.getTracks(), ...audioStream.getTracks()])
          this._displayVideoPreview()

        }).catch((err) => {
          console.warn('Error retrying to get access to screen video stream - ' + err.name + ": " + err.message, 'streams:', audioStream)
          this._streamError('Screen Video', err)
        })
    })
  }

  #apiVideoDelegatedToken() {
    const metaTag = document.querySelector('[name="api-video-delegated-token"]')
    return metaTag ? metaTag.content : ''
  }

  _displayRecordedVideoPreview(url) {
    showElements(this.videoTarget, this.recordedVideoTagContainerTarget, this.recordedVideoDisplayTarget)
    this.recordedVideoTagTarget.src = url
  }

  _restoreStateAfterRecording() {
    if (this.blurOngoing) {
      this.blurOngoing = false
    } else if (this.backgroundImageEffect) {
      this.backgroundImageEffect = false
    } else if (this.composingCameraStream) {
      this.composingCameraStream = false
    }
  }

  _buildBlob() {
    return new Blob(this.dataChunks, { type: this.videoContainer });
  }

  _buildFile(blob) {
    return new File([blob], `${new Date().getTime()}.mp4`, { type: 'video/mp4', lastModified:new Date().getTime() });
  }

  _stopStream() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());

    }
    if (this.canvasStream) {
      this.canvasStream.getTracks().forEach(track => track.stop());
    }
    if (this.streamComposed) this.streamComposed.getTracks().forEach(track => track.stop());
    if (this.mediaStreamComposer) this.mediaStreamComposer.destroy();
    if (this.composedCanvasContainerTarget) this.composedCanvasContainerTarget.innerHTML = '';
    this._toggleDisabledModalButtons({ disabled: false })
  }

  // We update the dom with the new background image
  _processUploadedBackgroundImage() {
    this.lastUsedBackgroundImageContainerTarget.classList.remove('hidden')
    this.lastUsedBackgroundImageContainerTarget.querySelector('img').src = this.backgroundImage.src
    // We store the image in the local storage with a timestamp as a Base64 string
    const timestamp = Date.now()
    // We remove the oldest image if there are more than 3 stored
    const storedBackgroundImages = Object.keys(localStorage).filter(key => key.includes('backgroundImage-'))
    if (storedBackgroundImages.length > 3) {
      const oldestImageKey = storedBackgroundImages.sort()[0]
      localStorage.removeItem(oldestImageKey)
    }
    const imageData = getBase64Image(this.backgroundImage)
    try {
      localStorage.setItem(`backgroundImage-${timestamp}`, imageData)
    } catch (e) {
      console.warn('Local storage is full, cannot store more images')

    }
  }

  revealButtons() {
    this.application.getControllerForElementAndIdentifier(this.element.closest('.content-writing-modal'), 'media-creation--submit').revealButtons()
  }

  markAsDone() {
    this.contentCard.classList.replace('todo', 'done')
    dispatchCustomEvent('contents:done')
  }

  // ######################################################### HELPERS END ###########################################################
  // #################################################################################################################################
  // ######################################################### ERRORS ################################################################
  _streamError(type, err) {
    console.warn('Error getting access to ' + type + ' stream - ' + err.name + ": " + err.message, 'constraints:', this.cameraConstraints)
    const title = this.fakeVideoTagTarget.querySelector('p')
    Swal.fire({
      title: `${type} ERROR`,
      text: err.name + ": " + err.message,
      icon: 'warning',
      confirmButtonColor: '#3d52d5',
      confirmButtonText: 'Ok',
    })
    this.fakeVideoTagTarget.querySelector('p').innerText = `${type} ERROR`
  }

  resetStreamError() {
    const title = this.fakeVideoTagTarget.querySelector('p')

    title.innerText = title.dataset.text
  }
}
