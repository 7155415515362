import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ ]

  duplicate(event) {
    const target = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    this._toggleCta(target)

    const chapterId = target.dataset.id
    const stepId = target.dataset.stepId
    Rails.ajax({
      url: `/chapters/${chapterId}/duplication`,
      type: "post",
      dataType: "json",
      data: `[step_id]=${stepId}`,
      success: function(data) {
        this._toggleCta(target)
        this.element.insertAdjacentHTML('beforeend', data.partial)
        this.element.lastElementChild.classList.add('notice-new-element')
         window.scrollTo({
          top: this.element.lastElementChild.offsetTop - window.innerHeight / 4,
          behavior: 'smooth'
        })
      }.bind(this),
      error: function(data) {
        this._toggleCta(target)
      }
    })
  }

  _toggleCta(target) {
    target.parentElement.querySelector('img').classList.toggle('hidden')
    target.classList.toggle('hidden')
  }
}
