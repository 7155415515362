import { Controller } from 'stimulus';

// Exemple:
// <tr data-controller="clickable-table-row"
//     data-clickable-table-row-url-value="<%= a_path %>">
//   <td>...</td>
//   <td data-clickable-table-row-target="noFollow">...</td>
// </tr>
// 
// We use the noFollowTarget to prevent the click event to be triggered when the user click on the td with the noFollowTarget

export default class extends Controller {
  static targets = ['noFollow']

  static values = {
    url: String,
  }

  connect() {
    this.element.addEventListener('click', this.navigate.bind(this))
  }    

  navigate(event) {
   if (this.hasNoFollowTarget && this.noFollowTarget.contains(event.target)) { return }
   if (this.noFollowTargets.some(target => target.contains(event.target))) { return } // if many noFollowTarget, we check if one of them contains the event.target
   
   window.location.href = this.urlValue

  }
}
