import { csrfToken } from "@rails/ujs";
import Swal from 'sweetalert2';

function showElements() {
  Array.from(arguments).flat().forEach((e) => {
    e.classList.remove('hidden')
  })
}

function hideElements() {
  Array.from(arguments).flat().forEach((e) => {
    e.classList.add('hidden')
    e.classList.remove('sm:block')
  })
}

function fadeShowElements() {
  Array.from(arguments).flat().forEach((e) => {
    e.classList.add('quick-fade-in') // 250ms
    e.classList.remove('quick-fade-out', 'hidden') // 250ms
    setTimeout(() => {
      e.classList.remove('quick-fade-in') // 250ms
    }, 300)
  })
}

function fadeHideElements() {
  Array.from(arguments).flat().forEach((e) => {
    e.classList.remove('quick-fade-in') // 250ms
    e.classList.add('quick-fade-out') // 250ms
    setTimeout(() => {
      e.classList.remove('quick-fade-out') // 250ms
      e.classList.add('hidden')
      e.classList.remove('sm:block')
    }, 300)
  })
}

function toggleElements() {
  Array.from(arguments).flat().forEach((e) => {
    e.classList.toggle('hidden')
  })
}

function createNodeFromString(html) {
  const container = document.createElement('div')
  container.innerHTML = html
  return container.children[0]
}

function fetchWithToken(url, options) {
  options.headers = {
    "X-CSRF-Token": csrfToken(),
    ...options.headers
  };

  return fetch(url, options)
}

function displayCatchedError(error = null, options = {}) {
  if (!error) return

  console.error(`Error: ${error}`);
  console.trace()
  Swal.fire({
    title: `Error: ${error.name}`,
    text: `Message: ${options.message || error.message}`,
    icon: 'warning',
    confirmButtonColor: '#FFBABA',
    confirmButtonText: 'Ok',
  })
}

function escapeHTML(htmlStr) {
   return htmlStr.replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#39;");
}

function formatTime(timeInSeconds) {
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

  return {
    hours: result.substr(0, 2),
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2),
  };
}

function isIphone() {
  return /(iPhone)/i.test(navigator.userAgent);
}

function isSafari() {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
}

function isIphoneSafari() {
  return isIphone() && isSafari();
}

function stopAllPlayingMedias() {
  document.querySelectorAll('video, audio').forEach(e => e.pause())
  dispatchCustomEvent('video-api:pause')
}

function HTMLStringToDomElement(string) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = string
  return wrapper.firstElementChild
}

function getBase64Image(img) {
  const canvas = document.createElement("canvas");
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL("image/png");
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

const dispatchCustomEvent = (name, details = {}) => {
  const event = new CustomEvent(name, { detail: details })

  document.dispatchEvent(event);
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const roundToDecimal = (n, decimals = 0) => +(Math.round(n + `e+${decimals}`)  + `e-${decimals}`)

const csvJson = (csv) => {
  const lines = csv.split('\n')
  const result = []
  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentline = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }

    result.push(obj)
  }

  return result
}

global.showElements           = showElements
global.hideElements           = hideElements
global.fadeShowElements       = fadeShowElements
global.fadeHideElements       = fadeHideElements
global.toggleElements         = toggleElements
global.escapeHTML             = escapeHTML
global.formatTime             = formatTime
global.createNodeFromString   = createNodeFromString
global.isIphone               = isIphone
global.isSafari               = isSafari
global.isIphoneSafari         = isIphoneSafari
global.stopAllPlayingMedias   = stopAllPlayingMedias
global.displayCatchedError    = displayCatchedError
global.HTMLStringToDomElement = HTMLStringToDomElement
global.dispatchCustomEvent    = dispatchCustomEvent
global.clamp                  = clamp
global.roundToDecimal         = roundToDecimal
global.getBase64Image         = getBase64Image
global.csvJson                = csvJson

export { fetchWithToken }
