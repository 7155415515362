import { Controller } from 'stimulus';
import { fetchWithToken } from '../../javascript/application/stimulus_helper';

export default class extends Controller {
  static targets = ['sourceContent', 'submitBtn', 'sourceContentInput', 'sourceContentForm', 'contentsContainer']
  static values = { questionOrAnswer: String , multiselect: Boolean, loadingState: Boolean}

  connect() {
    this.generalQuizzController = this.application.getControllerForElementAndIdentifier(this.element.closest('.writing-modal-content-quizz'), 'media-creation--quizz')
    this.relevantForm =  this.element.closest('.question-form')
    if (this.loadingStateValue) {
      this.rouleMaPoule()
    }
  }

  selectContent(e) {
    if(!this.multiselectValue) {
      this.sourceContentTargets.forEach(source => source.dataset.selected = false)
      e.currentTarget.dataset.selected = true
      this.sourceContentInputTarget.value = e.currentTarget.dataset.id
      this.generate()
    } else {
      const selectedState = e.currentTarget.dataset.selected === "true"
      e.currentTarget.dataset.selected = !selectedState
      this.submitBtnTarget.classList.remove('hidden')
    }
  }

  generate() {
    this.questionOrAnswerValue === "question" ? this.generateQuestion() : this.generateAnswer()
  }

  generateQuestion() {
    this.rouleMaPoule()
    const aiBtn = this.relevantForm.querySelector('.aiQuestionBtn')
    aiBtn.dataset.disabled = true
    const formData = new FormData()
    formData.append('question_format', this.relevantForm.querySelector('.question-format').value)
    formData.append('source_content', this.sourceContentInputTarget.value)
    fetchWithToken(this.sourceContentInputTarget.dataset.url, {
      method: "PATCH",
      body: formData,
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if(data.errors) {
        this.element.dataset.expanded = true
        aiBtn.dataset.disabled = false
      }
    })
  }

  generateQuestions() {
    this.rouleMaPoule()
    // FIND aiBtn
    var contentsIds = []
    this.sourceContentTargets.forEach((sourceContent) => {
      if (sourceContent.dataset.selected === "true") { contentsIds.push(sourceContent.dataset.id) }
    })

    if (contentsIds.length > 0) {
      const formData = new FormData()
      formData.append('multiple_contents_ids', contentsIds)
      fetchWithToken(this.sourceContentInputTarget.dataset.url, {
        method: "PATCH",
        body: formData,
        headers: { "Accept": "application/json" },
      })
      .then(response => response.json())
      .then((data) => {
        if(data.errors) {
          this.element.dataset.expanded = true
          aiBtn.dataset.disabled = false
        }
      })
    }
  }


  generateAnswer() {
    this.element.dataset.expanded = false
    const aiBtn = this.relevantForm.querySelector('.aiAnswerBtn')
    const correctedField = this.relevantForm.querySelector('.corrected-answer-field')
    const labelField = this.relevantForm.querySelector('.question-label')
    if (labelField.value )
    this.generalQuizzController.animateField(correctedField)
    aiBtn.dataset.disabled = true
    const formData = new FormData()
    formData.append('question_label', labelField.value)
    formData.append('source_content', this.sourceContentInputTarget.value)
    fetchWithToken(this.sourceContentInputTarget.dataset.url, {
      method: "PATCH",
      body: formData,
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      if(data.errors) {
        this.generalQuizzController.stopAnimatingField(correctedField)
        this.element.dataset.expanded = true
        aiBtn.dataset.disabled = false
      }
    })
  }

  rouleMaPoule() {
    //this.element.dataset.expanded = false
    //this.generalQuizzController.revealSpinner()
    //this.generalQuizzController.animateEmptyFields()
    showElements(this.element.querySelector('.quizzable-loading-state'))
    hideElements(this.contentsContainerTarget)
  }

  reset() {
    hideElements(this.element.querySelector('.quizzable-loading-state'))
    showElements(this.contentsContainerTarget)
    this.sourceContentTargets.forEach(source => source.dataset.selected = false)
  }
}
