import { Controller } from "stimulus";
import { fetchWithToken } from "../../application/stimulus_helper";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = ["newFormBtn", "createForm", "summaryCardContainer"];

  new() {
    hideElements(this.newFormBtnTarget);
    // showElements(this.createFormTarget)
    //  this.createFormTarget.querySelectorAll('input')[1].value= 'Nouveau Chapitre'
    this.create();
  }

  create() {
    const url = this.createFormTarget.action;
    const formData = new FormData(this.createFormTarget);
    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: formData,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else if (this.hasSummaryCardContainerTarget) {
          if (this.summaryCardContainerTarget.classList.contains("hidden")) {
            this.summaryCardContainerTarget.classList.remove("hidden");
          }
          this.summaryCardContainerTarget.insertAdjacentHTML(
            "beforeend",
            data.summary_card_html
          );
          this.createFormTarget.querySelectorAll("input")[1].value = "";
          this.hideCreate();
          dispatchCustomEvent("chapters:created");
        }
        this.createFormTarget.reset();
        this.editTitleNewChapter();
      });
  }

  hideCreate(event) {
    showElements(this.newFormBtnTarget);
    hideElements(this.createFormTarget);
  }

  editTitleNewChapter() {
    const container =
      this.summaryCardContainerTargets[
        this.summaryCardContainerTargets.length - 1
      ];
    const createdChapterPartial = container.lastElementChild;

    const createdChapterName = createdChapterPartial.querySelector(
      ".chapter-summary-card-name"
    );
    const createdChapterNameForm = createdChapterPartial.querySelector(
      ".chapter-summary-card-name-form"
    );
    const createdChapterNameInput =
      createdChapterPartial.querySelector(".card-name-input");

    hideElements(createdChapterName);
    showElements(createdChapterNameForm);
    createdChapterNameInput.focus();
    createdChapterNameInput.select();

    createdChapterNameInput.addEventListener(
      "blur",
      (event) => {
        this.update(event);
      },
      { once: true }
    );
  }

  edit(event) {
    const summaryCardNameTag = event.currentTarget
      .closest("li")
      .querySelector(".chapter-summary-card-name");
    const summaryCardNameForm = event.currentTarget
      .closest("li")
      .querySelector(".chapter-summary-card-name-form");
    const summaryCardNameInput = event.currentTarget
      .closest("li")
      .querySelector(".card-name-input");
    hideElements(summaryCardNameTag);
    showElements(summaryCardNameForm);
    summaryCardNameInput.focus();
    summaryCardNameInput.select();

    summaryCardNameInput.addEventListener(
      "blur",
      (event) => {
        this.update(event);
      },
      { once: true }
    );
  }

  update(event) {
    event.preventDefault();

    const summaryCardNameTag = event.currentTarget
      .closest("li")
      .querySelector(".chapter-summary-card-name");
    const summaryCardNameForm = event.currentTarget
      .closest("li")
      .querySelector(".chapter-summary-card-name-form");
    //  const form = event.currentTarget
    const formData = new FormData(summaryCardNameForm);
    const url = summaryCardNameForm.action;
    const options = {
      method: "PATCH",
      headers: { Accept: "application/json" },
      body: formData,
    };

    fetchWithToken(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          summaryCardNameTag.innerText = data.name;
          hideElements(summaryCardNameForm);
          showElements(summaryCardNameTag);
          summaryCardNameForm.querySelectorAll("input")[1].value = "";
        }
        //summaryCardNameForm.reset()
      });
  }

  duplicate(event) {
    event.preventDefault();
    event.stopPropagation();

    const toggleSpinner = (target) => {
      target.parentElement
        .querySelector(".loading-spinner")
        .classList.toggle("hidden");
      target.classList.toggle("hidden");
    };

    const target = event.currentTarget;
    const stepId = target.dataset.stepId;
    const data = new FormData();
    const options = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: data,
    };

    data.append(`[step_id]`, stepId);
    toggleSpinner(target);

    fetchWithToken(target.href, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          Swal.fire({
            title: "Erreur",
            text: data.errors.join(", "),
            icon: "error",
            confirmButtonColor: "#3d52d5",
          });
        } else {
          target
            .closest("li")
            .insertAdjacentHTML("afterend", data.summary_card_html);
          dispatchCustomEvent("chapters:created");
        }
        toggleSpinner(target);
      });
  }

  delete(event) {
    const id = event.params.id;
    const summaryCard = event.currentTarget.closest("li");
    const url = `/chapters/${id}`;
    const options = {
      method: "DELETE",
      headers: { Accept: "application/json" },
    };

    Swal.fire({
      title: I18n.t("js.sweet_alerts.chapters.delete.title"),
      text: I18n.t("js.sweet_alerts.chapters.delete.text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "#CDD2DE",
      cancelButtonText: I18n.t(
        "js.sweet_alerts.chapters.delete.cancel_button_text"
      ),
      confirmButtonText: I18n.t(
        "js.sweet_alerts.chapters.delete.confirm_button_text"
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        fetchWithToken(url, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.deleted) {
              summaryCard.remove();
              dispatchCustomEvent("chapters:deleted");
              const firstChapterCard = document.querySelector(
                ".writing-summary-chapter-card:last-of-type"
              );
              firstChapterCard.classList.add("active");
              setTimeout(() => {
                firstChapterCard
                  .querySelector(".writing-summary-section-card:last-of-type")
                  .click();
              }, 100);
            } else {
              Swal.fire({
                title: I18n.t("js.sweet_alerts.chapters.errors.title"),
                text: I18n.t("js.sweet_alerts.chapters.errors.text"),
                icon: "error",
                confirmButtonColor: "#3d52d5",
              });
            }
          });
      }
    });
  }
}
