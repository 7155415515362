const initTailwindTabs = () => {
  if (document.querySelector("#tabs") && document.querySelector('#default-tab')) {
    let tabsContainer = document.querySelector("#tabs")
    let tabTogglers = tabsContainer.querySelectorAll("a")

    tabTogglers.forEach(function(toggler) {
      toggler.addEventListener("click", function(e) {
        e.preventDefault()

        let tabName = this.getAttribute("href")
        let tabContents = document.querySelector("#tab-contents");

        for (let i = 0; i < tabContents.children.length; i++) {
          tabTogglers[i].parentElement.classList.remove("komin-tab-selected");
          tabTogglers[i].parentElement.classList.add("komin-tab-blue");
          tabContents.children[i].classList.remove("hidden")
          if ("#" + tabContents.children[i].id === tabName) {
            continue;
          }
          tabContents.children[i].classList.add("hidden")
        }
        e.target.parentElement.classList.add("komin-tab-selected");
        e.target.parentElement.classList.remove("komin-tab-blue")
      });
    });

    document.getElementById("default-tab").click()
  }
}

export { initTailwindTabs }
