import { Controller } from 'stimulus'
import { fetchWithToken } from '../../javascript/application/stimulus_helper';
import consumer from "../../javascript/channels/consumer";


export default class extends Controller {
  static targets = [ 'input']

  moveHandover(e) {
    const libraryId = e.currentTarget.dataset.libraryId
    this.inputTarget.value = libraryId
    this.inputTarget.dispatchEvent(new Event('change', { 'bubbles': true }))
  }
}
