import { Controller } from 'stimulus'
import { fetchWithToken } from '../../application/stimulus_helper';

export default class extends Controller {
  static targets = ['replaceable']
  static values = {}

  connect() {
    document.addEventListener('slideover:close_handover', this.updateReadStatus.bind(this))
  }

  updateReadStatus(e) {
    this.urlValue = '/handovers/:handover_id/comments/mark_as_read'
    const url = this.urlValue.replace(":handover_id", e.detail.id)

    const options  = {
      method: 'POST',
      headers: { 'Accept': 'application/json' }
    }

    fetchWithToken(url, options)
      .then(response => response.json())
      .then((data) => {
        if (data.failure) {
          Swal.fire({
            title: 'Erreur !',
            text: '',
            icon: 'error',
            confirmButtonColor: '#3d52d5'
          })
        } else {
        this.replaceableTarget.innerHTML = data.partial
        document.querySelectorAll('.unreadpill').forEach((pill) => {
          pill.classList.add('hidden')
        })
      }
    })

   // console.log(`le mec a lu les comments du handover ${e.detail.id} `)
  }
}
