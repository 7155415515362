// edit_transcript_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["subtitlesInput", "error"];

  connect() {
  }
  
  timeToMillis(timeString) {
    const [hours, minutes, seconds] = timeString.split(':');
    const [secs, millis] = seconds.split('.');
    return parseInt(hours) * 3600000 + parseInt(minutes) * 60000 + parseInt(secs) * 1000 + parseInt(millis);
  }

  checkVttContent() {
    // We check if the VTT content is valid
    // aka if the time is increasing, the format is correct, no empty lines, no overlapping timestamps
    const subtitles = this.subtitlesInputTarget.value.split('\n');
    let previousTime = 0;
    let previousEndTime = 0;
    let previousSubtitle = '';
    let isValid = true;
    let errorMessage = '';
    let errorLine = 0;
    this.resetError();

    subtitles.forEach((subtitle, index) => {
      if (subtitle === '') return;

      if (subtitle.includes('-->')) {
        const times = subtitle.split('-->');
        const startTime = this.timeToMillis(times[0]);
        const endTime = this.timeToMillis(times[1]);

        if (startTime >= endTime) {
          isValid = false;
          errorMessage = 'End time must be after start time.';
          errorLine = index + 1;
        }

        if (startTime < previousTime) {
          isValid = false;
          errorMessage = 'Timestamps must be in increasing order.';
          errorLine = index + 1;
        }

        if (startTime < previousEndTime) {
          isValid = false;
          errorMessage = 'Overlapping timestamps.';
          errorLine = index + 1;
        }

        previousTime = startTime;
        previousEndTime = endTime;
      } else {
        previousSubtitle = subtitle;
      }
    });

    if (!isValid) {
      this.setError(this.subtitlesInputTarget, errorMessage, errorLine);
    }
  }

  setError(input, message, line) {
    const errorElement = this.errorTarget
    errorElement.innerText = `Error on line ${line}: ${message}`;
    errorElement.style.display = 'block';
  }    

  resetError() {
    this.errorTarget.style.display = 'none';
  }

// Example of a valid VTT file:
// 00:00:00.000 --> 00:00:04.120
// Hello, and welcome to TemplateIn.

// 00:00:04.120 --> 00:00:06.640
// TemplateIn is a productivity tool

// 00:00:06.640 --> 00:00:09.720
// which centralizes all your templates.



}
