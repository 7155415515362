import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  connect() {
    if (!this._checkCookie()) {
      this.checkAuth();
    }
  }

  checkAuth() {
    this._verifyMediaAuth();
  }

  _checkCookie() {
    const cookie = document.cookie
      .split(";")
      .filter((item) =>
        item.trim().startsWith(`mediaDevicesAccessAllowed=`)
      )[0];
    if (cookie) {
      return cookie.trim().split("=")[1] === "true";
    } else {
      return false;
    }
  }

  _setCookie(allowed) {
    const cookie = `mediaDevicesAccessAllowed=${allowed};expires=${this._daysFromNow(
      365
    )};path=/;SameSite=Strict;Secure`;
    document.cookie = cookie;
  }

  _verifyMediaAuth() {
    var startTime = Date.now();

    var detectPermissionDialog = (allowed) => {
      if (Date.now() - startTime > 1000) {
        if (allowed) {
          // accepted access
        } else {
          // refused access
          this._noticeUser();
        }
      } else {
        if (allowed) {
          // navigator blocks access
        } else {
          // navigator authorizes access
          this._noticeUser();
        }
        this._setCookie(allowed);
      }
    };

    var successCallback = (error) => {
      stopSteam();
      detectPermissionDialog(true);
    };

    var errorCallback = (error) => {
      stopSteam();
      if (
        error.name == "NotAllowedError" ||
        error.name == "PermissionDismissedError"
      ) {
        detectPermissionDialog(false);
      }
    };

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        this.stream = stream;
      })
      .then(successCallback, errorCallback)
      .catch((err) => {
        console.warn(err.name + ": " + err.message);
      });

    var stopSteam = () => {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }

  _noticeUser(media) {
    var swalContent = document.createElement("div");
    swalContent.innerHTML = I18n.t(
      "js.sweet_alerts.check_media_device_auth.text_html",
      {
        class: "btn success btn-md text-xl mb-1",
        link: `https://intercom.help/kominio/fr/articles/6780482-comment-faire-marcher-la-camera-le-micro-et-le-partage-d-ecran`,
      }
    );
    Swal.fire({
      title: I18n.t("js.sweet_alerts.check_media_device_auth.title"),
      html: swalContent,
      icon: "warning",
      confirmButtonColor: "var(--danger)",
      confirmButtonText: I18n.t(
        "js.sweet_alerts.check_media_device_auth.confirm_button"
      ),
    });
  }

  _daysFromNow(days) {
    return new Date(Date.now() + days * 86400000).toUTCString();
  }
}
