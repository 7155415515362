import { Controller } from 'stimulus'
import Swiper from 'swiper/swiper-bundle';

export default class extends Controller {
  connect() {
    this._buildSwipers()
  }

  _buildSwipers() {
    const identifier = `.swiper-signin`
    var that = this

    this.swiper = new Swiper(identifier, {
      pagination: {
        el: `.swiper-pagination`,
        type: 'bullets'
      },
      autoplay: {
        delay: 5000,
      }
    })
  }

}
