import { Controller } from 'stimulus';
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [ 
    "templatesContainer",
    "template",
    "editImageForm",
    "submitBtn",
    "lockFormContainer",
    "searchOnWebInput",
    "imageUrlInput",
    "searchOnWebResults",
    "titleInput",
    "titleContainer",
    "logoContainer",
    "colorsContainer",
    "templatesAndFormContainer",
    "submitBtnContainer",
    "previewContainer",
    "modalTitle",
    "imageTemplateIdInput",
    "restartBtn",
    "previewButtonsContainer",
    "generatedImageContainer",
    "waitingForImageContainer",
    "allGoodBtn",
  ]

  static values = { 
    searchOnWebUrl: String,
    status: String,
    generatedImageableClass: String,
  }

  connect() {
    // We fetch buildings pictures from Unsplash to start with
    this.fetchRandomPic();
    this._setContainerWithStatus();
    this._broadcast()
  }

  fetchRandomPic() {
    const random_pic_url = `${this.searchOnWebUrlValue}?query=buildings&orientation=squarish`;
    this.fetchOnWeb(random_pic_url);
  }

  fetchOnWeb(url) {
    fetch(url)
    .then(response => response.json())
    .then((data) => {
      this.searchOnWebResultsTarget.innerHTML = data.partial;
      this._listenForImageClick();
    });
  }

  // On event click on a template
  selectTemplate(event) {
    // Toggle some classes on the selected template
    this._toggleTemplate(event);

    // We change the value of the hidden input
    this.imageTemplateIdInputTarget.value = event.currentTarget.dataset.templateId;

    // We change the position of the selected template to the first position
    this.templatesContainerTarget.prepend(event.currentTarget);

    this.submitBtnTarget.removeAttribute("disabled");

    this.revealLockFormContainer(event);
    this._addUnusedInputs();
  }

  // On event click on the No template button
  selectNoTemplate(event) {
    this._toggleTemplate(event);
    // We change the value of the hidden input to nil
    this.imageTemplateIdInputTarget.value = null;

    // We change the position of the selected template to the first position
    this.templatesContainerTarget.prepend(event.currentTarget);

    this.submitBtnTarget.removeAttribute("disabled");

    this.revealLockFormContainer(event);
    this._removeUnusedInputs();
  }

  revealLockFormContainer(event) {
    this.lockFormContainerTarget.classList.add("hidden");
    this.editImageFormTarget.classList.remove("blur-sm");
    // We focus on the first input of the form
    this.titleInputTarget.focus();
  }

  // On change of the search input
  searchOnWeb(event) {
    event.preventDefault();
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const query = this.searchOnWebInputTarget.value;
      const url = `${this.searchOnWebUrlValue}?query=${query}&orientation=landscape`;
      this.fetchOnWeb(url);
    }, 500);
  }

  // On click on the submit button
  submit(event) {
    event.preventDefault();
    const data = new FormData(this.editImageFormTarget);
    const url = this.editImageFormTarget.action;
    fetch(url, {
      method: "POST",
      body: data
    })
    .then(response => response.json())
    .then((data) => {
      if (data.no_template) {
        // We reload the page
        window.location.reload();
      } else {
        this.templatesAndFormContainerTarget.classList.add("hidden");
        this.submitBtnContainerTarget.classList.add("hidden");
        this.previewContainerTarget.classList.remove("hidden");
        this.modalTitleTarget.innerHTML = I18n.t("js.generated_image.banner_is_building")
        if (this.hasGeneratedImageContainerTarget) {
          this.generatedImageContainerTarget.classList.add("hidden");
          this.waitingForImageContainerTarget.classList.remove("hidden");
        }
      }
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  // On click on the restart button
  restart(event) {
    event.preventDefault();
    this.templatesAndFormContainerTarget.classList.remove("hidden");
    this.submitBtnContainerTarget.classList.remove("hidden");
    this.previewContainerTarget.classList.add("hidden");
    this.previewButtonsContainerTarget.classList.add("hidden");
    this.modalTitleTarget.innerHTML = I18n.t("js.generated_image.edit_your_banner")
    this.statusValue = "not_generated_yet";
  }

  _toggleTemplate(event) {
    this.templateTargets.forEach( (template) => {
      template.classList.remove("border");
      template.classList.remove("transform", "col-span-2");
      template.classList.add("hover:opacity-50");
      template.classList.add("cursor-pointer");

    })
    event.currentTarget.classList.add("transform", "col-span-2");
    event.currentTarget.classList.add("border");
    event.currentTarget.classList.remove("hover:opacity-50");
    event.currentTarget.classList.remove("cursor-pointer");
  }

  _listenForImageClick() {
    this.searchOnWebResultsTarget.querySelectorAll("img").forEach((image) => {
      image.addEventListener("click", (event) => {
        this._selectImageFromTheWeb(event);
      });
    });
  }

  _selectImageFromTheWeb(event) {
    // We add the selected image to the form
    const src = event.currentTarget.dataset.url;
    this._addImageToForm(src);
    this.searchOnWebResultsTarget.querySelectorAll("img").forEach((image) => {
      image.classList.remove("border-2");
    });
    event.currentTarget.classList.add("border-2");
  }

  _addImageToForm(src) {
    this.imageUrlInputTarget.value = src;
  }

  _setContainerWithStatus() {
    if (this.statusValue === "not_generated_yet") {
    } else if (this.statusValue === "pending") {
      this.templatesAndFormContainerTarget.classList.add("hidden");
      this.submitBtnContainerTarget.classList.add("hidden");
      this.previewContainerTarget.classList.remove("hidden");
      this.previewButtonsContainerTarget.classList.remove("hidden");
      this.modalTitleTarget.innerHTML = I18n.t("js.generated_image.banner_is_building")
      this.waitingForImageContainerTarget.classList.remove("hidden");
      
    } else if (this.statusValue === "success") {
      this.templatesAndFormContainerTarget.classList.add("hidden");
      this.submitBtnContainerTarget.classList.add("hidden");
      this.previewContainerTarget.classList.remove("hidden");
      this.previewButtonsContainerTarget.classList.remove("hidden");
      this.modalTitleTarget.innerHTML = I18n.t("js.generated_image.your_image_is_ready")
    }
  } 

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "GeneratedImageChannel", id: this.handoverIdValue },
      { received: (data) => {
        if (data.generated_image_id) {
          this.modalTitleTarget.innerHTML = I18n.t("js.generated_image.your_image_is_ready")
          
          const generatedImageContainer = this.generatedImageContainerTarget
          const generatedImageableId = data.generated_imageable_id.toString()
          const generatedImageableType = data.generated_imageable_type
          


          if (generatedImageContainer.dataset.generatedImageableId === generatedImageableId && generatedImageContainer.dataset.generatedImageableType === generatedImageableType) {
            this.generatedImageContainerTarget.innerHTML = data.html
            this.generatedImageContainerTarget.classList.remove("hidden");
            if (data.generated_imageable_type === "Handover") {
              const oldhandoverImage = document.getElementById("handoverImage")
              const editOldHandoverImage = document.getElementById("handoverImageEdit")
              // We replace the image with the new one
              if (oldhandoverImage) {
                oldhandoverImage.src = this.generatedImageContainerTarget.querySelector("img").src;
              }
              if (editOldHandoverImage) {
                editOldHandoverImage.src = this.generatedImageContainerTarget.querySelector("img").src;
              }
            }
          }
          
          this.waitingForImageContainerTarget.classList.add("hidden");

          this.previewButtonsContainerTarget.classList.remove("hidden");
          this.allGoodBtnTarget.removeAttribute("disabled");
        }
      }
    })
  }

  _removeUnusedInputs() {
    this.titleContainerTarget.classList.add("hidden");
    this.logoContainerTarget.classList.add("hidden");
  }

  _addUnusedInputs() {
    this.titleContainerTarget.classList.remove("hidden");
    this.logoContainerTarget.classList.remove("hidden");
  }
    
}