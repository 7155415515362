import { Controller } from 'stimulus';
import getBlobDuration from 'get-blob-duration'
import interact from 'interactjs'
import { fetchWithToken } from '../../application/stimulus_helper'

export default class extends Controller {
  static targets = [
    'videoTag',
    'controls',
    'totalVideoTime',
    'elapsedVideoTime',
    'playPause',
    'mute',
    'volumeInput',
    'volumeUp',
    'volumeDown',
    'muteIcon',
    'audioIcon',
    'playIcon',
    'pauseIcon',
    'timelineBase',
    'timeline',
    'cursor',
    'fullscreen',
    'playrateBtn',
    'startTooltip',
    'endTooltip',
    'startTime',
    'endTime',
  ]

  static values = {
    id: Number,
    threshold: { type: Number, default: 50 }
  }

  connect() {
    console.log('Connected to video controls controller')
    this.volume = 1
    this.displayFullScreenButton()
    if (!!document.createElement('video').canPlayType) this._displayVideoControls()
    this._initFullScreenData()
    //document.addEventListener('video-api:pause', (e) => {
    //  this.player.pause()
    //})
  }

  _displayVideoControls() {
    this.videoTagTarget.onloadedmetadata = () => {
      this.videoTagTarget.controls = false;
      if (!this._fullscreenEnabled()) hideElements(this.fullscreenTarget)

      // Chromium navigators cant get the duration of a videoTag loaded with a stream source
      // Hence this fix
      const blobUrl = localStorage.getItem(`videoPartBlobUrl-${this.idValue}`)
      fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => getBlobDuration(blobUrl))
        .then((duration) => {
          this.duration = duration
          this._initAll()
          localStorage.removeItem(`videoPartBlobUrl-${this.idValue}`)
        })
    }
  }

  _initAll() {
    showElements(this.controlsTarget)
    this._initVideoControls()
    this._updateControlsWithTrimValues()
    this._setTrimBegin()
    this._setTrimEnd()
  }

  _initVideoControls() {
    const time = formatTime(this.duration)

    // this.totalVideoTimeTarget.innerText = `${time.hours}:${time.minutes}:${time.seconds}`
    // this.totalVideoTimeTarget.setAttribute('datetime', `${time.hours}h ${time.minutes}m ${time.seconds}s`)

    this.videoTagTarget.ontimeupdate = (e) => {
      const currentTime = this.videoTagTarget.currentTime
      this._updateTimeElapsed(currentTime)
      this._updateProgress(currentTime)
      if (this._customEnd() && currentTime > this._customEnd()) {
        this.goTo(this._customStart())
        this.pause()
      }
    }
    this.videoTagTarget.onpause = () => { this.pause() }
    this.videoTagTarget.onplay = () => { this.play() }
  }

  _updateControlsWithTrimValues() {
    const start = this.videoTagTarget.dataset.start
    const end   = this.videoTagTarget.dataset.end

    this.startValue = start || 0
    this.endValue   = end || roundToDecimal(this.duration, 1)
  }

  _setTrimBegin() {
    const percentage = roundToDecimal(this.startValue, 4) / (this.duration / 100)
    const marginLeftValue = this.timelineBaseTarget.offsetWidth * (percentage / 100)
    this.timelineTarget.style.marginLeft = `${marginLeftValue}px`

    var that = this
    if (this.hasStartTimeTarget) {
      this.startTimeTarget.innerText = formatTime(this.startValue).hours + ':' + formatTime(this.startValue).minutes + ':' + formatTime(this.startValue).seconds
    }

    interact(`.trim-handle-begin-${this.idValue}`).draggable({
      listeners: {
        start(event) {
          that.trimming = true
          showElements(that.startTooltipTarget)
        },
        move(event) {
          const originalMarginCssRule    = (that.timelineTarget.style.marginLeft || '0').match(/\d+/)[0]
          const originalMarginLeftPx     = parseInt(originalMarginCssRule, 10)
          const nextCalculatedMarginLeft = originalMarginLeftPx + event.dx
          const timelineWidth            = that.timelineTarget.getBoundingClientRect().width
          const maxMarginLeft            = timelineWidth + nextCalculatedMarginLeft - that.thresholdValue
          const marginLeft               = clamp(0, nextCalculatedMarginLeft, maxMarginLeft)

          // that._hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth)
          that.timelineTarget.style.marginLeft = `${marginLeft}px`
          that.timestamp = that.duration / 100 * marginLeft / (that.timelineBaseTarget.offsetWidth / 100)
          that.startValue = that.timestamp
          that._updateStartEndTime(that.timestamp, that.startValue)
          that._updateTooltip(that.startTooltipTarget, that.timestamp)
          that._setTime(that.timestamp)
        },
        end(event) {
          hideElements(that.startTooltipTarget)
          setTimeout(() => that.trimming = false, 50)
          that._updateVideoTimestamps(that.timestamp, that.endValue)
        }
      }
    })
  }

  _setTrimEnd() {
    this.timelineTarget.style.marginRight = `${this.timelineBaseTarget.offsetWidth * ((this.duration - this.endValue) / this.duration )}px`
    var that = this

    this.endTimeTarget.innerText = formatTime(this.endValue).hours + ':' + formatTime(this.endValue).minutes + ':' + formatTime(this.endValue).seconds


    interact(`.trim-handle-end-${this.idValue}`).draggable({
      listeners: {
        start(event) {
          that.trimming = true
          showElements(that.endTooltipTarget)
        },
        move(event) {
          const originalMarginCssRule     = (that.timelineTarget.style.marginRight || '0').match(/\d+/)[0]
          const originalMarginRightPx     = parseInt(originalMarginCssRule, 10)
          const nextCalculatedMarginRight = originalMarginRightPx - event.dx
          const timelineWidth             = that.timelineTarget.getBoundingClientRect().width
          const maxMarginRight            = timelineWidth + nextCalculatedMarginRight - that.thresholdValue
          const marginRight               = clamp(0, nextCalculatedMarginRight, maxMarginRight)

          // that._hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth)
          that.timelineTarget.style.marginRight = `${marginRight}px`
          that.timestamp = that.duration - (that.duration / 100 * marginRight / (that.timelineBaseTarget.offsetWidth / 100))
          that.endValue = that.timestamp
          that._updateTooltip(that.endTooltipTarget, that.timestamp)
          that._updateStartEndTime(that.startValue, that.timestamp)
          that._setTime(that.timestamp)

          const formattedEndTime = formatTime(that.timestamp)
          // that.totalVideoTimeTarget.innerText = `${formattedEndTime.hours}:${formattedEndTime.minutes}:${formattedEndTime.seconds}`
        },
        end(event) {
          hideElements(that.endTooltipTarget)
          setTimeout(() => that.trimming = false, 50)
          that._updateVideoTimestamps(that.startValue, that.timestamp)
        }
      }
    })
  }

  _hideTotalVideoTimeIfTimelineTooNarrow(timelineWidth) {
    this.totalVideoTimeTarget.classList.toggle('hidden', (timelineWidth - (2 * 25) < 85))
  }

  _updateStartEndTime(start, end) {
    this.startTimeTarget.innerText = formatTime(start).hours + ':' + formatTime(start).minutes + ':' + formatTime(start).seconds
    this.endTimeTarget.innerText   = formatTime(end).hours + ':' + formatTime(end).minutes + ':' + formatTime(end).seconds
  }

  _updateTooltip(tooltip, timestamp) {
    const time        = formatTime(timestamp)
    const timeString  = `${time.hours}:${time.minutes}:${time.seconds}`
    tooltip.innerText = timeString
  }

  _updateTimeElapsed(currentTime) {
    const elapsedTime = formatTime(currentTime)

    this.elapsedVideoTimeTarget.innerText = `${elapsedTime.hours}:${elapsedTime.minutes}:${elapsedTime.seconds}`;
    this.elapsedVideoTimeTarget.setAttribute('datetime', `${elapsedTime.hours}:${elapsedTime.minutes}m ${elapsedTime.seconds}s`)
  }

  skip(e) {
    var rect    = this.timelineBaseTarget.getBoundingClientRect();
    var pos     = (e.pageX  - rect.left) / this.timelineBaseTarget.offsetWidth;
    const value = pos * this.duration;

    const customStart = this._customStart()
    const customEnd   = this._customEnd()

    if (customStart && value < customStart) {
      this.goTo(customStart)
    } else if (customEnd && value > customEnd) {
      this.goTo(customEnd)
    } else {
      this.goTo(value)
    }
  }

  moveSkip(e) {
    if (e.buttons == 1) {
      window.getSelection().removeAllRanges();
      this.skip(e)
    }
  }

  touchSkip(e) {
    if (e.touches.length == 1) {
      window.getSelection().removeAllRanges();
      this.skip(e)
    }
  }

  goTo(value) {
    if (this.trimming) return
    this._setTime(value)
  }

  _setTime(timestamp) {
    this.videoTagTarget.currentTime = timestamp
    const percentage         = roundToDecimal(timestamp / (this.duration / 100), 4)
    const marginLeftValue    = this.timelineBaseTarget.offsetWidth * (percentage / 100)
    const timelineMarginLeft = parseInt((this.timelineTarget.style.marginLeft || '0').match(/\d+/)[0], 10)
    this.cursorTarget.style.marginLeft = `${marginLeftValue}px`

    this._updateCursorTimestamp(timestamp)

    const cursorAtEnd = marginLeftValue > timelineMarginLeft + this.timelineTarget.offsetWidth - 10
    this.cursorTarget.classList.toggle('-translate-x-0.5', cursorAtEnd)
  }

  playPause() {
    if (this.videoTagTarget.paused || this.videoTagTarget.ended) {
      stopAllPlayingMedias()
      this.play()
    } else {
      this.pause()
    }
  }

  playPauseFromSpaceBar(e) {
    if (
      !(e.keyCode === 32) ||
      this.element.closest('.video-part-writing').classList.contains('hidden') ||
      this.element.closest('.content-writing-modal').classList.contains('hidden') ||
      document.activeElement.tagName === 'TEXTAREA' ||
      document.activeElement.tagName === 'INPUT'
    ) return

    this.playPause()
  }

  mute() {
    if (this.videoTagTarget.muted) {
      this.videoTagTarget.volume = this.volume
      this.volumeInputTarget.value = this.volume
    } else {
      this.videoTagTarget.volume = 0
      this.volumeInputTarget.value = 0
    }
    this.videoTagTarget.muted = !this.videoTagTarget.muted;
    this.audioIconTarget.classList.toggle('hidden', this.videoTagTarget.muted)
    this.muteIconTarget.classList.toggle('hidden', !this.videoTagTarget.muted)
  }

  play() {
    hideElements(this.playIconTarget)
    showElements(this.pauseIconTarget)
    const vid = this.videoTagTarget
    const start = this._customStart()
    const end = this._customEnd()
    if (start && vid.currentTime < start || end && vid.currentTime > end) vid.currentTime = start
    vid.play();
  }

  pause() {
    hideElements(this.pauseIconTarget)
    showElements(this.playIconTarget)
    this.videoTagTarget.pause();
  }

  updateVolume() {
    const vid = this.videoTagTarget
    const vol = this.volumeInputTarget.value
    if (vid.muted) {
      vid.muted = false
      showElements(this.audioIconTarget)
      hideElements(this.muteIconTarget)
    }
    if (vol == 0) {
      vid.muted = true
      hideElements(this.audioIconTarget)
      showElements(this.muteIconTarget)
    }
    this.volume = vol
    vid.volume = vol
  }

  toggleMute() {
    const video = this.videoTagTarget
    video.muted = !video.muted;

    if (video.muted) {
      this.volumeInputTarget.setAttribute('data-volume', this.volumeInputTarget.value);
      this.volumeInputTarget.value = 0;
    } else {
      this.volumeInputTarget.value = this.volumeInputTarget.dataset.volume;
    }
  }

  setPlayrate(e) {
    const playrateValue = e.params.value
    this.videoTagTarget.playbackRate = playrateValue
    this.playrateBtnTarget.innerText = `x${playrateValue}`
  }

  _updateProgress(currentTime) {
    this._updateCursorTimestamp(currentTime)
    const percentage = roundToDecimal(currentTime, 4) / (this.duration / 100)
    this.cursorTarget.style.marginLeft = `${this.timelineBaseTarget.offsetWidth * (percentage / 100)}px`
  }

  _updateCursorTimestamp(timestamp) {
    this.cursorTarget.dataset.timestamp = timestamp
  }

  _customStart() {
    if (this.videoTagTarget && this.videoTagTarget.dataset.start) {
      var start = this.videoTagTarget.dataset.start
    }
    if (start) return parseFloat(start, 10)
  }

  _customEnd() {
    if (this.videoTagTarget && this.videoTagTarget.dataset.end) {
      var end = this.videoTagTarget.dataset.end
    }
    if (end) return parseFloat(end, 10)
  }

  handleFullscreen() {
    if (this._isFullScreen()) {
      this.exitFullscreen()
    }
    else {
      this.enterFullscreen()
    }
  }

  enterFullscreen() {
    if (this.element.requestFullscreen) this.element.requestFullscreen();
    else if (this.element.mozRequestFullScreen) this.element.mozRequestFullScreen();
    else if (this.element.webkitRequestFullScreen) this.element.webkitRequestFullScreen();
    else if (this.element.msRequestFullscreen) this.element.msRequestFullscreen();
    else if (this.element.webkitEnterFullscreen) this.element.webkitEnterFullscreen();
    else console.error('No fullscreen available')
    this.setFullscreenData(true);
  }

  exitFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else console.error('No fullscreen available')
    this.setFullscreenData(false);
  }

  displayFullScreenButton() {
    const fullscreenAvailableOnFigureElement = this.element.requestFullscreen ||
                                                this.element.mozRequestFullScreen ||
                                                this.element.webkitRequestFullScreen ||
                                                this.element.msRequestFullscreen ||
                                                this.element.webkitEnterFullscreen
    if (!fullscreenAvailableOnFigureElement) hideElements(this.fullscreenTarget)
  }

  _initFullScreenData() {
    document.addEventListener('fullscreenchange', (e) => {
       this.setFullscreenData(!!(document.fullscreen || document.fullscreenElement));
    });
    document.addEventListener('webkitfullscreenchange', () => {
       this.setFullscreenData(!!document.webkitIsFullScreen);
    });
    document.addEventListener('mozfullscreenchange', () => {
       this.setFullscreenData(!!document.mozFullScreen);
    });
    document.addEventListener('msfullscreenchange', () => {
       this.setFullscreenData(!!document.msFullscreenElement);
    });
  }

  setFullscreenData(state) {
    this.element.setAttribute('data-fullscreen', !!state);
  }

  _fullscreenEnabled() {
    return !!(
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled ||
      document.webkitSupportsFullscreen ||
      document.webkitFullscreenEnabled ||
      document.createElement('video').webkitRequestFullScreen
    )
  }

  _isFullScreen() {
    return !!(
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    )
  }

  _updateVideoTimestamps(start, end) {
    this.element.dataset.start = start
    this.element.dataset.end   = end

    const id     = this.idValue
    let formData = new FormData()

    formData.append('video_part[video_start]', start)
    formData.append('video_part[video_end]', end)

    fetchWithToken(`/video_parts/${id}/update_video_timestamps`, {
      method: "PATCH",
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
    })
  }
}
