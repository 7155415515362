import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    sharingId: String,
    writingDuration: Number,
    fetchUrl: String,
  }

  connect() {
    this.startDuration = this.writingDurationValue;
    this.lastActiveTime = Date.now();
    this.userIsActive = false;
    this.setupEventListeners();
    this.setupInterval();
    this.setupActiveInterval();

    window.addEventListener("focus", () => this.handleFocus());
    window.addEventListener("blur", () => this.handleBlur());
  }

  setupEventListeners() {
    document.addEventListener('click', () => this.trackUserActivity());
    document.addEventListener('keydown', () => this.trackUserActivity());
    document.addEventListener('mousemove', this.trackUserActivityDebounced.bind(this));
    document.addEventListener('scroll', () => this.trackUserActivity());
  }

  setupInterval() {
    this.intervalId = setInterval(() => {
      this.sendTimestampToServer(this.writingDurationValue);
    }, 60000);
  }

  setupActiveInterval() {
    this.activeIntervalId = setInterval(() => {
      this.updateWritingDuration();
    }, 1000);
  }

  handleFocus() {
    this.lastActiveTime = Date.now();
    this.setupInterval();
    this.setupActiveInterval();
  }

  handleBlur() {
    this.updateWritingDuration();
    clearInterval(this.intervalId);
    clearInterval(this.activeIntervalId);
  }

  trackUserActivity() {
    this.lastActiveTime = Date.now();
    this.userIsActive = true;
  }

  trackUserActivityDebounced() {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => this.trackUserActivity(), 100);
  }

  updateWritingDuration() {
    const now = Date.now();
    const activeTime = now - this.lastActiveTime;
    if (this.userIsActive && activeTime < 1000) {
      this.writingDurationValue += 1;
    }
    this.lastActiveTime = now;
    this.userIsActive = false;
  }

  sendTimestampToServer(effectiveTime) {
    const data = {
      writing_duration: effectiveTime,
    };
    fetch(this.fetchUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
      },
      body: JSON.stringify(data)
    })
  }

  disconnect() {
    this.sendTimestampToServer(this.writingDurationValue);
    clearInterval(this.intervalId);
    clearInterval(this.activeIntervalId);
  }
}
