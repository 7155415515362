const globalLoader = () => {

  document.addEventListener("turbolinks:load", function () {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.classList.add("hide");
    }
  });

  document.addEventListener("turbolinks:click", function (event) {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.classList.remove("hide");
    }
  });

  // For submitting forms
  document.addEventListener("turbolinks:before-visit", function () {
      const loader = document.querySelector("#loader");
      if (loader) {
        loader.classList.remove("hide");
      }
  });
}

export { globalLoader }
