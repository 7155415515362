import { Controller } from 'stimulus'
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {

  static targets = ['results']
  static values = { 
    url: String,
    handovers: Array,
    limit: Number
  }

  getResults(e) {
    const url = `${this.urlValue}?criterium=${e.target.value}&handovers=${this.handoversValue}&limit=${this.limitValue}`

    fetchWithToken(url, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        this.resultsTarget.innerHTML = data.partial
    })
  }
}
