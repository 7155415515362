import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'tab', 'tabButton', 'orginput', 'fixedorginput', 'card', 'form']
  static values = { index: Number }
  next() {
    this.indexValue++
    this.updateTabButtons()
  }

  previous() {
    this.indexValue--
    this.updateTabButtons()
  }

  indexValueChanged() {
    this.showCurrentTab()
  }

  showCurrentTab() {
    this.tabTargets.forEach((element, index) => {
      element.classList.add('hidden')
      if(index === this.indexValue) {
        element.classList.remove('hidden')
      }
    })
  }

  noMoreEnterKey(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
    }
  }

  clickTab() {
    this.indexValue = event.srcElement.closest("div").dataset.signUpTabsIndexValue
    this.updateTabButtons()
  }

  assign_organisation(e) {
    this.cardTargets.forEach( (card) => {
      card.classList.remove('border', 'border-primary')
    })
    this.orginputTarget.value = e.currentTarget.dataset.id
    this.fixedorginputTarget.value = e.currentTarget.dataset.id
    e.currentTarget.classList.add('border', 'border-primary')
  }

  submit(e) {
    e.preventDefault()
    this.formTarget.submit()
    

    // document.dispatchEvent(new Event('lottie:click'))
    document.dispatchEvent(new Event('turbolinks:click'))

  }

  updateTabButtons() {
    this.tabButtonTargets.forEach((el, index) => {
      // hide all active
      el.querySelector('[data-sign-up-tabs-id="active"]').classList.add('hidden')
      // hide all inactive
      el.querySelector('[data-sign-up-tabs-id="inactive"]').classList.add('hidden')
      // hide all checked
      el.querySelector('[data-sign-up-tabs-id="checked"]').classList.add('hidden')
      // Show selected as active
      if (this.indexValue == index) {
        el.querySelector('[data-sign-up-tabs-id="active"]').classList.remove('hidden')
      }
      if (this.indexValue > index) {
        el.querySelector('[data-sign-up-tabs-id="checked"]').classList.remove('hidden')
      }
      if (this.indexValue < index) {
        el.querySelector('[data-sign-up-tabs-id="inactive"]').classList.remove('hidden')
      }
    })
  }

}
