//  Readme
// add the data-controller="autosave" to the form element or top level div
// to display the status outside the form, add the data-autosave-status-outside-scope-value="true" to the form element
// <div id="autosave-status" class="hidden group" data-status="-">
//   <!-- status ok/errror -->
//   <span class="inline-flex items-center gap-x-1.5 rounded-md group-data-[status=success]:bg-green-100 group-data-[status=error]:bg-red-100 px-1.5 py-0.5 text-xs font-medium group-data-[status=success]:text-green-700 group-data-[status=error]:text-red-700">
//     <svg class="h-1.5 w-1.5 group-data-[status=success]:fill-green-500 group-data-[status=error]:fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
//       <circle cx="3" cy="3" r="3" />
//     </svg>
//     <span></span>
//   </span>
// </div>
//
// in the form add a target="form"
// f.input .... data: { action: change->autosave#save }

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "status"];
  static values = { 
    duration: { type: Number, default: 1000 },
    statusOutsideScope: { type: Boolean, default: false }
  }

  connect() {
    this.timeout = null;
    this.statusElement = this.hasStatusTarget ? this.statusTarget : document.getElementById('autosave-status');
    this.statusText = this.statusElement.querySelector('span span');
  }

  save(event) {
    console.log("Saving...");
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.statusElement.classList.remove("hidden");
      console.log(this.formTarget.action);
      console.log(this.formTarget.method);
      console.log(event.currentTarget);
      console.log(new FormData(this.formTarget));

      this.statusText.textContent = "Saving...";
      fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          "Accept": "application/json",
          "X-CSRF-Token": Rails.csrfToken()
        },
        body: new FormData(this.formTarget)
      })
      .then(response => response.json())
      .then(data => this.success())
      .catch(error => this.error());
    }, this.durationValue);
  }

  success() {
    this.setStatus("Saved!.", "success");
  }

  error() {
    this.setStatus("Error! Changes not saved.", "error");
  }

  setStatus(text, status) {
    this.statusElement.classList.remove("hidden");
    this.statusText.textContent = text;
    this.statusElement.dataset.status = status;

    setTimeout(() => {
      this.statusText.textContent = "";
      this.statusElement.classList.add("hidden");
      this.statusElement.dataset.status = "-";
    }, 2000);
  }

}