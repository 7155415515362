// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';


export default class extends Controller {
  static values = {
    content: String,
    theme: String,
    onEllipsisOnly: { type: Boolean, default: false }
  }

  connect() {
    let options = {}
    if (this.hasContentValue) {
      options['content'] = this.contentValue
      // for background color
    }

    if (this.hasThemeValue) {
      options['theme'] = this.themeValue
    }
    
    if (this.onEllipsisOnlyValue) {
      const element = this.element.querySelector('.truncate')

      if (element.offsetWidth < element.scrollWidth) {
        this.tippy = tippy(this.element, {allowHTML: true, ...options});
      }
    } else {
      this.tippy = tippy(this.element, {allowHTML: true, ...options});
    }
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }
}