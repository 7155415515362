import { Controller } from 'stimulus';
import confetti from "canvas-confetti"


export default class extends Controller {
  static targets = ['menu'];
  connect() {
    // We add an event listener to all the <code> elements in the page
    document.querySelectorAll('code').forEach((element) => {
      element.addEventListener('click', (event) => {
        // We select the text inside the <code> element
        const codeText = event.target.innerText;
        // We append erb '<%=' and '%>' to the text
        const erbText = `<%= ${codeText} %>`;
        // We add the text to the clipboard
        navigator.clipboard.writeText(erbText);
        // We show a notification
        this.showNotification();
      });
    });

    // We build the menu based on the headings in the page
    this.buildMenu();

    // We insult the user if a component is note sorted alphabetically
    this.menuTarget.querySelectorAll('li').forEach((li, index) => {
      if (index > 0) {
        const previousLi = this.menuTarget.querySelectorAll('li')[index - 1];
        if (li.innerText < previousLi.innerText) {
          // We create a div with the insult in the midle of the page
          const insult = document.createElement('div');
          insult.style.cssText = 'position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9999;';
          insult.innerText = `Tu savais que C est avant D ? 🤔 Jeanro va pas être content et te punir. (replace le ${li.innerText} par ${previousLi.innerText} dans le menu.)`;
          
          insult.classList.add('bg-red-500', 'text-white', 'p-24', 'rounded', 'shadow-md');
          document.body.appendChild(insult);

          // We add a border to the li to make it more visible
          li.style.border = '1px solid red';

          // We add an event listener to the insult to add confetti when the user click anywhere
          insult.addEventListener('click', (event) => {
            // We add confetti
            const poop = confetti.shapeFromText({ text: '💩', font: '80px Arial' });
            confetti({
              particleCount: 100,
              startVelocity: 20,
              spread: 360,
              shapes: [poop],
              ticks: 90,
              flat: true,
              origin: {
                x: event.clientX / window.innerWidth,
                y: event.clientY / window.innerHeight
              }
            });
       
          });
        }
      }
    });
    
  }

  showNotification() {
    // We create at the bottom of the page a div with the notification
    const notification = document.createElement('div');
    // We position the notification at the bottom right of the page with a z-index of 9999
    notification.style.cssText = 'position: fixed; bottom: 20px; right: 20px; z-index: 9999;';
    // We add the notification to the page
    document.body.appendChild(notification);
    // We add the text to the notification
    notification.innerText = 'Copied to clipboard!';
    // We add a class to the notification
    notification.classList.add('bg-green-500', 'text-white', 'p-2', 'rounded', 'shadow-md');
    // We remove the notification after 2 seconds
    setTimeout(() => {
      notification.remove();
    }
    , 2000);
  }

  buildMenu() {
    // We select all the headings h2 in the page
    const headings = document.querySelectorAll('h2.title');
    // We add an id to each heading
    headings.forEach((heading) => {
      heading.id = heading.innerText.toLowerCase().replace(' ', '-');
      // We create a li element
      const li = document.createElement('li');
      // We create a link to the heading
      const link = document.createElement('a');
      // We add the text to the link
      link.innerText = heading.innerText;
      // We add btn class to the menu
      link.classList = "btn secondary btn-md";
      // We add the href to the link
      link.href = `#${heading.id}`;
      // We remove turbolinks from the link to avoid the page to reload
      link.setAttribute('data-turbolinks', 'false');
      // We add the link to the li
      li.appendChild(link);
      // We add the li to the menu
      this.menuTarget.appendChild(li);
    });
  }

}
