import { cos } from '@tensorflow/tfjs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'filter', 'results', 'filterInfos', 'documentTypeCheckbox', 'purgeQuery']

  connect() {
  }
  keyinput(e) {
    //keyinput must be triggered by a key-DOWN event or else form submit on enter will fire before preventDefault!
    if(e.keyCode == 13 ) {
     e.preventDefault()
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.search()
    }, 300)
  }

  purgeFilters() {
    this.filterTargets.forEach((filter) => {
      const controller = this.application.getControllerForElementAndIdentifier(filter, 'all-check-uncheck')
      controller.purge()
    })
    this.search()
  }

  purgeQuery() {
    this.inputTarget.value = ''
    this.purgeQueryTarget.classList.add('hidden')
    const url = this.buildParams()
    this.navigate(url.toString())
  }
  
  search() {
    if (this.inputTarget.value.length < 3) return
    
    this.purgeQueryTarget.classList.remove('hidden')

    this.resultsTarget.innerHTML = `
      <div class="w-full h-full p-6 flex flex-col gap-4">
        <div class="pb-4 flex items-center justify-between w-full">
          <div class="bg-gray-200 h-4 w-1/4 animate-pulse rounded-lg"></div>
          <div class="bg-gray-200 h-4 w-1/4 animate-pulse rounded-lg"></div>
        </div>
        
        <div class="h-16 w-full flex gap-4 items-center rounded-md bg-gray-100 p-2 animate-pulse">
          <div class="bg-gray-200 h-full aspect-16/9 w-fit animate-pulse"></div>
          <div class="bg-gray-200 h-4 w-1/3 animate-pulse rounded-full"></div>
        </div>
        <div class="h-16 w-full flex gap-4 items-center rounded-md bg-gray-100 p-2 animate-pulse">
          <div class="bg-gray-200 h-full aspect-16/9 w-fit animate-pulse"></div>
          <div class="bg-gray-200 h-4 w-1/3 animate-pulse rounded-full"></div>
        </div>
        <div class="h-16 w-full flex gap-4 items-center rounded-md bg-gray-100 p-2 animate-pulse">
          <div class="bg-gray-200 h-full aspect-16/9 w-fit animate-pulse"></div>
          <div class="bg-gray-200 h-4 w-1/3 animate-pulse rounded-full"></div>
        </div>
        <div class="h-16 w-full flex gap-4 items-center rounded-md bg-gray-100 p-2 animate-pulse">
          <div class="bg-gray-200 h-full aspect-16/9 w-fit animate-pulse"></div>
          <div class="bg-gray-200 h-4 w-1/3 animate-pulse rounded-full"></div>
        </div>
        <div class="h-16 w-full flex gap-4 items-center rounded-md bg-gray-100 p-2 animate-pulse">
          <div class="bg-gray-200 h-full aspect-16/9 w-fit animate-pulse"></div>
          <div class="bg-gray-200 h-4 w-1/3 animate-pulse rounded-full"></div>
        </div>
        <!-- Add more loading skeletons as needed -->
      </div>
    `
    clearTimeout(this.timeout) 

    this.timeout = setTimeout(() => {
      const url = this.buildParams()
      this.navigate(url.toString())
    }, 300)
  }

  buildParams() {
    let url = new URL(this.formTarget.action)
    const formData = new FormData(this.formTarget)
    let searchParams = new URLSearchParams();

    formData.forEach((value, key) => {
      if (key.endsWith('[]')) {
        searchParams.append(key, value)
      } else {
        searchParams.append(key, value)
      }
    })

    url.search = searchParams.toString()
    return url.toString()
  }

  startListeningToNav() {
  this.element.querySelectorAll('.kaminarilink').forEach(
    (span) => {
      if (span.querySelector('a')) {
        span.querySelector('a').addEventListener('click', (e) => {
          e.preventDefault();
          this.navigate(e.currentTarget.href)
          }
        )
      }
    }
  )
  }

  navigate(url) {
    fetch(url, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      this.resultsTarget.innerHTML = data.partial
      this.startListeningToNav()
    })
  }
}
