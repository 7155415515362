import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'actions' ]

  displayActions() {
    this.actionsTarget.classList.toggle('hidden')
  }

}
