import Rails from "@rails/ujs"
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['input']
  connect() {
  }
  tag(e) {
    const trigger = e.currentTarget.dataset.trigger
    const trixEditor = this.inputTarget.editor
    const currentPosition = trixEditor.getSelectedRange()[0]
        trixEditor.setSelectedRange([currentPosition, currentPosition])
        trixEditor.insertString(trigger)
  }
}