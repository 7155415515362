import { Controller } from 'stimulus'
import Swiper from 'swiper/swiper-bundle';


export default class extends Controller {
  static targets = [ 'swiper', 'pagination', 'slidesContainer', 'swiperPreviousLink', 'swiperNextLink', 'swiperPreviousBtn', 'swiperNextBtn', 'modal', 'killBtn', 'growBtn']
  static values = { id: Number, swipersNb: Number }

  connect() {
    this.swiperTargets.forEach((target, index) => {this._buildSwipers(index)})
    this.activeSwiperIndex = 0
    this.paginationTargets[0].style.display = 'none';
    this.swiperPreviousBtnTargets[0].style.display = 'none'; 
  }

  nextSlide(e) {
    const index = e.currentTarget.dataset.swiperIndex
    this.activeSwiperIndex = index
    this.paginationTargets[index].style.display = ''; // on ne met rien car on enlève juste 'none'
    this.swiperNextLinkTargets[index].click()
  }

  previousSlide(e) {
    const index = e.currentTarget.dataset.swiperIndex
    this.activeSwiperIndex = index
    this.paginationTargets[index].style.display = ''; // on ne met rien car on enlève juste 'none'
    this.swiperPreviousLinkTargets[index].click()
  }

  _buildSwipers(index) {
    const identifier = `.swiper-${this.idValue}-${index}`
    var that = this

    this.swiper = new Swiper(identifier, {
      allowTouchMove: true,
      pagination: {
        el: `.swiper-pagination-${this.idValue}-${index}`,
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: `.swiper-button-next-${this.idValue}-${index}`,
        prevEl: `.swiper-button-prev-${this.idValue}-${index}`,
      },
      on: {
        reachBeginning: () => {
          this.swiperPreviousBtnTargets[index].style.display = 'none'; 
        },
        reachEnd: () => {
            this.swiperNextBtnTargets[index].style.display = 'none'; 
        },
         slideChange: () => {
          //  if (window.innerWidth > 768) {
           this._toggleNavBtns(index)
           this.syncSwipers()
          //  }
         },
      },
    })
  }

  syncSwipers() {
    const slideIndex = this.swiperTargets[this.activeSwiperIndex].swiper.activeIndex
    if (this.swiperTargets.length > 1 ) {
      const slaveSwiperIndex = this.activeSwiperIndex == 0 ? 1 : 0
      const slaveSwiper = this.swiperTargets[slaveSwiperIndex]
      slaveSwiper.swiper.slideTo(slideIndex, 0, false)
    }
  }

 

  toggleKillSwitch() {
    
    if (document.querySelector('#navbar')) {
        document.querySelector('#navbar').classList.toggle('hidden')
    }
    if (document.querySelector('.write-container-left')) {
      document.querySelector('.write-container-left').classList.toggle('lg:block')
    } else if (document.querySelector('.read-container-left')){
      document.querySelector('.read-container-left').classList.toggle('hidden')
    }
  }

  _toggleNavBtns(index) {
    if (this.swiperPreviousLinkTargets[index].getAttribute('aria-disabled') === 'true') {
        this.swiperPreviousBtnTargets[index].style.display = 'none';
    } else {
        this.swiperPreviousBtnTargets[index].style.display = ''; // on ne met rien car on enlève juste 'none'
    }

    if (this.swiperNextLinkTargets[index].getAttribute('aria-disabled') === 'true') {
        this.swiperNextBtnTargets[index].style.display = 'none'; 
    } else {
        this.swiperNextBtnTargets[index].style.display = ''; // on ne met rien car on enlève juste 'none'
    }
  }
}
