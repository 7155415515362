import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']


  keyinput(e) {
    e.preventDefault()
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.submit()
    }, 300)
  }

}
