import { Controller } from 'stimulus';
import Swal from 'sweetalert2'


export default class extends Controller {
  static targets = ['formResponseForm', 'modeWrapper', 'block', 'blocks', 'formQuestion', 'formQuestions', 'formQuestionChoice', 'formQuestionChoices'];

  toggleMode(event) {
    event.preventDefault();
    this.element.dataset.mode = this.element.dataset.mode === 'read' ? 'write' : 'read';
    
    const path = this.modeWrapperTarget.dataset.contentLoaderUrlValue;
    const newUrl = new URL(path, window.location.origin);
    
    newUrl.searchParams.set('mode', this.element.dataset.mode);
    
    this.modeWrapperTarget.dataset.contentLoaderUrlValue = newUrl; // there is a onchange event in the content loader controller
  }


  submitForm(event) {
    event.preventDefault();
    // We need to let the classic html5 form validation to happen
    // If the form is not valid, we don't want to submit it
    if (this.formResponseFormTarget.checkValidity()) {
      // // We let the event go through
      // We first resubmit one last time the form to get the latest data
      // And then we Submit the form to handle form status
      const submitFormForm = event.currentTarget.closest('form');

      fetch(this.formResponseFormTarget.action, {
        method: this.formResponseFormTarget.method,
        body: new FormData(this.formResponseFormTarget),
        headers: { "Accept": "application/json" }
      })
        .then(response => response.json())
        .then((data) => {
          submitFormForm.requestSubmit();
        })
        .catch(error => console.error('Error:', error));
    } else {
      // We propagate the validation error
      form.requestSubmit();
      this.formResponseFormTarget.reportValidity();
    }
  }

  changedFormQuestionType(event) {
    event.preventDefault();
    console.log('changedFormQuestionType');
    const form = event.currentTarget
    const className = 'form_question';
    this._fetchData(form, className);
  }

  addBlock(event) {
    event.preventDefault();
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  deleteBlock(event) {
    event.preventDefault();
    const form = event.currentTarget.closest('form');
    const className = 'block';

    Swal.fire({
      title: I18n.t('js.form_builder.delete_block.are_you_sure'),
      text: I18n.t('js.form_builder.delete_block.no_revert'),
      icon: 'warning',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._fetchData(form, className);
      }
    });
  }

  duplicateBlock(event) {
    event.preventDefault();
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  addFormQuestion(event) {
    event.preventDefault();
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  deleteFormQuestion(event) {
    event.preventDefault();
    const form = event.currentTarget.closest('form');
    const className = 'form_question';

    Swal.fire({
      title: I18n.t('js.form_builder.delete_form_question.are_you_sure'),
      text: I18n.t('js.form_builder.delete_form_question.no_revert'),
      icon: 'warning',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._fetchData(form, className);
      }
    });
  }

  duplicateFormQuestion(event) {
    event.preventDefault();
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  addFormQuestionChoice(event) {
    event.preventDefault();
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  deleteFormQuestionChoice(event) {
    event.preventDefault();
    // No need for Swal confirmation as it's a simple delete
    const [form, className] = this._getFormAndClassName(event);
    this._fetchData(form, className);
  }

  _getFormAndClassName(event) {
    const form = event.currentTarget.closest('form'); // Get the form element from the button_to
    const className = event.currentTarget.dataset.className; // Get the class name from the button_to
    return [form, className];
  }

  _fetchData(form, className) {
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: { "Accept": "application/json" }
    })
      .then(response => response.json())
      .then(data => this._handleCallbacks(data, className))
      .catch(error => console.error('Error:', error));
  }

  _handleCallbacks(data, className) {
    switch (className) {
      case 'block':
        this._blockCallback(data);
        break;
      case 'form_question':
        this._formQuestionCallback(data);
        break;
      case 'form_question_choice':
        this._formQuestionChoiceCallback(data);
        break;
      default:
        console.error('Error: Callback not found');
    }
  }

  _blockCallback(data, className) {
    if (data.delete) {
      const block = this.blockTargets.find(target => target.dataset.blockId == data.block_id);
      block.remove();
      return;
    } else if (data.duplicate) {
      const block = this.blockTargets.find(target => target.dataset.blockId == data.source_block_id);
      block.insertAdjacentHTML('afterend', data.html);
      block.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
      return;
    } else {
      this.blocksTarget.insertAdjacentHTML('beforeend', data.html);
      // scroll down to the new block
      const newBlock = this.blocksTarget.lastElementChild;
      newBlock.scrollIntoView({ behavior: 'smooth' });
    }
  }

  _formQuestionCallback(data) {
    // Update is used when user changes the input type of the form question
    if (data.update) {
      const formQuestion = this.formQuestionTargets.find(target => target.dataset.formQuestionId == data.form_question_id);
      formQuestion.outerHTML = data.html;
    } else if (data.delete) {
      const formQuestion = this.formQuestionTargets.find(target => target.dataset.formQuestionId == data.form_question_id);
      formQuestion.remove();
    } else if (data.duplicate) {
      const formQuestion = this.formQuestionTargets.find(target => target.dataset.formQuestionId == data.source_form_question_id);
      formQuestion.insertAdjacentHTML('afterend', data.html);
      formQuestion.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
    } else {
      const formQuestionList = this.formQuestionsTargets.find(target => target.dataset.blockId == data.block_id);
      formQuestionList.insertAdjacentHTML('beforeend', data.html);
      const newFormQuestion = formQuestionList.lastElementChild;
      newFormQuestion.scrollIntoView({ behavior: 'smooth' });
    }
  }

  _formQuestionChoiceCallback(data) {
    if (data.delete) {
      const formQuestionChoice = this.formQuestionChoiceTargets.find(target => target.dataset.formQuestionChoiceId == data.form_question_choice_id);
      formQuestionChoice.remove();
      return;
    } else {
      const formQuestionChoiceList = this.formQuestionChoicesTargets.find(target => target.dataset.formQuestionId == data.form_question_id);
      formQuestionChoiceList.insertAdjacentHTML('beforeend', data.html);
    }
  }
}