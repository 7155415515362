import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['results', 'pagination', 'loading', 'nextPageResultsContainer']

  loadMore() {
    const results = this.resultsTarget
    const endOfResults = this.paginationTarget
    const nextPageLink = endOfResults.querySelector('a[rel="next"]')
    if (endOfResults === null || nextPageLink === null) {
      return
    }
    const nextPageUrl = nextPageLink.href
    const nextPageValue = parseInt(endOfResults.querySelector('a[rel="next"]').href.match(/page=(\d+)/)[1])
    const totalPagesValue = parseInt(endOfResults.dataset.totalPages)


    
    // If the user has scrolled to the bottom of the results div, then load more
    // by clicking the pagination link (next page)
    if (results.scrollTop + results.clientHeight >= results.scrollHeight) {
      if (nextPageValue > totalPagesValue) {
        // We hide the loading spinner
        this.loadingTarget.classList.add('hidden')
        return
      }
      // We show the loading spinner
      this.loadingTarget.classList.remove('hidden')
      
      // We check if we have reached the last page
      // We fetch the next page
      fetch(nextPageUrl)
        .then(response => response.text())
        .then((data) => {
          // We hide the loading spinner
          this.loadingTarget.classList.add('hidden')
          this.nextPageResultsContainerTarget.insertAdjacentHTML('beforeend', data)
          // We change the url of the pagination link to the next page + 1
        })
      endOfResults.querySelector('a[rel="next"]').href = nextPageLink.href.replace(/page=\d+/, `page=${parseInt(nextPageValue) + 1}`)
    }
  }
}