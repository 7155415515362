import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ ]

  duplicate(event) {
    event.preventDefault()
    event.stopPropagation()
    console.log('Duplicate video part')

    // button_to in rails generates a form with a button inside
    const target = event.currentTarget.closest('form')
    const url = target.action


    const contentCard = this.element.closest('.writing-modal-content-video')

    Rails.ajax({
      url: url,
      type: "post",
      dataType: "json",
      success: (data) => {
        const videoPartsController = this.application.getControllerForElementAndIdentifier(contentCard, 'video--video-parts');
        videoPartsController.insertVideoCardHTML(data.video_html, data.card_html, data.id, data.position)
      },
      error: (data) => {
        console.error(data)
      }
    })
  }
}
