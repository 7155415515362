import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'handleNumber' ]

  connect() {
    this.patchUrl = `/handover_review_categories/:id/reorder`
    this.sortable = Sortable.create(this.element, {
      group: 'handover_review_category',
      delayOnTouchOnly: 150,
      animation: 150,
      ghostClass: "bg-gray-400",
      handle: ".reorder-handle",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    const id = event.item.dataset.handoverReviewCategoryId
    const data = new FormData()

    data.append('position', event.newIndex + 1)

    Rails.ajax({
      url: this.patchUrl.replace(":id", id),
      type: 'PATCH',
      data: data
    })

    this.resetNumbers()
  }

  resetNumbers() {
    this.handleNumberTargets.forEach((e, i) => e.innerText = i + 1 )
  }
}
