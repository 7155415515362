import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { fetchWithToken } from '../application/stimulus_helper'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [ 'actions', 'chevrond', 'chevronu', 'user', 'user_form', 'newroles', 'selector' ]
  static values = { userEmail: String, userIndex: Number, actionsUserIndex: Number, handover: Number, askpublish: Boolean }

  // Click on role -> create sharing
  addSharing(e) {
    this.email = e.currentTarget.hasAttribute('data-email') ? e.currentTarget.dataset.email : e.currentTarget.closest("li").dataset.sharingsUserEmailValue
    this.role = e.currentTarget.dataset.role
    this.form = e.currentTarget.closest("form")
    e.currentTarget.closest("form").querySelector('#sharing_email').value = this.email
    e.currentTarget.closest("form").querySelector('#sharing_role').value = this.role

    const formData = new FormData(this.form)

    fetchWithToken(this.form.action, {
      method: 'POST',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if(document.querySelector(`.${data.role}s-container`)) {
        document.querySelector(`.${data.role}s-container`).innerHTML = data.partial;
        document.querySelector(`#${data.role}s_size`).innerText = Number(document.querySelector(`#${data.role}s_size`).innerText) + 1
      } else {
        Swal.fire({
          text: I18n.t('js.sharings.success'),
          icon: "success"
        })
      }
      if (data.role === 'reader' && this.askpublishValue) {
        document.querySelector(`#${data.role}s_size`).insertAdjacentHTML('afterend', data.cta_partial)
        fadeShowElements(document.querySelector('#askpublishmodal'))
      }
      // TODO Temporary solution before new sharing form
      window.location.reload()
    })
  }
}
