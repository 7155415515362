import Rails from "@rails/ujs"
import { fetchWithToken } from '../../../application/stimulus_helper'
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'modal', 'input', 'textinput', 'form', 'loadingSpinner']

  openmodal() {
    showElements(this.modalTarget)
  }

  hidemodal() {
    hideElements(this.modalTarget)
  }

  ask(e) {
    e.preventDefault()
    toggleElements(this.loadingSpinnerTarget, this.formTarget)
    const url = `${this.formTarget.action}?query=${this.inputTarget.value}`
    fetchWithToken(url, {
      method: 'GET',
      headers: {"Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
        this.hidemodal()
        toggleElements(this.loadingSpinnerTarget, this.formTarget)
        this.repeatQuestion(data.question)
        this.simulateTyping(data.answer)
    })
  }

  repeatQuestion(string) {
    this.textinputTarget.value = `${this.textinputTarget.value}<br><br><strong style="color: green;">${string} :</strong>`
    this.textinputTarget.scrollTo({
      top: this.textinputTarget.scrollHeight
     })
  }

  async simulateTyping(string) {
    let i = 0
    while (i < string.length) {
      this.textinputTarget.value = this.textinputTarget.value.replace("</div><div>", "")
      this.textinputTarget.scrollTo({
       top: this.textinputTarget.scrollHeight,
      // behavior: 'smooth'
      })
      if (string[i].match(/\|/)) {
          this.textinputTarget.value = `${this.textinputTarget.value}<br>`
      } else if (i < string.length - 1) {
          if (string[i+1].match(/\s/)) {
            this.textinputTarget.value = this.textinputTarget.value + string[i] + " "
          } else {
            this.textinputTarget.value = this.textinputTarget.value + string[i]
          }
      } else {
      this.textinputTarget.value = this.textinputTarget.value + string[i]
      }
      i++
      await this.sleep(25);
    }
    this.resetForm()
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

 // bulkfill(string, presentText) {
 //   for( let i= 0; i < string.length; i++) {
 //     if (string[i].match(/\|/)) {
 //       presentText.insertAdjacentHTML('beforeend', "<br>")
 //   } else {
 //       presentText.insertAdjacentHTML('beforeend', string[i]);
 //     }
 //   }
 // }

  resetForm() {
    this.formTarget.reset()
  }
}
