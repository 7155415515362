import { Controller } from 'stimulus';

// If the first checkbox is checked (all), we uncheck all the other checkboxes
// If another checkbox is checked, we uncheck only the first checkbox

export default class extends Controller {
  static targets = ['checkbox', 'caretUp', 'caretDown', 'mainButton', 'list']

  connect() {
    this.caretSvg = this.mainButtonTarget.querySelector('svg')
  }

  checkChange(e) {
    if (e.currentTarget.dataset.index == 0) {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = false
      })
      e.currentTarget.checked = true
    } else {
      this.checkboxTargets[0].checked = false
      // if all checkboxes are unchecked, we check the first one
      if (this.checkboxTargets.filter((checkbox) => checkbox.checked).length == 0) {
        this.checkboxTargets[0].checked = true
      }
    }
    this._changeMainButton()
  }

  purge() {
    console.log('purge')
    // We uncheck all checkboxes and check the first one
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.checkboxTargets[0].checked = true
    this._changeMainButton()
  }

  scroll(e) {
    const div = e.currentTarget
    if (div.scrollTop == 0) {
      this.caretUpTarget.classList.remove('hidden')
      this.caretDownTarget.classList.add('hidden')
    } else if (div.scrollTop + div.clientHeight == div.scrollHeight) {
      this.caretUpTarget.classList.add('hidden')
      this.caretDownTarget.classList.remove('hidden')
    }
  }

  scrollToBottom() {
    this.listTarget.scrollTop = this.listTarget.scrollHeight
  }

  scrollToTop() {
    this.listTarget.scrollTop = 0
  }

  _changeMainButton() {
    if (this.checkboxTargets.filter((checkbox) => checkbox.checked).length == 1) {
      const checkedCheckbox = this.checkboxTargets.find((checkbox) => checkbox.checked)
      const label = this.element.querySelector(`label[for="${checkedCheckbox.id}"]`)
      // We cannot use innerText because it will remove the caret
      this.mainButtonTarget.innerHTML = `${label.innerText} ${this.caretSvg.outerHTML}`
    } else {
      const checkedCheckboxes = this.checkboxTargets.filter((checkbox) => checkbox.checked)
      const firstLabel = this.element.querySelector(`label[for="${checkedCheckboxes[0].id}"]`)
      this.mainButtonTarget.innerHTML = `${firstLabel.innerText} (+ ${checkedCheckboxes.length - 1}) ${this.caretSvg.outerHTML}`
    }
  }
}