import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'item', 'icon' ]
  static classes = [ 'hidden' ]
  static values  = { chapterId: Number }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    window.addEventListener(`navigationRevealChapter${this.chapterIdValue}`, (event) => {
      this.show()
    })
  }

  toggle() {
    if (!this.hasIconTarget || !this.hasItemTarget) return

    this.iconTarget.classList.toggle('-rotate-180')
    this.iconTarget.classList.toggle('fill-primary')
    this.iconTarget.classList.toggle('fill-secondary')
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  show() {
    if (!this.hasIconTarget || !this.hasItemTarget) return

    this.iconTarget.classList.add('-rotate-180')
    this.iconTarget.classList.replace('fill-secondary', 'fill-primary')
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
  }

  hide() {
    if (!this.hasIconTarget || !this.hasItemTarget) return

    this.iconTarget.classList.remove('-rotate-180')
    this.iconTarget.classList.replace('fill-primary', 'fill-secondary')
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
}
