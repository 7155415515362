import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal' ]
  static values = {
    contentId: String,
    contentType: String,
  }

  connect() {
    this.body = document.querySelector('body')
    this.html = document.querySelector('html')
    this.bodyOverflowValue = this.body.style.overflow || 'visible'
    this.htmlOverflowValue = this.html.style.overflow || 'visible'
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';
  }

  open(e) {
    let modal = this.modalTarget
    if (e.currentTarget.dataset.modalIndex) {
      modal = this.modalTargets[e.currentTarget.dataset.modalIndex]
    }
    fadeShowElements(modal)
    this.body.style.overflow = 'hidden'
    this.html.style.overflow = 'hidden'

    if (this.hasContentTypeValue && this.hasContentIdValue)
      this._addParamsToUrl(this.contentTypeValue, this.contentIdValue)

    if (document.querySelector("#chevron_collapse"))
      document.querySelector("#chevron_collapse").click()
  }

  hideModal(e) {
    if (e.currentTarget == e.srcElement && this.allowBackgroundClose) {
      this.closeModal()
    }
  }

  closeModal() {
    this.body.style.overflow =  this.bodyOverflowValue
    this.html.style.overflow = this.htmlOverflowValue
    stopAllPlayingMedias()
    fadeHideElements(this.modalTargets)
    this._removeParamsFromUrl()
  }

  _addParamsToUrl(type, id) {
    const url = new URL(window.location.href)
    url.searchParams.set('content_type', type)
    url.searchParams.set('content_id', id)
    window.history.pushState({}, '', url)
  }

  _removeParamsFromUrl() {
    const url = new URL(window.location.href)
    url.searchParams.delete('content_type')
    url.searchParams.delete('content_id')
    window.history.pushState({}, '', url)
  }

  disconnect() {
    this.body.style.overflow =  this.bodyOverflowValue
    this.html.style.overflow = this.htmlOverflowValue
  }
}
