import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { sharingId: Number }
  static targets= ['modalcontent']

  connect() {
    console.log(this.sharingIdValue)
    this.channel = consumer.subscriptions.create(
      { channel: "CertificationChannel", id: this.sharingIdValue },
      { received: (data) => {
          this.modalcontentTarget.innerHTML = data.html
        }
      }
    )
  }
}
