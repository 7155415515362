import { Controller } from 'stimulus'
import { passwordStrength } from 'check-password-strength'


export default class extends Controller {
  static targets = ["password", "passwordConfirmation", "uppercase", "lowercase", "number", "special", "length", "passwordMatch", "requirements"]

  checkStrength() {
    const password = this.passwordTarget.value
    this.showRequirements()
    this.checkPasswordRequirements(password)
    this.checkPasswordMatch()
  }

  checkPasswordRequirements(password) {
    const strength = passwordStrength(password).value

    this.toggleRequirement(this.uppercaseTarget, /[A-Z]/.test(password))
    this.toggleRequirement(this.lowercaseTarget, /[a-z]/.test(password))
    this.toggleRequirement(this.numberTarget, /[0-9]/.test(password))
    this.toggleRequirement(this.specialTarget, /[#!?@$%^&*-]/.test(password))
    this.toggleRequirement(this.lengthTarget, password.length >= 8 && password.length <= 128)
  }

  checkPasswordMatch() {
    const password = this.passwordTarget.value
    const confirmation = this.passwordConfirmationTarget.value
    const match = password === confirmation && password.length > 0

    this.toggleRequirement(this.passwordMatchTarget, match)
  }


  toggleRequirement(element, condition) {
    if (condition) {
      element.classList.remove('text-red-500')
      element.classList.add('text-green-500')
    } else {
      element.classList.remove('text-green-500')
      element.classList.add('text-red-500')
    }
  }

  showRequirements() {
    this.requirementsTarget.classList.remove('hidden')
  }
}