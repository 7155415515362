import { Controller } from 'stimulus'
import Swal from 'sweetalert2'
import Rails from '@rails/ujs'
import { fetchWithToken } from '../application/stimulus_helper';

export default class extends Controller {
  static targets = [ 'handoverDate', 'handoverDateForm', 'handoverDateInput', 'pencilDate', 'container', 'tickDate' ]

  // TODO clean this
  displayEditDate() {
    const form = this.handoverDateFormTarget
    const date = this.handoverDateTarget
    const input = this.handoverDateInputTarget
    const pencil = this.pencilDateTarget
    const tick = this.tickDateTarget
    showElements(form);
    hideElements(date);
    hideElements(pencil);
    showElements(tick);
    input.focus();
    input.size = input.value.length;
  }


  editDate(event) {
    const form = this.handoverDateFormTarget
    event.preventDefault()
    const date = this.handoverDateTarget
    const input = this.handoverDateInputTarget
    const pencil = this.pencilDateTarget
    const formData = new FormData(form)
    const tick = this.tickDateTarget

    fetchWithToken(form.action, {
      method: 'PATCH',
      headers: {"Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then((data) => {
      if (data.updated) {
        showElements(date)
        showElements(pencil)
        hideElements(form)
        hideElements(tick)
        this.containerTarget.innerHTML = data.partial
      }
    })
  }

}
