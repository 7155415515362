import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label'];

  select(event) {
    event.preventDefault();
    // We set the aria-checked attribute to true on the selected label
    this.labelTargets.forEach((label) => {
      label.setAttribute('aria-checked', false);
    });
    event.currentTarget.setAttribute('aria-checked', true);
    // We add the checked attribute to the selected radio button
    const radio = event.currentTarget.querySelector('input[type="radio"]');
    radio.checked = true;
    // We dispatch a change event on the radio button to notify the browser
    // that its value has changed
    radio.dispatchEvent(new Event('change'));
  }
}