import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleable']

  toggle(e) {
    const currentState = e.currentTarget.dataset.switchedOn === 'true'
    e.currentTarget.dataset.switchedOn = !currentState
    this.toggleableTarget.classList.toggle('hidden')
  }
}
