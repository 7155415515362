// app/javascript/controllers/session_timeout_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static values = { 
    pollFrequency: Number,
  }

  initialize() {
    this.pollFrequency = this.pollFrequencyValue || 120; // Default polling frequency to 5 seconds if not specified
    console.log(this.pollFrequency)
    this.lastActive = new Date().valueOf();
    this.setupActivityListeners();
    this.startPolling();
  }

  // Use Stimulus action to track user activities
  trackActivity() {
    this.lastActive = new Date().valueOf();
  }

  // Start the session timeout polling
  startPolling() {
    this.pollForSessionTimeout();
  }

  // Method to add event listeners for user activity
  setupActivityListeners() {
    ['mousemove', 'scroll', 'click', 'keydown'].forEach(activity =>
      document.addEventListener(activity, () => this.trackActivity())
    );
  }

  // Method to poll for session timeout
  pollForSessionTimeout() {
    console.log('Polling for session timeout');
    if (window.location.pathname.includes('sign_in')) { return; }

    if ((Date.now() - this.lastActive) < (this.pollFrequency * 1000)) {
      setTimeout(() => { this.pollForSessionTimeout() }, this.pollFrequency * 1000);
      return;
    }

    fetch('/check_session_timeout',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        console.log(data);
        if (data.ttl_to_timeout <= 0 && data.current_user != false) {
          // We purge the history to prevent the user from going back to the previous page
          window.history.pushState({}, '', '/session_timeout');
          window.location.href = '/session_timeout';
        }
      })
      .catch(error => console.error('There has been a problem with your fetch operation:', error))
      .finally(() => {
        setTimeout(() => { this.pollForSessionTimeout() }, this.pollFrequency * 1000);
      });
  }
}
