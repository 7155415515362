import { Controller } from 'stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [ 'input', 'img' ]

  connect() {
    window.addEventListener("dragover", function(e) {
      e = e || event
      e.preventDefault()
    }, false);
    window.addEventListener("drop", function(e) {
      e = e || event;
      e.preventDefault();
    }, false);
    this.reader = new FileReader();
    this._switchBackGroundColor()
  }

  dropFile(event) {
    var list = new DataTransfer();
    if (event.dataTransfer.items) {
      var file = event.dataTransfer.items[0].getAsFile()
    } else {
      var file = event.dataTransfer.files[0]
    }

    if (!/image/.test(file.type)) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.dropfile.not_image'),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else if (file.size >= 22009610) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.dropfile.too_big.title'),
        text: I18n.t('js.sweet_alerts.dropfile.too_big.content', { size: this._bytesToHumanString(file.size) }),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else {
      list.items.add(file)

      this.inputTarget.files = list.files
      this.displayFileInPreviewObject()
    }

  }

  openOSWindow() {
    this.inputTarget.click()
  }

  removePhoto() {
    this.imgTarget.src = ''
    this.inputTarget.files = null
    this._switchBackGroundColor()
  }

  displayFileInPreviewObject() {
    this.reader.onload = (ev) => {
      this.imgTarget.src = ev.currentTarget.result
      this._switchBackGroundColor()
    }
    this.reader.readAsDataURL(this.inputTarget.files[0])
  }

  _switchBackGroundColor() {
    const value = this.imgTarget.getAttribute('src') === '' ? 0 : 1
    this.imgTarget.style.opacity = value
  }

  _bytesToHumanString(size) {
    const i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }
}
