// Usage example with simple toggle:
// <div data-controller='toggle' class="m-2" data-toggle-open-value="false"">
//   <div data-action='click->toggle#toggle touch->toggle#toggle' class="bg-gray-900 text-white p-6">
//     What is the question?
//   </div>
//   <div data-toggle-target='toggleable' class="m-4 hidden">
//     <p>This is the answer</p>
//   </div>
// </div>

// Documentation: https://github.com/excid3/tailwindcss-stimulus-components#toggle

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable', 'caret']
  static values = { 
    open: Boolean,
    attribute: String,
    animate: {type: Boolean, default: false},
    actionDataset: {type: Boolean, default: false}
  }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    console.log(event)
    if (event.target.getAttribute('type') != 'checkbox') {
      event.preventDefault();
    }

    this.openValue = !this.openValue
    
    if(this.hasAnimateValue && this.toggleableTarget.dataset.animate === "close") {
      this.toggleableTarget.dataset.animate = "open"
    } else if(this.hasAnimateValue && this.toggleableTarget.dataset.animate === "open") {
      this.toggleableTarget.dataset.animate = "close"
    }
    if (this.actionDatasetValue) {
      const button = event.target.closest('[data-action]')
      button.dataset.open = this.openValue
    }



    if (this.hasCaretTarget) {
      if (this.caretTarget.classList.contains("fa-angle-up")) {
        this.caretTarget.classList.remove("fa-angle-up")
        this.caretTarget.classList.add("fa-angle-down")

      } else {
        this.caretTarget.classList.remove("fa-angle-down")
        this.caretTarget.classList.add("fa-angle-up")
      }
    }
  }

  hide(event) {
    event.preventDefault();

    this.openValue = false;
  }

  show(event) {
    event.preventDefault();

    this.openValue = true;
  }

  openValueChanged() {
    if (!this.toggleClass) { return }
    
    this.toggleableTargets.forEach(target => {
      if (this.hasAttributeValue) {
        if (this.openValue) {
          target.removeAttribute(this.attributeValue);
        } else {
          target.setAttribute(this.attributeValue, true);
        }


      } else {
        target.classList.toggle(this.toggleClass)
      }
    })
  }
}
