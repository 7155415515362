import Rails from "@rails/ujs"
import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['form',
    'formContainer',
    'instructionsContainer',
    'globalContainer',
    'toggleBtn',
    'instructionsInput',
    'noInstructionsTitle',
    'addInstructionsBtn',
    'expandBtn',
  ]

  static values = {expanded: Boolean}

  connect() {
    if (this.hasExpandedValue && this.expandedValue === true && this.hasExpandBtnTarget ) {
      this.expand()
    }
  }

  expand() {
    setTimeout(() => {
      this.expandBtnTarget.click();
    }
    , 1000);
  }


  reveal(e) {
    if(this.hasInstructionsContainerTarget) {
      hideElements(this.instructionsContainerTarget, this.toggleBtnTarget)
    } else {
      hideElements(this.addInstructionsBtnTarget)
    }
    fadeShowElements(this.formContainerTarget)
  }

  hide(e) {
    hideElements(this.formContainerTarget)
    if(this.hasInstructionsContainerTarget) {
      fadeShowElements(this.instructionsContainerTarget, this.toggleBtnTarget)
    } else {
      fadeShowElements(this.noInstructionsTitleTarget, this.addInstructionsBtnTarget)
    }
  }

  hideAll() {
    fadeHideElements(this.element)
  }

  delete() {
    Swal.fire({
      title: I18n.t('js.sweet_alerts.instructions.delete.title'),
      icon: "warning",
      confirmButtonText: I18n.t('js.sweet_alerts.instructions.delete.confirm_button_text'),
      showCancelButton: true,
      cancelButtonText: I18n.t('js.sweet_alerts.instructions.delete.cancel_button_text'),
      dangerMode: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.instructionsInputTarget.value = ''
        this.submitForm()
      } else {

      }
    })
  }

  submitForm() {
    const formData = new FormData(this.formTarget)
    const partialTarget = this.element.closest('.instructions-banner')

    Rails.ajax({
             url: this.formTarget.action,
             type: "patch",
             dataType: "json",
             data: formData,
             success: function(data) {
               partialTarget.outerHTML = data.partial
             }.bind(this),

           })
  }
}
