import { Controller } from 'stimulus';
import consumer from "../../channels/consumer"

export default class extends Controller {
  static values = { handoverId: Number }

  connect() {
    this._broadcast()
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _broadcast() {
    this.channel = consumer.subscriptions.create(
      { channel: "Video::ProcessingChannel", id: this.handoverIdValue },
      { received: this._received.bind(this) }
    )
  }

  _received(data) {
    const event = new CustomEvent(`videoPartProcessing${data.video_part_id}:end`, { 
      detail: { thumbnail_url: data.thumbnail_url }
    })
    document.querySelector('body').dispatchEvent(event)
  }
}
