import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "input", "results", "nbResults"]

  search() {
    // We could later parametrize the query param name
    let url = `${this.formTarget.action}?query=${this.inputTarget.value}`

    // We include eventual existing params in the URL
    // to keep the pagination working
    const params = new URLSearchParams(window.location.search)
    params.forEach((value, key) => {
      if (key !== "query") {
        url += `&${key}=${value}`
      }
    })

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.fetchResults(url)
    }, 100)
  }

  fetchResults(url) {
    this.insertSpinner(this.resultsTarget)
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" },
    })
    .then(response => response.text())
    .then((data) => {
      this.resultsTarget.innerHTML = data
      if (this.hasNbResultsTarget) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, "text/html")
        const nbResults = doc.querySelector("#nb-results").innerText
        this.nbResultsTarget.innerHTML = nbResults
      }
    })
  }

  insertSpinner(target) {
    if (this.hasResultsTargetTarget) {
      this.resultsTarget.innerHTML = '<div class="h-48 text-center py-20 leading-8 m-5 rounded-xl font-sans"><i class="mr-2 fas fa-spinner fa-spin"></i>Loading</div>'
    }
  }
}