import consumer from "./consumer";

consumer.subscriptions.create("OnlineChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    let onlineUsers = document.getElementById("online-users");
    let el = document.querySelector(`[data-user-id="${data.user_id}"]`);
    if (onlineUsers == null) return;
    if (data.online && el == null) {
      onlineUsers.insertAdjacentHTML("beforeend", data.html);
    } else if (!data.online && el != null) {
      el.remove();
    }
  },
});