import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'button' ]
  static values = { prefix: String }

  connect() {
    this.updateButtons()
  }

  toggle(event) {
    const btn = event.currentTarget

    const radioId = `${this.prefixValue}${btn.dataset.radioButtonId}`
    const radioButton = this.element.querySelector(`#${radioId}`)

    radioButton.checked = !radioButton.checked

    this.updateButtons()
  }

  updateButtons() {
    this.buttonTargets.forEach((e) => { this.updateState(e) })
  }

  updateState(btn) {
    const radioId = `${this.prefixValue}${btn.dataset.radioButtonId}`
    const radioButton = this.element.querySelector(`#${radioId}`)
    const elements = Array.from(btn.querySelectorAll('*'))

    elements.forEach((elem) => {
      if (radioButton.checked) {
        elem.className = elem.dataset.checkedStateClass
      } else {
        elem.className = elem.dataset.uncheckedStateClass
      }
    })
  }
}
