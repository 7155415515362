import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      menuItemLimit: 3,
      menuShowMinLength: 0,
      lookup: 'name',
      collection: [
        {
          trigger: '@',
          values: this.fetchUsers,
        },
        {
          trigger: '#',
          values: this.fetchContents,
        }
      ]
    })
    this.tribute.attach(this.fieldTarget)
    this.fieldTarget.addEventListener('tribute-replaced', this.replaced)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  fetchContents(text, callback) {
    fetch(`/contents/mentions.json?query=${text}`)
    .then(response => response.json())
    .then(contents => callback(contents))
    .catch(error => callback([]))
  }

  fetchUsers(text, callback) {
    fetch(`/users/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

  replaced(e) {
    console.log(e)
    let mention = e.detail.item.original
    if (mention.sgid) {
      let attachment = new Trix.Attachment({
        content: mention.content,
        sgid: mention.sgid,
      })
      this.editor.insertAttachment(attachment)
      this.editor.insertString(" ")
    } else {
      this.editor.insertHTML(mention.content)
    }
  }

  _pasteHtml(html, startPos, endPos) {
    let range = this.editor.getSelectedRange()
    let position = range[0]
    let length = endPos - startPos

    this.editor.setSelectedRange([position - length, position])
    this.editor.deleteInDirection("backward")
  }
}
