import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import Swal from 'sweetalert2'

export default class extends Controller {
  // static targets = ['template', 'associations', 'fields', 'newFields'];
  // Some applications don't compile correctly with the usual static syntax.
  // Thus implementing targets with standard getters below

  static targets = ['handover', 'container', 'modal', 'BtnContainer']
  static values = {libraryId: Number,
                   alertTitle: String,
                   alertContent: String,
                   confirmText: String,
                   cancelText: String}



  filterHandovers(e) {
    this.search = e.currentTarget.value.toLowerCase();
    this.handoverTargets.forEach((handover) => {
      this.name  = handover.dataset.name.toLowerCase();
      if (!this.name.includes(this.search)) {
        if (!handover.classList.contains('hidden')) {
          handover.classList.add('hidden')
        }
      } else {
        handover.classList.remove('hidden')
      }
    })
  }

  select(e) {
    const classList = e.currentTarget.classList
    if (classList.contains('bg-komin-blue')) {
      classList.remove('bg-komin-blue')
      classList.replace('text-white', 'text-gray-900')
      classList.add('hover:bg-secondary-low')
    } else {
     classList.add('bg-komin-blue')
     classList.replace('text-gray-900', 'text-white')
     classList.remove('hover:bg-secondary-low')
    }
  }

  validate() {
        Swal.fire({
          title: this.alertTitleValue,
          text: this.alertContentValue,
          icon: 'question',
          confirmButtonColor: '#3d52d5',
          confirmButtonText: this.confirmTextValue,
          showCancelButton: true,
          cancelButtonText: this.cancelTextValue,
        }).then((result) => {
        if (result.isConfirmed) {
          this.containerTarget.querySelectorAll('.bg-komin-blue').forEach((handover) => {
            const url = `/handovers/${handover.dataset.id}/library_update?library=${this.libraryIdValue}`
            Rails.ajax({
              url: url,
              type: "patch",
              dataType: 'json',
              success: (data) => {
                if(document.querySelector('.handovergridlist')) {
                  document.querySelector('.handovergridlist').insertAdjacentHTML('afterbegin',data.partial)
                } else {
                  document.querySelector('.emptygrid').insertAdjacentHTML('beforebegin', `<div class="handovergridlist grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">${data.partial}</div>`)
                  document.querySelector('.emptygrid').remove()
                }
                handover.remove()
              },
            })
          });
          this.resetAndCloseModal()
        }
        });
      }


  cancelMove() {
    this.containerTarget.querySelectorAll('.bg-komin-blue').forEach((handover) => {
      if (handover.classList.contains('bg-komin-blue')) {
        handover.classList.remove('bg-komin-blue')
        handover.classList.replace('text-white', 'text-gray-900')
        handover.classList.add('hover:bg-secondary-low')
      }
    })
  }

  resetAndCloseModal() {
    document.querySelector('body').style.overflow= 'visible'
    document.querySelector('html').style.overflow='visible'
    fadeHideElements(this.modalTarget)
  }

}
