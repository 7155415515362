import { Controller } from 'stimulus';
import FilerobotImageEditor from 'filerobot-image-editor';
import { fetchWithToken } from '../application/stimulus_helper';
import { KominUploader } from '../components/komin_uploader';


export default class extends Controller {
  static targets = [ 'image', 'editContainer',
                     'input', 'loader', 'editButton',
                     'expandButton', 'shrinkButton',
                     'pdfpreview', 'pdfbackground',
                     'filePreview', 'opaqueoverlay',
                    'changeFileButton' ]
  static values = {
    // acceptedMimetypes: String,
    // slidableMimetypes: String,
    // customModal: Boolean,
    saveText: String,
    cancelText: String,
    swalEditTitle: String,
    swalEditText: String,
    objectClass: String,
    objectId: Number,
    docIconUrl: String,
    genericIconUrl: String,
    pptIconUrl: String,
    xlsIconUrl: String,
    zipIconUrl: String,
    fileExtension: String,
    fileType: String,
    mediaType: String,
    contentId: Number,
  }

  connect() {
    this.editContainer = this.editContainerTarget;
    // // this.acceptedMimetypes = this.acceptedMimetypesValue.split('&&')
    // this.slidableMimetypes = this.slidableMimetypesValue.split('&&')
    window.addEventListener("dragover", function(e) {
      e = e || event
      e.preventDefault()
    }, false);
    window.addEventListener("drop", function(e) {
      e = e || event;
      e.preventDefault();
    }, false);
    if (this.imageTarget.getAttribute('src') === '') {
      this.imageTarget.classList.replace('z-30', 'z-10')
      this.opaqueoverlayTarget.classList.add('hidden')
    }
    this.reader = new FileReader();
    this.reader.onload = (ev) => {
      this.imageTarget.src = ev.currentTarget.result
      hideElements(this.pdfpreviewTarget, this.pdfbackgroundTarget)
      this.imageTarget.classList.replace('z-10', 'z-30')
      this.opaqueoverlayTarget.classList.remove('hidden')
      this.#switchBackGroundColor()
    }
    this.#displayFromFile()
    this.#displayEditButton()
    this.#listenToSrcChanges()
    this.#switchBackGroundColor()
  }

  #displayFromFile() {
    if(this.fileTypeValue !== 'image') {
      if (!this.fileTypeValue) {
        this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget, this.editButtonTarget)
      } else if (this.fileExtensionValue === 'pdf') {
        this.#previewPDF()
      } else {
        this.#previewFile(`application/${this.fileExtensionValue}`)
      }
    }
    this.#displayShrinkExpandButton()
  }

  #previewFile(mime) {
    const iconUrl = this.#determineIconURL(mime)
    this.filePreviewTarget.classList.remove('hidden')
    hideElements(this.pdfpreviewTarget, this.pdfbackgroundTarget, this.opaqueoverlayTarget)
    this.imageTarget.classList.replace('z-30', 'z-10')
    this.filePreviewTarget.querySelector('img').src = iconUrl
    this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget, this.editButtonTarget)
    this.#enableButtons(this.changeFileButtonTarget)
  }

  #previewPDF() {
    showElements(this.pdfpreviewTarget, this.pdfbackgroundTarget)
    hideElements(this.opaqueoverlayTarget, this.filePreviewTarget)
    if (document.querySelector(`.display-pages-form-field[data-content-id="${this.contentIdValue}"]`)) {
      showElements(document.querySelector(`.display-pages-form-field[data-content-id="${this.contentIdValue}"]`))
    }
    this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget, this.editButtonTarget)
    this.#enableButtons(this.changeFileButtonTarget)
  }

  openOSWindow(e) {
    if (e.currentTarget.getAttribute('disabled')) {
      return
    } else {
      this.inputTarget.click()
    }
  }

  changeFile() {
    this.inputTarget.click()
  }

  expand(e) {
    if (e.currentTarget.getAttribute('disabled')) {
    return
    } else {
    if (this.imageTarget.classList.contains('object-cover')) {
      this.imageTarget.classList.replace('object-cover','object-scale-down')
    } else {
      this.imageTarget.classList.replace('object-scale-down','object-cover')
    }
    this.#displayShrinkExpandButton()
  }
  }

  dropFile(event) {
    if (event.dataTransfer.items) {
      var file = event.dataTransfer.items[0].getAsFile()
    } else {
      var file = event.dataTransfer.files[0]
    }

    if (file) {
       this.#insertFile(file)
    }
  }

  removePhoto(event) {
    const url = event.params.detachFilePath
    Swal.fire({
      title: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.title'),
      text: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.text'),
      icon: 'question',
      confirmButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.confirm_button_text'),
      showCancelButton: true,
      cancelButtonText: I18n.t('js.sweet_alerts.droppable_editable_input.remove_photo.cancel_button_text')
    }).then((result) => {
      if (result.isConfirmed) {

        this.imageTarget.src = ''
        this.inputTarget.files = null

        if (url) {
          fetchWithToken(url, {
            method: 'PATCH'
          })
            .then(response => response.json())
            .then((data) => {
              const detachedEvent = new CustomEvent(`content:file-deleted`, {
                detail: {
                  id: this.objectIdValue,
                  class: this.objectClassValue
                }
              });
              document.dispatchEvent(detachedEvent)
            })
        }
        this.#switchBackGroundColor()
      } // else if (result.dismiss === Swal.DismissReason.cancel) {}
    })
  }

  openEditor(e) {
    if (e.currentTarget.getAttribute('disabled')) {
      return
     } else {
      fadeShowElements(this.editContainer)
      document.body.style.overflow = 'hidden'
      this.#buildEditor()
     }
  }

  displayFileInPreviewObject() {
    const file = this.inputTarget.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      if (/image/.test(file.type)) {
        this.reader.readAsDataURL(file)
        this.fromCanvas = false
        hideElements( this.filePreviewTarget, this.pdfpreviewTarget, this.pdfbackgroundTarget)
      }  else if (this.slidableMimetypes.includes(file.type)) {
          if (file.type === 'application/pdf') {
            this.pdfpreviewTarget.setAttribute('data', `${url}#toolbar=0&zoom=30`)
            this.#previewPDF()
          } else {
            this.#previewFile(file.type)
            if (document.querySelector(`.display-pages-form-field[data-content-id="${this.contentIdValue}"]`)) {
              showElements(document.querySelector(`.display-pages-form-field[data-content-id="${this.contentIdValue}"]`))
            }
            this.filePreviewTarget.querySelector('p').innerText = file.name
          }
        this.prepareSlideShow(file, url)
      }
      else {
        this.#previewFile(file.type)
        this.filePreviewTarget.querySelector('p').innerText = file.name
      }
    }
    this.#displayShrinkExpandButton()
    this.#switchBackGroundColor()
    this.application.getControllerForElementAndIdentifier(this.element.closest('.content-writing-modal'), 'media-creation--submit').revealButtons()
    //dispatchCustomEvent('files:added')
  }

  prepareSlideShow(file, url) {
    const waitingForTheWorms =  document.querySelector(`.content-preview-loader[data-content-id="${this.contentIdValue}"]`)
    const waitingMsg = document.querySelector(`.waiting-container[data-content-id="${this.contentIdValue}"]`)
    if (waitingForTheWorms) {
      if(waitingForTheWorms.querySelector('.waiting-preview')) {
        hideElements(document.querySelector(`.writing-content-modal-button[data-content-id="${this.contentIdValue}"]`))
        if (file.type === 'application/pdf') {
          waitingForTheWorms.querySelector('.waiting-preview').setAttribute('data', `${url}#toolbar=0&zoom=30`)
          showElements(waitingForTheWorms.querySelector('.waiting-preview'))
        } else {
          const iconUrl = this.#determineIconURL(`application/${this.fileExtensionValue}`)
          waitingForTheWorms.querySelector('.mime-image').src = iconUrl
          showElements(waitingForTheWorms.querySelector('.waiting-preview-office'))
        }
      }
      showElements(waitingForTheWorms)
    }
    if (waitingMsg) {
      showElements(waitingMsg)
    }
    this.uploadFile(this.inputTarget, file)
  }

  uploadFile(input, file) {
    const url = input.dataset.directUploadUrl
    const my_uploader = new KominUploader(file, url, this.contentIdValue)
    my_uploader.start(input)
  }

  #determineIconURL(type) {
    var url=""
    switch (type) {
      case 'application/msexcel' :
        url = this.xlsIconUrlValue
        break;
      case 'application/mspowerpoint' :
        url = this.pptIconUrlValue
        break;
      case 'application/msword' :
        url = this.docIconUrlValue
        break;
      case 'application/zip' :
        url = this.zipIconUrlValue
        break;
      default:
        url = this.genericIconUrlValue;
    }
    return url
  }

  #listenToSrcChanges() {
    const observer = new MutationObserver((changes) => {
      changes.forEach(change => {
        if (change.attributeName.includes('src')) {
            this.#displayEditButton()
            this.#displayShrinkExpandButton()
          }
        });
    });
    observer.observe(this.imageTarget, { attributes : true });
  }

  #displayEditButton() {
    if (this.fromCanvas) {
      this.#disableButtons(this.editButtonTarget)
    } else {
       !this.#fileIsEditable() ? this.#disableButtons(this.editButtonTarget) : this.#enableButtons(this.editButtonTarget)
    //  this.editButtonTarget.classList.toggle('hidden', !this.#fileIsEditable())
    }
  }

  #disableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.classList.add('hidden')
      // button.setAttribute('disabled', true)
      // button.classList.replace('bg-komin-blue', 'bg-komin-gray')
      // button.classList.remove('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }

  #enableButtons() {
    Array.from(arguments).flat().forEach((button) => {
      button.classList.remove('hidden')
      // button.removeAttribute('disabled')
      // button.classList.replace('bg-komin-gray', 'bg-komin-blue')
      // button.classList.add('hover:border-komin-cyan-medium', 'cursor-pointer' )
    })
  }

  #displayShrinkExpandButton() {
    if (this.mediaTypeValue === 'file') {
      hideElements(this.expandButtonTarget, this.shrinkButtonTarget)
    } else {
      this.expandButtonTarget.classList.remove('hidden')
      this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget)
      //this.#disableButtons(this.expandButtonTarget, this.shrinkButtonTarget, this.changePicButtonTarget)
      this.shrinkButtonTarget.classList.add('hidden')
      if (this.imageTarget.getAttribute('src') && this.imageTarget.classList.contains('object-scale-down')) {
        this.expandButtonTarget.classList.remove('hidden')
        this.shrinkButtonTarget.classList.add('hidden')
        this.#enableButtons(this.expandButtonTarget)
        //this.#enableButtons(this.expandButtonTarget, this.changePicButtonTarget)
      } else if (this.imageTarget.getAttribute('src') && this.imageTarget.classList.contains('object-cover')) {
        this.expandButtonTarget.classList.add('hidden')
        this.shrinkButtonTarget.classList.remove('hidden')
        this.#enableButtons(this.shrinkButtonTarget)
        //this.#enableButtons(this.shrinkButtonTarget, this.changePicButtonTarget)
      }
    }
  }

  #fileIsEditable() {
    const img = new Image();
    img.src = this.imageTarget.src;

    if (img.src.endsWith('.svg')) {
      this.#disableButtons(this.editButtonTarget, this.changeFileButtonTarget)
      return false
    } else if (img.complete) {
      this.#enableButtons(this.editButtonTarget, this.changeFileButtonTarget)
      return true
    } else {
      img.onload = () => {
        this.#enableButtons(this.editButtonTarget, this.changeFileButtonTarget)
        return true
      };
      img.onerror = () => {
        return false
        this.#disableButtons(this.editButtonTarget, this.changeFileButtonTarget)
      };
    }
  }

  #closeEditor() {
    fadeHideElements(this.editContainer)
    document.body.style.overflowY = 'visible'
    if (this.editor) this.editor.terminate();
  }

  #buildEditor() {
    const filerobotImageEditor = new FilerobotImageEditor(
      this.editContainer,
      this.#editorConfig()
    );

    this.editor = filerobotImageEditor

    // the default save button is hidden with a css rule on .FIE_topbar-save-button
    this.editor.render({
      onClose: (closingReason) => {
        this.#closeEditor()
      },
      onModify: () => {
        this.#displayCustomSaveButton()
      }
    })
    this.editContainer.querySelectorAll('button').forEach((e) => {
      e.setAttribute('type', 'button')
    })
  }

  #displayCustomSaveButton() {
    const button = document.createElement('button')
    const originalButton = this.editContainer.querySelector('.FIE_topbar-save-button')

    button.innerHTML = 'Save'
    button.classList.add('btn', 'secondary', 'leading-5', 'btn-md')

    if (originalButton) {
      originalButton.parentNode.insertBefore(button, originalButton.nextSibling);
      originalButton.remove()
    }

    button.addEventListener('click', this.#applyEditchanges.bind(this))
  }

  #applyEditchanges(event) {
    event.stopPropagation()
    event.preventDefault()

    Swal.fire({
      title: this.swalEditTitleValue,
      text: this.swalEditTextValue,
      icon: 'question',
      confirmButtonText: this.saveTextValue,
      showCancelButton: this.cancelTextValue,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          var data = this.editor.getCurrentImgData()
        } catch (error) {
          displayCatchedError(error, { message: 'The file is too big' })
          return
        }
        const imageBase64 = data.imageData.imageBase64
        const blobExtension = data.imageData.extension

        this.#buildNewFile(imageBase64, blobExtension)
        this.#closeEditor()
      } // else if (result.dismiss === Swal.DismissReason.cancel) {}
    })
  }

  async #buildNewFile(base64url, extension) {
    await fetch(base64url)
      .then((response) => response.blob())
      .then((blobData) => {
        const newFile = this.#createFileFromBlob(blobData, extension)
        this.#insertFile(newFile)
      })
  }

  #createFileFromBlob(blob, extension) {
    return new File([blob], `${new Date().getTime()}.${extension}`,{ type: `image/${extension}`, lastModified:new Date().getTime()});
  }

  #editorConfig() {
    const c = document.createElement('canvas')
    c.height = this.imageTarget.naturalHeight
    c.width = this.imageTarget.naturalWidth
    const ctx = c.getContext('2d')

    ctx.drawImage(this.imageTarget, 0, 0, c.width, c.height)
    const base64String = c.toDataURL()

    const { TABS, TOOLS } = FilerobotImageEditor;
    return {
      source: base64String,
      annotationsCommon: {
        fill: '#ffAA13',
      },
      Text: {
        text: 'Your text here'
      },
      Rotate: {
        angle: 90,
        componentType: 'slider'
      },
      Crop: {
        presetsItems: [
          {
            titleKey: 'Square',
            descriptionKey: '1:1',
            ratio: 1 / 1,
          },
        ],
      },
      tabsIds: [ TABS.ANNOTATE, TABS.ADJUST ],
      defaultTabId: TABS.ANNOTATE,
      defaultToolId: TOOLS.TEXT,
    };
  }

  #insertFile(file) {
    var list = new DataTransfer();

    if (!this.acceptedMimetypes.includes(file.type)) {
      Swal.fire({
        title: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.title'),
        text: I18n.t('js.sweet_alerts.droppable_editable_input.insert_file.wrong_file_type.text', { file_types: this.acceptedMimetypes }),
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else if (file.size >= 22009610) {
      Swal.fire({
        title: `L'image que vous souhaitez télécharger est trop lourde (maximum 20Mo).`,
        text: `Votre image fait ${this.#bytesToHumanString(file.size)}`,
        icon: 'warning',
        confirmButtonColor: '#3d52d5',
        timer: 3000
      })
    } else {
      list.items.add(file)

      this.inputTarget.files = list.files
      this.displayFileInPreviewObject()
      this.#closeEditor()
    }
  }

  #switchBackGroundColor() {
    const value = this.imageTarget.getAttribute('src') === '' ? 0 : 1
    this.imageTarget.style.opacity = value
  }

  #bytesToHumanString(size) {
    const i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  #buildCustomModal() {
    const shallowNode = document.createElement('div')
    const modalHTML = `<div class="hidden quick-fade-in fixed inset-0 p-16 overflow-y-auto flex items-center justify-center w-full h-full bg-opacity-75 bg-komin-lightgray" aria-labelledby="modal-title" role="dialog" aria-modal="true"
         data-modal-target="modal"
         data-droppable-editable-input-target="editContainer"
         style="z-index: 90;">
    </div>`
    shallowNode.innerHTML = modalHTML
    const modal = shallowNode.firstChild
    document.body.insertAdjacentElement('beforeend', modal)
    return modal
  }
}
