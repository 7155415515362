import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import Swal from 'sweetalert2'
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [
    'sections', 'contents', 'chapterProgress', 'contentTitle', 'fireworksContainer',
    'sectionReadCount', 'sectionViewCount', 'contentViewCount', 'sectionReadCountContainer', 'chapterNumber', 'nextSectionButton'
  ]

  static values = { sharingId: Number }

  connect() {
    if (this.sharingIdValue) {
      this.channel = consumer.subscriptions.create(
        { channel: "ReadingProgressChannel", id: this.sharingIdValue },
        { received: (data) => {
            // TODO link to handover card?
           this.updateReadStatus(data)
          }
        }
      )
    }
  }

  markAsReadAndCountView(event) {
    const target = event.currentTarget
    this.countView(target)
    if (target.dataset.isRead === "true" || target.dataset.isReader === 'false') { return }
    this.sendReadRequest(target)
  }



  triggerCountEvent(event) {
    const target = event.currentTarget
    this.countView(target)
  }

  countView(target) {
  //  const target = event.currentTarget
    Rails.ajax({
      url: `/contents/${target.dataset.contentId}/count`,
      type: "post",
      dataType: 'json',
      success: (data) => {
        const sectionViewCount = this.sectionViewCountTargets.find(e => e.dataset.sectionId == data.response.section_id)
        if (sectionViewCount) {
          sectionViewCount.innerText = data.response.section_content_views
        }
      },

    })
  }

  sendReadRequest(target) {
    Rails.ajax({
      url: `/content_sections/${target.dataset.contentSectionId}/read`,
      type: "post",
      dataType: 'json',
      success: (data) => {
        target.setAttribute('data-is-read', 'true')
        //this.updateReadStatus(data)

      },

    })
  }

  updateReadStatus(data) {

    // TODO handle model errors
    if (data.response.section !== undefined) {
      const sectionReadCount = this.sectionReadCountTargets.find(e => e.dataset.sectionId == data.response.section.id)
      const sectionReadCountContainer = this.sectionReadCountContainerTargets.find(e => e.dataset.sectionId == data.response.section.id)
      const chapterNumber = this.chapterNumberTargets.find(e => e.dataset.chapterId == data.response.chapter.id)
      if (data.response.content.read) {
        this.displayReadNotice(data.response.content.id)
      }
      sectionReadCount.innerHTML = data.response.section.number_of_read_contents
      if (data.response.section.read) {
        sectionReadCountContainer.dataset.status = 'read'
      }
      if (data.response.chapter.read) {
        // We unlock the next chapter
        const nextChapterNumber = this.chapterNumberTargets.find(e => e.dataset.chapterId == data.response.chapter.next_chapter_id)
        if (nextChapterNumber && nextChapterNumber.dataset.locked === 'true') {
          nextChapterNumber.dataset.locked = 'false'
        }

        // We unlock the next section button
        const nextSectionButton = document.getElementById(`next-section-button-${data.response.section.id}`)

        if (nextSectionButton && nextSectionButton.disabled) {
          nextSectionButton.disabled = false
          // We remove the first svg (lock)
          nextSectionButton.querySelector('svg').remove()
          // We remove the last span (tooltip workaround) (see Button::Component.rb)
          nextSectionButton.querySelector('span#tooltip-disabled-span').remove()
        }

        chapterNumber.classList.add('read')
      }
    }
  }

  markSectionAsRead(e) {
    Rails.ajax( {
      url: `/sections/${e.currentTarget.dataset.sectionId}/mark_as_read`,
      type: "post",
      dataType: 'json',
      success: (data) => {
        if (data.alert) {
          Swal.fire({
            title: 'Quizz!',
            text: data.alert,
            icon: 'question',
            confirmButtonColor: '#3d52d5',
            timer: 3000
          })
        }
        //this.updateReadStatus(data)
      },
      error: (data) => {console.warn(data)}
    })
  }

  addFireworksEventToNextButton(sectionId) {
    const btn = this.nextSectionButtonTargets.find(e => parseInt(e.dataset.sectionId, 10) ===  sectionId)

    btn.addEventListener('click', (event) => {
      this._startFireworks()
    }, {once: true})
  }

  displayReadNotice(id) {
    const notice = this.element.querySelector(`#content-read-notice-${id}`)
    // notice will be null when a content has already been read...
    if(notice) {
      const url = notice.src
      notice.src = ''
      notice.src = url
      showElements(notice)
    }
  }

  _startFireworks() {
    showElements(this.fireworksContainerTarget)
    this.fireworks = new Fireworks(this.fireworksContainerTarget, this._fireworksOptions())
    this._toggleOverflow(true)

    this.fireworks.start()
  }

  _closeFireworks() {
    this.fireworks.clear()
    hideElements(this.fireworksContainerTarget)
    this._toggleOverflow(false)
  }

  endFireworks(event) {
    this._closeFireworks()
  }

  _toggleOverflow(value) {
    document.querySelector('body').classList.toggle('overflow-hidden', value)
  }

  _fireworksOptions() {
    return {
      hue: {
        min: 0,
        max: 360,
      },
      rocketsPoint: 50,
      opacity: 0.5,
      acceleration: 1.02,
      friction: 0.97,
      gravity: 1.5,
      particles: 50,
      trace: 3,
      explosion: 4,
      autoresize: true,
      mouse: {
        click: false,
        move: false,
        max: 3,
      },
      boundaries: {
        visible: false,
        x: 50,
        y: 50,
        width: window.innerWidth,
        height: window.innerHeight,
      },
      delay: {
        min: 30,
        max: 60,
      },
      brightness: {
        min: 50,
        max: 80,
        decay: {
          min: 0.015,
          max: 0.03,
        },
      },
      flickering: 50,
      intensity: 30,
      traceSpeed: 10,
      lineWidth: {
        explosion: {
          min: 0,
          max: 0,
        },
        trace: {
          min: 0,
          max: 0,
        },
      },
      lineStyle: 'round',
      sound: {
        enabled: false,
        files: [ 'explosion0.mp3', 'explosion1.mp3' ],
        volume:  { min: 1, max: 2 },
      }
    }
  }
}
